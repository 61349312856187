const { instance } = require('./../../../_axios.js');
import SyncHelper from './SyncHelper'

let current = 0
export default (context) => {
    current += 1
    if(current>=context.testConfig.AUTOSAVE_INTERVAL){
        silentSync(context)
        current = 0
    }
}

const silentSync = (context) => {
    instance.post('/test/sync', SyncHelper.SyncPayload(context))
    .then(data => {  console.log('Auto Saved!') })
    .catch(err =>  console.log(err) )
}