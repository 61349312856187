<template>
<ul>
    <li v-for="(qa, qind) in attachments" :key="qind">
        <audio v-if="qa.type.startsWith('audio')" controls><source :src="qa.src" :type="qa.type" /></audio>
        <video v-if="qa.type.startsWith('video')" controls style="max-width: 100%; max-height: 300px"><source :src="qa.src" :type="qa.type" /></video>
        <img v-if="qa.type.startsWith('image')" :src="qa.src" style="max-width: 100%" :alt="qa.name"/>
        <PdfViewer v-if="qa.type.startsWith('application/pdf')" :docPath="qa.src" />
    </li>
    <li class="text-center title" v-if="attachments.length<1 && !hideEmptyMessage">There are no attachments for this test!</li>
</ul>
</template>
<style scoped>
ul{
    list-style: none;
}
</style>
<script>
import PDFJS from './PDFJS.vue'

export default {
    data() {
        return {
            publicPath: process.env.BASE_URL
        }
    },
    props: {
        attachments: {
            type: Array,
            default: () => []
        },
        hideEmptyMessage: {
            type: Boolean,
            default: false
        }
    },
    components:{
        'PdfViewer': PDFJS
    }
}
</script>