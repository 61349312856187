import {auth, user } from './../initialValues'
import { clearToken, tokensToUser } from './../utils/tokenManager'
  
export default  {
    state: () => ({ 
        auth,
        user,
    }),
    mutations: { 
        set_auth(state, bool) {
            state.auth = bool
        },
        set_user(state, user) {
            state.user = user
        },
        update_user(state, user) {
            for(var x in user) {
                state.user[x] = user[x]
            }
        },
        set_mp(state, meritpoints) {
            state.user.meritpoints = meritpoints
        },
        deduct_mp(state, cost){
            state.user.meritpoints = state.user.meritpoints-cost
        },
    },
    actions: { 
        setAuthenticated({commit}, data){
            commit('set_user', tokensToUser(data))
            commit('set_auth', true)
        },
        clearAuthenticated({commit,state}){
            var user = {}
            for(var i in state.user) user[i] = ''
                user.meritpoints = 0
                commit('set_user', user)
                commit('set_auth', false)
                commit('set_bookmarks', [])
                commit('set_todos', [])
            },
        clearToken(){
            clearToken()
        },
        showWelcome(){

        }
    },
    getters: { 
        user: state => {
            return state.user
        },
        auth: state => {
            return state.auth
        },
    }
  }