<template>
<v-form v-model="valid">
    <v-container>
        <v-card>
            <v-card-text>
                <v-layout column>
                    <v-flex xs12>
                        <h4>Submit New Query</h4>
                    </v-flex>
                    <v-flex xs12>
                        <v-select :items="queryTypes" item-text="name" item-value="_id" label="Query Type*" v-model="formData.query_type_id" :disabled="loading || submitting"></v-select>
                    </v-flex>
                     <v-flex xs12>
                        <v-select :items="subTypes" item-text="name" item-value="id" label="Query Sub Type*" v-model="formData.query_sub_type_id" :disabled="loading || submitting"></v-select>
                    </v-flex>
                    <v-flex xs12>
                        <v-select :items="subjects" item-text="name" item-value="id" label="Subject (Optional)" v-model="formData.subject_id" :disabled="loading || submitting"></v-select>
                    </v-flex>
                    <v-flex xs12>
                    <v-textarea
                        solo
                        v-model="formData.query"
                        :rules="requiredRule"
                        label="Write Query"
                        hint="Type your message here"
                        required
                        :disabled="loading || submitting"
                    ></v-textarea>
                    </v-flex>
                    <v-flex xs12>
                        <label for="">Attachment</label> <br/>
                        <input type="file" name="file" id="file" accept=".pdf,.png,.jpg,.jpeg" ref="file">
                    </v-flex>
                    <v-flex xs12 style="justify-content: space-between; display: flex; margin-top: 20px;">
                        <v-btn :disabled="loading || submitting" @click="submit" color="primary" >SUBMIT</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn :disabled="loading || submitting" @click="backtoList()">BACK</v-btn>
                    </v-flex>
                
                </v-layout>
            </v-card-text>
      </v-card>

    <v-dialog
        v-model="submitting"
        persistent
        width="300">
        <v-card
        color="primary"
        dark>
        <v-card-text>
            Submitting please wait ...
            <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
            ></v-progress-linear>
        </v-card-text>
        </v-card>
    </v-dialog>

    </v-container>
</v-form>
</template>
<script>
import { mapGetters } from 'vuex'
import { instance } from '../../_axios'
export default {
    data(){
        return {
            loading: true,
            submitting: false,
            queryTypes: [],
            subjects: [],
            formData: {
                query: '',
                query_type_id: '',
                query_sub_type_id: '',
                query_type_name: '',
                query_sub_type_name: '',
                subject_id: '',
                subject_name: '',
                attachment: null
            },
            requiredRule: [
                v => !!v || 'Field is required',
                v => v.length > 10 || 'Field is too short (min 10 character)'
            ],
            valid: false
        }
    },
    mounted(){
        this.fetchQueryTypes()
    },
    methods: {
        backtoList() {
            this.$router.push('/queries')
        },
        async fetchQueryTypes(){
            try {
                this.queryTypes = (await instance.get('/query-types')).data
            } catch (error) {
                console.log(error)
            }
            try {
                this.subjects = (await instance.get('/subjects')).data
            } catch (error) {
                console.log(error)
            }
            this.loading = false
            return []
        },
        fetchAttachmentUrl(){
            return ""
        },
        uploadAttachment(){
            
        },
        async submit(){
            if(!this.valid){
                return alert("Please resolve all the fields!")
            }
            this.submitting = true
            if(this.$refs.file.files && this.$refs.file.files.length >= 1){
                let file = this.$refs.file.files[0]
                try {
                    let uploadUrl = await instance.post('/queries-attachment-url', {contentType: file.type})
                    this.formData.attachment = uploadUrl.data.url


                    var options = {
                            headers: {
                                'Content-Type': file.type,
                                'x-amz-acl': 'public-read'
                            }
                        };
                    await instance.put(this.formData.attachment, file, options)
                } catch (error) {
                    this.submitting = false
                    return alert("File upload failed!")
                }
            }

            let qt = this.queryTypes.find(v => v._id == this.formData.query_type_id)
            this.formData.query_type_name = qt.name

            let qst = qt.sub_types.find(v => v.id == this.formData.query_sub_type_id )
            this.formData.query_sub_type_name = qst.name

            let subject = this.subjects.find(v => v.id == this.formData.subject_id)
            this.formData.subject_name = subject ? subject.name : ''

            try {
                if(this.formData.attachment) this.formData.attachment = this.formData.attachment.split('?')[0]
                let resp = await instance.post('/submit-query', this.formData)
                this.$store.commit('open_snackbar',{text:'Query submitted!', color: 'green'})
                this.$router.push('/queries')
            } catch (error) {
                this.$store.commit('open_snackbar',{text:'Query submit failed!', color: 'red'})
            } finally{
                this.submitting = false
            }
            console.log(this.formData)

        },
        validate(){
            return this.formData.query.length >= 10 && this.formData.query_type_id
        }
    },
    computed: {
        ...mapGetters(['user']),
        subTypes(){
            if(this.formData.query_type_id){
                let queryType = this.queryTypes.find(v => v._id == this.formData.query_type_id)
                return queryType.sub_types || []
            }
            return []
        }
    },
}
</script>