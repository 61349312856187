<template>
    <v-container grid-list-lg class="pa-0" >
        
        <v-layout row wrap justify-center >
            <v-flex xs12><page-title>TIMETABLE</page-title></v-flex>
            <!--<v-flex xs12 >
                <h4 class="headline text-xs-center pb-2 text-uppercase">TIMETABLE</h4>
            </v-flex>-->
            <v-flex v-if='loading'>
                <v-card-text class="text-xs-center">
                    {{loadingMessage}}
                    <v-progress-linear indeterminate color="info" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-flex>
            <v-flex v-else>
                <span style="float:right" class="hidden-sm-and-down">{{day}}, {{new Date(date).toLocaleDateString()}}</span>
                <!--<v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>-->
                <v-card elevation="0">
                    <table class="hidden-sm-and-down">
                        <tr >
                            <td>Days</td>
                            <td v-for="od in 10" :key="od" class="slot-header">Slot {{od}}</td>
                        </tr>
                        <tr v-for="oday in days" :key="oday" :class="{'today':oday==day}">
                            <td>{{oday}}</td>
                            <td v-for="od in 10" :key="od" @click="showJoin(oday==day, oday, od)"> <dayslot :schedules="schedules" :slot-index="od" :day="oday"></dayslot> </td>
                        </tr>
                    </table>
                    <v-card-text class="hidden-md-and-up">
                        <!--<v-select :items="days" label="Day" :value="selectedDay" @change="(v) => selectedDay = v"></v-select>-->
                        <v-layout row>
                            <v-flex xs2 v-for="day in days.filter((v,index) => index <=5)" :key="day">
                                <v-btn fab small color="primary" :outline="selectedDay!==day" @click.stop="selectedDay = day"><small>{{ day.substring(0,3) }}</small></v-btn>
                            </v-flex>
                            <v-flex xs12>
                                <v-divider></v-divider>
                            </v-flex>
                        </v-layout>
                        <ul id="list">
                            <li v-for="od in 10" :key="od"  @click="showJoin(selectedDay==day, selectedDay, od)" class="text-xs-center">
                                <small class="caption" style="opacity: 0.5;" >SLOT {{od}}</small>
                                <dayslot :schedules="schedules" :slot-index="od" :day="selectedDay"></dayslot>
                            </li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-flex>

    <v-dialog v-model="showDialog.bool" width="500">
      <v-card v-if="showDialog.schedule">
        <v-card-title
          :class="`headline white--text info`"
          primary-title
        >
          {{showDialog.schedule.subject_name}}
        </v-card-title>

        <v-card-text class="subheading">
          Teacher(s): <span style="float:right"> {{(showDialog.schedule.teachers || []).map(teac => teac.name).join(', ')}}</span><br>
          Starts at: <span style="float:right">{{showDialog.schedule.time}} </span><br>
          Duration: <span style="float:right">{{showDialog.schedule.duration}}</span> <br>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
            <v-btn
            color="info"
            flat
            round
            @click="showDialog.bool = false"
          >
            CLOSE
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="info"
            round
            :disabled="!!showDialog.schedule.canceled_at"
            _to="`/live-class/${showDialog.schedule._id}`"
            @click="joinClass(showDialog.schedule)"
          >
            {{!!showDialog.schedule.canceled_at?'CANCELED':'JOIN'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
const {instance} = require('./../../_axios.js');
import DaySlot from './DaySlot'
import Schedule from './Schedule';
const days = Schedule.days
export default {
    data() {
        return {
            schedules: [],
            todaysSchedules: [],
            day: 'Monday',
            selectedDay: 'Monday',
            loadingMessage: 'Loading please wait..',
            loading: true,
            error: false,
            days,
            date: null,
            showDialog:{
                bool: false,
                day: '',
                slot: -1,
                schedule: null
            }
        }
    },
    mounted(){
        this.fetchSchedule()
    },
    methods: {
        async fetchSchedule(){
            try {
                let temp = (await Schedule.fetch())
                this.schedules = temp.data.schedules.map(v => {return {...v, order: parseInt(v.time.split(':')[0])}})
                this.day  = temp.data.day
                this.selectedDay = temp.data.day+''
                this.date = temp.data.date
                this.todaysSchedules = this.schedules.filter((ss) => ss.day == this.day)
                console.log(this.day, this.todaysSchedules)

            } catch (error) {
                error = error.message
            }
            this.loading = false
        },
        showJoin(bool, day, slot){
            this.showDialog.schedule = (this.schedules || []).find((v) => v.slot == slot && v.day == day) 
            if(!this.showDialog.schedule) return 
            if(bool==false) this.$store.commit('open_snackbar',{color:'info' ,text: `Class can be joined only on ${this.selectedDay}`})
            
            this.showDialog.bool = bool
            this.showDialog.day = day
            this.showDialog.slot = slot
        },
        joinClass(schedule){
            window.open(window.location.origin+'/live-class.html?classid='+schedule._id+'&endpoint='+process.env.VUE_APP_ROOT_API)
        }
    },
    components:{
        'dayslot': DaySlot
    },
    computed: {
        items(){
            return [
                {
                text: 'Home',
                disabled: false,
                href: '/'
                },
                 {
                text: 'Timetable',
                disabled: true,
                href: '/timetable',
                tag: 'span'
                }
                // {
                // text: 'Live Class',
                // disabled: true,
                // href: '/live-class',
                // tag: 'span'
                // }
            ]
        }
    }
}
</script>
<style scoped>
table{
    width: 100% !important;
    border-collapse: collapse;
}
td {
    border: 1px solid #eee;
    padding: 10px;
}
tr:first-child, td:first-child{
    color: #6c757d !important;
    text-transform: uppercase;
    box-shadow: 1px 1px 2px #eee;
    background-color:rgb(250 251 252);
}

td:not(:first-child){
    text-align:center
}
tr:not(:first-child) td:not(:first-child){
    cursor: not-allowed
}
tr.today:not(:first-child) td:not(:first-child){
    cursor: pointer
}

tr.today{
    background: rgb(250 251 252);
    border: 1px solid #cbdce9;
    border-width: 2px;
}

#list{
    list-style: none;
    margin: 0;
    padding: 0;
}
#list> li{
    border: 1px solid #eeeeee8c;
    padding: 5px 2px;
    margin-bottom: 8px;
    box-shadow: 1px 1px #eee;
    border-radius: 5px;
}

.slot-header{
    /*background: primary;
    color: white;*/
    border:none;
}

</style>
