
import FireLog from './Firelog'
import PINGFIREBASE from './PINGFIREBASE'

function cacheBlurCount(context){
  sessionStorage.setItem('blurCount', context.blurCount)
}

export default (that) => {
  
  if(that.testConfig.DETECT_FULLSCREEN && that.user.browser!=='safari'){
    
    window.addEventListener('blur', () => {
      if(that.inTest && !that.pauseProctor){
        PINGFIREBASE(that)
        FireLog(that, 'BLUR_CHANGE')
        if(!document.hasFocus()) {
          that.blurCount += 1
          cacheBlurCount(that)
        }
      }
    })

    document.addEventListener('fullscreenchange', () => {
      if(that.inTest && !that.pauseProctor){
        let notInFullscreen = !(document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement)
        PINGFIREBASE(that)
        FireLog(that, notInFullscreen?'FULL_SCREEN_OUT':'FULL_SCREEN_RESUME')
        if(notInFullscreen) {
          that.blurCount += 1
          cacheBlurCount(that)
        }
      }
    })

    document.addEventListener('keydown', (event) => {
      if(that.inTest && !that.pauseProctor){
        if(event.key=='F11'){
          let notInFullscreen = true
          PINGFIREBASE(that)
          FireLog(that, notInFullscreen?'FULL_SCREEN_OUT':'FULL_SCREEN_RESUME')
        }
      }
    })
  }
  
}