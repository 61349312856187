<template>
<div>
    <!-- color="#26c6da" -->
  <v-card
    class="mx-auto"
    max-width="500">
   <!--  <v-card-title color="red" primary-title>
      <v-icon large left ripple> bookmarks </v-icon>
      <span class="display-1 font-weight-bold">Bookmarks</span>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-btn light right fab small color="transparent"  dark flat><v-icon ripple> delete </v-icon></v-btn>
      </v-fab-transition>
    </v-card-title> -->
    <v-toolbar dark color="orange lighten-2" class="pt-1 pb-1">
    <v-icon large left ripple> bookmarks </v-icon>
    <v-toolbar-title>BOOKMARKS</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items >
      <v-fab-transition >
        <v-btn v-if="selected.length" @click="deleteBookmarks()" outline secondary fab small :loading="loading"><v-icon>delete</v-icon></v-btn>
      </v-fab-transition>
    </v-toolbar-items>
  </v-toolbar>

    <v-card-text style="height: 301px;overflow:auto">
      <div v-if="loading" class="headline font-weight-bold text-xs-center">
          <v-progress-circular :width="3"  color="white"  indeterminate ></v-progress-circular>
          <br><br>Loading ...
      </div>
      <div v-else>
          
          <v-list-tile  v-for="bookmark in bookmarks" :key="bookmark">
            <v-list-tile-action>
              <v-checkbox :disabled="progress" v-model="selected" :value="bookmark._id" :color="selected.indexOf(bookmark._id)>-1?'orange':'white'"></v-checkbox>
            </v-list-tile-action>

            <v-list-tile-content @click="goTo(bookmark)" >
              <v-list-tile-title>{{bookmark.subtopic?bookmark.subtopic.name:bookmark.topic.name}}</v-list-tile-title>
              <v-list-tile-sub-title>{{bookmark.chapter.name}}</v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
          <div v-if="!bookmarks.length" class="pt-4">
            <p class="headline text-xs-center white--text"><v-icon size="4em" color="orange lighten-3" left ripple>bookmarks</v-icon></p>
            <p class="subheading text-xs-center white--text"> No Bookmarks found!</p>
          </div>
      </div>
    </v-card-text>
    <!-- <v-divider></v-divider> -->

   <!--  <v-card-actions>
      <v-list-tile class="grow">
        <v-list-tile-avatar color="grey darken-3">
          <v-img class="elevation-6"
            src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light" ></v-img>
        </v-list-tile-avatar>

        <v-list-tile-content>
          <v-list-tile-title>Evan You</v-list-tile-title>
        </v-list-tile-content>

        <v-layout align-center justify-end >
          <v-fab-transition >
            <v-btn v-if="selected.length" @click="deleteBookmarks()" outline secondary fab small :loading="loading"><v-icon>delete</v-icon></v-btn>
          </v-fab-transition>
        </v-layout>
      </v-list-tile>
    </v-card-actions> -->
  </v-card>
	
</div>
</template>
<script>
import { instance } from './../_axios.js'
import { mapGetters, mapActions } from 'vuex'
export default{
	data(){
    return {
    	loading:  false, // for loading data
      progress: false, // if action progress
      selected: []
    }
	},
	mounted(){
    if(!this.dontFetch) this.init()
	},
	methods:{
    ...mapActions(['errorSnack']),
    init() {
    	this.loading = true
      instance.get('/bookmark/list')
      .then(resp  => this.$store.commit('set_bookmarks', resp.data))
      .catch((e) =>  this.errorSnack({e,b:'Error loading bookmarks!'}) )
      .then(()  =>   this.loading = false )
    },
    toggleAll () {
      if (this.selected.length) this.selected = []
      else this.selected = this.bookmarks.slice()
    },
    deleteBookmarks(){
      this.progress = true
      instance.post('/bookmark/delete',{ids: this.selected})
      .then(resp=> {
        this.$store.commit('delete_bookmarks',this.selected)
        this.selected = []
        this.$store.commit('open_snackbar', {text: `Bookmark${this.selected.length>1?'':'s'} deleted!`, color: 'green'}) 
      })
      .catch((e) => this.$store.commit('open_snackbar', {text: 'Error deleting bookmark!', color: 'red'}) )
      .then(()   => this.progress = false )
    },
    goTo(bookmark){
      let path = `/notes/${bookmark.subject_name}/${bookmark.chapter.name.split(' ').join('-')}/${bookmark.chapter.id}`
      let query = {topic:bookmark.topic.id}
      if(bookmark.subtopic) query.subtopic = bookmark.subtopic.id
      this.$router.push({path,query})
    }
	},
	computed: {
		...mapGetters(['bookmarks','auth'])
	},
  props:{
    dontFetch: {
      type: Boolean,
      default: false
    }
  },
}
</script>
