import firebase from "firebase/app";

export default (context, action) => {
  return context.saveLog({
    qn: context.$store.state.assessment.qi+1, // currently at
    p:  context.$route.path,
    tid:  context.$route.query.id || null,
    utid: context.$route.params.id || null,
    ts: firebase.firestore.FieldValue.serverTimestamp(),
    b:  !document.hasFocus(),
    f:  context.pauseProctor?false:!!document.fullscreen,
    fc: context.faceCount,
    cp: context.pauseProctor,
    iu: context.lastImageProctor || null,
    a: action || ''
  })
}