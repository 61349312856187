<template>
    <div>
        <page-title>{{data.length?'SELECT A SUBJECT':'STUDY MATERIAL'}}</page-title>
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingMessage}}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-container
            v-if='!loading'
            grid-list-lg class="pa-0" >
            <v-layout row wrap justify-center>
                <v-flex xs12 >
                    <!--<h4 class="headline text-xs-center pb-2 text-uppercase"></h4>-->
                </v-flex>
                <v-flex xs12 md3 v-for="(sm,idx) in data" :key="idx" class="mt-4">
                   <sm-item :sm="sm" :idx="idx" @selected="(item) => currentItem = item"></sm-item>
                </v-flex>
                <v-flex xs12 v-if="data.length<1">
                    <page-no-data icon="auto_stories"> NO STUDY MATERIAL PUBLISHED!</page-no-data>
                    <!--<v-alert :value="true" color="info" type="warning"> &nbsp; NO STUDY MATERIAL PUBLISHED YET!</v-alert>-->
                </v-flex>
            </v-layout>
        </v-container>
        <material-view :show="currentItem!=null" :item="currentItem" @close="currentItem=null" @attempt="showFileUploadView()"></material-view>
        <file-upload :show="showFileUpload" @close="showFileUpload = false" @uploadFile="uploadFile" ref="fileUpload" @deleteFile="deleteFile"
            :upload-statuses="uploadStatuses"
            :uploading="uploading"
        ></file-upload>
        <!-- #004D40 -->
    </div>
</template>
<script>
const {instance} = require('./../../_axios.js');
import { mapGetters } from 'vuex'
import MaterialView from './MaterialView'
import StudyMaterialPaginator from './Paginator'
import SMItem from './SMItemBox'
import SMFilter from './SMFilter'
import FileUpload from './FileUpload'
import getCompressed from './../../utils/ImageCompressor'
import SizeConverter from './../../utils/SizeConverter'

export default {
    data(){
        return {
            loading: true,
            uploading: false,
            loadingMessage: 'Loading data please wait...',
            data: [],
            lastPage: 1,
            filters: {
                type: this.$route.query.type || 'All Type',
                subject: this.$route.query.subject,
                sort: 'latest', // latest , oldest
                page: parseInt(this.$route.query.page) || 1,
                subject: this.$route.query.subject,
                sname: this.$route.query.sname
            },
            subjects: [],
            currentItem: null,
            showFileUpload: false,
            uploadStatuses: [],
            // existingUploads: []
        }
    },
    mounted(){
        this.fetchStudyMaterials()
        this.subjects = this.user.subjects
    },
    methods: {
        async fetchStudyMaterials(){
            // instance.
            this.loading = true
            try {
                let temp = (await instance.get('/study-material/list/authorwise', { params: this.$route.query || {} })).data
                this.data = temp.data
                this.lastPage = temp.last
            } catch (error) {
                this.data = []
            }
            this.loading = false
        },
        filterUpdate(){
            this.filters = this.$route.query
            try { this.filters.page = parseInt(this.$route.query.page) || 1 } catch (error) { this.filters.page = 1 }
            this.fetchStudyMaterials()
        },
        uploadFile(files){
            // this.loadingMessage = 'Uploading Data please wait...'
            console.log(this.currentItem, files)

            this.uploadStatuses = []
            this.uploading = true
            this.loading = true
            this.loadingMessage = 'Uploading files please wait...'

            var promises = files.map(async (file, index) => {
                this.uploadStatuses.push({
                    uplaodPercent: 0,
                    uploadMessage: `Uploading <b>${file.name}</b>...`
                })
                var fd = new FormData()
                let contentType = file.type
                
                fd.append('assignment_id', String(this.currentItem._id));
                fd.append('content_type', contentType)
                fd.append('file_name', file.name)
                console.log("HELLo", files, contentType.startsWith('image/'))
                
                if(contentType.startsWith('image/')){ file = await getCompressed(file) }
                var options = {
                    headers: {
                        'Content-Type': contentType, //getContentType(fileType),
                        'x-amz-acl': 'public-read'
                    },
                    onUploadProgress: progressEvent => {
                        this.uploadStatuses[index].uploadPercent = progressEvent.loaded*100/(file.size || 1)
                        this.uploadStatuses[index].uploadMessage = `Uploading <b>${file.name}</b>...  ${this.uploadStatuses[index].uploadPercent.toFixed(2)}% (${SizeConverter.byteToMB(progressEvent.loaded)}/${SizeConverter.byteToMB(file.size)} MB)`
                    }
                };
                console.log('Progress')
                return instance.post(`/presignURLAssignment/${this.currentItem._id}`, fd)
                    .then(resp => {
                        fd.append('fileName', resp.data.fileName)
                        return instance.put(resp.data.signedURL, file, options)
                    })
                    .then(resp => {
                        return instance.post(`/savePresignURLAssignment/${this.currentItem._id}`, fd)
                    })
                    .then(resp => {
                        this.$store.commit('open_snackbar', { text: 'UPLOAD SUCCESSFULL', color: 'green' })
                        this.$refs.fileUpload.data[resp.data.ukey] = resp.data.newData
                        return { success: true, newData: resp.data.newData, type: contentType }
                    })
                    .catch(error => {
                        this.$store.commit('open_snackbar', {text: 'UPLOAD FAILED! PLEASE TRY AGAIN.', color: 'red'})
                        return { success: false, error }
                    })
            })
            
            Promise.all(promises)
            .then(values => {
                this.$refs.fileUpload.state = null
            })
            .catch(error => {
                this.$store.commit('open_snackbar', {text: 'UPLOAD FAILED! PLEASE TRY AGAIN.', color: 'red'}) 
            })
            .finally(() => {
                this.uploading = false
                this.loading = false
            })
        },
        showFileUploadView(){
            this.showFileUpload = true
            this.fetchUploadedFiles()
        },
        async fetchUploadedFiles(){
            try {
                this.loading = true
                this.loadingMessage = 'Loading Data. Please Wait...'
                this.$refs.fileUpload.data = (await instance.post(`/assignment/${this.currentItem._id}/uploads`)).data
            } catch (error) {
                console.log(error)
            }   
             this.loading = false
        },
        async deleteFile({index, ukey}){
            console.log(index, ukey)
            try {
                this.loading = true
                this.loadingMessage = 'Deleting File. Please Wait...'
                const resp = (await instance.post(`/assignment/${this.currentItem._id}/delete`, { fileName: this.$refs.fileUpload.data[ukey][index], ukey })).data
                this.$refs.fileUpload.data[ukey].splice(index, 1)
            } catch (error) {
                
            }
            this.loading = false
        }
    },
    computed:{
        ...mapGetters(['user']),       
    },
    components: {
        'material-view': MaterialView,
        'paginator': StudyMaterialPaginator,
        'sm-item': SMItem,
        'sm-filter': SMFilter,
        'file-upload': FileUpload,
        
    },
    
}

function serialize(object){
    let out = ''
    for (const key in object) {
        if(object[key]!=null){
            out+=key+'='+object[key]+'&'
        }
    }
    return out
}

function contentTypeToKey(contentType){
    if(contentType.startsWith('image/')) return 'iu'
    else if(contentType.startsWith('application/pdf')) return 'pu'
    else if(contentType.startsWith('video')) return 'vu'
    else if(contentType.startsWith('audio')) return 'au'
    else if(contentType.startsWith('application/vnd')) return 'eu'
    else {
        console.error('Unknown File Type')
    }
}


</script>
