<template>
    <v-flex xs12> 
        <v-card elevation="0" color="transparent">
            <v-card-actions>
        <!-- <span><v-icon >filter_list</v-icon> FILTERS</span> -->
        <v-breadcrumbs :items="items" divider=">"></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-menu offset-y class="mr-1">
            <div slot="activator">
                <v-btn
                    :loading="loading"
                    :disabled="loading || !!stype"
                    :outline="filters.type==='All Type'"
                    color="purple darken-4 white--text"
                    round 
                    >{{filters.type || 'All Type'}}  <v-icon >arrow_drop_down</v-icon>
                    
                </v-btn>
            </div>
                <v-list>
                    <v-list-tile
                    v-for="(item, index) in types"
                    :key="index"
                    @click="filterType(item)"
                    ><v-list-tile-title>{{ item }}</v-list-tile-title>
                </v-list-tile>
            </v-list>
        </v-menu>

            <!-- <v-menu offset-y>
            <div slot="activator">
                <v-btn
                    :loading="loading"
                    :disabled="loading"
                    color="orange darken-4 white--text"
                    round 
                    :outline="!filters.sname"
                    >{{filters.sname || 'All Subject'}} <v-icon >arrow_drop_down</v-icon>
                </v-btn>
            </div>
                <v-list>
                    <v-list-tile
                        @click="filterSubject({})"
                        ><v-list-tile-title>All Subject</v-list-tile-title>
                    </v-list-tile>
                    <v-list-tile
                        v-for="(item, index) in user.subjects"
                        :key="index"
                        @click="filterSubject(item)"
                        ><v-list-tile-title>{{ item.name }}</v-list-tile-title>
                    </v-list-tile>
                </v-list>
        </v-menu> -->
            </v-card-actions>
        </v-card>
        
    </v-flex>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            types: [ 'All Type', 'Assignment', 'Home Work', 'Material', 'Video-Lecture' ],
             items: [
                {
                text: 'Home',
                disabled: false,
                href: '/'
                },
                {
                text: this.$route.meta.type?this.$route.meta.type:'Study',
                disabled: false,
                href: this.$route.meta.type?this.$route.meta.type.toLowerCase().replace(" ", '-'):'/study'
                },
                {
                text: this.$route.query.sn +' : '+this.$route.query.tn ,
                disabled: true,
                href: 'breadcrumbs_link_2'
                }
            ],
            stype: this.$route.meta.type
        }
    },
    mounted(){
        if(this.stype){
            this.items.splice(2,1)
        }
    },
    props: {
        loading: {
            type: Boolean,
            default: true
        },
        filters: {
            type: Object,
            default: () => {}
        },
    },
    computed:{
        ...mapGetters(['user']),       
    },
    methods: {
        filterType(val){
            let query = {...this.$route.query, type: val}
            this.$router.push({path: this.$router.path, query})
            console.log(query)
            this.$emit('filterUpdate', query)
        },
        filterSubject(sub){
            this.filters.sname = sub.name
            let query = {...this.$route.query, subject: sub.id, sname: sub.name}
            this.$router.push({path: this.$router.path, query})
            this.$emit('filterUpdate', query)
        }
    }
}
</script>