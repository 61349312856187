import Vue from 'vue'
import Router from 'vue-router'
// import Home from './views/Home'
import HomeB2B from './b2b/views/Home'
import TestMain from './views/TestMain'
import store from './store'
import Auth from './views/Auth/index.vue' 
import Login from './views/Auth/login.vue' 
import ChangePassword from './views/Profile/ChangePassword' 
import TeacherWise from './views/StudyMaterial/TeacherWise'
import Video from './views/Video/Index'
import FeeList from './views/Finance/FeeList'
import LiveClass from './views/LiveClass/Home'
import Attendance from './views/Attendance/Attendance.vue'
// import ClassInside from './views/LiveClass/ClassInside'
import ListMaterials from './views/StudyMaterial/ListMaterials'
import Desk from './views/Desk'
import ResultList from './views/ResultList'
import Submitted from './views/Test/Submitted'
import TestInside from './views/Test/TestInside'
 import TestInsidePartwise from './views/Test/TestInsidePartwise'
//  import TestInsideAIOPDF from './views/Test/TestInsideAIOPDF'
//  import TestInsideNoProctor from './views/TestInsideNoProctor'
import TestDashBoard from './views/TestDashboard'
import TestResult from './views/TestResult'
import TestReview from './views/TestReview'
import TestInstruction from './views/TestInstruction'
import TestPrepare from './views/Test/TestPrepare'
import MarksheetList from './views/MarkSheet/List'
import Marksheet from './views/MarkSheet/View'
import MockTest from './views/MockTest'
import TroubleShootIndex from './views/Troubleshoot/Index'
import CaptureMedia from './views/Troubleshoot/CaptureMedia'
import MyAnswerSheet from './views/MyAnswerSheet/Index'
import MyAnswerSheetView from './views/MyAnswerSheet/View'
import Profile from './views/Profile'
import QueryNew from './views/Query/QueryNew'
import QueryHome from './views/Query/QueryHome'
import QueryView from './views/Query/QueryView'
import Certificates from './views/Certificates/Index'
import CertificateRequest from './views/Certificates/CertificateRequest'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) { return savedPosition } else { return { x: 0, y: 0 } }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeB2B,//process.env.VUE_APP_TYPE==='b2b'?HomeB2B:Home,
      meta: { title: process.env.VUE_APP_NAME+(process.env.VUE_APP_HIDE_POWERED_BY=='true'?'':' | Powered by ThinkMerit'), skipAuth: false }
    },
    // {
    //   path: '/third-party-auth',
    //   name: 'Third Party',
    //   component: ThirdParty,
    //   meta: { title: 'Auth', skipAuth: true }
    // },
    {
      path: '/auth',
      name: 'auth',
      component: Auth,
      meta: { title: 'Auth', skipAuth: true },
      children: [
        {
          path: 'login',
          component: Login,
          meta: { title: 'Login', skipAuth: true },
        },
        // {
        //   path: 'register',
        //   component: () => import('./views/Auth/register.vue' ),
        //   meta: { title: 'Register' }
        // }
      ]
    },
    // {
    //   path: '/password/reset',
    //   name: 'password-reset',
    //   component: () => import('./views/PasswordReset'),
    //   meta: { title: 'PasswordReset' }
    // },
    {
      path: '/password/change',
      name: 'password-change',
      component:  ChangePassword,
      meta: { title: 'PasswordReset' }
    },
    {
      path: '/study',
      name: 'study',
      component:  TeacherWise,
      meta: { title: 'Study Material' }
    },
    {
      path: '/assignment',
      name: 'assignment',
      component:  ListMaterials,
      meta: { title: 'Assignments', type: 'Assignment' }
    },
    {
      path: '/attendance',
      name: 'attendance',
      component:  Attendance,
      meta: { title: 'Attendance' }
    },
    {
      path: '/certificates',
      name: 'certificates',
      component:  Certificates,
      meta: { title: 'Certificates', type: 'certificates' }
    },
    {
      path: '/certificate-request',
      name: 'certificate-request',
      component:  CertificateRequest,
      meta: { title: 'Certificate-Request', type: 'certificate-request' }
    },
    {
      path: '/homework',
      name: 'homework',
      component:  ListMaterials,
      meta: { title: 'Homework', type: 'home work' }
    },
    {
      path: '/video-lecture',
      name: 'video-lecture',
      component: ListMaterials,
      // component:  Video,
      meta: { title: 'Video Lecture', type: 'Video-lecture' }
    },
    {
      path: '/fee-list',
      name: 'fee-list',
      component:  FeeList,
      meta: { title: 'Fee List' }
    },
    {
      path: '/query-new',
      name: 'query-new',
      component:  QueryNew,
      meta: { title: 'New Query' }
    },
    {
      path: '/queries/:id',
      name: 'query',
      component:  QueryView,
      meta: { title: 'Query View' }
    },
    {
      path: '/queries',
      name: 'queries',
      component:  QueryHome,
      meta: { title: 'New Query Home' }
    },
    {
      path: '/timetable',
      name: 'timetable',
      component:  LiveClass,
      meta: { title: 'Timetable' }
    },
    // {
    //   path: '/live-class',
    //   name: 'live-class',
    //   component:  LiveClass,
    //   meta: { title: 'Live Class' }
    // },
    // {
    //   path: '/live-class/:id',
    //   name: 'live-class-inside',
    //   component:  ClassInside,
    //   meta: { title: 'Live Class Inside' }
    // },
    {
      path: '/study/:subject',
      name: 'study-subject',
      component:  ListMaterials,
      meta: { title: 'Study Material' }
    },
    {
      path: '/desk',
      name: 'desk',
      component:  Desk,
      meta: { title: 'Desk' }
    },
    // {
    //   path: '/env',
    //   name: 'ENV',
    //   component: () => import('./views/Environment'),
    //   meta: { title: 'ENV' }
    // },
    {
      path: '/test-results',
      name: 'test-results',
      component:  ResultList,
      meta: { title: 'Result List' }
    },
    // {
    //   path: '/test-analytics',
    //   name: 'test-analytics',
    //   component: () => import('./views/Dashboard/TestAnalytics'),
    //   meta: { title: 'Analytics' }
    // },
    {
      path: '/test-submitted',
      name: 'test-submitted',
      component: Submitted,
      meta: { title: 'Submitted', skipAuth: true }
    },
    {
      path: '/test/:id',
      name: 'test-inside',
      component: TestMain,
      children: [
        {
          path: 'in',
          component:TestInside,
          name: 'test-in',
          meta: { requiresAuth: true,  title: 'Test' }
        },
        {
          path: 'test-in-partwise',
          component:  TestInsidePartwise,
          name: 'test-in-partwise',
          meta: { requiresAuth: true,  title: 'Test' }
        },
        // {
        //   path: 'test-in-aio',
        //   component:  TestInsideAIOPDF,
        //   name: 'test-in-pdf',
        //   meta: { requiresAuth: true,  title: 'Test' }
        // },
        // {
        //   path: 'test-in-third-party',
        //   component:  TestInsideThirdParty,
        //   name: 'test-in-third-pary',
        //   meta: { requiresAuth: true,  title: 'Test' }
        // },
        // {
        //   path: 'test-in-webex',
        //   component:import('./views/Webex/TestInside'),
        //   name: 'test-in',
        //   meta: { requiresAuth: true,  title: 'Test' }
        // },
        // {
        //   path: 'test-in-basic',
        //   component:  TestInsideNoProctor,
        //   name: 'test-in-no-proctor',
        //   meta: { requiresAuth: true,  title: 'Test' }
        // },
        {
          path: 'dashboard',
          component: TestDashBoard,
          name: 'test-dashboard',
          meta: { requiresAuth: true,  title: 'Test Dashboard' }
        },
        {
          path: 'result',
          component: TestResult,
          meta: { requiresAuth: true, title: 'Test Result' }
        },
        
        {
          path: 'review',
          component: TestReview,
          meta: { requiresAuth: true, title: 'Test Review' }
        },
         {
          path: 'instruction',
          component: TestInstruction,
          meta: { requiresAuth: true, title: 'Test Instructions' }
        },
        {
          path: 'ready-check',
          component: TestPrepare,
          meta: { requiresAuth: true, title: 'Test Prepare' }
        },
      ]
    },
    {
      path: '/marksheet',
      component: MarksheetList,
      meta: { requiresAuth: true, title: 'Marksheet' }
    },
    {
      path: '/marksheet/:id',
      component: Marksheet,
      meta: { requiresAuth: true, title: 'Marksheet View' }
    },
    {
      path: '/test',
      name: 'test',
      component: MockTest,
      meta: { title: 'Scheduled Tests' }
    },
    {
      path: '/troubleshoot',
      name: 'Troubleshoot',
      component: TroubleShootIndex,
      meta: { title: 'Troubleshoot' }
    },
    {
      path: '/troubleshoot/capture-image',
      name: 'Troubleshoot Capture Image',
      component: CaptureMedia,
      meta: { title: 'Troubleshoot' }
    },
    {
      path: '/answer-sheet',
      name: 'Answer Sheet',
      component: MyAnswerSheet,
      meta: { title: 'View My Answer Sheet' }
    },
    {
      path: '/answer-sheet/:id',
      name: 'Test Answer Sheet',
      component: MyAnswerSheetView,
      meta: { title: 'View My Answer Sheet' }
    },
    // {
    //   path: '/contact',
    //   name: 'contact',
    //   meta: { title: 'Contact' },
    //   component: Contac)
    // },
    {
      path: '/profile',
      name: 'profile',
      meta: { title: 'Profile' },
      component: Profile
    },
  ]
})

router.beforeEach((to, from, next) => {
  /* must call `next` */
  // console.log(store.getters.auth)
  // console.log(to,from,next)

  document.title = to.meta.title
  try{
    if(store.getters.inTest)
    if(from.name.indexOf('test-in')==0 && to.name.indexOf('test-in')!=0){
      return next(false)
    }
  } catch(e){
    return next(false)
  }
  
  if(!to.meta.skipAuth && !store.getters.auth){
    store.dispatch('errorSnack',{e:'Please log in to continue!'})
    next({
          path: '/auth/login',
          query: { nextUrl: to.fullPath }
        })
  } else {
    next()
  }
})

router.afterEach((to,from) => {
  if(!store.getters.inTest && to.path!=='/' && to.path.indexOf('auth')<0){
    setTimeout(() => {
      if(process.env.VUE_APP_TYPE!=='b2b') store.dispatch('showOffer', true)
    },1500)
  }   
})

export default router