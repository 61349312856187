<template>
    <div class="text-xs-center"> 
    <h3 class="display-2 pt-4">{{message}}</h3> 
    <v-btn @click="reloadTest()"><v-icon>refresh</v-icon> Retry</v-btn>
  </div>
</template>
<script>
export default {
    methods: {
        reloadTest(){ window.location.reload() },
    },
    props: {
        message: {
            type: String,
            default: ''
        }
    }
}
</script>