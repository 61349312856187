<template>
<div>
<div id="section-2" >
  <v-scroll-x-transition>
  <div v-if="!auth" class="text-xs-center" >
    <v-btn round style="margin: 50px auto" large color="primary" @click="startPreparation()">Student Login</v-btn>
  </div>
  <div v-else >
      <v-layout row wrap justify-center style="margin: 0 auto;">
      <v-flex md8 xs12 style="padding: 8px"> 
        <student-welcome></student-welcome>
      </v-flex>
      <v-flex xs12></v-flex>
      <v-flex md8>
        <upcoming-class></upcoming-class>
      </v-flex>
      <v-flex md8>
        <home-menus></home-menus>
      </v-flex>
      </v-layout>
  </div>
  </v-scroll-x-transition>
</div>
<chome-update></chome-update>
</div>
</template>
<script>
import HomeMenu from './HomeMenu.vue'
import StudentWelcome from './StudentWelcome.vue'
import ChomeUpdate from './ChomeUpdate.vue'
import UpcomingClass from './UpcomingClass.vue'

import { mapGetters } from 'vuex'
let logoData = null
if(process.env.VUE_APP_HOST_LOGO) logoData = `https://s3.eu-central-1.wasabisys.com/subdomainlogo/${window.location.host}.png`
else if(process.env.VUE_APP_LOGO) logoData = process.env.VUE_APP_LOGO
else logoData = require('../../assets/logo.png')

export default{
	data(){
    return {
      hover_more: false,
      logo: logoData,
      hide_powered_by: process.env.VUE_APP_HIDE_POWERED_BY==='true',
   }
	},
	mounted(){
	  if(!this.auth) this.$store.commit('toggle_drawer',false)
    this.$store.commit('toggle_cardtoolbar',true)
    
	},
	beforeDestroy(){
    this.$store.commit('toggle_cardtoolbar',false)
	},
	methods:{
    startPreparation() {
      if(!this.auth){
        this.$router.push('/auth/login')
      } else {
        this.$router.push('/dashboard/desk')
      }
    }
	},
	computed: {
	  ...mapGetters([ 'auth' ,'user']),
	},
  components:{
    'home-menus': HomeMenu,
    'student-welcome': StudentWelcome,
    'chome-update': ChomeUpdate,
    'upcoming-class': UpcomingClass
  }
}
</script>
<style>
.inline-block{
  display: inline-block;
  width: 100px;
}
@media screen and (max-width: 600px) {
  .inline-block{
    display: none;
  }
  .right-contacts{
    padding-bottom: 50px
  }
}
</style>
<style scoped>
#section-1{
 min-height: 150px;
}
#section-2{
  min-height: 300px;
}
/*#section-3{
  padding-top: 50px;
  margin-bottom: 100px;
  min-height: 400px;
}
#section-4{
  padding: 20px;
  margin: 100px -16px;
}
#section-5{
  margin: 0 -16px;
}*/


</style>