import WarningOffset from './WarningOffset'
import FetchHelper from './FetchHelper'
import requestWakeLock from './../../../utils/wakeLock'
import {openFullscreen} from './../../../utils/fullscreen.js'


const beforeDestroy = (context) => {
    context.$store.commit('set_in_test',    false)
    context.$store.commit('toggle_toolbar', true)
    try {
      context.hangupVideoProctor()
      if(context.localStream) context.localStream.getTracks().forEach((track) => track.stop())
    } catch(e){
      console.log(e)
    }
}

const onMount = (context) => {
  WarningOffset(context)
  FetchHelper(context)
  MarkInTest(context)
}

const MarkInTest = (context) => {
  context.$nextTick(() => {
    context.$store.commit('set_in_test',    true)
    context.$store.commit('toggle_toolbar', false)
    openFullscreen().catch(()=> context.showWarning = true)
    requestWakeLock()
  })
}

export default {onMount, beforeDestroy}