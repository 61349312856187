<template>
    <v-dialog v-model="show" fullscreen style="z-index:208">
    <v-card >
        <v-toolbar dark color="purple darken-4" fixed>
          <v-btn icon dark @click="close()">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{item?item.title:'No title'}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark flat v-if="item && item.type=='assignment'" @click="$emit('attempt')">Attempt</v-btn>
          </v-toolbar-items>
        </v-toolbar>

      <v-card-text class="text-xs-center" style="height: 90vh;">
        <v-progress-linear :indeterminate="true" v-if="isLoading && (!isPdf && !isNative)" style="margin-top: 54px;"></v-progress-linear>
        <template v-if="item">
          <v-btn v-if="isPdf && isNative" @click="viewPdf()" style="margin-top: 54px;">View PDF</v-btn>
          <iframe v-else :src="item.attachment_urls?item.attachment_urls[aindex]:null" frameborder="0" ref="holder" width="100%" height="100%" style="margin-top: 48px;" @load="loaded()"></iframe>
        </template>
      </v-card-text>
    </v-card>
    </v-dialog>
</template>
<script>
import { Capacitor } from '@capacitor/core'
import { Browser } from '@capacitor/browser'

export default {
    data(){
      return {
        isLoading: true
      }
    },
    props: {
        show: {
          type: Boolean,
          default: false
        },
        item: {
            type: Object,
            default: () => null
        },
        aindex: {
          type: Number,
          default: 0
        }
    },
    methods: {
        close(){
          if(this.$refs.holder) this.$refs.holder.src = null
          this.$emit('close')
          this.isLoading = true
        },
        loaded(){
          console.log('loaded')
          this.isLoading = false
        },
        async viewPdf(){
          console.log(Browser, this.item.attachment_urls[this.aindex])
          await Browser.open({ url: this.item.attachment_urls?this.item.attachment_urls[this.aindex]:null });
        }
    },
    computed: {
      isNative() {
        return Capacitor.isNativePlatform()
      },
      isPdf(){
        if(this.item && this.item.attachment_urls && this.item.attachment_urls[this.aindex].indexOf(".pdf") >= 0) return true
        return false
      }
    }
}
</script>