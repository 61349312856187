<template>
    <div>
        <span v-if="schedule" :class="schedule.canceled_at?`red--text`:''">
            <b>{{schedule.subject_name.toUpperCase()}}</b>
            <br>
            {{schedule.time}} / {{schedule.duration}} mins
            <br>
            {{(schedule.teachers || []).map(tea => tea.name).join(', ')}}
            <!-- {{schedule.teacher_name}} -->
        </span>
        <span v-else>&nbsp;</span>
    </div>
</template>
<script>
export default {
    props:{
        schedules: {
            type: Array,
            default: () => []
        },
        slotIndex: {
            type: Number,
            default: 1
        },
        day: {
            type: String,
            default: ''
        }
    },
    computed: {
        schedule(){
            try {
                return (this.schedules || []).find((v) => v.slot == this.slotIndex && v.day == this.day)  
            } catch (error) {
                console.log(error)
                return null
            }
            
        }
    }
}
</script>