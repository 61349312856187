<template>
<div style="background: white;">
    <v-card-text>
        <div :class="{'hidden':imageCaptured, 'text-xs-center': true}">
            <p >Have proper light and capture only the required portion to get a clear image.</p>
            <video autoplay="true" id="videoElement" ></video>
        </div>
        <div :class="{'hidden':!imageCaptured}">
            <v-flex xs12 class="text-xs-center">
                <p>Please make sure your handwriting is visible.</p>
                <canvas id="canvas"></canvas>
                <div class="output" style="visibility:hidden;position: absolute;z-index:-1;top:0">
                    <img id="photo" alt="The screen capture will appear in this box.">
                </div>
            </v-flex>
        </div>
    </v-card-text>
    <v-card-actions class="text-xs-center bt">
          <v-btn color="info darken-1" outline flat :disabled="uploading || !isDesktop"  round @click="close()">Back</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outline flat :disabled="uploading || !isDesktop" round @click="init()">Retake</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" round @click="takepicture()" v-if="!imageCaptured" :loading="uploading || !isDesktop">Capture</v-btn>
          <v-btn color="green darken-1" v-else dark round :disabled="!imageCaptured" :loading="uploading || !isDesktop" @click="uploadAnswer()" >Upload</v-btn>
    </v-card-actions>
    <!-- <br v-if="isDesktop"> -->
</div>
</template>
<script>
var gumStream
import {getCameraDeviceID} from './../../../utils/webCam'
import ReleaseMedia from './../../../utils/releaseMedia'
export default {
    data(){
        return {
            imageCaptured: false,
            video:  null,
            canvas: null,
            width:  640,
            height: 360,
            devices: [],
            videoInput: null,
            zoomImage: false,
            isDesktop: window.innerWidth>500,
            mediaError: null
        }
    },
    props: ['stream', 'uploading'],
    mounted(){
        this.init()
    },
    beforeDestroy(){
        ReleaseMedia(gumStream)
    },
    methods: {
        init(){
            this.mediaError = null
            this.imageCaptured = null
            if(!this.isDesktop){
                this.width = window.innerWidth-30
                this.height = window.innerWidth*4/3
                return 
            }
            this.listCameraDevices()
            this.$nextTick(() => {
                this.videoInput = getCameraDeviceID()
                this.startMedia(this.mediaType)
            })
            console.log('here')
        },
        async startMedia(type) {
            this.mediaError = null
            this.video = document.getElementById('videoElement')
            // do this at parent
            // if(!this.isDesktop){
            //     this.$refs.mobileCapture.click()
            //     return false
            // }

            if(this.stream) {
                gumStream = this.stream
                return this.captureMedia(type)
            }

            ReleaseMedia(gumStream)
            try {
                gumStream = await navigator.mediaDevices.getUserMedia(this.getMediaConfig())
                this.captureMedia(type)
            } catch (err0r) {
                if(/permission/.test(err0r.message.toLowerCase())) this.mediaError = "Please allow this website to access webcam!"
                else  this.mediaError = err0r.message.replace(/[^\:]*:/, '')
            }
        },
        captureMedia(){
            this.video.srcObject = gumStream;
            this.video.play();
            this.video.addEventListener('canplay', (ev) => {
                this.height = this.video.videoHeight / (this.video.videoWidth/this.width);
            })
        },
        takepicture() {
            var canvas = document.getElementById('canvas')
            var context = canvas.getContext('2d');
            if (this.width && this.height) {
                canvas.width = this.width;
                canvas.height = this.height;
                context.drawImage(this.video, 0, 0, this.width, this.height);
                var data = canvas.toDataURL('image/png');
                photo.setAttribute('src', data);
                this.base64 = data
                this.imageCaptured = true
            } else {
                this.clearphoto();
            }
        },
        getMediaConfig(){
            return {
                video: {
                    width: 640,
                    height: 480,
                    deviceId: this.videoInput
                }
            }
        },
        clearphoto() {
            var canvas = document.getElementById('canvas')
            var context = canvas.getContext('2d');
            context.fillStyle = "#AAA";
            context.fillRect(0, 0, canvas.width, canvas.height);

            var data = canvas.toDataURL('image/png');
            photo.setAttribute('src', data);
            this.imageCaptured = false
        },
        async listCameraDevices  ()  {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            this.devices = videoDevices.map(videoDevice => {
                return { deviceId: videoDevice.deviceId, label: videoDevice.label.charAt(0).toUpperCase()+videoDevice.label.substring(1) }
            });
            // if(videoDevices.length>1) this.devices = [{deviceId: 'user', label: 'Front'}, {deviceId: 'environment', label: 'Rear'}]
        },
        close(){
            this.$emit('close')
        },
        uploadAnswer(){
            document.getElementById('canvas').toBlob(blob => 
                this.$emit('uploadAnswer', {blob, type:'image', ext:'png'})
            )
        }
    }
}
</script>
<style scoped>
.hidden{
    visibility: hidden;
    height: 0px;
    max-height: 0px;
    position: absolute;
    top: 0;
}
#videoElement {
	width: 700px;
    max-width: 100%;
	height: auto;
	background-color: #666;
    margin: 0 auto;
    display: block
}
@media screen and (max-width: 500px) {
    #videoElement {
        width: 480px;
        max-width: 100%;
        height: auto;
        background-color: #666;
    }
}
canvas{
    max-width: 100%;
}
.bt{
    position: fixed;
    bottom: 0;
    width: 100%;
}
</style>