<template>
    <div>
        <iframe src="https://www.magnetbrains.com/" frameborder="0" style="width: 100%; height: 80vh"></iframe>
    </div>
</template>
<script>
export default {
    data() {
        return { }
    },
    mounted(){
        // window.open('https://www.magnetbrains.com/', '_blank')
    },
    beforeMount(){
        document.body.style = 'overflow:hidden'
    },
    beforeDestroy(){
        document.body.style = 'overflow:auto'
    }
}
</script>