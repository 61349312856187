import store from './../store'
import { setAccessToken }  from './../_axios.js';
import jwt from 'jsonwebtoken'

const clearToken = function() {
  localStorage.removeItem('jwt_token')
  localStorage.removeItem('tm_auth')
  localStorage.removeItem('tm_user')
}

/*
stores firebase token and sets auth token
*/
const storeToken = function(data) {
  if(localStorage)  {
    localStorage.setItem('jwt_token',data.token)
    localStorage.setItem('config_token',data.config)
    localStorage.setItem('tm_auth',true)
  }
  else {
    console.log('Doesn\'t support localStorage')
    store.commit('open_snackbar', {text: 'Please upgrade your browser!'})
  } 
}

const storeUser = function(user) {
  if(localStorage)  {
    localStorage.setItem('tm_user',JSON.stringify(user))
    localStorage.setItem('tm_auth',true)
  }
  else {
    console.log('Doesn\'t support localStorage')
    store.commit('open_snackbar', {text: 'Please upgrade your browser!'})
  } 
}

const hasToken = function () {
  if(localStorage && localStorage.getItem('jwt_token')) return true
  return false
}

const tokenHandler = function(vm, resp) {
  storeToken(resp)
  setAccessToken(resp.token)
  vm.$store.dispatch('setAuthenticated', resp)
}

const tokensToUser = function(resp){
  var out = jwt.decode(resp.token)
  try {
    out = { ...out, config: jwt.decode(resp.config)}
  } catch (error) {
    console.log(error)
    out.config = {}
  }
  return out
}

export {clearToken, storeToken, storeUser, hasToken, tokenHandler, tokensToUser}