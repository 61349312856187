const __lCanvas     = document.querySelector('#localCanvas')
const __lContext    = __lCanvas.getContext('2d')
const __lVideo      = document.querySelector('#localVideo')

export default (callback) => {
    __lCanvas.width = __lVideo.videoWidth; // set its size to the one of the video
    __lCanvas.height = __lVideo.videoHeight;
    // following two lins are only for jitsi
    __lContext.clearRect(0, 0, __lCanvas.width, __lCanvas.height)
    __lContext.drawImage(__lVideo, 0, 0)
    __lContext.font="20px Arial"
    __lContext.fillText(new Date().toLocaleTimeString()+ ',' + new Date().toLocaleDateString(), 4, 24)
    return new Promise((resolve, reject) => {
        __lCanvas.toBlob((blob => {
            resolve(callback?callback(blob):blob)
        }), 'image/webp')
    })
}

const ImageBitmapToBlob = function(data, ret = false){
    __lCanvas.width = data.width; // set its size to the one of the video
    __lCanvas.height = data.height;
    __lContext.clearRect(0, 0, __lCanvas.width, __lCanvas.height)
    __lContext.drawImage(data, 0, 0)
    __lContext.font="20px Arial"
    __lContext.fillText(new Date().toLocaleTimeString()+ ',' + new Date().toLocaleDateString(), 4, 24)
    if(!ret) return
    return new Promise((resolve, reject) => {
        __lCanvas.toBlob((blob => {
            resolve(blob)
        }), 'image/webp')
    })
}

const ImageBitmapToCanvas = function(dataURL, ret = false){
    let data = new Image()
    
    // if(!ret) return
    return new Promise((resolve, reject) => {
        data.onload = function() {
            __lCanvas.width = data.width; // set its size to the one of the video
            __lCanvas.height = data.height;
            __lContext.clearRect(0, 0, __lCanvas.width, __lCanvas.height)
            __lContext.drawImage(data, 0, 0)
            __lContext.font="20px Arial"
            __lContext.fillText(new Date().toLocaleTimeString()+ ',' + new Date().toLocaleDateString(), 4, 24)
            __lCanvas.toBlob((blob => {
                resolve(blob)
            }), 'image/webp')
        };
        data.src = dataURL
    })
}

const GetImageBlob = function(){
    return new Promise((resolve, reject) => {
        __lCanvas.toBlob((blob => {
            resolve(blob)
        }), 'image/webp', 0.7)
    })
}

export {ImageBitmapToBlob, GetImageBlob, ImageBitmapToCanvas}
