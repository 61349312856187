<template>
 <v-dialog v-model="notChrome" width="400">
  <v-card >
    <v-toolbar>
      <v-avatar>
        <v-icon color="red">warning</v-icon>
      </v-avatar>
      <v-toolbar-title >Warning</v-toolbar-title>
    </v-toolbar>
    <v-card-text> Please use only updated version of Google Chrome or Edge browser. </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn @click="notChrome=false">Close</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>
<script>
import isChrome from './../../utils/isChrome'

export default{
    data(){
        return {
            notChrome: !isChrome(),
        }
    }
}
</script>