<template>
<v-toolbar fixed style="z-index: 4" v-if="$vuetify.breakpoint.mdAndUp">
    <v-toolbar-title>
      <span class="hidden-sm-and-down"> {{testName}}  </span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items >  
      <v-btn flat @click="toggleSubmitDialog()"  v-if="!error && !hideSubmit" :disabled="!lastQuestion" title="Please go to last question to submit.">Submit</v-btn> 
      <!-- <v-btn :to="resultLink" flat @click="showSubmitDialog()"  v-if="error">Show Result</v-btn> -->
      </v-toolbar-items> 
  </v-toolbar>

  <v-toolbar id="mobile-toolbar" fixed style="z-index: 4;" v-else>
    <v-toolbar-items >  
        <!-- qNavDialog=true -->
      <v-btn flat @click="togglemQDialog(true)">Questions</v-btn>
    </v-toolbar-items>
    <v-toolbar-title>
      <div class="subheading">
        <small class="timer-icon"><v-icon>schedule</v-icon> &nbsp;</small>
        <span v-html="timeLeft"></span>
      </div> 
    </v-toolbar-title>
    <v-toolbar-items>
      <v-btn flat @click="toggleSubmitDialog();" v-if="!error" :disabled="!lastQuestion" :title="!lastQuestion?'Please go to the last question to Submit the Test.':''" round>Submit</v-btn> 
      <!-- <v-btn :to="resultLink" flat @click="showSubmitDialog()"  v-if="error" round>Show Result</v-btn>  -->
    </v-toolbar-items>
</v-toolbar>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data(){
        return {
        }
    },
    props: {
        testName:{
            type: String,
            default:  ''
        },
        // timeLeftHtml: {
        //     type: String,
        //     default: '00:00:00'
        // },
        // timeLeftPartHtml: {
        //     type: String,
        //     default: '00:00:00'
        // },
        error: {
            type: String,
            default: null
        },
        hideSubmit: {
          type: Boolean,
          default: false
        },
        // lastQuestion: {
        //   type: Boolean,
        //   default: true
        // }
    },
    methods: {
      ...mapActions(['toggleSubmitDialog', 'togglemQDialog'])
    },
    computed: {
      ...mapGetters([ 'timeLeft', 'qi', 'questions']),
      lastQuestion(){
        return this.qi===this.questions.length-1
      }
    }
}
</script>