<template>
<div>
  <div>
    <div class="text-xs-center" v-if='loading'>
    <v-progress-circular
      :size="250"
      :width="10"
      color="indigo"
      indeterminate
    >Loading Instructions...</v-progress-circular>
  </div>
    <v-flex xs12 md8 offset-md2 v-if="test">
      <v-card  >
        <v-layout>
          <v-flex xs12>
            <v-card-title primary-title>
              <div style="width: 100%">
                <div class="headline text-xs-center">{{test.name }}</div>
                <div v-if="chapters" v-html="chapters.map(v=> v.name).join(', ')" style="font-style: italic;font-size:90%"> </div>
                <v-list class="pa-0">
                  <v-layout row wrap>
                    <v-flex xs12 md4>Test Time = {{testTime}}</v-flex>
                    <v-flex xs12 md4 class="text-md-center">Max Marks = {{test.format.max_marks}}</v-flex>
                    <v-flex xs12 md4 class="text-md-right">Duration = {{test.format.duration}} min</v-flex>
                  </v-layout>
                  </v-list>
              </div>
            </v-card-title>
          </v-flex>
        </v-layout>
        <v-divider light></v-divider>
        <v-card-text class="pa-0">
          <v-card-title primary-title>
            <div> 
              <div class="headline">Test Instruction</div> 
              <p v-html="test.description"></p>
              <div class="headline">General Instruction</div> 
              <p class="subheading" style="font-size: 90% !important;">
               The Question Number Pad on the right side of the screen will show the status of each question using the following color code: <br><br>
                <!-- <v-btn class="visited-no-answer marked  legend-btn" fab small></v-btn>:  Questions Marked for Review <br> -->
                <v-btn class="has-answer legend-btn" fab small></v-btn>:  Attempted questions <br>
                <!-- <v-btn class="has-answer marked legend-btn" fab small></v-btn>:  Attempted and marked questions <br> -->
                <!-- <v-btn class="visited-no-answer legend-btn" fab small></v-btn>:  Visited questions but not answered <br> -->
                <v-btn class="legend-btn" fab small></v-btn>:  Non visited questions <br><br>
              </p>

              <ul>
                <li>
                  Clicking on the question number from the Number Pad will navigate to the corresponding question.<br>
                </li>
                <li>The timer above the Number Pad will display the time remaining for the test and when the timer reaches zero, the test will be submitted automatically.<br> </li>
                <li>Navigating to other websites during test is strictly prohibited and may result in auto submission of the test or the test may not be evaluated at all. <br></li>
              </ul>
              <div v-html="instruction"></div>

          </div>
          </v-card-title>

        </v-card-text>
        <v-card-actions class="pa-3" >
          <v-checkbox v-model="read" flat  color="red">
             <template slot="label">
                <div>I have read the instructions and agree to <a href="https://thinkmerit.in/terms-and-conditions" target="_blank">terms and conditions</a></div>
              </template>
          </v-checkbox>
          <v-spacer></v-spacer>
          <v-btn  color="info" :disabled="!read || test.time_to_start>0 || starting" @click="startTest0()" >{{startFilter}}</v-btn>
        </v-card-actions>
      </v-card>

    </v-flex>
  </div>

  <v-dialog
    v-model="accessCodeDialog"
    persistent
    width="400">
    <v-form v-model="valid" ref="accessCodeForm" @submit.prevent="confirmAccessCode()">
    <v-card>
      <v-card-title>Enter the &nbsp;<b>ACCESS CODE</b>&nbsp; to {{resume?'resume':'start'}} this test.</v-card-title>
      <v-card-text>
        
         <v-text-field
            label="Access Code"
            v-model="accessCode"
            class="text-xs-center"
            :rules="codeRules"
            :loading="confirming"
            :readonly="confirming"
            required
          ></v-text-field>
        
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn color="info" @click="confirmAccessCode()" :disabled="confirming" round>Verify</v-btn>
      </v-card-actions>
    </v-card>
    </v-form>
  </v-dialog>

  <v-dialog
    v-model="starting"
    persistent
    width="300">
    <v-card
      color="primary"
      dark>
      <v-card-text>
        Please wait while test being prepared
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>

</div>
</template>
<script>
const {instance} = require('./../_axios.js');
import { mapGetters, mapActions  } from 'vuex'
import {DateTime, Duration} from 'luxon'
import countDownFormat from './../utils/countdownFormat'
import firebase from "firebase/app";

export default{
	data(){
	  return {
      test: null,
      chapters: null,
      instruction: null,
      loading: false,
      read: false,
      starting: false,
      message: null,
      snackBar: {color: 'red', text: ''},
      testType: null,
      accessCode: '',
      accessCodeDialog: false,
      confirming: false,
      codeRules: [
        v => !!v || 'Access Code is required'
      ],
      valid: false,
      resume: Boolean(this.$route.query.resume)
    }
	},
	mounted(){
    if(this.$route.meta.testParent=='chapter_test') this.testType = 'chapterTest'
	  this.fetchTest()
    // required before starting test
    sessionStorage.setItem('blurCount', 0)
  },
	methods:{
    ...mapActions(['errorSnack','saveLog']),
	  fetchTest(){
      this.loading = true
      var url = '/test/instruction/'+this.$route.params.id+'?'+(this.resume?'resume=true':'')

	  	instance.get(url)
	  	.then((resp) => {
        this.test = resp.data.test
        this.instruction = resp.data.instruction
        this.chapters = resp.data.chapters
        if(this.$route.meta.testName) this.test.name = this.$route.meta.testName
        if(this.test.time_to_start>0){
          this.test.startsAt = Date.now()+this.test.time_to_start
        }
        this.startCountDown()
      })
	  	.catch((e)=>{ 
        console.log(e)
        this.errorSnack({e,b:'Error fetching test instruction!'}) 
        this.$router.go(-1)
      })
      .then(() => this.loading = false)
    },
    startTest0(){
       if(this.test.access_code===true) this.accessCodeDialog = true 
       else this.startTest()
    },
    async startTest(){
     if(this.starting) return
      this.starting = true
      
      var currentVideo = localStorage.getItem('CAMERA_DEVICE')
      var devices = (await navigator.mediaDevices
                .enumerateDevices())
                .map(videoDevice => {
                return { 
                  deviceId: videoDevice.deviceId, 
                  type: videoDevice.kind,
                  active: videoDevice.deviceId===currentVideo,
                  label: videoDevice.label.charAt(0).toUpperCase()+videoDevice.label.substring(1) 
                }
              });
      var params = { id: this.$route.params.id, devices }

      if(this.test.access_code) params.access_code = this.accessCode
      if(this.resume) params.resume = true

      instance.post('/test/start', params)
      .then(async (resp) => {
        if(resp.data.error){
          this.errorSnack({e:resp.data.error,b:'There was something wrong while creating test!'})
          this.confirming = false
        } else {
          this.$store.commit('deduct_mp', this.test.mp_cost)
          let path = '/test/'+resp.data.test_id+'/test-in-basic' 
          if(!this.user.config.NO_PROCTOR){
            if(this.test.type==='aio-pdf') {
              path = '/test/'+resp.data.test_id+'/test-in-aio'
            } else if(this.test.type=='third-party'){
              path = '/test/'+resp.data.test_id+'/test-in-third-party'
            } 
            // else if(this.test.format.partwise_timing){
            //   path = '/test/'+resp.data.test_id+'/test-in-partwise'
            // } 
            else {
              path = '/test/'+resp.data.test_id+'/in'
            }
          }
          path+='?id='+this.$route.params.id+'&room='+resp.data.room_no || 'common'
          setTimeout(() =>  this.$router.push({path}) ,500)
          this.saveLog({
            p:  this.$route.path,
            tid:  this.$route.params.id || null,
            utid: resp.data.test_id || null,
            ts: firebase.firestore.Timestamp.now(),
            f:  !!document.fullscreen,
            a:  this.resume?'RESUME':'START'
          })
        }
      })
      .catch(e =>  {
        this.errorSnack({e:e, b:'There was something wrong while creating test!'})
      })
      .then(() => {
        this.starting = false
      })
    },
    confirmAccessCode(){
      this.$refs.accessCodeForm.validate()
      if(this.valid){
        this.confirming = true
        this.startTest()
      }
    },
    startCountDown(){
      if(this.test.time_to_start>0){
        setTimeout(() => {
          this.test.time_to_start = this.test.startsAt - Date.now()
          this.startCountDown()
        },1000)
      }
    }
  },
  computed: {
    ...mapGetters(['user']),
    startFilter () {
      return this.test && this.test.time_to_start>0?countDownFormat(Duration.fromMillis(this.test.time_to_start).shiftTo('days', 'hours', 'minutes','seconds').toObject()):'Start'
    },
    testTime (){
      let temp = DateTime.fromISO(this.test.activation_date)
      return temp.toLocaleString(DateTime.TIME_SIMPLE) +', '+ temp.toLocaleString(DateTime.DATE_FULL);
    }
  },
	props: {}
}
</script>
<style>
.test-logo{
	padding: 4px;
	margin: 5px 20px
}
.legend-btn{
   /*border-radius: 0px !important;*/
   height:20px !important;
   width:20px !important;
}
.visited-no-answer.blue{
  background-color: #2196f3 !important;
}

.visited-no-answer.marked, .marked{
  background: #FFAB00 !important;
  color:white !important;
}
.has-answer{
  /*border: 4px solid #009688 !important;*/
  background: #689F38 !important;
  color:white !important;
}
/*.has-answer.blue{
   border-color: #009688 !important;
}*/
.has-answer.v-btn__content{
  color: #689F38 !important;
}
.visited-no-answer{
  /*border: 2px solid red !important;*/
  background-color:#F44336 !important;
  color:white !important;
}
.blue.marked, .blue.marked.has-answer, .blue.marked.visited-no-answer{
  background-color:#2196f3 !important
}
.marked.has-answer{
  background-color:#689F38 !important
}
.marked.has-answer::after{
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color:#FFAB00 !important;
  top: 40px;
  left: 40px;
}
.legend-btn.marked.has-answer::after{
  top: 6px;
  left: 6px;
}
/*.orange--text.has-answer, .orange--text.visited-no-answer{
  border-top: 2px solid orange !important;
  border-right: 2px solid orange !important;
}*/
.sm-title{
  font-size: 12px;
}
</style>