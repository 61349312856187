<template>
<v-container fluid grid-list-lg>
<v-layout row wrap v-if="!loading">
  <v-flex md8 xs12>
    <v-layout row wrap xs12>
      <v-flex xs12  :class="$vuetify.breakpoint.smAndDown?'pt-3':'pa-1'">
        <exam-updates dont-fetch></exam-updates>
      </v-flex>
      <v-flex xs12 md6>
        <v-card class="mx-auto" id="desk-calendar" flat elevation="1">
          <v-date-picker style="box-shadow: none" color="teal lighten-2" full-width readonly v-model="datePicker" :landscape="$vuetify.breakpoint.smOnly"></v-date-picker>
        </v-card>
      </v-flex>
      <v-flex xs12 md6 >
        <todo dont-fetch></todo>
      </v-flex>
    </v-layout>
    
  </v-flex>
  <v-flex md4 xs12>
    <v-layout row wrap>
        <v-flex xs12>
          <attendance-stat></attendance-stat>
        </v-flex>

        <v-flex xs12>
          <daily-schedule></daily-schedule>
        </v-flex>

        <v-flex xs12>
          <v-card class="text-xs-center" elevation="1">
            <v-toolbar flat>
              <v-toolbar-title class="blue-grey--text">UPCOMING EXAMS</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items >
                <!-- <v-btn light right fab small color="blue-grey lighten-1" depressed @click="newTodoDialog=true" dark to="/timetable"><v-icon ripple> arrow_outward </v-icon></v-btn> -->
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text style="min-height: 198px" class="d-flex align-center justify-content-center">
              <span class="dark--text text--ligten-5" style="opacity: 0.5">No upcoming exam!</span>
            </v-card-text>
          </v-card>
        </v-flex>

    </v-layout>
  </v-flex>
  
</v-layout>

<v-dialog
  v-model="loading"
  persistent
  width="300">
  <v-card color="primary" dark>
    <v-card-text>
    Loading data please wait...
    <v-progress-linear
        indeterminate
        color="white"
        class="mb-0"
    ></v-progress-linear>
    </v-card-text>
  </v-card>
</v-dialog>


</v-container>
	
	
</template>
<script>
const {instance} = require('./../_axios.js');

import ExampUpdates from './../components/ExamUpdates'
import Motivation from './../components/Motivation'
import DailyScheduleVue from '../components/desk/DailySchedule.vue';
import AttendanceCounterVue from '../components/desk/AttendanceCounter.vue';

// import { database } from './../fireapp'

export default{
	data(){
      return {
        loading: false,
      	datePicker:new Date().toISOString().substr(0, 10),
      }
	},
  mounted(){
    this.init()
  },
	methods:{
    init(){
      this.loading = true
      instance.get('/desk')
      .then(resp => {
        this.$store.commit('set_todos',resp.data.todos)
        this.$store.commit('set_bookmarks',resp.data.bookmarks)
        this.$store.commit('set_motivation',resp.data.motivations)
        this.$store.commit('set_notices', resp.data.notices)
      })
      .catch(e => {})
      .then(() => {this.loading = false})
    }
	},
	props:{

	},
  computed:{
    timePicker(){
      return new Date().getHours()+':'+new Date().getMinutes()
    }
  },
	components:{
    'exam-updates':ExampUpdates,
	  'motivation':Motivation,
    'daily-schedule': DailyScheduleVue,
    'attendance-stat': AttendanceCounterVue
	}
}
</script>
<style >
.v-window.v-carousel{
  height: 308px;
}
.v-picker--time.v-picker--landscape >.v-picker__body{
  height: 380px;
  justify-content: center;
}
.v-date-picker-title__year{
  display:none
}
@media screen and (max-width: 600px) {
  .v-picker--landscape .v-picker__actions, .v-picker--landscape .v-picker__body{
    margin-left:0;
  }
}
.v-carousel__controls{
  background-color:rgba(49, 27, 146, 0.96)
}
#desk-calendar .v-date-picker-title__year{
  display:none
}
#desk-calendar .v-picker__title{
  height: 75px;
}
#desk-calendar .v-picker__body{
  min-height: 300px;
}

</style>
