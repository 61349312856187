export default (context) => {
    if(context.testConfig.DETECT_MOUSEOUT){
        window.addEventListener('mouseout', () => {
            context.mouseOut = true
        })

        window.addEventListener('mouseover', () => {
            context.mouseOut = false
        })
    }
}