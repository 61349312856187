<template>
<div v-if="testData">

  <v-layout row wrap class="mb-4">
    <v-flex xs6 class="text-xs-right"></v-flex>
    <v-flex xs6 class="text-xs-right"><v-btn flat :to="{path: '/test-results'}">Back</v-btn></v-flex>
    <v-flex xs12 class="pa-4 text-xs-center display-1 grey--text">{{testData?testData.name:''}} Result</v-flex>
  </v-layout>

  <v-layout row wrap>
    <v-flex xs12 class="text-xs-center">
      <v-btn-toggle v-model="resultComponent" mandatory flat>
          <v-btn flat active-class="primary white--text"   v-for="n in tabs.length" :key="n" :value="n-1" style="opacity: 1">
            {{ tabs[n-1] }} 
          </v-btn>
      </v-btn-toggle>
    </v-flex>

    <v-flex xs12>
      <result-wrapper :test-data="testData" :user-data="userData" :part="resultComponent" :title="(tabs[resultComponent]||'') +' Marks Scored'"></result-wrapper>
    </v-flex>
  </v-layout>
   
</div>
</template>
<script>
import ResultWrapper from './ResultWrapper'

export default{
	data(){
    return {
      resultComponent: 0
    }
	},
	mounted(){
    this.$store.commit('set_in_test', false)
	},
  filters:{
    maxChar(v) {
      if(v.length >50) return v.substring(0, 50)+' ...'
      return v
    }
  },
	computed:{
	  testData(){
		  return this.$parent.testData?this.$parent.testData.testData:null
    },
    userData(){
		  return this.$parent.testData?this.$parent.testData.userData:null
	  },
    tabs(){
      if(this.testData) { 
        let temp = this.testData.format.parts.map((v,i) => v.name || 'Part '+(i+1))
        if(temp.length>1) {
          temp.unshift("Overall")
          return temp
        } 
     }
     return []
    }
	},
  components: {
    'result-wrapper': ResultWrapper
  }
}
</script>
<style>
.sm-chart{
  position: relative; 
  height:auto !important; 
  width:80vw !important	
}
</style>