<template>
<v-dialog v-model="model" persistent class="dialog-mathcontent" width="600px" fullscreen>
  <v-card >
    <v-card-title class="headline primary white--text text-capitalize" 
          primary-title>
      <span class="headline">{{type}}</span>
      <v-spacer></v-spacer>
      <v-btn small fab icon @click="$emit('close')" ><v-icon dark color="#fff">close</v-icon></v-btn>
    </v-card-title>
    <v-card-text > <div class="dialog-mathcontent OSX" v-html="content?content:'No data provided.'"></div> </v-card-text>
  </v-card>
</v-dialog>
</template>
<script >
export default{
  data(){
  	return {
  		choices: ['A','B','C','D']
  	}
  },
  props: ['type','model','question'],
  computed: {
  	content(){
  		if(this.type=='Answer'){
          if(this.question.type == 4) return this.question.answer.split(',').map(v=> this.choices[parseInt(v)]+'. '+this.question[this.choices[parseInt(v)]]).join('<br>')
          else if(this.question.type == 6 || this.question.type == 8) return this.question.answer
          else return this.question[this.choices[this.question.answer]]
  		} else if(this.type=='Solution'){
  			return this.question.solution
  		} 
  		return ''
  		
  	}
  },
  beforeDestroy(){
    console.log('asd')
  }
}
</script>