/* eslint-disable */
import 'animate.css';
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Vuetify from 'vuetify'
import MathJax from './components/MathJax.vue'
import Bookmark from './components/BookMark.vue'
import Todo from './components/ToDo.vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faRupeeSign, faBolt, faBullseye, faPercentage, faDotCircle, faCheckCircle, faTimesCircle, faClock, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faGooglePlus,faGoogle, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// import VueWorker from 'vue-worker'
import initFirebase from './initFirebase'
import knuthShuffleSeeded from 'knuth-shuffle-seeded';
import PageTitle from './components/global/PageTitle'
import PageNoData from './components/global/PageNoData'

import { App as CapApp } from '@capacitor/app';

// Vue.use(VueWorker)

library.add(faFacebook, faTwitter, faGooglePlus, faGoogle, faLinkedin, faRupeeSign, faBolt, faBullseye, faPercentage, faDotCircle, faCheckCircle, faTimesCircle, faClock, faAngleDoubleRight, faYoutube)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.use(Vuetify, {
  theme: {
    primary: '#1976D2',
    secondary: '#424242',
    accent: '#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FFC107'
  }
})


// used for option shuffle
Array.prototype.shuffle = function(should = false, seed = null){
  if(!should) return this
  return knuthShuffleSeeded(this, seed)
}

import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
import {getUser} from './auth'

Vue.component('mathjax',   MathJax)
Vue.component('bookmarks', Bookmark)
Vue.component('todo',      Todo)

Vue.component('page-title', PageTitle)
Vue.component('page-no-data', PageNoData)

new Vue({
  router,
  created(){ initFirebase(this); getUser(this) },
  store,
  render: h => h(App)
}).$mount('#app')

window.onunhandledrejection = function(e) {
  console.log(e.reason);
}

CapApp.addListener('backButton', ({ canGoBack }) => {
  if(canGoBack){
    window.history.back();
  } else {
    CapApp.exitApp();
  }
});