<template>
<div>
  <div v-if="fetchingData" class="text-xs-center">
    <v-progress-circular  :size="250" :width="10" color="purple" indeterminate >Loading</v-progress-circular>
  </div>
  <v-layout v-else wrap :align-content-center="true">
  <v-flex xs12 v-if="!auth">
    <v-card  class="mx-auto" max-width="400">
      <v-card-title class="justify-center">Please log in to see your profile.</v-card-title>
    </v-card>
  </v-flex>
  <v-flex xs12 v-if="auth">
    <v-card class="mx-auto" max-width="400" >
        <v-card-title> Profile </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
           <div>
              <div class="mt-4 mb-4">
                <div v-if="inProgress">
                  <v-progress-circular
                      :width="3"
                      color="green"
                      indeterminate
                    ></v-progress-circular>
                </div>
                <template v-else>
                  <v-img v-if="profileData.avatar"
                    :src="profileData.avatar?profileData.avatar:null"
                    :lazy-src="profileData.avatar?profileData.avatar:null"
                    aspect-ratio="1"
                    max-width="200px"
                    class="grey lighten-2"
                  >
                    <template v-slot:placeholder>
                      <v-layout
                        fill-height
                        align-center
                        justify-center
                        ma-0
                      >
                        <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                      </v-layout>
                    </template>
                  </v-img>
                  <div v-else class="text-xs-center">
                    <v-icon large>account_circle</v-icon>
                  </div>
                </template>
                <input type="file" class="mt-4" :accept="fileFormats" @change="filesSelected()" ref="fileInput" multiple />
              </div>
              <h3 class="headline">{{user.name}}</h3>
              <div> Class: <b>{{ profileData?profileData.class : '-' }}</b> </div>
              <div> Phone: <b>{{ user.phone }}</b> </div>
              <div> Email: <b>{{ user.email || 'NA' }}</b> </div>
              <div> Subjects: <b>{{profileData && profileData.subjects?profileData.subjects.map(item => item.name).join(', '): ''}}</b><i v-if="!profileData || profileData.subjects.length==0">No subjects assigned to your account.</i></div>
            </div>
          <!-- <v-layout row nowrap>
            <v-flex xs9>
              <h4 class="pt-1">{{user.name || 'Unknwon'}} </h4>
            </v-flex>
            <v-flex xs3 style="text-align: end;">
              <v-btn icon @click="change('name')" small><v-icon color="grey" >edit</v-icon></v-btn>
            </v-flex>
             </v-layout>
             <v-layout row nowrap>
            <v-flex xs9>
              <h4 class="pt-2">{{user.email || 'No email record'}} </h4>
            </v-flex>
            <v-flex xs3 style="text-align: end;">
            </v-flex>
             </v-layout>
             <v-layout row nowrap class="pt-2">
            <v-flex xs9>
              <h4 class="pt-2">{{user.phone || 'No phone record'}} </h4>
            </v-flex>
            <v-flex xs3 style="text-align: end;">
              <v-btn icon @click="change('email')" style="visibility: hidden;" small><v-icon color="grey">edit</v-icon></v-btn>
            </v-flex>
            </v-layout>
            <v-layout row nowrap>
              <v-flex xs5 sm6>
                <h4 class="pt-1">******** </h4>
              </v-flex>
              <v-flex xs7 sm6>
              <v-btn outline color="red" @click="change('password')">Change Password</v-btn>
              </v-flex>
          </v-layout> -->
          
        </v-card-text>
        
       <!--  <v-card-actions>
        </v-card-actions> -->
  
    <!-- <br v-if="$vuetify.breakpoint.smAndDown"> -->
    <br> <v-divider></v-divider> <br>
    
    <!-- </v-card>
    <v-card class="mx-auto" max-width="400" v-else> -->
     <!--  <v-card-title>More</v-card-title>
        <v-divider></v-divider> -->
      <v-card-text>
        <v-form ref="more" v-model="valid2">
        <!-- <h4 class="subheading"></h4> -->
        <v-layout column wrap>
          <v-btn outline @click="$router.go(-1)">Back</v-btn>
        <!-- <v-flex xs12>
          <v-text-field :readonly="true" label="Class" v-model="changeUserData.class" ></v-text-field>
        </v-flex>
        <v-flex xs12>
          <v-text-field :readonly="true" label="Institute" v-model="changeUserData.institute" ></v-text-field>
        </v-flex> -->
        <!-- <v-flex xs12>
          <v-textarea :readonly="true" label="Address" :rules="[rules.address]" v-model="changeUserData.address" maxlength="255" counter></v-textarea>
        </v-flex> -->
        <!-- <v-flex xs12 >
          <v-btn outline color="orange" v-if="!editMore" @click="editMore=true">Edit</v-btn>
          <v-btn outline color="orange" v-if="editMore" @click="updateUserData()">Update</v-btn>
          <v-btn outline v-if="editMore" style="float: right" @click="editMore=false;$refs.more.resetValidation()">Cancel</v-btn>
        </v-flex> -->
        </v-layout>
        
        </v-form>
      </v-card-text>
   </v-card>
  
  </v-flex>
  <v-flex xs12 md6 v-if="auth" >
  </v-flex>
</v-layout>


<v-dialog v-model="profileDialog"  persistent max-width="600px">
  <v-form ref="basicForm" v-model="valid" @submit.prevent="updateProfile(false)">
  <v-card>
    <v-card-text v-if="changeType=='name'">
      <v-text-field :disabled="inProgress" label="Name" :rules="[rules.required, rules.minName]" v-model="changeUser.name" v-on:keyup.enter="updateProfile(false)"></v-text-field>
    </v-card-text>
    <v-card-text v-if="changeType=='email'">
      <v-text-field :disabled="inProgress" label="Email" :rules="[rules.required, rules.email]" v-model="changeUser.email" v-on:keyup.enter="updateProfile(false)"></v-text-field>
    </v-card-text>
    <v-card-text v-if="changeType=='password'">
      <v-text-field
        :disabled="inProgress"
        v-model="changeUser.current_password"
        :rules="[rules.required, rules.minPass]"
        type="password"
        label="Current Password"
        hint="At least 6 characters"
        counter>
      </v-text-field>
      <v-text-field
        :disabled="inProgress"
        v-model="changeUser.password"
        :rules="[rules.required, rules.minPass]"
        type="password"
        label="New Password"
        hint="At least 6 characters"
        v-on:keyup.enter="updateProfile(false)"
        counter>
      </v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-btn outline color="secondary" :disabled="inProgress" type="submit" @click.prevent="profileDialog=false">Cancel</v-btn>
      <v-spacer></v-spacer>
      <v-btn  outline color="primary" :loading="inProgress" type="submit" @click.prevent="updateProfile(false)">Update</v-btn>
    </v-card-actions>
  </v-card>
  </v-form>
</v-dialog>
</div>
</template>

<style scoped>
code{
  cursor: pointer;
  font-size: 125%
}
</style>

<script>
import { DateTime } from 'luxon'
const { instance } = require('./../_axios.js');
import { mapGetters, mapActions  } from 'vuex'
import getCompressed from './../utils/ImageCompressor'
import { uploadAws } from './../utils/AwsImageUpload'

export default{
  name: 'Profile',
  data(){
    return {
      valid: false,
      valid2: false,
      profileDialog: false,
      changeType: '',
      fileFormats: '.png,.jpg',
      changeUser: {
        name: '',
        email: '',
        password: '',
        current_password: ''
      },
      changeUserData: {
        phone: null,
        institute: null,
        calss: null,
        address: null
      },
      rules: {
        required: value => !!value || 'Required.',
        minName: v => v.length >= 3 || 'Min 3 characters',
        minPass: v => (v && v.length >= 6) || 'Min 6 characters',
        phone: v => (v==null || v=='' || (v>0 && String(v).length==10)) || 'Invalid phone number',
        address: v => ( !v || v.length<=255) || 'Max 255 characters',
        email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
        }
      },
      editMore: false,
      inProgress: false,
      fetchingData: false,
      profileData: {
        address:"",
        class:"",
        institute:"",
        phone:'',
        payments: [],
        subjects: []
      },
      purchaseHistory: [],
      purchaseTableHeader: [
        {
          text: 'Purchase Date',
          sortable: false,
          value: 'created_at'
        },
        {
          text: 'Meritpoints',
          sortable: false,
          value: 'meritpoints',
          align: 'right',
        },
        {
          text: 'Paid',
          sortable: false,
          value: 'amount',
          align: 'right',
        },
      ]
    }
  },
  methods: {
    ...mapActions(['errorSnack']),
    change(type){
      this.profileDialog = true
      this.changeType = type
      this.changeUser[type] = this.user[type]
    },
    updateProfile(dontPostUpdate){
      this.$refs.basicForm.validate()
      
      if(!this.valid) return false
      this.inProgress = true

      // // Change functions here
      // if(this.changeType=='email'){
      //   return this.changeEmail()
      // }
      // // Change functions here
      // if(this.changeType=='password'){
      //   return this.updatePassword()
      // }
      
      if(dontPostUpdate){
        this.changeUser[this.changeType] = ''
        this.profileDialog = false
        this.inProgress = false
      } else {
        return this.postUpdate()
      }

      // fireapp.auth().currentUser.updateProfile({
      //   displayName: this.changeUser[this.changeType],
      //   // photoURL: "https://example.com/jane-q-user/profile.jpg"
      // }).then(() => {
      //   // Update successful.
      //   console.log(dontPostUpdate)
      //   if(dontPostUpdate){
      //     this.changeUser[this.changeType] = ''
      //     this.profileDialog = false
      //     this.inProgress = false
      //   } else {
      //     this.postUpdate()
      //   }
      // }).catch((error) => {
      //   // An error happened.
      //   this.$store.commit('open_snackbar',{text: error.resp.data.message||'Update failed!', color: 'red'})
      //   this.inProgress = false
      // })
    },
    errorHandler(error){
      console.log(error)
      if(error.code==='auth/requires-recent-login'){
        fireapp.auth().signOut()
        .then(() => {
          this.$store.dispatch('clearAuthenticated')
          this.$store.dispatch('clearToken')

          this.$store.commit('open_snackbar',{text: 'Please log in again!', color: 'blue'})
          this.$router.push({path:'/auth',query:{nextUrl:'/profile'}})
        })
        .catch(console.log)
        .then(()=>this.inProgress = false)
      }else {
        this.$store.commit('open_snackbar',{text: error.message || 'Something went wrong!', color: 'red'})
        this.inProgress = false
      }
    },
    postUpdate(){
      var updateData = {}
        updateData[this.changeType] = this.changeUser[this.changeType]
        if(this.changeType=='password')  updateData.current_password = this.changeUser.current_password
          
        instance.post(`/update/basic/${this.changeType}`,updateData)
        .then((data) => {
          this.$store.commit('open_snackbar',{text: 'Update successful!', color: 'green'})
          this.$store.commit('update_user',updateData)
          this.changeUser[this.changeType] = ''
          if(this.changeType=='password')  updateData.current_password = ''
          this.profileDialog = false
        })
        .catch((ee) => {
          console.log(ee)
          this.$store.commit('open_snackbar',{text: ee || 'Update failed!', color: 'red'})
        })
        .then(() => {
          this.inProgress = false
          // this.$store.commit('open_snackbar',{text: 'Update failed!', color: 'red'})
        })
      },
    // updatePassword(newPassword){
    //   fireapp.auth().currentUser.updatePassword(newPassword).then(function() {
    //     this.$store.commit('open_snackbar',{text: 'Password change successful!', color: 'green'})
    //   }).catch(this.errorHandler);
    // },
    fetchUserProfile(){
      this.fetchingData = true
      instance.get('/user/profile')
      .then((resp) => {
        this.changeUserData = resp.data
        this.profileData = resp.data
        this.profileData.payments.map(v => {
          v.created_at = DateTime.fromISO(v.created_at).toLocaleString({ day: 'numeric', month: 'long' })  
          return v
        })
      })
      .catch((e) => {
        this.$store.commit('open_snackbar',{text: 'Error fetching data!',color: 'red'})
      })
      .then(() => { this.fetchingData = false})
    },
    updateUserData(){
      this.$refs.basicForm.validate()
      if(!this.valid2) return 
      this.editMore=false
      
      instance.post(`/update/other`,this.changeUserData)
      .then((data) => {
        this.$store.commit('open_snackbar',{text: 'Update successful!', color: 'green'})
        this.profileData = this.changeUserData
      })
      .catch((ee) => {
        this.$store.commit('open_snackbar',{text: 'Update failed!', color: 'red'})
        this.editMore = true
      })
      .then(() => {
        this.inProgress = false
      })
    },
    async filesSelected(){
      console.log(this.$refs.fileInput.files)
      try {
        this.inProgress = true
        let file = this.$refs.fileInput.files[0]
        let cfile = await getCompressed(file)

        await uploadAws('/presignAvatar', '/saveAvatar', 'image/png', cfile, {})

        this.inProgress = false

        this.fetchUserProfile()

      } catch (error) {
        console.log(error)
        this.$store.commit('open_snackbar',{text: 'Update failed!', color: 'red'})
      }
      this.inProgress = false
    }
  },
  computed:{
    ...mapGetters(['auth','user']),
    
  },
  mounted(){
    if(this.auth) this.fetchUserProfile()
  },
  watch:{
    'this.auth'(val){
      if(val) this.fetchUserProfile()
    }
  }
}
</script>
