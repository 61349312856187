const ImageUploaded = (context, data) => {
    if (Array.isArray(context.userData.iu)){
        context.userData.iu = context.userData.iu.concat(data)
    } else {
        context.$set(context.userData,'iu', data)
    }
}

const PdfUploaded = (context, data) => {
    if (Array.isArray(context.userData.pu)){
      context.userData.pu = context.userData.pu.concat(data)
    } else {
      context.$set(context.userData,'pu', data)
    }
}

export default {ImageUploaded, PdfUploaded}