<template>
<div class="pagination">
    <v-btn
      :loading="loading"
      :disabled="loading || page==1"
      color="info"
      round @click="goPrev()"
    >
    <v-icon dark>keyboard_arrow_left</v-icon>
    Prev
    </v-btn>
    <v-btn
      :loading="loading"
      :disabled="loading || lastPage"
      color="info"
      round @click="goNext()"
    >Next
    <v-icon dark>keyboard_arrow_right</v-icon>
    </v-btn>
   
</div>
</template>
<script>
import { mapGetters, mapActions  } from 'vuex'

export default{
	data(){
      return {
        loading: false,
        filtersOpen: false,
      }
	},
	methods:{
      goNext(){
        let query = {...this.$route.query}
        query.page = parseInt(this.page)+1
        console.log(query, this.path)
        this.$router.push({ path: this.path, query })
        this.$emit('goNext')
      },
      goPrev(){
        let query = {...this.$route.query}
        query.page = parseInt(this.page)-1
        // console.log(query)
        this.$router.push({ path: this.path, query })
        this.$emit('goPrev')
      },
      openFilter(){
        this.$emit('openFilter')
      }
	},
	props:{
      lastPage: Boolean,
      page: Number,
      path: {
        type: String,
        default: 'study/'
      }
	},
	computed:{
    ...mapGetters(['auth']),
  },
}
</script>
<style scoped>
.pagination{
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}
</style>