<template>
<v-card class="text-xs-center" elevation="0" style="border-radius: 8px; background:trasparent"> 
    <v-card-text color="transparent">
        <v-layout row>
            <v-flex xs4 md2> 
                <v-avatar   
                    :tile="false"
                    :size="60"
                    color="grey lighten-4">
                <img v-if="user.photo" :src="user.photo" alt="avatar">
                </v-avatar>
            </v-flex>
            <v-flex xs8 md10 style="align-self: center; text-align: left;">
                <!---->
                <p> 
                    <span style="opacity: 0.6"><small>Welcome</small>&nbsp;</span> <br/>
                    <b style="font-size: 16px" class="primary--text">{{user.name}}</b>
                </p>
            </v-flex>
        </v-layout>
        <!-- <h3 class="headline m-0"><router-link to="/profile">{{user.name}}</router-link></h3> -->
        <!-- <small class="font-weight-light">CLASSROOM</small> - <b class="text-uppercase">{{user.classroom_name}}</b> 
        <br class="hidden-md-and-up"> &nbsp;
        <small class="font-weight-light mt-2">SUBJECTS</small> - <b class="text-uppercase">{{user.subjects.length?user.subjects.map(v => v.name).join(', '):'No subjects assigned!'}}</b>  -->
        <!-- <h3><small class="font-weight-light">RECOMENDED BROWSER:</small> <b> Latest version of <span style="color: red">{{user.browser || 'Chrome'}} Browser</span></b></h3>  -->
        <!--<v-divider></v-divider>-->
    </v-card-text>
    </v-card>
</template>
<script>
import {mapGetters} from 'vuex'

export default{
    data(){
        return {}
    },
    computed: {
        ...mapGetters(['user'])
    }
}
</script>