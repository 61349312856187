const groupBy = function(source, groupKey){
    const reducer = function(accumulator, currentObj){
        if(Array.isArray(accumulator[currentObj[groupKey]]) ) accumulator[currentObj[groupKey]].push(currentObj)
        else accumulator[currentObj[groupKey]] = [currentObj]
        return accumulator
    }

    return source.reduce(reducer, {})
}

export default groupBy