<template>
<div>
<!-- <div class="text-xs-center" v-if='loading'>
    <v-progress-circular
      :size="250"
      :width="10"
      color="indigo"
      indeterminate
    >Loading data ...</v-progress-circular>
  </div> -->
   <v-dialog
    v-model="loading"
    persistent
    width="300">
    <v-card color="primary" dark>
      <v-card-text>
        Loading data please wait...
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  <router-view v-if='!loading' :outData="testData" ></router-view>	
</div>
</template>
<script>
const {instance} = require('./../_axios.js');
export default{
	data(){
      return {
        testData: null,
        loading: false
      }
	},
	methods:{
      fetchTestOutData(){
        this.loading = true
      	instance.get('/test/out/'+this.$route.params.id)
      	.then((val) => {
          this.testData = val.data
      	})
      	.catch((err) => {
      	  console.log(err)
          this.$store.commit('open_snackbar',{text: err||'Something went wrong!',color: 'red'})
          setTimeout(()=> {
            this.$router.replace({path:'/test'})
          },1000)
      	})
      	.then((d) => {
          this.loading = false
      	})
      }
	},
	props:{
	},
	mounted(){
      // fetch data else clear before going to test
      if(this.isResultOrReview) this.fetchTestOutData()
      else this.testData = null
      
	},
  computed:{
    isResultOrReview(){
      return this.$route.path.indexOf('result')>-1 || this.$route.path.indexOf('review')>-1
    }
  },
	watch:{
	  '$route.path'(val){
        if(val.indexOf('result')>-1 || val.indexOf('review')>-1){
          if(this.testData==null) this.fetchTestOutData()
        } else {
          this.testData=null
        }
	  }
	}
}
</script>
<style>
</style>