<template>
    <div>
      <page-title>My Certificates</page-title>
      <div>
        <div class="text-xs-center" v-if='loading'>
            <v-progress-circular
              :size="250"
              :width="10"
              color="indigo"
              indeterminate
              >Loading Data...
            </v-progress-circular>
        </div>
        <v-flex xs12 _md8 _offset-md2 v-else>
          <div><v-btn color="primary" to="/certificate-request">Request Certificate</v-btn></div>
          <!--<h3 class="display-1 mb-2">Certificates</h3>-->
          <v-card class="mt-2">
            <v-layout>
              <v-flex xs12>
                  <v-data-table
                    :headers="headers"
                    :items="certificateList"
                    item-key="_id"
                    :rows-per-page-items="[10, 20, 50]"
                    class="elevation-1" >
                    <template v-slot:items="props">
                        <td >1</td>
                        <td ><a @click="dialog=false; fee = props.item">{{ props.item.type_name}} </a></td>
                      <td >{{ parseFloat(props.item.fee_amount).toFixed(2) }}</td>
                      <td >{{ props.item.delivery_status }} </td>
                      <td >{{ props.item.updated_at | dateFormat }}</td>
                      <td class="text-xs-center">
                        <v-btn small round outline color="primary" :disabled="!props.item.certificate_url" :href="props.item.certificate_url" target="_blank">Download</v-btn>
                        <!-- <v-btn small round outline @click="feePaymentItem = props.item; receiptDialog = true;"> Upload Receipt</v-btn> -->
                        <!--<v-btn small round outline @click="printReceiptRequest(props.item.fee_head_id, props.item._id, props.item.student_id)"> Print Receipt</v-btn>-->
                      </td>
                    </template>
                </v-data-table>
              </v-flex>
            </v-layout>
           </v-card>
        </v-flex>
        </div>
    
        <v-dialog v-model="dialog" width="500" :scrollable="true">
         <v-card v-if="fee">
            <v-card-title class="headline grey lighten-2"  primary-title> {{fee | feeName}} </v-card-title>
            <v-card-text class="pb-4">
              
              
            </v-card-text>
            <v-divider></v-divider>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn  color="primary" flat @click="dialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    
        <v-dialog v-model="loadingPrint" fullscreen :scrollable="true" persistent>
          <v-card>
            <v-card-text>
              <v-progress-circular :size="200" :width="7" color="indigo" indeterminate>Loading please wait...</v-progress-circular>
            </v-card-text>
          </v-card>
          
        </v-dialog>
    
    </div>
    </template>
    
    <script>
    const {instance} = require('./../../_axios.js');
    import moment from 'moment';

    export default {
        data() {
            return {
                loading: true,
                data: {},
                certificateList: [],
                certificateTypeList: [],
                selected: [],
                fee: null,
                dialog: false,
                paymentDialog: false,
                receiptDialog: false,
                feePaymentItem: null,
                loadingPrint: false,
                headers: [
                    { text: 'SL', sortable: false, value: '_id' },
                    { text: 'Certificate', sortable: false, value: 'certificate_type_name' },
                    { text: 'Fee Amount', sortable: false, value: 'fee_amount' },
                    { text: 'Status', sortable: false, value: 'delivery_status' },
                    { text: 'Last update', sortable: true, value: 'updated_at' },
                    { text: 'Action', sortable: false, align: 'center'},
                ]
            }
        },
        mounted(){
            this.load()
        },
        methods: {  
            async load(){
                try {
                    this.loading = true
                    let resp = await instance.get(`/certificate-request-types`)
                    this.certificateTypeList = resp.data

                    resp = await instance.get(`/certificate-requests`)
                    this.certificateList = resp.data
                    

                } catch (error) {
                    console.log(error.message)
                } finally{
                    this.loading = false
                }
            },
        },
        filters: {
            dateFormat: (val, format = "DD-MM-Y") => {
                return moment(new Date(val)).format(format)
            }
        },
    }
    </script>