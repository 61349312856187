import Compressor from 'compressorjs'

export default function (file){
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.8,
            maxWidth: 1080,
            maxHeight: 720,
            success(result) {
                resolve(result)
            },
            error(err) {
                console.log(err.message);
                reject(err)
            }
        })
    })
}