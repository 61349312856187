<template>
<div>
    <!-- :class="{'mx-auto':$vuetify.breakpoint.smAndDown}" -->
  <v-card class="mx-auto"  >
    <v-toolbar dark color="rgb(224, 68, 56)" class="pt-1 pb-1">
      <v-icon large left ripple> whatshot </v-icon>
      <v-toolbar-title>Motivation</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>

    <v-card-text style="height: 305px;">
      <div v-if="loading" class="headline font-weight-bold text-xs-center">
        <br><br><br>
        <v-progress-circular :width="4"  color="teal lighten-2"  indeterminate ></v-progress-circular>
      </div>
      <div v-if="motivation[0]"  v-html="motivation[0].content"></div>  
    </v-card-text>
  </v-card>
</div>
</template>
<script>
import {instance} from './../_axios.js'
import { mapGetters, mapActions } from 'vuex'
// import { database } from './../fireapp'

export default{
	data(){
      return {
      	loading:  false,
        // motivation: ''
      }
	},
	mounted(){
    // var docRef = database.collection("motivations");
    // this.loading = true
    // docRef.where('active','==',true).limit(1).get().then((querySnapshot) => {
    //     if (!querySnapshot.empty) {
    //       querySnapshot.docs.forEach((doc)=> {
    //         this.motivation = doc.data().content
    //       });
    //     } else {
    //         console.log("No such document!");
    //     }
    // }).catch(function(error) {
    //     console.log("Error getting document:", error);
    // })
    // .then(() => {
    //   this.loading = false
    // })
  },
  computed: {
    ...mapGetters(['motivation'])
  }
}
</script>
<style scoped>
.p4{
  padding: 1em;
}
.p4 p{
  margin-top: 4px;
  text-align: justify;
}

</style>
<style>
#citem .v-carousel__controls{
  background-color: #7e57c25c
}
</style>