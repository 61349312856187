<template>
<v-dialog v-model="showDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-card color="blue-grey darken-2 ">
    <v-toolbar dark color="primary">
        <v-btn icon dark @click="togglemQDialog(false);">
        <v-icon>close</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-items>
        <div class="subheading pt-3">
        <small class="timer-icon"><v-icon>schedule</v-icon> &nbsp;</small>
        <span v-html="'Overall = '+ timeLeft"></span>
        </div> 
    </v-toolbar-items>
    </v-toolbar>
    <v-card-text>
        <question-nav-all :on-select="() => togglemQDialog(false)"  :qpi="part"></question-nav-all>
        <v-btn v-if="!hidePreview" color="info" @click="$emit('showPreview')" block>Preview All Questions</v-btn>
        <v-btn color="blue" dark @click="$emit('showAttachments')" block>Show Attachments</v-btn>
    </v-card-text>
    </v-card>
</v-dialog>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import QuestionNavigatorAll from './../../components/QuestionNavigatorAll.vue'
export default {
    data() {
        return {
            // showDialog: false
        }
    },
    props: {
        // qNavDialog: {
        //     type: Boolean,
        //     default: false
        // },
        // timeLeftHtml: {
        //     type: String,
        //     default: () => ''
        // },
        qn: {
            type: Number,
            default: 1
        },
        selectQuestion: {
            type: Function,
            default: () => {}
        },
        close: {
            type: Function,
            default: () => {}
        },
        hidePreview: {
            type: Boolean,
            default: false
        },
        part: {
            type: Number,
            default: -1
        },
        // timeLeftPartHtml: {
        //     type: String,
        //     default: () => ''
        // }
    },
    components: {
        'question-nav-all': QuestionNavigatorAll
    },
    methods: {
        ...mapActions(['togglemQDialog'])
    },
    computed: {
        ...mapGetters([ 'questions', 'timeLeft', 'timeLeftPart', 'mQDialog' ]),
        showDialog() {
            return this.mQDialog
        }
        // localQuestions(){
        //     if(this.part>=0) return this.questions.filter(q => q.pi===this.part)
        //     return this.questions
        // }
    }
}
</script>