let colorBasket = [
    {color: 'orange darken-3', colorHex: '#ef6c00', colorDark: '#b35508'},
    //{color: 'success', colorHex: '#2E7D32', colorDark: '#1B5E20'},
    //{color: '#00695C', colorHex: '#00695C', colorDark: '#004D40'},
    //{color: '#EA80FC', colorHex: '#EA80FC', colorDark: '#077a88'},
    //{color: 'cyan', colorHex: '#077a88', colorDark: '#077a88'},
    //{color: 'blue-grey darken-2', colorDark: '#274757', colorHex: '#455a64'}
    // OTHER PALLETE
    //{color: '#CDB4DB', colorDark: '#274757', colorHex: '#455a64'}
  ]

  let palletes = [
    ['42a5f5', 'ab47bc', 'd32f2f', 'f57c00', '0288d1', '388e3c'],
    ['b35508', 'ab47bc', 'd32f2f', 'f57c00', '0288d1', '388e3c'],
    ['2E7D32', 'ab47bc', 'd32f2f', 'f57c00', '0288d1', '388e3c'],
    ['6A1B9A', 'ab47bc', 'd32f2f', 'f57c00', '0288d1', '388e3c'],
    //['264653', '2A9D8F', 'E9C46A', 'F4A261', 'E76F51']
  ].map(v => v.map(e => `#${e}`))

  export { palletes }
  export default colorBasket