// import TimeAgo from 'javascript-time-ago'
 
// // Load locale-specific relative date/time formatting rules.
// import en from 'javascript-time-ago/locale/en'
 
// // Add locale-specific relative date/time formatting rules.
// TimeAgo.addLocale(en)
 
// // Create relative date/time formatter.
// const timeAgo = new TimeAgo('en-US')

// export default timeAgo

export default function(previous) {
	var current = (new Date()).getTime()
	previous = (new Date(previous)).getTime()
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;
    // console.log(current, previous, elapsed)
    if (elapsed < msPerMinute) {
         return Math.round(elapsed/1000) + ' seconds ago';   
    }

    else if (elapsed < msPerHour) {
         return Math.round(elapsed/msPerMinute) + ' minutes ago';   
    }

    else if (elapsed < msPerDay ) {
         return Math.round(elapsed/msPerHour ) + ' hours ago';   
    }

    else if (elapsed < msPerMonth) {
        return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';   
    }

    else if (elapsed < msPerYear) {
        return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';   
    }

    else {
        return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';   
    }
}