
function calculate(data) {
	let attempted = 0, marked = 0, skipped = 0, imageCount = 0
	try{
		attempted = data.filter(q => q.ats!=null).length
		skipped = data.length - attempted
		marked = data.filter(q => q.mts!=null)
		data.forEach(element => {
			imageCount += (element.iu || []).length
		})
	} catch(e){
		console.log(e)
	}
	return {attempted, marked, skipped, imageCount}
}
export default calculate