import ImageProctorTick from './ImageProctor.js'
import DetectFace from './DetectFace.js'
import AutoSave from './AutoSave.js'
import NotifyUpload from './NotifyUpload'
import DetectFullScreen from './DetectFullScreen'
import DetectTimeout from './DetectTimeout'
import UpdatePart from './UpdatePart'
// import {FirePingTimer} from './PINGFIREBASE.js'
import TIMELEFTFORMAT from './TimeLeftFormat.js'

const initTimer = (context) => {
    context.duration = context.testData.format.duration*60
    context.timeLeft = context.userData.timeLeft 
    
    context.timeStart = Date.now()
    context.timeEnd = Date.now()+context.timeLeft*1000
}

const updateTimer = (context) => {
    DetectFace(context)
    context.updateIntervalID = setInterval(()=>{
      
      context.timeLeft = Math.round((context.timeEnd-Date.now())/1000)
     
      context.increaseTimeSpent()

      // context.localUpdate[context.qn-1] = true
      
      DetectTimeout(context)

      NotifyUpload(context)
      
      if(context.pauseProctor) return

      AutoSave(context)
      ImageProctorTick(context)
      
      DetectFullScreen(context)
      UpdatePart(context)

      context.$store.commit('set_time_left', TIMELEFTFORMAT(context.formatTimeLeft))
      context.$store.commit('set_time_left_part', TIMELEFTFORMAT(context.formatTimeLeftPart))
      // FirePingTimer(context)
    }, 1000) // decrease each second interval
}
export {initTimer, updateTimer}