<template>
<div class="small-circles">
	<div v-for="i in Math.round(Math.random()*5+5)" class="c"  :key="i"
	:style="{
	  background: colorDark, 
	  top: hi==active_hover?`${Math.random()*70+10}%`:`${Math.random()*70+10}%`,
	  visibility: hi==active_hover?'visible':'hidden', 
	  left: hi==active_hover?`${Math.random()*70+10}%`:`${Math.random()<0.5?100:0}%`}">
	</div>
</div>
</template>
<style type="text/css" scoped>
.small-circles{
  background: red
}
.small-circles .c{
  width: 4px;
  height: 4px;
  border-radius: 50%;
  position: absolute;
  transition: all 1s linear;/*cubic-bezier(0.68, 0.02, 1, 0.59);*/
}

#circles>span{
  display:inline-block;
  position: absolute;
  top: 170px;
  left: 20%;
  width: 100px;
  height:100px;
  border-radius: 50%;
  background-color:green;
  opacity:0.02;
  animation-name: circles1;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}
#circles>span:nth-child(2){
  top:190px;
  left: 30%;
  width: 50px;
  height:50px;
  background-color:red;
  opacity:0.03;
  animation-name: circles2;
  animation-delay:2s
}
#circles>span:nth-child(3){
  top:  180px;
  left: 60%;
  width: 70px;
  height:70px;
  background-color:brown;
  opacity:0.02;
  animation-name: circles3;
  animation-delay:1.5s
}

#circles>span:nth-child(4){
  top:  80px;
  left: 80%;
  width: 60px;
  height:60px;
  background-color:blue;
  opacity:0.03;
  animation-name: circles4;
  animation-delay:3s
}
#circles>span:nth-child(5){
  top:  200px;
  left: 70%;
  width: 90px;
  height:90px;
  background-color:teal;
  opacity: 0.03;
  animation-name: circles5;
  animation-delay:3s
}
.h158{
  height:158px;
}

@media screen and (max-width: 600px) {
  #title-exams{
    font-size: 16px !important
  }
  #section-1{
    min-height:150px
  }
  #circles>span:nth-child(4){
    top:  80px;
    left: 20%;
    width: 60px;
    height:60px;
    background-color:blue;
    opacity:0.03;
    animation-name: circles4;
    animation-delay:3s
  }
}

@keyframes circles1 {
  from {top: 170px;}
  to {top: -120px}
}
@keyframes circles2 {
  from {top: 190px;}
  to {top: -120px}
}
@keyframes circles3 {
  from {top: 180px;}
  to {top: -120px}
}
@keyframes circles4 {
  from {top: 80px;}
  to {top: -120px}
}
@keyframes circles5 {
  from {top: 200px;}
  to {top: -120px}
}

</style>
<script>
export default {
	data(){
		return {
		}
	},
	props: ['colorDark','active_hover','hi']
}
</script>