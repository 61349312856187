import {TestLocation, queryLocation, logLocation, auth, user } from './../initialValues'

export default  {
    state: () => ({  }),
    mutations: {},
    actions: { 
        sendNewChatMessage({ rootState }, {userId,  payload}){
            return rootState.global.firebaseApp.firestore().collection(queryLocation+'_n').doc(userId).set(payload)
        },
        sendChatMessage({ rootState }, { payload }){
            return rootState.global.firebaseApp.firestore().collection(queryLocation+'_a').add(payload)
        },
        hookChat({ rootState }, { mockId, userId, onData }){
            if(!rootState.global.firebaseApp) return console.log('Firebase not initialised!')
            else console.log('Initializing chat..')
            rootState.global
            .firebaseApp
            .firestore()
            .collection(queryLocation+'_a')
            .where('tid', '==', mockId)
            .where('uid', '==', userId)
            .orderBy('ts','desc')
            .onSnapshot(onData)
        },
        setAllRead({rootState}, {mockId, userId, onReadUpdate}){
            rootState.global
            .firebaseApp
            .firestore()
            .collection(queryLocation+'_a')
            .where('tid', '==', mockId)
            .where('uid', '==', userId)
            .where('r', '==', 0)
            .onSnapshot(async (snapShot) => {
                var promises = snapShot.docs.map( s => {
                    console.log(s)
                    return s.ref.update({r: 1})
                });
                try {
                    await Promise.all(promises)
                    onReadUpdate()
                    } catch (error) {
                    console.log(error)
                }
            })
        },
    },
    getters: { }
  }