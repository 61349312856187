
function openFullscreen() {
  console.log('opening full screen')
  try{
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      return elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      return elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
      return elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      return elem.msRequestFullscreen();
    } return Promise.reject('fail')
  } catch(e){
    console.log(e)
    return false
  }
}

/* Close fullscreen */
function closeFullscreen() {
  try{
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) { /* Firefox */
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) { /* IE/Edge */
      document.msExitFullscreen();
    }
  } catch(e){
    console.log(e)
  }
  
}

export {openFullscreen, closeFullscreen}