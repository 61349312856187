<template>
  <v-dialog v-model="show" fullscreen  style="z-index:208">
    <v-card>
      <v-card-text class="text-xs-center">
        <v-toolbar dark color="teal darken-3" fixed>
          <v-btn icon dark @click="close()">
            <v-icon>arrow_back</v-icon>
          </v-btn>
          <v-toolbar-title>Upload File</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
                
            <template v-if="state!='take-picture'">
                <v-btn dark flat @click="updateState('take-picture')" class="hidden-sm-and-down"> <v-icon left>photo_camera</v-icon> Take Snapshot</v-btn>
                <v-btn dark flat @click="$refs.fileInput.click()"> <v-icon left>attach_file</v-icon> File Upload</v-btn>
            </template>
            <template v-else>
                <v-btn dark flat @click="$refs.snapper.takepicture()" > <v-icon left>photo_camera</v-icon> Capture</v-btn>
            </template>
           
          </v-toolbar-items>
        </v-toolbar>
        <v-divider></v-divider>
            <v-alert :value="!!message" color="info" outline class="mt-4"> {{message}}</v-alert>
            <upload-progress v-if="uploading" :upload-statuses="uploadStatuses" :uploading="uploading"></upload-progress>
            
            <fuhome v-if="state==null" @updateState="updateState" :uploaded-files="data" @deleteFile="deleteFile"></fuhome>
            <take-picture v-if="state=='take-picture'" @updateState="updateState" ref="snapper" @takePicture="(files) => $emit('uploadFile', files)"></take-picture>
            <!-- FILE UPLOAD HOME -->

            <!-- TAKE PICTURE -->

            <!-- UPLOAD PROGRESS -->

      </v-card-text>

    </v-card>
    <input type="file" style="width:0; height:0; visibility: hidden;position: absolute;top: 0;" :accept="fileFormats" @change="filesSelected()" ref="fileInput" multiple>
   <!-- <input style="width:0; height:0; visibility: hidden;position: absolute;top: 0;" type="file" accept="image/*" capture="environment" @change="mobileCamCaptured" ref="mobileCapture" /> -->
  </v-dialog>
</template>

<script>
import FileUploadHome from './FileUploadHome'
import TakePicture from './TakePicture'
import UploadProgress from './UploadProgress'

export default {
    data(){
      return {
        message: '',
        state: null,
        selectedImages: [],
        selectedFiles: [],
        data: {
          iu: [],
          pu: [],
          eu: [],
          vu: [],
          au: [],
        },
        fileFormats: 'image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,video/*,audio/*',
        isDesktop: window.innerWidth>500,
      }
    },
    components: {
      'fuhome': FileUploadHome,
      'take-picture': TakePicture,
      'upload-progress': UploadProgress
    },
    props: {
      show: {
        type: Boolean,
        default: false
      },
      existingFiles: {
        type: Array,
        default: () => {}
      },
      uploading: {
        type: Boolean,
        default: false
      },
      uploadStatuses: {
        type: Array,
        default: () => []
      }
    },
    mounted(){},
    methods: {
        updateState(val){
          this.state = val
        },
        close(){
          if(this.state!=null) return this.state = null
          this.$emit('close')
        },
        deleteFile(data){
          this.$emit('deleteFile', data)
        },
        filesSelected(){
          // console.log(Array.from(this.$refs.fileInput.files))
          this.$emit('uploadFile', Array.from(this.$refs.fileInput.files))
        }
    }, 
    watch:{
      existingFiles(val){
        for(let x in this.data){
          this.data[x] = val[x] || []
        }
      }
    }
}
</script>
