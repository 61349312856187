import PINGFIREBASE from './PINGFIREBASE.js'

const revertFab = (context) => {
    setTimeout(() => {
      context.fab = -1
      context.syncing = false
    },1000)
}

const updateRoute = (context) => {
    if(parseInt(context.$route.query.qi || 0)!==context.qi)
    context.$router.replace({path: context.$route.path, query: { ...context.$route.query, qi: context.qi }})
    PINGFIREBASE(context)
}

export default {
    revertFab,
    updateRoute
}