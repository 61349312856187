<template>
<div>
  <test-toolbar 
    :error="error" 
    :test-name="testData?testData.name:''" 
    :time-left-html="timeLeftHTML"
    :time-left-part-html="timeLeftPartHTML"
    :next-disabled="nextDisabled"
    @showupload="showUploadDialog()"
    @showqnav="qNavDialog=true">
  </test-toolbar>
  
  <div v-if="!error">
    <!-- <v-dialog v-model="askingPermission" fullscreen  style="z-index:202">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Permission required</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="text-xs-center" style="padding: 50px 0">
          <h3 class="title mb-4">{{permissionMessage}}.</h3>
          <v-btn color="primary" @click="startTest()">Retry</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <v-dialog v-model="showPreview" fullscreen  style="z-index:202" scrollable>
      <test-preview v-if="showPreview" :questions="questions.filter(q => q.pi==activePartIndex)" @close="showPreview=false"></test-preview>
    </v-dialog>

    <v-layout :class="$vuetify.breakpoint.mdAndUp?'row':'column'" class="mathcontent min80vh">
      <v-flex xs12 md8 :class="{'large-text':largeText}">
        <div v-if="question" style="margin-bottom: 100px">
          <question-header :question="question" :total-questions="totalQuestions" :part-index="activePartIndex" :part="activePart"></question-header>
          <question-item 
            ref="activeQuestionItem"
            :key="question.id" 
            :_question="question" 
            :submitdialog="submitdialog"
            :time-left-html="timeLeftHTML"
            :show-audio-recording="testConfig.AUDIO_RECORDING"
            :stream="localStream?localStream.stream:null"
            @answer="setAnswer"
            @answerDeleted="answerDeleted"> 
          </question-item>
        </div>
      </v-flex>

      <!-- DESKTOP QUESTION NAVIGATOR -->
      <v-flex 
        xs12 
        md4 
        offset-md1 
        v-if="$vuetify.breakpoint.mdAndUp && questions.length">
        <desktop-q-nav
          :qn="qn"
          :time-left-html="timeLeftHTML"
          :time-left-part-html="timeLeftPartHTML"
          :select-question="selectQuestion"
          :large-text="largeText"
          :hide-preview="testConfig.HIDE_TEST_PREVIEW"
          :part="activePartIndex"
          @toggleSize="toggleTextSize()"
          @showPreview="() => showPreview=true">
        </desktop-q-nav>
        
        <br><a ><img  src="./../../assets/logo.png" width="200px"></a><br>
      </v-flex>
    </v-layout>
 
    <!-- MOBILE QNAVIGATOR -->
    <mobile-q-nav
      :qn="qn"
      :part="activePartIndex"
      :q-nav-dialog="qNavDialog"
      :time-left-html="timeLeftHTML"
      :time-left-part-html="timeLeftPartHTML"
      :select-question="selectQuestion"
      :hide-preview="testConfig.HIDE_TEST_PREVIEW"
      :close="() => qNavDialog = false"
      @showPreview="() => { qNavDialog = false; showPreview=true } ">
    </mobile-q-nav>

<chat v-if="!testConfig.DISABLE_CHAT && testData" 
:show="showChatPanel" 
:test-mockid="testData._id" 
:test-name="testData.name" 
@close="showChatPanel=false"
@extendTest="extendTest"
@startProctor="startProctor( initVideoStream , true)"></chat>
<!-- && showChatPanel -->
<bottom-nav 
  :overlayLoading="overlayLoading"
  :syncing="syncing"
  :fab="fab"
  :save-and-next="saveAndNext"
  :prev="prev"
  :next="next"
  :q-length="questions?questions.length:0"
  :prev-disabled="prevDisabled"
  :next-disabled="nextDisabled"
  :disable-chat="testConfig.DISABLE_CHAT"
  @toggleChat="() => showChatPanel=!showChatPanel"
  @showChat="() => showChatPanel=true"
></bottom-nav>
  

<!-- LOADING PROGRESS -->
  <v-dialog v-model="overlayLoading" persistent width="300">
    <v-card color="info" dark >
      <v-card-text>
        {{overlayMessage}}
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>

<!-- SUBMIT DIALOG -->
  <submit-dialog 
    :submit-overview="submit_overview" 
    :time-left="formatTimeLeft" 
    :submitdialog="submitdialog" 
    @submitTest="submitTest()"
    @closeSubmitDialog="submitdialog=false">
  </submit-dialog>

<!-- UPLOAD DIALOG -->
  <upload-dialog 
    v-if="userData"
    :stream="localStream?localStream.stream:null"
    :show="uploadDialog" :test-id="userData._id"
    :test-images="userData.iu || []" 
    :pdf-files="userData.pu || []" 
    :time-left="timeLeftHTML"
    :pdf-upload="testConfig.PDF_UPLOAD"
    @imageUploaded="imageUploaded"
    @pdfUploaded="pdfUploaded"
    @closeDialog="uploadDialog=false"
    @showSubmitDialog="showSubmitDialog()">
  </upload-dialog>

<!-- WARNING DIALOG -->
  <warning-dialog :show-warning-dialog="showWarningDialog" @hidewarning="showWarning=false" :blur-time="String(blurTime)"></warning-dialog>
  <multiple-faces  v-if="testConfig.DETECT_MULTIFACE" @multiFaces="notifyMultiFaces" @modelLoading="setModelLoading" :submitted="submitted"></multiple-faces>
  </div>
  <div v-else class="text-xs-center"> 
    <h3 class="display-2 pt-4">{{error}}</h3> 
  </div>
  <detect-speech v-if="testData && !testConfig.isMobileDevice && testConfig.DETECT_SPEECH" :mock-id="testData._id"></detect-speech>

  <v-dialog v-model="restTime"  persistent max-width="500">
    <v-card>
        <v-card-title class="headline blue lighten-3 dark">
          <v-icon>alarm</v-icon> Time over for {{activePart && activePart.name?activePart.name:`Part ${activePartIndex+1}`}}.
        </v-card-title>

        <v-card-text class="text-xs-center">
          <h3 class="red--text title">Next part will start in <b>{{timeLeftPart}}</b> seconds.</h3>
        </v-card-text>
    </v-card>
</v-dialog>

</div>
</template>
<script>
import * as firebase from "firebase/app";
const { instance } = require('./../../_axios.js');
// import configuration from './../../rtcConfig'
import { mapGetters, mapActions } from 'vuex'

import Chat from './../../components/ChatOverlay.vue'

import DetectSpeech from './../../components/DetectSpeech.vue'
import MultipleFaces from './MultipleFaceDetector.vue'

import TestToolbar from './TestToolbar.vue';
import BottomNav from './BottomNav.vue'
import TestQuestionItem from './../../components/TestQuestionItem.vue';
import QuestionHeaderPartwise from './QuestionHeaderPartwise.vue'
import WarningDialog from './Warning.vue';
import SubmitDialog from './SubmitDialog.vue'
import UploadDialog from './UploadDialog.vue'
import TestPreview from './TestPreview.vue';

import MobileQNavigator from './MobileQNavigator.vue';
import DesktopQNavigator from './DesktopQNav.vue';
// import {hangUp, webexHelper} from './webexapi'

import { DateTime, Duration } from "luxon";
// Utils
import PINGFIREBASE, {FirePingTimer} from './utils/PINGFIREBASE.js'
import ImageProctorTick from './utils/ImageProctor.js'
import AutoSave from './utils/AutoSave.js'
import NotifyUpload from './utils/NotifyUpload'
import DetectFullScreen from './utils/DetectFullScreen'
import DetectMouseOut from './utils/DetectMouseOut'
import DetectTimeout from './utils/DetectTimeout'
import DetectVisibility from './utils/DetectVisibility'
import {openFullscreen, closeFullscreen} from './../../utils/fullscreen.js'
import submitCount from './../../utils/TestQuestionAttemptCounts'
import {extractQuestion, appendUserData, hasAnswer} from './../../utils/questionExtractor';
import requestWakeLock from './../../utils/wakeLock'
import envChangeDetecor from './utils/envChangeDetector.js'
import TIMELEFTFORMAT from './utils/TimeLeftFormat.js'
import FireLog from './utils/Firelog'
import WarningOffset from './utils/WarningOffset'
import ApplyConfig from './utils/ApplyConfig'
import ReleaseMedia from './../../utils/releaseMedia'
import UpdatePart from './utils/UpdatePart'

import testConfig from './utils/readEnv'

window.api ;
export default{
    name: 'TestInside',
	data(){
	  return {
      qn: this.$route.query.qn?parseInt(this.$route.query.qn) : 1,
      activePartIndex: 0,
      totalQuestions: 0,
      testData: null,
      userData: null,
      instructions: null,
      loading: false,
      overlayLoading: true,
      overlayMessage: '',
      qNavDialog: false,
      showSectionInstruction: false,
      fab: -1,
      syncing: false,
      submitdialog: false,
      uploadDialog: false,
      timeLeft: 999999,
      timeLeftPart: 999999,
      partRestTime: false, 
      duration: 999999,
      error: null,
      localUpdate: {},
      faceCount: 1,
      updateIntervalID: null,
      submit_overview: {},
      notifyUploadSheet: false,
      
      blurCount: parseInt(sessionStorage.getItem('blurCount')) || 0,
      isBlur: false,
      isFullscreen: false,

      submitted: false,

      partwiseTimeGap: 0,
      restTime: false,
      
      blurTime: testConfig.MAX_BLUR_ALLOWED_TIME,
      warningOffset: testConfig.WARNING_OFFSET,

      showWarning: false,
      showChatPanel: false,
      showPreview: false,
      localStream: null,
      denied: {
        screen: false,
        webcam: false
      },
      askingPermission: false,
      permissionMessage: 'Checking webcam and audio permission status...',

      largeText: localStorage.getItem('large_text')==='true',
      timeStart: null,
      timeEnd: null,
      mediaDevices: [],
      mouseOut: false,
      testConfig,
      lastImageProctor: null,
      PTYPES: ProctorHelper.PROCTOR_TYPES,
      isDesktop: window.innerWidth > 500

    }
  },
  created(){},
  mounted(){
      WarningOffset(this)
      this.startTest()
	},
    methods:{
    ...mapActions(['initJitsiProctor', 'updateQuestion', 'setJitsiOptions', 'increaseTimeSpent', 'saveLog', 'hangupVideoProctor']),
    startTest(){
      this.fetchTest()
      this.$nextTick(() => {
        this.$store.commit('set_in_test',    true)
        this.$store.commit('toggle_toolbar', false)
        openFullscreen().catch(()=> this.showWarning = true)
        requestWakeLock()
      })
    },
    async initVideoStream(callback) {
      console.log('Getting media stream')
      if(this.testConfig.PROCTOR_TYPE !== 'none') {
        ReleaseMedia(this.localStream)
        try {
          this.localStream = await navigator.mediaDevices.getUserMedia({video:  true, audio: false});
          document.querySelector('#localVideo').srcObject = this.localStream;
        } catch (error) {
          console.log(error)
          this.denied.webcam = true
          this.askingPermission = true
          this.permissionMessage = 'Please allow webcam and microphone access permission to start test..'
          return
        }
        this.askingPermission = false
        this.startProctor(callback)
      } else {
        callback()
      }
    },
    startProctor(callback = () => {}, now = false){
      if(this.testConfig.PROCTOR_TYPE === 'screen') callback()
      else {
        this.setJitsiOptions({
          user: this.user, 
          mockID: this.$route.query.id, 
          roomNo: this.$route.query.room || 'common',
          roomPrefix: this.testConfig.ROOM_PREFIX,
          roomPass: this.testConfig.ROOM_PASS,
          type: this.testConfig.PROCTOR_TYPE,
          domain: this.testConfig.PROCTOR_DOMAIN
        })
        if(this.testConfig.PROCTOR_TYPE === 'jitsi-multiple')  this.initJitsiProctor({})
        else if(this.testConfig.PROCTOR_TYPE === 'jitsi-single' && now)  this.initJitsiProctor({
          onJoin: () => {},
          onLeave: () => {
            console.error('Loading video stream')
            this.initVideoStream()
          }
        })
      }
    },
  
	fetchTest(){
    this.messageLoader(true, 'Fetching Test Data. Please wait ... ')
    instance.get(this.testURL)
    .then((resp) => {
      this.checkIfAlreadySubmitted(resp.data)
      ApplyConfig(this, resp.data.testConfig)
      sessionStorage.setItem('blurCount', 0)
      this.extractData(resp.data)
      
      PINGFIREBASE(this)
      FireLog(this, 'FETCH')
      envChangeDetecor(this)
      
      this.error = null
      this.initVideoStream(() => {})
    })
    .catch((err) => { 
      console.log(err)
      try{
        this.error = err.message || err.response?err.response.data.message:'';
      }catch(e){
        console.log(e)
        this.error = 'Something went wrong!'
      }
      this.$store.commit('open_snackbar', { text: this.error, color: 'red' })
      this.$router.go(-1) 
    })
    .then(() => {
      this.overlayLoading = false
      if(!this.error){  
          this.initTimeLeft() 
          this.updateTimers()

          // RECALCULATE TIMELEFT ON VISIBILITY CHANGE
          DetectVisibility(this)
          DetectMouseOut(this)
      }
    })
    },
    checkIfAlreadySubmitted(data){
      if(data.type && data.type===100) {
        this.$store.commit('open_snackbar', {text: data.message, color: 'red'})
        this.$store.commit('set_in_test',    false)
        let query = {reason: 'Test already submitted.'}
        this.$router.replace({ path: '/test-submitted', query }) 
        throw Error('Test already submitted!')
      }
    },
    hasLocalData(){
      if(sessionStorage){
        return sessionStorage.getItem(this.$route.params.id)
      }
      return false
    },
    extractData(data){
      this.testData = data.testData
      this.userData = data.userData

      this.instructions = data.instructions

      this.$store.commit('set_questions', appendUserData( extractQuestion(this.testData), this.userData.test_data))

      this.reversedParts = this.testData.format.parts.concat([]).reverse()
      this.partwiseTimeGap = (this.testData.format.partwise_time_gap || 0)*60
      this.totalQuestions = this.questions.length //parseInt(data.userData.test_data[data.userData.test_data.length-1].qn)
      if(this.userData.last_qn) this.qn = parseInt(this.userData.last_qn)
      // delete data
    },
    initTimeLeft(local){
      this.duration = this.testData.format.duration*60
      this.timeLeft = this.userData.timeLeft 
      
      this.timeStart = Date.now()
      this.timeEnd = Date.now()+this.timeLeft*1000
    },
    toggleTextSize(){
      this.largeText = !this.largeText
    },
    updateTimers(){
      this.updateIntervalID = setInterval(()=>{
        
        this.timeLeft = Math.round((this.timeEnd-Date.now())/1000)
       
        this.increaseTimeSpent(this.qn-1)

        this.localUpdate[this.qn-1] = true
        
        DetectTimeout(this)

        NotifyUpload(this)
        
        if(this.pauseProctor) return

        AutoSave(this)
        ImageProctorTick(this)
        
        DetectFullScreen(this)
        
        FirePingTimer(this)

        UpdatePart(this)
      }, 1000) // decrease each second interval
    },
    prepareSync(type){
      /* create params
      *  set answer and set marked if exists
      *  return payload
      */
      this.localUpdate[this.qn-1] = true
      return { 
        id: this.userData._id, 
        updates: this.getLocalUpdates(), 
        qn: this.qn
      }
    },

    getLocalUpdates(){
      return Object.keys(this.localUpdate).map( index => {
        let question = {...this.questions[index]}
        delete question.question
        delete question.A
        delete question.B
        delete question.C
        delete question.D
        delete question.answer
        delete question.sk
        return question
      })
    },
   
    saveAndNext(){
      this.fab = 3
      this.syncing = true

      instance.post('/test/sync', this.prepareSync())
      .then(data => { 
        this.reflectLocalUpdate()
        this.next() 
      })
      .catch(err =>  console.log(err) )
      .then(this.revertFab())
    },
    markQ() {
      this.fab = 2
      this.syncing = true
      instance.post('/test/sync', this.prepareSync('mark'))
      .then(data => { 
        this.reflectLocalUpdate()
        this.next()  
      })
      .catch(err => console.log(err))
      .then(this.revertFab())
    },
    clearQR() {
      this.fab = 4
      this.syncing = true

      instance.post('/test/sync', this.prepareSync('clear'))
      .then(data => { 
          // this.disableClear = true
          this.$refs.activeQuestionItem.clearLocalTypedAnswer() // for clearing local typed answer
          this.reflectLocalUpdate()
       })
      .catch(err => console.log(err))
      .then(this.revertFab())
    },
    reflectLocalUpdate(){
      this.localUpdate = {}
    },
    showSubmitDialog(){
      this.submitdialog = true
      this.submit_overview = submitCount(this.questions)
    },
    prev() {
      if(!this.prevDisabled) { 
        if(this.testData.format.partwise_timing){
          if(this.qn === this.activePart.fqn)  {
            this.$store.commit('open_snackbar', {text: 'Previous part questions are not accesible at this moment.', color: 'info'})
            return false
          }
        } 
        this.qn -= 1
      }
      this.$nextTick(() => {
        this.updateRoute()
        this.revertFab()
      })
    },
    next() {
      this.fab = 1
      this.revertFab()
      if(!this.nextDisabled) {
        if(this.testData.format.partwise_timing){
          if(this.qn === this.activePart.lqn)  {
            this.$store.commit('open_snackbar', {text: 'You have reached last question of the Part.', color: 'info'})
            return false
          }
        } 
        this.qn += 1
      } else {
        this.$store.commit('open_snackbar', {text: 'You have reached last question of the test.', color: 'info'})
      }
      this.updateRoute() 
    },
    
    revertFab() {
      setTimeout(() => {
        this.fab = -1
        this.syncing = false
      },1000)
    },
    updateRoute(){
      this.$router.replace({path: this.$route.path, query: { ...this.$route.query, qn: this.qn }})
    },
    selectQuestion(data){
      if(this.$vuetify.breakpoint.smAndDown) this.qNavDialog = false //data.close
      this.qn = data
      this.$nextTick(() =>  this.updateRoute())
    },
    submitTest(reason){
      clearInterval(this.updateIntervalID) 
      this.messageLoader(true, 'Submitting Test. Please Wait ..')
      this.submitdialog = false
      
      instance.post('/test/submit', {id: this.userData._id, updates: this.getLocalUpdates(), terminated: reason})
      .then(async data => { 
        
        setTimeout(() => {
          window.location.href = process.env.VUE_APP_URL+`/test-submitted?${reason?'reason='+reason:''}&tid=${this.testData._id}&uid=${this.user.id}`
        }, 1000)
      })
      .catch(err =>   {
        console.log(err)
        this.$router.replace({ path: '/test-submitted', query: {} }) 
      })
      .then(() =>  {  this.overlayLoading = false })
    },
    imageUploaded(data){
      // answer sheet upload before final test
      if (Array.isArray(this.userData.iu)){
        this.userData.iu = this.userData.iu.concat(data)
      } else {
        this.$set(this.userData,'iu', data)
      }
    },
    pdfUploaded(data){
      if (Array.isArray(this.userData.pu)){
        this.userData.pu = this.userData.pu.concat(data)
      } else {
        this.$set(this.userData,'pu', data)
      }
    },
    
    setAnswer({qn, answer}){
      var question = {...this.questions[qn-1]}
      if(Array.isArray(answer)) {
        question.ua = answer
        if(answer.length) question.ats = new Date()
        else question.ats = null
      } else {
        if(answer==='' || answer === null) {
          question.ua = null
          if(question.iu.length || question.vu.length) question.ats = new Date()
          else question.ats = null
        }
        else {
          question.ua = answer
          question.ats = new Date()
        }
      }
      
      this.localUpdate[qn-1] = true
      this.updateQuestion({index: qn-1, question})
    },
    silentSync(){
      instance.post('/test/sync', this.prepareSync())
      .then(data => { 
        console.log('Auto Saved!')
      })
      .catch(err =>  console.log(err) )
    },
    answerDeleted(data){
      console.log(data)
      let question = {...this.questions[this.qn-1]}
      question[data.type] = data.data
      if(data.data.length<1) question.ats = null
      else question.ats = new Date()
      this.$set(this.question,question)
      this.updateQuestion({index: question.qn-1, question})
    },
    notifyMultiFaces(nof = 1){
      this.faceCount = nof
      if(nof>1) {
        PINGFIREBASE(this)
        FireLog(this, 'MULTI_FACE')
      }
    },
    setModelLoading(val){
      this.messageLoader(val, 'Initiating AI video detector ..')
    },
    messageLoader(bool, text=''){
      this.overlayLoading = bool
      this.overlayMessage = text
    },
    showUploadDialog(){
      if(this.testConfig.HIDE_PRE_SUBMIT){ this.showSubmitDialog() } 
      else { this.uploadDialog = true }
    },
    extendTest(minutes){
      try {
        clearInterval(this.updateIntervalID)
        this.timeEnd += minutes*60*1000
        this.timeLeft += minutes*60

        this.updateIntervalID = null
        setTimeout(() => {
          this.updateTimers()
        },1000)
      } catch (error) {
        console.log(error)
      }
      this.$store.commit('open_snackbar', {text: `Test time extended by ${minutes} minutes.`, color: 'info'})
    }
	},
  beforeDestroy(){
    
    this.$store.commit('set_in_test',    false)
    this.$store.commit('toggle_toolbar', true)

    try{
      this.hangupVideoProctor()
      if(this.localStream) this.localStream.getTracks().forEach((track) => track.stop())
    } catch(e){
      console.log(e)
    }

  },
  
  computed: {
    ...mapGetters([ 'inTest', 'user', 'pauseProctor', 'questions', 'firebaseApp', 'jitsiLoaded']),
    question(){
      if(this.questions.length<1) return null
      return this.questions[this.qn-1]
    },
    prevDisabled() {
      return this.qn <= 1
    },
    nextDisabled() {
      return this.qn==this.totalQuestions
    },
    testURL() {
      return '/test/in/'+this.$route.params.id+"?ts="+Date.now()
    },
    formatTimeLeft() {
      return Duration.fromObject({hours: 0, minutes: 0, seconds: this.timeLeft}).normalize().values
    },
    showBottomNav(){
      return !this.submitdialog && !this.overlayLoading
    },
    showWarningDialog(){
      return this.showWarning && this.warningOffset<=0 // && this.testConfig.DETECT_FULLSCREEN
    },
    timeLeftHTML(){
      return TIMELEFTFORMAT(this.formatTimeLeft)
    },
    timeLeftPartHTML(){
      return TIMELEFTFORMAT(Duration.fromObject({hours: 0, minutes: 0, seconds: this.timeLeftPart}).normalize().values)
    },
    activePart(){
      if(this.testData) return this.testData.format.parts[this.activePartIndex]
      else {}
    }
    
  },
  components: {
    'question-item': TestQuestionItem,
    'submit-dialog': SubmitDialog,
    'upload-dialog': UploadDialog,
    'chat': Chat,
    'test-toolbar': TestToolbar,
    'warning-dialog': WarningDialog,
    'test-preview': TestPreview,
    'multiple-faces': MultipleFaces,
    'detect-speech': DetectSpeech,
    'question-header': QuestionHeaderPartwise,
    'bottom-nav': BottomNav,
    'mobile-q-nav': MobileQNavigator,
    'desktop-q-nav': DesktopQNavigator
  },
  watch: {
    'inTest'(val) {
      if(val) this.showWarning = true
      else closeFullscreen()
    },
    'pauseProctor'(val) {
      FireLog(this, val?'IMAGE_CAPTURE':'IMAGE_CAPTURE_RESUME')
    }
  }
}
</script>
<style >
.large-text{
  font-size: 200% !important;
}
.min80vh{
  min-height: 80vh;
}
.test-logo{
	padding: 4px;
	margin: 5px 20px;
}
#mobile-toolbar>.v-toolbar__content{
  justify-content:space-between
}
.timer-icon i{
  margin-bottom: -4px;
}
.table-bordered{
  border-collapse: collapse;
}
.table-bordered thead{
  padding: 5px 0
}
.table-bordered  td{
  border:1px solid #eee;
  padding: 5px;
}

</style>