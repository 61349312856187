const { instance } = require('./../../../_axios.js');
import FireLog from './Firelog'
import ApplyConfig from './ApplyConfig'
import PINGFIREBASE from './PINGFIREBASE'
import DetectMouseOut from './DetectMouseOut'
import DetectVisibility from './DetectVisibility'
import envChangeDetector from './envChangeDetector.js'
import {initTimer, updateTimer}  from './TestTimer'
import LoadingHelper  from './LoadingHelper'
import {extractQuestion, appendUserData} from './../../../utils/questionExtractor';


export default (context) => {
    LoadingHelper(context, true, 'Fetching Test Data. Please wait ... ')
    instance.get(context.testURL)
    .then((resp) => handleSuccess(context, resp))
    .catch((err) => handleError(context, err))
    .then(()     => handleFinally(context))
}

const handleSuccess = (context, resp) => {
    handleIfSubmitted(context, resp.data)
    ApplyConfig(context, resp.data.testConfig)
    sessionStorage.setItem('blurCount', 0)
    extractData(context, resp.data)
    context.$store.dispatch('selectQuestion', context.$store.state.assessment.qi)
    PINGFIREBASE(context)
    FireLog(context, 'FETCH')
    
    context.error = null
    context.initStream(() => {})
}

const handleError = (context, err) => {
    try{
        context.error = err.message || err.response?err.response.data.message:'';
    }catch(e){
        console.log(err, e)
        context.error = 'Something went wrong!'
    }
    context.$store.commit('open_snackbar', { text: context.error, color: 'red' })
    context.$router.go(-1) 
}

const handleFinally = function(context){
    context.overlayLoading = false
    if(!context.error){  
        initTimer(context)
        updateTimer(context)
        // RECALCULATE TIMELEFT ON VISIBILITY CHANGE
        envChangeDetector(context)
        DetectVisibility(context)
        DetectMouseOut(context)
    }
}

const handleIfSubmitted = function (context, data){
    if(data.type && data.type===100) {
      context.$store.commit('open_snackbar', {text: data.message, color: 'red'})
      context.$store.commit('set_in_test',    false)
      let query = {reason: 'Test already submitted.'}
      context.$router.replace({ path: '/test-submitted', query }) 
      throw Error('Test already submitted!')
    }
}

const hasLocalData = (context) => {
    if(sessionStorage){
      return sessionStorage.getItem(context.$route.params.id)
    }
    return false
}

const extractData = (context, data) => {
    context.testData = data.testData
    context.userData = data.userData

    context.instructions = data.instructions

    context.$store.commit('set_questions', appendUserData( extractQuestion(context.testData, context.testConfig.SHUFFLE_QUESTIONS, context.user.id), context.userData.test_data))

    context.totalQuestions = context.questions.length // parseInt(data.userData.test_data[data.userData.test_data.length-1].qn)
    if(context.userData.last_qn) context.qn = parseInt(context.userData.last_qn)
    
    if(context.$route.query.qi) context.$store.commit('set_qi', parseInt(context.$route.query.qi))
    // delete data
}

// function shuffle(array) {
//     var currentIndex = array.length, temporaryValue, randomIndex;
  
//     // While there remain elements to shuffle...
//     while (0 !== currentIndex) {
  
//       // Pick a remaining element...
//       randomIndex = Math.floor(Math.random() * currentIndex);
//       currentIndex -= 1;
  
//       // And swap it with the current element.
//       temporaryValue = array[currentIndex];
//       array[currentIndex] = array[randomIndex];
//       array[randomIndex] = temporaryValue;
//     }
  
//     return array;
//   }
  