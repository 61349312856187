// const isMobileDevice = window.innerWidth < 500
// const MAX_BLUR_ALLOWED_COUNT = (process.env.VUE_APP_BAC || 10)*(isMobileDevice?20:1)
// const AUTO_TERMINATE         = process.env.VUE_APP_AUTO_TERMINATE==='true'

// const MAX_BLUR_ALLOWED_TIME  = parseInt((isMobileDevice?process.env.VUE_APP_BATM:process.env.VUE_APP_BAT ) || 10)
// const PING_INTERVAL          = parseInt(process.env.VUE_APP_EAPI || 20)
// const DISABLE_SCREEN_PROCTOR = process.env.VUE_APP_SCREEN_PROCTOR=='false'
// const DISABLE_PROCTOR        = process.env.VUE_APP_NO_PROCTOR === 'true'
// const DISABLE_VIDEO_PROCTOR  = process.env.VUE_APP_DISABLE_VIDEO_PROCTOR === 'true'
// const MULTIFACEDETECTOR      = process.env.VUE_APP_MULTIFACEDETECTOR === 'true'
// const DETECT_FULLSCREEN      = !(process.env.VUE_APP_DETECT_FULLSCREEN === 'false')
// const DETECT_MOUSEOUT        = process.env.VUE_APP_DETECT_MOUSEOUT === 'true'
// const IMAGE_PROCTOR             = process.env.VUE_APP_IMAGE_PROCTOR === 'true'

// export { 
//     isMobileDevice, 
//     MAX_BLUR_ALLOWED_COUNT, 
//     AUTO_TERMINATE, 
//     MAX_BLUR_ALLOWED_TIME, 
//     PING_INTERVAL, 
//     DISABLE_SCREEN_PROCTOR, 
//     DISABLE_PROCTOR, 
//     DISABLE_VIDEO_PROCTOR,
//     MULTIFACEDETECTOR ,
//     DETECT_MOUSEOUT,
//     DETECT_FULLSCREEN,
//     IMAGE_PROCTOR
// }

const isMobileDevice = window.innerWidth < 500
const MAX_BLUR_ALLOWED_COUNT = isMobileDevice?10:50// (process.env.VUE_APP_BAC || 10)*(isMobileDevice?20:1)
const AUTO_TERMINATE         = false // process.env.VUE_APP_AUTO_TERMINATE==='true'

const MAX_BLUR_ALLOWED_TIME_M= 40 // parseInt((isMobileDevice?process.env.VUE_APP_BATM:process.env.VUE_APP_BAT ) || 10)
const MAX_BLUR_ALLOWED_TIME  = 10 // parseInt((isMobileDevice?process.env.VUE_APP_BATM:process.env.VUE_APP_BAT ) || 10)
const PING_INTERVAL          = 20 //parseInt(process.env.VUE_APP_EAPI || 20)
const NOTIFY_UPLOAD_BEFORE   = 10*60-100 // (process.env.VUE_NOTIFY_UPLOAD || 10)*60
const DISABLE_CHAT           = true // process.env.VUE_APP_DISABLE_CHAT=='true'
const AUDIO_RECORDING        = true
const PDF_UPLOAD             = false

const WARNING_OFFSET         = 10

const PROCTOR_TYPE           = 'none' // process.env.VUE_APP_PROCTOR_TYPE || 'none'
const PROCTOR_DOMAIN           = 'proctor.thinkmerit.in'

const DETECT_MOUSEOUT        = false // process.env.VUE_APP_DETECT_MOUSEOUT === 'true'
const DETECT_SPEECH          = false // isMobileDevice?false:process.env.VUE_APP_DETECT_SPEECH == 'true'
const MULTIFACEDETECTOR      = false // process.env.VUE_APP_MULTIFACEDETECTOR === 'true'
const DETECT_FULLSCREEN      = false // !(process.env.VUE_APP_DETECT_FULLSCREEN === 'false')

const IMAGE_PROCTOR          = false // process.env.VUE_APP_IMAGE_PROCTOR === 'true'
const HIDE_PRE_SUBMIT        = true // process.env.VUE_APP_HIDE_PRE_SUBMIT === 'true'

const AUTOSAVE_INTERVAL      = 5*60

const HIDE_TEST_PREVIEW      = true
const DETECT_FACE_NINTERVAL  = 10000
const DETECT_FACE_GNINTERVAL  = 14000

let CONFIG = { 
    isMobileDevice, 
    MAX_BLUR_ALLOWED_COUNT, 
    AUTO_TERMINATE, 
    MAX_BLUR_ALLOWED_TIME, 
    MAX_BLUR_ALLOWED_TIME_M,
    PING_INTERVAL,
    NOTIFY_UPLOAD_BEFORE,
    DISABLE_CHAT,
    DETECT_SPEECH,
    DETECT_MOUSEOUT,
    PROCTOR_TYPE,
    MULTIFACEDETECTOR,
    DETECT_FULLSCREEN,
    IMAGE_PROCTOR,
    HIDE_PRE_SUBMIT,

    HIDE_TEST_PREVIEW,
    // HIDE_TEST_INSTRUCTION,
    PROCTOR_DOMAIN,
    WARNING_OFFSET,
    PDF_UPLOAD,
    AUDIO_RECORDING,
    AUTOSAVE_INTERVAL,
    DETECT_FACE_NINTERVAL,
    DETECT_FACE_GNINTERVAL
}

export default CONFIG