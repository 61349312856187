<template>
    <v-container>
      <v-layout column>
        <v-flex xs12>
            <v-btn style="float: right" to="/query-new" color="primary">NEW QUERY</v-btn>
            <h4>All Queries</h4>
        </v-flex>
        <!-- 
            <v-flex xs12>
                <v-select :items="queryTypes" item-text="name" item-value="_id" label="Query Type" v-model="formData.query_type_id" :disabled="loading || submitting"></v-select>
            </v-flex> 
        -->
        <v-flex xs12 class="mt-4">
            <!-- 
                <table style="width: 100%">
                <thead>
                    <tr>
                        <th>Serial</th>
                        <th>Regarding</th>
                        <th>Query</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item, index in queries" :key="index">
                        <td>{{ index+1 }}</td>
                        <td>{{ item.query_type_name }}</td>
                        <td>{{ item.query }}</td>
                        <td>{{ item.status }}</td>
                        <td></td>
                    </tr>
                </tbody>
                </table> 
            -->
            <v-data-table
                :headers="headers"
                :items="queries"
                :pagination.sync="pagination"
                class="elevation-1" >
                <template v-slot:items="props">
                <td>{{ props.index+1 }}</td>
                <td >{{ props.item.query_type_name }}</td>
                <td >{{ props.item.query_sub_type_name }}</td>
                <td >{{ props.item.query }}</td>
                
                <td >{{ props.item.subject_name }}</td>
                <td >{{ props.item.created_at | formatTime }}</td>
                <td :class="`text-uppercase ${props.item.status=='closed'? 'teal--text': 'red--text'}`" >{{ props.item.status }}</td>
                <td >
                    <v-btn :to="`/queries/${props.item._id}`">Follow up</v-btn>
                </td>
                </template>
            </v-data-table>
        </v-flex>
        <!-- <v-flex xs12>
            <v-select :items="subjects" item-text="name" item-value="id" label="Subjects (Optional)" v-model="formData.subject_id" :disabled="loading || submitting"></v-select>
        </v-flex>
        <v-flex xs12>
          <v-textarea
            solo
            v-model="formData.query"
            :rules="requiredRule"
            label="Write Query"
            hint="Type your message here"
            required
            :disabled="loading || submitting"
          ></v-textarea>
        </v-flex>
        <v-flex xs12>
            <label for="">Attachment</label> <br/>
          <input type="file" name="file" id="file" accept=".pdf,.png,.jpg,.jpeg" ref="file">
        </v-flex>
        <v-flex xs12 style="justify-content: space-between; display: flex;">
            <v-btn :disabled="loading || submitting" @click="submit">SUBMIT</v-btn>
            <v-spacer></v-spacer>
            <v-btn :disabled="loading || submitting" @click="backtoList()">BACK</v-btn>
        </v-flex> -->
     
      </v-layout>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { instance } from '../../_axios'
import moment from 'moment'
export default {
    data(){
        return {
            loading: true,
            submitting: false,
            queries: [],
            pagination: {
                rowsPerPage: 25,
                sortBy: 'created_at',
                descending: true
            },
            headers: [
                {
                    text: 'SN',
                    align: 'left',
                    sortable: false,
                    value: '_id'
                },
                { text: 'Type', value: 'query_type_name' },
                { text: 'Sub Type', value: 'query_sub_type_name' },
                { text: 'Query', value: 'query' },
                { text: 'Subject', value: 'subject_name' },
                { text: 'Time', value: 'created_at' },
                { text: 'Status', value: 'status' },
                { text: 'Action', value: '_id' },
            ]
        }
    },
    mounted(){
        this.fetchAllQueries()
    },
    methods: {
        backtoList() {
            this.$router.push('/queries')
        },
        async fetchAllQueries(){
            try {
                this.queries = (await instance.get('/queries')).data
            } catch (error) {
                console.log(error)
            }
            this.loading = false
            return []
        },
    },
    computed: {
        ...mapGetters(['user'])
    },
    filters: {
        formatTime(val){
            return moment(val).format('hh:mm a T DD-MM-Y').split('T').join('\n\n')
        }
    },
    watch: {
    }
}
</script>
<style scoped>

</style>