<template>
<v-bottom-sheet hide-overlay :value="true" persistent style="z-index:200">
  <v-card tile style="border-radius: 10px" v-if="!overlayLoading">
    <v-progress-linear indeterminate class="my-0"  height="3" v-if="syncing" ></v-progress-linear>
    <v-list>
      <v-list-tile class="justify-space-between">

        <!-- DESKTOP VERSION -->
        <template v-if="$vuetify.breakpoint.mdAndUp">
          <v-list-tile-action> 
            <v-btn :disabled="prevDisabled" :color="fab==0?'blue':''" round @click="prevQuestion()">  Prev  </v-btn> 
          </v-list-tile-action> &nbsp;

          <v-list-tile-action> 
            <v-btn round dark color="indigo" :loading="fab==3" @click="saveAndNext()"> 
              <small>Save<br v-if="fab==3"><span v-else><b v-if="!nextDisabled">-</b></span><span v-if="!nextDisabled">Next</span></small>
            </v-btn> 
          </v-list-tile-action>
          &nbsp;
          
          <v-spacer></v-spacer>

          <v-list-tile-action > 
            <v-btn v-if="!disableChat" round dark large color="#F57F17"  @click="toggleChatPanel()"> 
              <small>Chat</small>
            </v-btn>  
          </v-list-tile-action>
        </template>
        <!-- MOBILE VERSION -->
        <template v-else> 
          <v-list-tile-action> 
            <v-btn 
              fab
              :disabled="prevDisabled" 
              outline 
              :color="fab==0?'blue':''"  
              @click="prevQuestion()" 
              :class="{'elevation-4':fab==4}"> <small>&nbsp;Prev </small>
            </v-btn> 
          </v-list-tile-action> 
          <template v-if="!inTest">
            <v-spacer></v-spacer>
            <v-list-tile-action  >  
              <v-btn 
                fab
                outline 
                :disabled="nextDisabled" 
                :color="fab==1?'blue':''" 
                @click="nextQuestion()" 
                :class="{'elevation-4':fab==4}" > <small>&nbsp;Next </small>
              </v-btn> 
            </v-list-tile-action> 
          </template>
          <v-spacer></v-spacer>
             <v-list-tile-action>  
            <v-btn 
              v-show="!disableChat && inTest"
              fab
              color="secondary" 
              :disabled="disableChat" 
              :class="{'elevation-4':fab==4}" 
              @click="toggleChatPanel()"> <small>&nbsp;Chat </small>
            </v-btn> 
          </v-list-tile-action>

          <v-spacer></v-spacer>
          <v-list-tile-action > 
            <v-btn fab dark outlin color="indigo"  :loading="fab==3" @click="saveAndNext()"> 
              <small>Save</small>
            </v-btn> 
          </v-list-tile-action>

        </template>

      </v-list-tile>
    </v-list>
  </v-card>
</v-bottom-sheet>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
export default {
    computed: {
      ...mapGetters([ 'inTest', 'testConfig', 'qi', 'questions' ]),
      disableChat(){
        return this.testConfig.DISABLE_CHAT
      },
      prevDisabled(){
        return this.qi === 0
      },
      nextDisabled(){
        return this.qi === this.questions.length-1
      }
    },
    props: {
        overlayLoading: {
            type: Boolean,
            default: true
        },
        syncing: {
            type: Boolean,
            default: true
        },
        fab: {
            type: Number,
            default: 0
        },
        saveAndNext: {
            type: Function,
            default: () => {}
        },
        prevQuestion: {
            type: Function,
            default: () => {}
        }
    },
    methods: {
        ...mapActions(['toggleChatPanel', 'nextQuestion']) 
        // , 'prevQuestion'
    }
}
</script>