<template>
  <v-layout row wrap style="margin-top: 56px" class="justify-center">
    <v-flex xs12 v-if="!isDesktop">
      <v-card hover class="pa-4" color="primary" dark>
        <v-btn @click="$emit('updateState', 'take-picture')" outline round v-if="isDesktop">Take Snapshot</v-btn><b></b>
        <v-btn @click="$refs.file.click()" outline primary round v-if="fileUpload">Upload File(s)</v-btn>
        <input 
          type="file" ref="file" 
          style="visibility: hidden; width:0; height:0" 
          multiple @change="fileSelected" 
          :accept="user.config.ALLOWED_FILE_UPLOAD?user.config.ALLOWED_FILE_UPLOAD:fileFormats">
      </v-card>
    </v-flex>
    <v-flex xs12 md6> <list-files :file-uploads="uploadedFiles" @deleteFile="deleteFile"></list-files> </v-flex>
    <!-- LIST FILE UPLOADS -->
  </v-layout>
</template>
<script>
import {mapGetters} from 'vuex'
import FileList from './ListFiles'

export default {
  data() {
    return {
      isDesktop: window.innerWidth>500,
      selectedFiles: [],
      fileFormats: 'image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,video/*,audio/*'
    }
  },
  methods: {
    fileSelected(event){
        this.selectedFiles = Array.from(this.$refs.file.files)
        this.$emit('uploadedFiles')
    },
    deleteFile(data){
      this.$emit('deleteFile', data)
    }
  },
  props: {
    fileUpload: {
      type: Boolean,
      default: false
    },
    uploadedFiles: {
      type: Object,
      default: () => {}
    }
  },
  computed:{
    ...mapGetters(['user'])
  },
  components:{
    'list-files': FileList
  }
}
</script>