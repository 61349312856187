<template>
    <v-card class="text-xs-center" elevation="1">
        <v-layout row wrap>
            <v-flex xs4>
            <h4 class="text-xs-center body2 pt-4 pb-4 teal--text font-weight-regular" style="text-decoration: underline;text-underline-offset: 1rem;">PRESENT</h4>
            <h3 class="title text-xs-center pb-4 teal--text font-weight-bold">{{present}}</h3>
            </v-flex>
            <v-flex xs4>
            <h4 class="text-xs-center body2 pt-4 pb-4 red--text font-weight-regular" style="text-decoration: underline;text-underline-offset: 1rem;">ABSENT</h4>
            <h3 class="title text-xs-center pb-4 red--text font-weight-bold">{{absent}}</h3>
            </v-flex>
            <v-flex xs4>
            <h4 class="text-xs-center body2 pt-4 pb-4 yellow--text text--darken-3 font-weight-regular" style="text-decoration: underline;text-underline-offset: 1rem;">LATE</h4>
            <h3 class="title text-xs-center pb-4 yellow--text text--darken-3 font-weight-bold">{{late}}</h3>
            </v-flex>
        </v-layout>
        </v-card>
</template>
<script>
import AttendanceCounter from './AttendanceCounter'
export default{
    data () {
        return {
            present: 0,
            absent: 0,
            late: 0
        }
    },
    mounted(){
        this.fetchData()
    },
    methods: {
        fetchData(){
            AttendanceCounter.fetchStat()
            .then((resp) => {
                let data = resp.data.data
                if(data.hasOwnProperty('present')) this.present = data.present || 0
                if(data.hasOwnProperty('absent')) this.absent = data.absent || 0
                if(data.hasOwnProperty('late')) this.late = data.late || 0
            })
            .catch((err) => {
                console.log(err)
            })
        }
    }

}
</script>