<template>
<div>
  <div>
    <v-container grid-list-md class="pa-0">
      <v-layout row  wrap class="white--text">
        <!-- <v-flex sm12 md6> 
          <v-layout >
            <v-flex sm12>
              <v-btn round class="visited-no-answer marked  legend-btn" fab small></v-btn>
              <span class="sm-title">  Marked for review</span>
            </v-flex>
          </v-layout>
        </v-flex> -->
        <v-flex sm12 md6> 
          <v-layout >
            <v-flex sm12>
              <v-btn round class="has-answer legend-btn" fab small></v-btn>
              <span class="sm-title">Attempted question</span>
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- <v-flex sm12 md6> 
          <v-layout >
            <v-flex sm12>
              <v-btn round class="has-answer marked legend-btn" fab small></v-btn>
              <span class="sm-title"> Attempted & Marked</span>
            </v-flex>
          </v-layout>
        </v-flex> -->
        <!-- <v-flex sm12 md6> 
          <v-layout >
            <v-flex sm12 >
              <v-btn round class="visited-no-answer legend-btn" fab small></v-btn>
              <span class="sm-title"> Visited but unanswered</span>
            </v-flex>
          </v-layout>
        </v-flex> -->
        <v-flex sm12 md6> 
          <v-layout >
            <v-flex sm12 >
              <v-btn round class="legend-btn" fab small></v-btn>
              <span class="sm-title"> Non visited questions</span>
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- <v-flex sm12 md6> 
          <v-layout >
            <v-flex sm12  >
              <v-btn round class="legend-btn" color="transparent" flat fab small></v-btn>
            </v-flex>
          </v-layout>
        </v-flex> -->
      </v-layout>      
    </v-container>
  </div>
  <v-divider class="mt-2 pb-2"></v-divider>
  <template v-for="(question, lqi) in questions">
    <v-btn 
      fab
      round
      :icon="Boolean(question.mts)" 
      :key="question.id" 
      class="qnav-btn"
      active-class="font-weight-bold"
      :class="{
        'has-answer': Boolean(question.ats),
        'visited-no-answer': Boolean(question.ats)==null && question.ts>0, 
        'marked': question.mts,
        'selected': lqi==qi,
        'inaccessible': qpi>-1&&question.pi!=qpi
      }"
      :disabled="qpi>-1&&question.pi!=qpi"
      :dark="lqi==qi"
      @click="selectQuestion({qi: lqi, qn: question.qn, pi: question.pi})" >
      <!-- {{lqi+1}} -->
      {{question.qnf}}
    </v-btn>
  </template>	
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import NavigateHelper from './../views/Test/utils/NavigateHelper'
export default{
  data(){
    return { 
      // qpi: -1
    }
  },
	methods:{
    selectQuestion({qi, qn, pi}, dontClose){
      this.$store.commit('set_qi', qi)
      NavigateHelper.updateRoute(this)
      this.onSelect()
      // this.$emit('selectQuestion',load)
    }
	},
	props:{
    // 'questions': Array,
    // 'qn': Number,
    'qpi': {
      type: Number,
      default: -1
    },
    'onSelect': {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters(['qi', 'questions']) // , 'qpi'
  }
}
</script>
<style>
.legend-btn{
   /*border-radius: 0px !important;*/
   height:20px !important;
   width:20px !important;
}
.visited-no-answer.blue{
  background-color: #2196f3 !important;
}

.visited-no-answer.marked, .marked{
  background: #FFAB00 !important;
  color:white !important;
}
.has-answer{
  /*border: 4px solid #009688 !important;*/
  background: #689F38 !important;
  color:white !important;
}
/*.has-answer.blue{
   border-color: #009688 !important;
}*/
.has-answer.v-btn__content{
  color: #689F38 !important;
}
.selected{
  background: #2196f3 !important
}
.selected.has-answer{
  background: green !important;
  border: 4px solid #2196f3;
}
.visited-no-answer{
  /*border: 2px solid red !important;*/
  background-color:#F44336 !important;
  color:white !important;
}
.blue.marked, .blue.marked.has-answer, .blue.marked.visited-no-answer{
  background-color:#2196f3 !important
}
.marked.has-answer{
  background-color:#689F38 !important
}
.marked.has-answer::after{
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color:#FFAB00 !important;
  top: 40px;
  left: 40px;
}
.legend-btn.marked.has-answer::after{
  top: 6px;
  left: 6px;
}
/*.orange--text.has-answer, .orange--text.visited-no-answer{
  border-top: 2px solid orange !important;
  border-right: 2px solid orange !important;
}*/
.sm-title{
  font-size: 12px;
}
</style>