<template>
<v-dialog v-model="notificationModal" :fullscreen="$vuetify.breakpoint.smAndDown" max-width="500px" persistent transition="scale-transition">
  <v-card>
    <v-toolbar dark color="secondary">
      <v-toolbar-items>
        <div class="subheading pt-3">
          {{notificationModalData.title}}
        </div> 
      </v-toolbar-items>
        <v-spacer></v-spacer>
      <v-btn icon dark @click="notificationModal=false">
        <v-icon>close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>{{notificationModalData.body}}</v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import InitCloudMessaging, {FCMReceiver} from './../CloudMessaging'
export default{
    data(){
        return {
            notificationModal: false,
            notificationModalData: { title: '', body: ''}
        }
    },
    computed:{
        ...mapGetters(['auth'])
    },
    props: {
        onMessage: {
            type: Function,
            default: () => {}
        }
    },
    watch: {
    'auth': {
      handler: function(newval, oldval) {
        if(newval == true) {
          InitCloudMessaging(this)
          FCMReceiver(this, this.onMessage)
        }
      },
      deep: false
    }
  }
}
</script>