<template>
<v-dialog v-model="open"  max-width="500px" persistent transition="scale-transition">
  <v-card v-if="open">
    <v-card-title class="headline grey lighten-2 text-capitalize " primary-title >
      <span class="headline"><v-icon>report_problem</v-icon> {{title}}</span>
    </v-card-title>
    <v-card-text >
          <!-- hint="Max Char 500" -->
      <v-textarea
          name="input-7-1"
          label="Please write report in short!"
          value=""
          :rules=[rules.maxChar]
          validate-on-blur
          v-model="text"
          :disabled="reporting"
          counter
          maxlength="500"
        ></v-textarea>
     </v-card-text>
     <v-card-actions>
       <v-btn outline  @click="$emit('reported');text=''" :disabled="reporting">Cancel</v-btn>
       <v-spacer></v-spacer>
       <v-btn outline color="red" @click="report()" :loading="reporting">Submit</v-btn>
     </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
const {instance} = require('./../_axios.js');
export default{
  data() {
  	return {
  	  // open: false,
      title: 'Report Question',
      text: '',
      // question: null,
      reporting: false,
	  rules: {
		maxChar(v) {
		  if(v.length>500) return 'Max characters (500) exceeded!'
		  else if(v.length==0) return 'It can\'t be empty'
		  return true
		}
	  } 
  	}
  },
  props: ['open', 'question'],
  methods: {
  	report(){
      if(this.text.length==0 || this.text.length>500){
        this.$store.commit('open_snackbar',{color:'red',text:'Please resolve the report first!'})
        return false
      }
      this.reporting = true
      instance.post('/question/report', {
        id: this.question.id, 
        text: this.text
      })
      .then(() => {
         this.$store.commit('open_snackbar',{color:'green',text:'Question reported. Thanks!'})
         this.$emit('reported')
         this.text = ''
      })
      .catch((e) => this.$store.commit('open_snackbar',{color:'red',text:e.message||'Error submitting report!'}))
      .then(() => {
         this.reporting = false 
      })
    }
  }
}
</script>