const { instance } = require('./../_axios.js');

const uploadAws = async function(presignUrl, postUploadUrl, contentType, file, extraPostData = {}, onUploadProgress = {}){
    return new Promise(async (resolve, reject) => {

        try {
            let resp = await instance.post(presignUrl, { contentType: 'image/png' })
    
            var options = {
                headers: {
                    'Content-Type': contentType, //getContentType(fileType),
                    'x-amz-acl': 'public-read'
                },
                onUploadProgress: progressEvent => {
                    
                }
            };
            await instance.put(resp.data.url, file, options)
            console.log({...extraPostData, url: resp.data.url.split('?AWSAccessKeyId')[0]})
            await instance.post(postUploadUrl, {...extraPostData, url: resp.data.url.split('?AWSAccessKeyId')[0]})

            resolve('Done')
        } catch (error) {
            console.log(error)
            reject(error.message)
        }
    })
}

export {uploadAws} 