const badWords = ['sexy','sexxy','aand','hot','hot','gandi','mast','nangi','video','land','lund','nanga','chut','bob','boob','gaand','gand','lauda','loda','muh','muth','degi','fuck','fck','suck','dick','lick','touch','porn','sex','ladki','film','bsdk','bhosdike','ma','maa','ki','blue','behen','randi','rendi']
export default function(text){
    try{
        text = text.toLowerCase().trim();
        [].forEach.call(badWords, (word) => { text = text.replaceAll(word,'_') })
        return text
    } catch(e){
        console.log(e)
        return text
    }
}