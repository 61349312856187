<template>
    <div>
        <page-title>{{$route.meta.type?getTypeTitle($route.meta.type):'STUDY MATERIAL'}}</page-title>
        <v-dialog v-model="loading" persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    {{loadingMessage}}
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-container
            v-if='!loading'
            grid-list-lg class="pa-0" >
            <v-layout row wrap justify-center>
                <!--<v-flex xs12 >
                    <h4 class="headline text-xs-center pb-2 text-uppercase">{{$route.meta.type?$route.meta.type:'STUDY MATERIALS'}}</h4>
                </v-flex>-->
                <!--<sm-filter :loading="loading" :filters="filters" @filterUpdate="filterUpdate" v-if="!$route.meta.type"></sm-filter>-->
                <v-flex xs12 v-for="(sm,idx) in data" :key="idx">
                   <sm-item :sm="sm" :idx="idx" @selected="({sm, iidx}) => showAttachmentModal(sm, iidx)" @showuploads="currentItem = sm; showFileUploadView()"></sm-item>
                </v-flex>
                <v-flex xs12 v-if="data.length<1">
                    <!--<v-alert :value="true" color="teal" type="warning"> &nbsp; NO MATERIAL FOUND!</v-alert>-->
                    <page-no-data :icon="getIcon($route.meta.type)"> NO {{$route.meta.type.toUpperCase()}} FOUND</page-no-data>
                </v-flex>

                <v-flex>
                    <!--:_path="`/study/${filters.subject}`"-->
                    <paginator 
                    :lastPage='filters.page>=lastPage' 
                    :page="filters.page" 
                    :path="`${$route.path}`"
                    @goNext="filters.page+=1;fetchStudyMaterials()" 
                    @goPrev="filters.page-=1;fetchStudyMaterials()">
                    </paginator>
                </v-flex>
            </v-layout>
        </v-container>
        <material-view :show="showAttachments" :item="currentItem" :aindex="attachmentIndex" @close="currentItem=null;showAttachments = false" @attempt="showFileUploadView()"></material-view>
        <file-upload :show="showFileUpload" @close="showFileUpload = false" @uploadFile="uploadFile" ref="fileUpload" @deleteFile="deleteFile"
            :upload-statuses="uploadStatuses"
            :uploading="uploading"
        ></file-upload>
        <!-- #004D40 -->
    </div>
</template>
<script>
const {instance} = require('../../_axios.js');
import { mapGetters } from 'vuex'
import MaterialView from './MaterialView'
import StudyMaterialPaginator from './Paginator'
import SMItem from './SMItem'
import SMFilter from './SMFilter'
import FileUpload from './FileUpload'
import getCompressed from '../../utils/ImageCompressor'
import SizeConverter from '../../utils/SizeConverter'

export default {
    data(){
        return {
            loading: true,
            uploading: false,
            loadingMessage: 'Loading data please wait...',
            data: [],
            lastPage: 1,
            filters: {
                type: this.$route.query.type || 'All Type',
                sort: 'latest', // latest , oldest
                page: parseInt(this.$route.query.page) || 1,
                subject: this.$route.params.subject,
                sname: this.$route.query.sname,
                author: this.$route.query.tid
            },
            subjects: [],
            currentItem: null,
            attachmentIndex: null,
            showFileUpload: false,
            showAttachments: false,
            uploadStatuses: [],
            metaType: this.$route.meta.type
            // existingUploads: []
        }
    },
    mounted(){
        if(this.metaType) {
            this.filters.type = this.metaType // .substring(0, this.metaType.length-1)
        }
        this.fetchStudyMaterials()
        this.subjects = this.user.subjects
    },
    methods: {
        getTypeTitle(type){
            if(type== 'home work') return 'Homework'
            return type.split('-').map(v => v.toUpperCase()).join(' ')
        },
        async fetchStudyMaterials(){
          
            this.loading = true
            try {
                let query = { subject_id: this.filters.subject, author_id: this.filters.author, type: this.filters.type.toLowerCase(), page: this.filters.page  }
                if(query.type.toLowerCase()=='all type') delete query.type
                let temp = (await instance.get('/study-material/list', { params: query } )).data
                this.data = temp.data
                this.lastPage = temp.last
            } catch (error) {
                this.data = []
            }
            this.loading = false
        },
        filterUpdate(query){
            this.filters = {...this.filters, ...query}
            try { this.filters.page = parseInt(this.$route.query.page) || 1 } catch (error) { this.filters.page = 1 }
            this.fetchStudyMaterials()
        },
        uploadFile(files){
            // this.loadingMessage = 'Uploading Data please wait...'

            this.uploadStatuses = []
            this.uploading = true
            this.loading = true
            this.loadingMessage = 'Uploading files please wait...'

            var promises = files.map(async (file, index) => {
                this.uploadStatuses.push({
                    uplaodPercent: 0,
                    uploadMessage: `Uploading <b>${file.name}</b>...`
                })
                var fd = new FormData()
                let contentType = file.type
                
                fd.append('assignment_id', String(this.currentItem._id));
                fd.append('assignmentID', String(this.currentItem._id));
                fd.append('content_type', contentType)
                fd.append('file_name', file.name)
                fd.append('stype', (this.filters.type|| '').toLowerCase())
                
                
                if(contentType.startsWith('image/')){ file = await getCompressed(file) }
                var options = {
                    headers: {
                        'Content-Type': contentType, //getContentType(fileType),
                        'x-amz-acl': 'public-read'
                    },
                    onUploadProgress: progressEvent => {
                        this.uploadStatuses[index].uploadPercent = progressEvent.loaded*100/(file.size || 1)
                        this.uploadStatuses[index].uploadMessage = `Uploading <b>${file.name}</b>...  ${this.uploadStatuses[index].uploadPercent.toFixed(2)}% (${SizeConverter.byteToMB(progressEvent.loaded)}/${SizeConverter.byteToMB(file.size)} MB)`
                    }
                };
                return instance.post(`/presignURLAssignment/${this.currentItem._id}`, fd)
                    .then(resp => {
                        // fd.append('fileName', resp.data.fileName)
                        fd.append('url', resp.data.signedURL.split('?')[0])
                        return instance.put(resp.data.signedURL, file, options)
                    })
                    .then(resp => {
                        return instance.post(`/savePresignURLAssignment2/${this.currentItem._id}`, fd)
                    })
                    .then(resp => {
                        this.$store.commit('open_snackbar', { text: 'UPLOAD SUCCESSFULL', color: 'green' })
                        // this.$refs.fileUpload.uploads = resp.data.newData
                        this.fetchUploadedFiles()
                        return { success: true, newData: resp.data.newData }
                    })
                    .catch(error => {
                        console.log(error)
                        this.$store.commit('open_snackbar', {text: 'UPLOAD FAILED! PLEASE TRY AGAIN.', color: 'red'})
                        return { success: false, error }
                    })
            })
            
            Promise.all(promises)
            .then(values => {
                this.$refs.fileUpload.state = null
            })
            .catch(error => {
                this.$store.commit('open_snackbar', {text: 'UPLOAD FAILED! PLEASE TRY AGAIN.', color: 'red'}) 
            })
            .finally(() => {
                this.uploading = false
                this.loading = false
            })
        },
        showAttachmentModal(sm, aidx){
            this.currentItem = sm; 
            this.attachmentIndex = aidx; 
            this.showAttachments = true 
        },
        showFileUploadView(){
            this.showFileUpload = true
            this.fetchUploadedFiles()
        },
        async fetchUploadedFiles(){
            try {
                this.loading = true
                this.loadingMessage = 'Loading Data. Please Wait...'
                this.$refs.fileUpload.data = (await instance.post(`/assignment/${this.currentItem._id}/uploads`)).data
            } catch (error) {
                console.log(error)
            }   
             this.loading = false
        },
        async deleteFile({index, ukey}){
            try {
                this.loading = true
                this.loadingMessage = 'Deleting File. Please Wait...'
                let file = this.$refs.fileUpload.data.uploads[index]
                const resp = (await instance.post(`/assignment/${this.currentItem._id}/delete2`, { url: file.url })).data
                this.$refs.fileUpload.data.uploads.splice(index, 1)
            } catch (error) {
                console.log(error)
            }
            this.loading = false
        },
        getIcon(type){
            //if(type){
    //            { title: 'Study Material',        icon: 'auto_stories',       to: '/study?type=material',     color: '#00695C', colorHex: '#00695C', colorDark: '#004D40'},
    //  { title: 'Homework',              icon: 'home_work',         to: '/home-work', color: '#EA80FC', colorHex: '#EA80FC', colorDark: '#077a88'},
    //  { title: 'Assignments',           icon: 'event_note',         to: '/assignments', color: 'cyan', colorHex: '#077a88', colorDark: '#077a88'},
    //  { title: 'Video Lecture',         icon: 'slideshow',            active: true  ,  to: '/video-lecture', color: 'blue-grey darken-2', colorDark: '#274757', colorHex: '#455a64'},
    //  { title: 'Assessments',           icon: 'quiz',
            //}
            switch (type.toLowerCase()) {
                case 'home work':
                    return 'home_work';
                case 'assignment':
                    return 'event_note';
                case 'video-lecture':
                    return 'slideshow';
                case 'assessment':
                    return 'quiz';
                default:
                    break;
            }
        }
    },
    computed:{
        ...mapGetters(['user']),       
    },
    components: {
        'material-view': MaterialView,
        'paginator': StudyMaterialPaginator,
        'sm-item': SMItem,
        'sm-filter': SMFilter,
        'file-upload': FileUpload,
        
    },
    
}

function serialize(object){
    let out = ''
    for (const key in object) {
        if(object[key]!=null){
            out+=key+'='+object[key]+'&'
        }
    }
    return out
}

function contentTypeToKey(contentType){
    if(contentType.startsWith('image/')) return 'iu'
    else if(contentType.startsWith('application/pdf')) return 'pu'
    else if(contentType.startsWith('video')) return 'vu'
    else if(contentType.startsWith('audio')) return 'au'
    else if(contentType.startsWith('application/vnd')) return 'eu'
    else {
        console.error('Unknown File Type')
    }
}


</script>
