<template>
  <div class="text-xs-center">
    <v-progress-circular v-if="loading" :indeterminate="true"></v-progress-circular>
    <div v-show="!loading">
      <v-btn @click="zoomIn()" :disabled="currentScale==2" ><v-icon>add</v-icon>ZOOM IN</v-btn>
      <v-btn @click="zoomOut()" :disabled="currentScale==0.25">ZOOM OUT<v-icon>remove</v-icon></v-btn>
      <div class="page-container" ref="pdfContainer">
        <div id="viewer" class="pdfViewer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { PDFViewer } from "pdfjs-dist/web/pdf_viewer";
import "pdfjs-dist/web/pdf_viewer.css";

pdfjsLib.GlobalWorkerOptions.workerSrc = "https://cdn.jsdelivr.net/npm/pdfjs-dist@2.2.228/build/pdf.worker.min.js";
let pdfViewer = null

export default {
  name: "PdfViewer",
  props: { docPath: String },
  data(){
    return {
      loading: true,
      currentScale: 1
    }
  },
  mounted() {
    this.getPdf();
  },
  methods: {
    async getPdf() {
      console.log('STARTING PDF')
      // let container = document.getElementById("page-container");
      console.log(this.$refs.pdfContainer)
      pdfViewer = new PDFViewer({
        container: this.$refs.pdfContainer,
      });
      
      var pdf = await pdfjsLib.getDocument(this.docPath);
      this.loading = false
      pdfViewer.setDocument(pdf);
      
    },
    zoomIn(){
      if(pdfViewer.currentScale>=2) return
      pdfViewer.currentScale += 0.25
      this.currentScale = pdfViewer.currentScale
    },
    zoomOut(){
      if(pdfViewer.currentScale<=0.25) return
      pdfViewer.currentScale -= 0.25
      this.currentScale = pdfViewer.currentScale
    }
  },
};
</script>

<style>
.page-container {
  margin: auto;
  width: 80%;
  max-width: 100%;
  overflow: auto;
}
@media screen and (max-width: 600px) {
  .page-container {
    width: 100%;
  }
}
div.page {
  display: inline-block;
}
</style>