<template>
    <div>
        <v-card elevation="0">
        <v-card-text class="text-xs-center">
            <v-layout row wrap >
                <v-flex xs12 md5><h3 class="text-xs-ceter text-md-right">Troubleshooting Prerequisites:</h3></v-flex>
                <v-flex xs12 md7><ul style="list-style:none; " class="text-xs-left mb-4">
                    <li>1. Updated Google chrome browser</li>
                    <li>2. Working Webcam device</li>
                    <li>3. Working Mic device</li>
                </ul></v-flex>
                <v-flex xs12 class="mb-2">
                    <v-divider></v-divider>
                </v-flex>
            <v-flex xs12 >
                
                <div v-if="!cameraError">
                    <h4>Make sure all the checkboxes are green ticked.</h4>
                    <b v-if="!videoOk || !audioOk"> {{!audioOk?'Audio is not working.':'Video is not working'}}( Click on Try Again.If problem still persists, contact invigilator/concerned personnel.)</b>
                <b>Status: Both video and audio are working fine.</b>
                <v-layout row style="max-width: 300px;margin: 0 auto;">
                    <v-flex xs6 class="justify-center"><v-checkbox v-model="videoOk" :color="videoOk?'green':'red'" :label="`Video`" readonly></v-checkbox></v-flex>
                    <v-flex xs6 class="justify-center"><v-checkbox v-model="audioOk" :color="audioOk?'green':'red'" :label="`Audio`" readonly></v-checkbox></v-flex>
                </v-layout>
                <video autoplay="true" id="videoElement" ref="videoElement"></video>
                <!-- <audio v-show="audioBlob" ref="audioPlayer" controls/> -->
                </div>
                <div v-else>
                    <p class="pt-4"> Please allow website to access webcam and audio! </p>
                    <br>
                    <img class="pt-4 pb-4" src="./../../assets/video-permission.png" alt="Allow permission">
                    <br>
                    <v-btn outline @click="allow()" >Recheck</v-btn>
                    <!-- <v-btn btnprimary @click="uploadState=0" outline>Back</v-btn> -->
                </div>
                <v-select
                    :items="devices"
                    v-model="videoInput"
                    item-text="label"
                    item-value="deviceId"
                    label="Select Camera Device"
                    outline
                    @change="allow()"
                    style="max-width:350px; margin: 0 auto"
                    ></v-select>
            </v-flex>

            </v-layout>
        </v-card-text>
        <v-card-actions class="mt-2 text-xs-center">
            <v-btn color="red darken-1" outline flat round @click="tryAgain()">Try Again.</v-btn>
            <v-spacer></v-spacer>
            <v-btn  color="info" round to="/troubleshoot/capture-image">Check Capture Image</v-btn>
        </v-card-actions>
        
        </v-card>
        <v-card elevation="0" class="mt-2">
            <v-card-text>
                <h3 class="title text-xs-center">Troubleshooting Guide</h3>
                <p>Please go through following docs.</p>
                <ol>
                    <li><a href="https://drive.google.com/file/d/18qddFsS9oJQHdJad4UqhOv5htNS7i5BP/view?usp=sharing" target="_blank">Common Troubleshooting</a> </li>
                    <li><a href="https://drive.google.com/file/d/1RIAjy7RRd-CBRwgCwXMAzAccwa5mtIow/view?usp=sharing" target="_blank">Mobile Specific Troubleshooting</a> </li>
                    <li><a href="https://drive.google.com/file/d/19uEBqIcmBCM67zfBGaggI6lGDFgmnXGT/view?usp=sharing" target="_blank">Laptop Specific Solutions</a></li>
                </ol>
                <h3 class=" mt-4 mb-4">Video guide for Troubleshooting</h3>

                <v-layout row wrap>
                    <v-flex xs12 md6>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/PtZDToiZkCQ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </v-flex>
                    <v-flex xs12 md6>
                        <iframe width="560" height="315" src="https://www.youtube.com/embed/YHzVrxAo8aA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </v-flex>
                </v-layout>


            </v-card-text>
            <v-card-text v-if="false">
                <h3 class="title text-xs-center">Troubleshooting Guide</h3>
                <ul style="list-style:none">
                    <li>
                        <b>Problem.</b>  <u><i>"Please allow website to access webcam and audio!"</i></u>  message or all checkboxes are not green ticked
                        <br>
                        <br>
                        <b>Reason: </b> 
                        <br>-- The chrome browser do not have all the camera and mic permission required. <br>
                        <span>-- If video checkbox is not ticked, it means camera permission is not given or chrome couldn't access camera device</span>  <br>
                        <span>-- Similarly, if audio checkbox is not ticked, it means mic permission is not given or chrome couldn't access mic</span>  <br>
                        <b>Solution: </b> <br>
                        <ul style="list-style:none">
                            <!-- 
                                <li>
                                    Step 1: Go to chrome settings - 
                                    <br> <span> Click on 3 verticle dots at top right corner
                                    <img class="middle-align" src="./../../assets/troubleshoot/chrome-option.png" alt=""></span>
                                    <br> <span>
                                        Click on settings
                                    <img class="middle-align"  src="./../../assets/troubleshoot/settings.png" alt="">
                                    </span>
                                </li> 
                            -->
                        </ul>
                    </li>
                </ul>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import ReleaseMedia from './../../utils/releaseMedia'
var gumStream; 						//stream from getUserMedia()
import {mapGetters} from 'vuex'
var URL = window.URL || window.webkitURL;

export default {
    data(){
        return {
            cameraError: false,
            video:  null,
            canvas: null,
            width:  640,
            height: 0,
            devices: [],
            videoInput: null,
            selectedDevice: 'user',
            localStream: null,
            videoOk: false,
            audioOk: false
        }
    },
    mounted(){
        this.cameraError = null
        this.getCameraSelection()
        setTimeout(() => {
            this.allow()
        }, 500)
    },
    beforeDestroy(){
        ReleaseMedia(gumStream)
    },
    destroyed(){
        ReleaseMedia(gumStream)
    },
    props: {
        testId: {
            type: String,
            default: ''
        }
    },
    methods: {
        tryAgain(){
            this.allow()
            window.location.reload()
        },
        allow(){
            this.cameraError = null
            ReleaseMedia(gumStream)
            this.$nextTick(() => {
                this.startWebCam()
            })
        },
        async getCameraSelection  ()  {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            this.devices = videoDevices.map(videoDevice => {
                return { deviceId: videoDevice.deviceId, label: videoDevice.label.charAt(0).toUpperCase()+videoDevice.label.substring(1) }
            });
        },
        startWebCam(){
            this.cameraError = null
            const config = { 
                    video: {
                        width: 640,
                        height: 480,
                        deviceId: this.videoInput
                    }
                }
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                this.video = document.getElementById('videoElement')
                navigator.mediaDevices.getUserMedia(config)
                .then( (stream) => {
                    this.videoOk = true
                    gumStream = stream
                    // this.localStream = stream
                     if ("srcObject" in this.video) {
                        this.video.srcObject = stream;
                    } else {
                        // Avoid using this in new browsers, as it is going away.
                        this.video.src = window.URL.createObjectURL(stream);
                    }
                    this.video.onloadedmetadata = (e) => {
                        this.video.play();
                    };
                    this.video.addEventListener('canplay', (ev) => {
                        this.height = this.video.videoHeight / (this.video.videoWidth/this.width);
                    })

                })
                .catch( (err0r) => {
                    this.videoOk = false
                    console.log(err0r)
                    // err0r = String(err0r)
                    if(/permission/.test(err0r.message.toLowerCase())){
                       this.cameraError = "Please allow this website to access webcam!"
                    } else {
                        this.cameraError = err0r.message.replace(/[^\:]*:/, '')
                    }
                });

                navigator.mediaDevices.getUserMedia({ audio: true })
                .then( (stream) => {
                    this.audioOk = true
                })
                .catch( (err0r) => {
                    this.audioOk = false
                });
            }
        }
    },
    computed: {
        ...mapGetters(['user']),
        fine(){
            if(this.user.config.SKIP_PREPARE==='true') return true
            return  this.videoOk && this.audioOk
        }
    },
    watch:{}
}
</script>

<style>
#videoElement {
    height: 250px;
    max-width: 100%;
	background-color: #666;
}
.middle-align{
    vertical-align: middle;
}
</style>