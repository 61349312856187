<template>
<div v-if="question">
    <!-- {{JSON.stringify(question)}} -->
     <v-card style="height: 100vh;">
         <v-card-title primary class="pa-0">
             <v-toolbar :color="question.is_correct?'success':'red'" dark>
                <h4 class="title">  Question {{question.qn}}</h4>
                <v-spacer></v-spacer>
                <v-btn small fab icon @click="$emit('close')" dark><v-icon dark>close</v-icon></v-btn>
            </v-toolbar>
         </v-card-title>
          <v-card-text > 
              <p v-html="question.question"></p>  
            <br>
            <!-- <v-divider></v-divider> -->
            <br>
            <table class="table-1">
                <thead>
                    <tr>
                        <td class="subheading">Correct Answer</td>
                        <td class="subheading">Your Answer</td>
                    </tr>
                </thead>
                <tr>
                    <td v-html="fAnswer"></td>
                    <td v-html="uAnswer"></td>
                </tr>
            </table>
            <br>
            <!-- <v-divider></v-divider> -->
            <br>
            <h5 class="subheading">Solution</h5>
            <p v-html="question.solution"></p>
          </v-card-text>
    </v-card>
    
</div>  
</template>

<script>
// import QuestionAnswer from './../../components/QuestionAnswer'
import formattedAnswer from './../../utils/answer'
export default {
    props: {
        'question': {
            type: Object,
            default: () => {}
        }
    },
    components: {
        // 'qa' :QuestionAnswer
    },
    computed: {
        fAnswer(){
            return formattedAnswer(this.question, this.question.answer)
        },
        uAnswer(){
            return formattedAnswer(this.question, this.question.user_answer)
        }
    },
    mounted() {
        this.renderMathJax()
    },
    methods:{
        renderMathJax(){
            setTimeout(() => {
                if(window.MathJax){ window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub,this.$refs.aa]);  }  
                else this.renderMathJax()
            }, 500)
        }
	},
}
</script>
<style>
.table-1{
    width: 100%; 
    text-align: center;
    border-collapse: collapse
}
.table-1 td{
    border: 1px solid #eee;
    padding: 5px
}
</style>