<template>
<div class="mt-4 mb-4">
    <div v-if="inProgress">
        <v-progress-circular :width="3" color="green" indeterminate ></v-progress-circular>
    </div>
    <template v-else>
        <v-img 
            v-if="imageUrl"
            :src="imageUrl"
            :lazy-src="imageUrl"
            aspect-ratio="1"
            max-width="200px"
            class="grey lighten-2 mx-auto">
            <template v-slot:placeholder>
                <v-layout fill-height align-center justify-center ma-0>
                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                </v-layout>
            </template>
        </v-img>
        <div v-else class="text-xs-center"> <v-icon x-large>image</v-icon> </div>
    </template>
    <div class="text-xs-center mt-2">
        <v-btn class="text-center" @click="() => $refs.fileInput.click()" :disabled="uploading">Select Image</v-btn>
        <v-btn class="text-center" :disabled="!imageUrl || disableUpload" color="primary" @click="uploadNow()" :loading="uploading"><v-icon>upload</v-icon> Upload</v-btn>
    </div>
    <input type="file" :accept="fileFormats" @change="filesSelected()" ref="fileInput" multiple style="visibility: hidden"/>
</div>
</template>
<script>
import getCompressed from './../utils/ImageCompressor'
import { uploadAws } from './../utils/AwsImageUpload'

export default{
    data(){
        return {
            inProgress: false,
            uploading: false,
            imageUrl: null
        }
    },
    props: {
        fileFormats: {
            type: String,
            default: () => '.png,.jpeg,.jpg'
        },
        presignUrl: {
            type: String,
            default: () => null
        },
        postUploadUrl: {
            type: String,
            default: () => null
        },
        disableUpload: {
            type: Boolean,
            default: false
        },
        payload: {
            type: Object,
            default: () => {}
        }
    },
    methods: {
        async filesSelected(){
            try {
                this.inProgress = true
                let file = this.$refs.fileInput.files[0]
                if(file){
                    let reader = new FileReader()
                    reader.onload = (eve) => {
                        this.imageUrl = eve.target.result
                    }
                    reader.readAsDataURL(file)

                    
                }
                
            } catch (error) {
                this.imageUrl = null
                this.$store.commit('open_snackbar',{text: 'Update failed!', color: 'red'})
            }
            this.inProgress = false
        },
        async uploadNow(){
            let file = this.$refs.fileInput.files[0]
            if(file) {
                this.uploading = true
                let cfile = await getCompressed(file)
                let resp = await uploadAws(this.presignUrl, this.postUploadUrl, 'image/png', cfile, this.payload)

                this.uploading = false
                this.$refs.fileInput.target.value = ''
                this.imageUrl = null
                this.$emit('fileUploaded', resp.data)
            }
        }
    }
}
</script>