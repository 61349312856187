const setCameraDevice = function (device){
    if(!window.localStorage) return 
    if(device) {
        window.localStorage.setItem('CAMERA_DEVICE',device.deviceId)
        window.localStorage.setItem('CAMERA_LABEL',device.label)
    }
    else {
        window.localStorage.removeItem('CAMERA_DEVICE')
        window.localStorage.removeItem('CAMERA_LABEL')
    }
}

const getCameraDeviceID = function () {
    if(!window.localStorage) return 
    return localStorage.getItem('CAMERA_DEVICE')
}

const getCameraDeviceLabel = function () {
    if(!window.localStorage) return 
    return localStorage.getItem('CAMERA_LABEL')
}

export {setCameraDevice, getCameraDeviceID, getCameraDeviceLabel}