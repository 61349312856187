<template>
    <v-container>
      <v-layout column>
        <v-flex xs12>
            <h4>Query Follow Up</h4>
        </v-flex>
        <v-flex xs12>
            <!-- <v-data-table
                :headers="headers"
                :items="queries"
                class="elevation-1 mt-4" >
                <template v-slot:items="props">
                <td>{{ props.index+1 }}</td>
                <td >{{ props.item.query_type_name }}</td>
                <td >{{ props.item.query }}</td>
                <td >{{ props.item.subject_name }}</td>
                <td :class="`text-uppercase ${props.item.status=='closed'? 'teal--text': 'red--text'}`" >{{ props.item.status }}</td>
                <td >
                    <v-btn :to="`/queries/${props.item._id}`">Follow up</v-btn>
                </td>
                </template>
            </v-data-table> -->

            <v-card class="mt-4">
                <v-card-text v-if="queries[0]">
                    <!-- <div style="display: flex;" v-for="engage, index in (queries[0].engagements || [])" :key="index">
                        <div style="display: inline-block; width: 100px">{{index+2}}</div>
                        <div style="display: inline-block;">{{ engage.content }}</div>
                        <div style="display: inline-block; width: 100px">{{engage.created_at}}</div>
                    </div> -->
                    <table style="width: 100%;">
                        <thead>
                            <tr>
                                <th>SN</th>
                                <th class="text-xs-left">Post By</th>
                                <th class="text-xs-left">Content</th>
                                <th>Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td style="width: 100px" class="py-4">1</td>
                                <td style="width: 140px"><small>{{ queries[0].request_by_name }}</small></td>
                                <td>{{ queries[0].query }}
                                    <a v-if="queries[0].attachment" :href="queries[0].attachment" target="_blank"><br/><u>Attachment</u></a>
                                </td>
                                <td style="width: 120px"><small>{{queries[0].created_at | formatTime}}</small></td>
                            </tr>
                            <tr v-for="engage, index in (queries[0].engagements || [])" :key="index" class="py-4">
                               <td style="width: 100px" class="py-4">{{ index+2 }}</td> 
                               <td style="width: 140px"><small>{{ engage.post_by_name }}</small></td> 
                               <td>{{ engage.content }}
                                <a v-if="engage.attachment" :href="engage.attachment" target="_blank"><br/><u>Attachment</u></a>
                                </td> 
                               <td style="width: 120px"><small>{{ engage.created_at | formatTime }}</small></td> 
                            </tr>
                        </tbody>
                    </table>
                </v-card-text>
                
            </v-card>
            <v-card class="mt-4" v-if=" queries[0] &&  queries[0].status == 'open'">
                <v-card-text>
                    <v-form>
                        <label>Type reply here</label>
                        <v-textarea solo v-model="reply" class="mt-4" :disabled="loading || submitting" :rules="requiredRule"></v-textarea>
                    </v-form>
                </v-card-text>
                <v-card-text>
                    <label for="">Attachment</label> <br/>
                    <input type="file" name="file" id="file" accept=".pdf,.png,.jpg,.jpeg" ref="file" :disabled="loading || submitting">
                </v-card-text>
                <v-card-text style="display: flex; justify-content: space-between;">
                    <v-btn color="primary" @click="submitContent()" :loading="submitting" :disabled="loading || submitting">SUBMIT</v-btn>
                    <v-btn to="/queries" :disabled="loading || submitting">BACK</v-btn>
                </v-card-text>
            </v-card>
            <div v-if=" queries[0] &&  queries[0].status == 'closed'" class="text-xs-center mt-4 red--text">
                The query is closed!<br/>
                <v-btn to="/queries" :disabled="loading || submitting">BACK</v-btn>
            </div>

            <v-dialog
                v-model="submitting"
                persistent
                width="300">
                <v-card
                color="primary"
                dark>
                <v-card-text>
                    Submitting please wait ...
                    <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                    ></v-progress-linear>
                </v-card-text>
                </v-card>
            </v-dialog>
        </v-flex>
      </v-layout>
    </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import { instance } from '../../_axios'
import moment from 'moment'
export default {
    data(){
        return {
            loading: true,
            submitting: false,
            queryId: this.$route.params.id,
            requiredRule: [
                v => !!v || 'Field is required',
                v => v.length > 10 || 'Field is too short (min 10 character)'
            ],
            queries: [],
            reply: '',
            attachment: '',
        }
    },
    mounted(){
        this.fetchQuery()
    },
    methods: {
        backtoList() {
            this.$router.push('/queries')
        },
        async fetchQuery(){
            try {
                this.queries = (await instance.get('/query/'+this.$route.params.id)).data
            } catch (error) {
                console.log(error)
            }
            this.loading = false
            return []
        },
        async submitContent(){
            if(this.reply.length < 10){
                return alert('Type reply and it should not be less than 10 characters')
            }
            this.submitting = true
            if(this.$refs.file.files && this.$refs.file.files.length >= 1){
                let file = this.$refs.file.files[0]
                try {
                    let uploadUrl = await instance.post('/queries-attachment-url', {contentType: file.type})
                    this.attachment = uploadUrl.data.url

                    var options = {
                            headers: {
                                'Content-Type': file.type,
                                'x-amz-acl': 'public-read'
                            }
                        };
                    await instance.put(this.attachment, file, options)

                } catch (error) {
                    console.log(error)
                    this.submitting = false
                    this.$store.commit('open_snackbar',{text:'File upload failed', color: 'red'})
                    return //alert("File upload failed!")
                }
            }

            try {
                let resp = await instance.post('/query/'+this.$route.params.id, { content: this.reply, attachment: this.attachment?this.attachment.split('?')[0]:null })
                console.log(resp)
                this.$store.commit('open_snackbar',{text:'Reply submitted!', color: 'green'})
                this.fetchQuery()
            } catch (error) {
                console.log(error)
                // alert(typeof error === 'string' ? error : error.message)
                this.$store.commit('open_snackbar',{text:'Reply submi failed! + '+(typeof error === 'string' ? error : error.message), color: 'red'})
            } finally{
                this.submitting = false
            }
        }
    },
    computed: {
        ...mapGetters(['user'])
    },
    filters: {
        formatTime(val){
            return moment(val).format('hh:mm a T DD-MM-Y').split('T').join('\n\n')
        }
    },
    watch: { }
}
</script>