<template>
  <v-menu  left fixed offset-y >
    <v-btn flat :icon="$vuetify.breakpoint.xsOnly" slot="activator"> 
      <v-badge right>
        <span v-if="unreadCount>0" slot="badge" color="info--text text--lighten-4">{{unreadCount}}</span>
        <v-icon :color="unreadCount>0?'info':'grey'">notifications</v-icon>
      </v-badge>
    </v-btn>
    <v-list two-line dense>
      <v-list-tile v-if="notifications.length < 1" >
        <v-list-tile-title v-text="'No unread notification'" ></v-list-tile-title>
      </v-list-tile>
      <template v-else>
        
        <v-list-tile avatar v-for="item in notifications" :key="item.title" @click="toggleItem(item)" >
          <v-list-tile-avatar>
            <v-icon :color="item.read_at==null?'info':'blue-grey'">notifications</v-icon>
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title v-text="item.data.title" ></v-list-tile-title>
            <v-list-tile-sub-title >{{item.created_at | formatDate}}</v-list-tile-sub-title>
          </v-list-tile-content>
        </v-list-tile>
        <v-divider></v-divider>
        <v-list-tile avatar @click="markAllRead" >
          <v-list-tile-avatar>
            <v-icon >done_all</v-icon>
          </v-list-tile-avatar>
          <v-list-tile-title v-text="'Mark All Read'" ></v-list-tile-title>
        </v-list-tile>

      </template>
    </v-list>
    <NotificationDialogVue :showDialog="showItemDialog" :item="showItem" :hideNoticeDialog="() => { showItemDialog = false }"></NotificationDialogVue>
    <NotificationGlobalVue :onMessage="() => fetchNotification()"></NotificationGlobalVue>
  </v-menu>
</template>
<script>
import { mapGetters } from 'vuex'
import timeAgo from '../../utils/timeAgo'
import NotificationDialogVue from '../desk/NotificationDialog.vue'
import NotificationGlobalVue from '../NotificationGlobal.vue'
import NotificationModel from './../../models/NotificationModel'

export default {
    data() {
        return {
          loading: false,
          notifications: [],
          showItemDialog: false,
          showItem: null
        }
    },
    computed: {
        ...mapGetters(['auth', 'user', 'inTest']),
      unreadCount(){
        return this.notifications.filter(v => !v.read_at).length
      }
    },
    mounted(){
      this.fetchNotification()
    },
    methods: {
      async fetchNotification(){
        if(this.inTest) return
        this.loading = true

        try {
          let data = await NotificationModel.fetch()
          this.notifications = data.data
          this.notifications.map( v => {
            try {
              v.data = JSON.parse(v.data)
            } catch (error) {
              v.data = {}
            }
            return v
          })
          
        } catch (error) {
          console.log(error)
          this.loading = false
        }

        setInterval(() => {
          if (Date.now() > parseFloat(NotificationModel.getNextUpdate())) {
            this.fetchNotification()
          }
        }, NotificationModel.updateInterval*60*1000)
      },
      async markAllRead(){
        if(!confirm('Mark all read?')) return
        try {
          await NotificationModel.markAllRead()
          this.notifications = this.notifications.map(v => {
            v.read_at = new Date()
          })
        } catch (error) {
          console.log(error)
        }
      },
      toggleItem(item){
        this.showItem = { ...item.data, created_at: item.created_at }
        setTimeout(() => {
          this.showItemDialog = true; 
          item.read_at = new Date()
          NotificationModel.markRead(item.id)
        }, 100);
        console.log(this.showItem)
      }
  },
  filters: {
    formatDate(value){
      return timeAgo(value)
    },
  },
  components: {
    NotificationDialogVue,
    NotificationGlobalVue
  },
}
</script>