<template>
<div class="text-xs-center">
      <v-alert :value="emptyFile" color="orange" outline icon="info" class="mt-4">
      UPLOADED FILES WILL BE SHOWN HERE!
      </v-alert>
      <v-alert :value="count>0" color="success" outline icon="info" class="mt-4">
      {{count}} FILE(S) FOUND!
      </v-alert>
    
      <v-layout row wrap v-if="Array.isArray(fileUploads.uploads) && fileUploads.uploads.length">
      <v-flex v-for="(file,i) in fileUploads.uploads" :key="i" xs12 class="pa-1">
        <v-card tile>
          <v-card-actions>
              <v-btn :href="`${file.url}`" target="_blank" color="primary" flat> <v-icon left>{{file | getIcon}}</v-icon> VIEW file {{i+1}}</v-btn>
              <v-img v-if="file.contentType.startsWith('image')" :src="`${file.url}`" height="50px" contain></v-img>
              <v-spacer></v-spacer>
              <v-btn @click="deleteFile(i)" round dark color="red" flat>Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      </v-layout>


      <!-- <v-layout row wrap v-if="Array.isArray(fileUploads.iu) && fileUploads.iu.length">
      <v-flex v-for="(image,i) in fileUploads.iu" :key="i" xs12 class="pa-1">
        <v-card tile>
          <v-card-actions>
              <v-btn :href="`${user.config.CDN}${folder}/${image}`" target="_blank" color="primary" flat> <v-icon left>image</v-icon> VIEW IMAGE {{i+1}}</v-btn>
              <v-img :src="`${user.config.CDN}${folder}/${image}`" height="50px" contain></v-img>
              <v-spacer></v-spacer>
              <v-btn @click="deleteFile(i,'iu')" round dark color="red" flat>Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      </v-layout>

      <v-layout row wrap >
      <v-flex v-for="(pdf,i) in fileUploads.pu || []" :key="i" xs12 md12 class="pa-1">
        <v-card tile>
          <v-card-actions>
              <v-btn :href="`${user.config.CDN}${folder}/${pdf}`" target="_blank" color="info" dark flat ><v-icon left>picture_as_pdf</v-icon> View PDF {{i+1}}</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="deleteFile(i,'pu')" round color="red" flat>Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex v-for="(video,i) in fileUploads.vu || []" :key="`v${i}`" xs12 md12 class="pa-1">
        <v-card tile>
          <v-card-actions>
            <v-btn :href="`${user.config.CDN}${folder}/${video}`" target="_blank" color="#006064" dark flat ><v-icon left>videocam</v-icon> View Video {{i+1}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="deleteFile(i,'vu')" round color="red" flat>Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex v-for="(audio,i) in fileUploads.au || []" :key="`a${i}`" xs12 md12 class="pa-1">
        <v-card tile>
          <v-card-actions>
              <v-btn :href="`${user.config.CDN}${folder}/${audio}`" target="_blank" color="#E65100" dark flat ><v-icon left>audiotrack</v-icon> View Audio {{i+1}}</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="deleteFile(i,'au')" round color="red" flat>Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>

      <v-flex v-for="(excel,i) in fileUploads.eu || []" :key="`e${i}`" xs12 md12 class="pa-1">
        <v-card tile>
          <v-card-actions>
              <v-btn :href="`${user.config.CDN}${folder}/${excel}`" target="_blank" color="#311B92" dark flat ><v-icon left>table_rows</v-icon> View excel {{i+1}}</v-btn>
              <v-spacer></v-spacer>
              <v-btn @click="deleteFile(i,'eu')" round color="red" flat>Delete</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex> -->

    </v-layout>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  data() {
    return {}
  },
  props: {
    fileUploads: {
      type: Object,
      default: () => {}
    },
    folder: {
      type: String,
      default: 'assignment-files'
    }
  },
  methods: {
    deleteFile(index, ukey){
      this.$emit('deleteFile', {index, ukey})
    }
  },
  computed:{
    ...mapGetters(['user']),
    count(){
      // let count = 0
      // for(let x in this.fileUploads){
      //   if(Array.isArray(this.fileUploads[x])) count += this.fileUploads[x].length
      // }
      return this.fileUploads?this.fileUploads.length : 0
    },
    emptyFile(){
      return this.count<1
    }
  },
  filters: {
    getIcon: (file) => {
      if(file.contentType.startsWith('image')) return 'image'
      if(file.contentType.startsWith('application/pdf')) return 'picture_as_pdf'
      if(file.contentType.startsWith('audio')) return 'audiotrack'
      if(file.contentType.startsWith('video')) return 'videocam'
      if(file.contentType.startsWith('application/')) return 'table_rows'
      return 'file'
    }
  }
}
</script>