<template>
<div>
    <v-card class="text-xs-center" style="min-height: 300px;padding-top: 50px; margin-top: 25px; background: transparent">
        <div v-if="!result ">
            <v-icon :size="50">announcement</v-icon>
            <br>
            <br>
            <h2 class="title">{{message}}</h2>
       </div>
       <div v-else>
            <v-data-table
              :headers="headers"
              :items="result.ranks"
              class="elevation-1"
              :pagination="{rowsPerPage:-1}"
            >
              <template  slot="items" slot-scope="props">
                  <tr :class="user.id===props.item.user_id?'my-rank':''">
                <td class="text-xs-left">{{ props.item.rank }}</td>
                <td class="text-xs-left">{{ props.item.name }}</td>
                <td class="text-xs-center">{{props.item.result.scored}}</td>
                <td class="text-xs-center">{{props.item.result.accuracy}}</td>
                <td class="text-xs-center">{{props.item.result.attempted}}</td>
                <td class="text-xs-center">{{props.item.result.skipped}}</td>
                <td class="text-xs-center">{{props.item.result.correct}}</td>
                <td class="text-xs-center">{{props.item.result.wrong}}</td>
                <td class="text-xs-center">{{props.item.result.time_spent}}</td>
                </tr>
              </template>
            </v-data-table>
       </div>
     </v-card>
     </div>
</template>
<script>
import { instance } from './../_axios'
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            message: 'Ranking is available for Live Test Series only.',
            test_id: this.$route.params.id,
            columnWidth: this.$vuetify.mdAndUp?'auto':'100px',
            result:  null,
            headers: [
                { text: 'Rank', sortable: false, value: 'rank', width: '50px' },
                { text: 'Name', align: 'left', value: 'name', width: this.columnWidth, sortable: false },
                { text: 'Score', align: 'center', value: 'result.scored',  width: this.columnWidth, sortable: false },
                { text: 'Accuracy(%)', align: 'center', value: 'result.accuracy',  width: this.columnWidth, sortable: false },
                { text: 'Attempt', align: 'center', value: 'result.attempted',  width: this.columnWidth, sortable: false },
                { text: 'Skipped', align: 'center', value: 'result.skipped',  width: this.columnWidth, sortable: false },
                { text: 'Correct', align: 'center', value: 'result.correct',  width: this.columnWidth, sortable: false },
                { text: 'Wrong', align: 'center', value: 'result.wrong',  width: this.columnWidth, sortable: false },
                { text: 'Time Spent', align: 'center', value: 'result.time_spent',  width: this.columnWidth, sortable: false },
            ]
        }
    },
    props:['testData'],
    mounted(){
        if(this.testData.type=='live'){
            this.message = 'Result will be declared soon.'
        }
        this.fetchResultData()
    },
    methods: {
        fetchResultData() {
            instance.get(`/test/out/${this.test_id}/ranking`) 
            .then(resp => {
                this.result = resp.data
            })
            .catch(error => {
                this.message = error.message
            })           
        }
    },
    computed:{
        ...mapGetters(['user'])
    }
}
</script>
<style scoped>
.my-rank{
    background-color: aquamarine
}
</style>