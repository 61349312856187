<template>
   <v-card elevation="0">
      <v-card-text class="text-xs-center">
        <v-layout row wrap >
          <v-flex xs12 >
            <h2 headline class="primary--text">DEVICE CHECK</h2>
            <div v-if="(videoOk&&audioOk)">
                <b v-if="!videoOk || !audioOk"> {{!audioOk?'Audio is not working.':'Video is not working'}}</b>
                <b v-else>Both webcam and microphone are working fine.</b>
               <v-layout row style="max-width: 300px;margin: 0 auto;">
                    <v-flex xs6 class="justify-center"><v-checkbox v-model="videoOk" :color="videoOk?'green':'red'" :label="`Video`" readonly></v-checkbox></v-flex>
                    <v-flex xs6 class="justify-center"><v-checkbox v-model="audioOk" :color="audioOk?'green':'red'" :label="`Audio`" readonly></v-checkbox></v-flex>
                </v-layout>
               <video autoplay="true" id="videoElement" ref="videoElement"></video>
               <v-select
                    :items="devices"
                    v-model="videoInput"
                    item-text="label"
                    item-value="deviceId"
                    label="Select Camera Device"
                    outline
                    @change="allow()"
                    style="max-width:350px; margin: 20px auto"
                ></v-select>
            </div>
            <div v-else>
                <p class="pt-4" style="font-size: 18px"> Please allow website to access <b>{{errorText}}</b>.<br> Click on <b>Recheck</b> button after allowing permission(s) 
                    <br/> <small><i>( If problem still persists, contact Invigilator/concerned personnel. )</i></small>
                </p>
                <div>
                    <img v-if="!videoOk" src="https://s3.eu-central-1.wasabisys.com/classroom/webcam.png" class="pimg"/>
                    <img v-if="!audioOk" src="https://s3.eu-central-1.wasabisys.com/classroom/microphone.png" class="pimg"/>
                    <!-- <img class="pt-4 pb-4" src="./../../assets/video-permission.png" alt="Allow permission" /> -->
                </div>
                <v-layout row wrap v-if="showHowTo" style="min-height:400px; margin-top: 20px">
                    <template v-if="!desktop">
                    <v-flex xs12 md3 >
                        <div style="background: url(https://s3.eu-central-1.wasabisys.com/classroom/HowToMicrophoneCamera.PNG) 10px 0;width: 270px; height: 636px; max-width:100%"></div>
                    </v-flex>
                    <v-flex xs12 md3 >
                        <div style="background: url(https://s3.eu-central-1.wasabisys.com/classroom/HowToMicrophoneCamera.PNG) 900px 0;
                        width: 310px;
                        height: 636px; max-width:100%"></div>
                    </v-flex>
                    <v-flex xs12 md3 >
                        <div style="background: url(https://s3.eu-central-1.wasabisys.com/classroom/HowToMicrophoneCamera.PNG) 600px 0;
                    width: 290px;
                    height: 636px; max-width:100%"></div>
                    </v-flex>
                    <v-flex xs12 md3 ><div style="background: url(https://s3.eu-central-1.wasabisys.com/classroom/HowToMicrophoneCamera.PNG) 300px 0;
                    width: 290px;
                    height: 636px; max-width:100%"></div></v-flex>
                    </template>
                    <template v-else> 
                        <v-flex xs12 md4 >
                            <div style="background: url(https://s3.wasabisys.com/classroom/laptop1.PNG); width: 420px; height: 400px; max-width:100%"></div>
                        </v-flex>
                        <v-flex xs12 md4 ><div style="background: url(https://s3.wasabisys.com/classroom/Laptop2.PNG); width: 420px; height: 400px; max-width:100%"></div></v-flex>
                        <v-flex xs12 md4 ><div style="background: url(https://s3.wasabisys.com/classroom/Laptop3.PNG); width: 420px; height: 400px; max-width:100%"></div></v-flex>
                    </template>
                </v-layout>
                <v-btn  @click="showHowTo=!showHowTo" style="margin-top: 50px" color="info" round>How To Allow?</v-btn>
                <v-btn  @click="tryAgain()" style="margin-top: 50px" color="primary" round>Recheck</v-btn>
            </div>
               
           </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions class="mt-2 text-xs-center">
          <v-btn  outline flat round to="/test">Back</v-btn>
          <v-spacer></v-spacer>
          <v-btn :disabled="!fine" ref="proceed" color="info" round :to="`/test/${$route.params.id}/instruction?${resume?'resume=true':''}`">Proceed</v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>

import ReleaseMedia from './../../utils/releaseMedia'
import {setCameraDevice, getCameraDeviceID, getCameraDeviceLabel} from './../../utils/webCam'
var skip = process.env.VUE_APP_SKIP_PREPARE==='true' || false
var gumStream; 						//stream from getUserMedia()
var URL = window.URL || window.webkitURL;

export default {
    data(){
        return {
            cameraError: false,
            video:  null,
            canvas: null,
            width:  640,
            height: 0,
            devices: [],
            videoInput: null,
            selectedDevice: 'user',
            localStream: null,
            APPURL: process.env.VUE_APP_URL,
            resume: Boolean(this.$route.query.resume),
            videoOk: false,
            audioOk: false,
            showHowTo: false,
            desktop: window.innerWidth>600
        }
    },
    mounted(){
        this.cameraError = null
        if(skip){
            this.$refs.proceed.click()
        } else {
            this.getCameraSelection()
        }
    },
    beforeDestroy(){
        ReleaseMedia(gumStream)
    },
    destroyed(){
        ReleaseMedia(gumStream)
    },
    props: {
        testId: {
            type: String,
            default: ''
        }
    },
    methods: {
        tryAgain(){
            window.location.reload()
        },
        allow(){
            this.cameraError = null
            ReleaseMedia(gumStream)
            this.$nextTick(() => {
                // required
                setCameraDevice( this.devices.find(({deviceId}) => deviceId===this.videoInput) )
                this.startWebCam()
            })
        },
        async getCameraSelection  ()  {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            this.devices = videoDevices.map(videoDevice => {
                return { deviceId: videoDevice.deviceId, label: videoDevice.label.charAt(0).toUpperCase()+videoDevice.label.substring(1) }
            });
            this.$nextTick(() => {
                if(getCameraDeviceLabel()) {
                    this.videoInput = getCameraDeviceID()
                } else {
                    if(this.devices.length) setCameraDevice( this.devices[0] )
                }
                this.allow()
            })
            
        },
        startWebCam(){
            this.cameraError = null
            const config = { 
                        video: {
                            width: 640,
                            height: 480,
                            deviceId: this.videoInput
                        }
                    }
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                
                navigator.mediaDevices.getUserMedia(config)
                .then( (stream) => {
                    console.log('Here')
                    gumStream = stream
                    this.videoOk = true
                })
                .catch( (err0r) => {
                    this.videoOk = false
                    console.log(err0r)
                    if(/permission/.test(err0r.message.toLowerCase())){
                       this.cameraError = "Please allow this website to access webcam!"
                    } else {
                        this.cameraError = err0r.message.replace(/[^\:]*:/, '')
                    }
                });

                navigator.mediaDevices.getUserMedia({ audio: true })
                .then( (stream) => {
                    this.audioOk = true
                })
                .catch( (err0r) => {
                    this.audioOk = false
                });
            }
        },
        startWebcamVideo(){
           this.$nextTick(() => {
               this.video = document.getElementById('videoElement') 
            console.log(this.video)
                if(!this.video) return
                if ("srcObject" in this.video) {
                        console.log(this.video)
                    this.video.srcObject = gumStream;
                } else {
                        console.log('AVOID')
                    // Avoid using this in new browsers, as it is going away.
                    this.video.src = window.URL.createObjectURL(gumStream);
                }
                this.video.onloadedmetadata = (e) => {
                    this.video.play();
                };
                this.video.addEventListener('canplay', (ev) => {
                    this.height = this.video.videoHeight / (this.video.videoWidth/this.width);
                })
           })
        }
    },
    computed: {
        fine(){
            if(skip) return true
            return  this.videoOk && this.audioOk
        },
        errorText(){
            if(!this.videoOk && !this.audioOk) return 'webcam and microphone'
            if(!this.videoOk && this.audioOk) return 'webcam'
            if(this.videoOk && !this.audioOk) return 'microphone'
            return ''
        }
    },
    watch: {
        videoOk(val){
            console.log(val, this.audioOk)
            if(val && this.audioOk){
                this.startWebcamVideo()
            }
        },
        audioOk(val){
            console.log(val, this.videoOk)
            if(val && this.videoOk){
                this.startWebcamVideo()
            }
        }
    }
}
</script>

<style>
#videoElement {
    height: 250px;
    max-width: 100%;
	background-color: #666;
}
.pimg{
    max-width: 150px
}
</style>