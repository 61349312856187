<template>
   <v-card>
      <v-card-text class="text-xs-center">
        <v-layout row wrap >
          <v-flex xs12 >
            <div v-if="!mediaError">
                <div v-if="mediaType==2">
                    <div :class="{'hidden':imageCaptured}">
                        <h4 class="title pb-4">Click on capture image button to see captured image -</h4>
                        <video autoplay="true" id="videoElement" ></video>
                        <br>
                        <v-btn color="primary" round @click="takepicture()">Capture</v-btn>
                    </div>
                    <div :class="{'hidden':!imageCaptured}">
                        <v-flex xs12>
                            <p>
                                <h3 class="title pb-2">Please make sure -</h3>
                                <ul style="list-style:none">
                                    <li>1. Your handwriting is visible.  <i>(To improve quality - ensure sufficient light intensity from front side)</i></li>
                                    <li>2. Captured Image is not flipped. <i>(Fix internal camera settings to avoid this issue.)</i> </li>
                                </ul>
                            </p>
                            <canvas id="canvas"></canvas>
                            <div class="output" style="visibility:hidden;position: absolute;z-index:-1;top:0">
                                <img id="photo" alt="The screen capture will appear in this box.">
                            </div>
                        </v-flex>
                    </div>
                    <v-select
                        v-if="devices.length>1 && !uploading"
                        :items="devices"
                        v-model="videoInput"
                        item-text="label"
                        item-value="deviceId"
                        label="Select Camera Device"
                        outline
                        @change="startMedia(2)"
                        style="max-width:350px; margin: 0 auto"
                    ></v-select>
                    <br v-if="isDesktop">
                </div>
                <div v-if="mediaType==1">
                    <div >
                        <v-btn color="primary" round :disabled="audioRecording || uploading" @click="startMedia(1)">Record</v-btn>
                        <v-btn color="primary" round :disabled="!audioRecording || uploading" @click="stopRecordingAudio()" >Stop</v-btn>
                        <p v-if="audioRecording">Recording Answer : {{audioRecordTimer}}s</p>
                    </div>
                    <br v-if="isDesktop"/>
                    <audio v-show="audioBlob" ref="audioPlayer" controls/>
                </div>
                
                <v-progress-linear :indeterminate="true" v-if="uploading"></v-progress-linear>
            </div>
            <div v-else>
                <p class="pt-4"> Please allow website to access webcam! </p>
                <br>
                <img class="pt-4 pb-4" src="./../../assets/video-permission.png" alt="Allow permission">
                <br>
                <p>Click try again to recheck.</p>
                <!-- <v-btn outline @click="init()" >Recheck</v-btn> -->
            </div>
           </v-flex>
            
        </v-layout>


      </v-card-text>
      <v-card-actions class="mt-2 text-xs-center">
          <v-btn color="info darken-1" outline flat :disabled="uploading"  round to="/troubleshoot">Back</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outline flat :disabled="uploading" round @click="tryAgain()">Try Again / Retake</v-btn>
          <!-- <v-spacer></v-spacer>
          <v-btn color="green darken-1" dark round :disabled="!audioBlob && !imageCaptured" :loading="uploading" @click="uploadAnswer()" >Upload</v-btn> -->
      </v-card-actions>
      <input style="width:0; height:0; visibility: hidden" type="file" accept="image/*" capture="environment" @change="mobileCamCaptured" ref="mobileCapture" @click="internalCameraClicked()"/>
    
    
    </v-card>
</template>

<script>
import Recorder from './../../utils/recorder'
import {instance as axios} from './../../_axios'
import ReleaseMedia from './../../utils/releaseMedia'
import Compressor from 'compressorjs'
import {mapActions,mapGetters} from 'vuex'

const AudioContext = window.AudioContext || window.webkitAudioContext;
var audioContext //audio context to help us record
var gumStream; 						//stream from getUserMedia()
var rec; 							//Recorder.js object
var input; 							//MediaStreamAudioSourceNode we'll be recording
var URL = window.URL || window.webkitURL;

export default {
    data(){
        return {
            uploadState: 0,
            mediaError: false,
            video:  null,
            canvas: null,
            width:  640,
            height: 360,
            devices: [],
            videoInput: null,
            uploading: false,
            base64: null,
            zoomImage: false,
            localStream: null,
            audioRecording: false,
            audioBlob: null,
            audioRecordTimer: 0,
            audioRecordTimerInterval: null,
            imageCaptured: false,
            isDesktop: window.innerWidth>500,
            mediaType: 2
        }
    },
    props: {
        testImages: {
            type: Array,
            default: function(){ return [] }
        }
    },
    mounted(){
        ReleaseMedia(gumStream)
        ReleaseMedia(this.localStream)

        setTimeout(() => {
            this.init()
        }, 500)
        if(!this.isDesktop){
            this.width = window.innerWidth-30
            this.height = window.innerWidth*4/3
        }
    },
    beforeDestroy(){
        ReleaseMedia(gumStream)
        ReleaseMedia(this.localStream)
    },
    destroyed(){
        ReleaseMedia(gumStream)
        ReleaseMedia(this.localStream)
    },
    methods: {
        ...mapActions(['initJitsiProctor']),
        tryAgain(){
            this.init()
        },
        fileSelected(event){
            Array.from(this.$refs.file.files).forEach((file) => {
                var reader = new FileReader()
                reader.onloadend = () => {
                    this.selectedImages.push({data: reader.result, status: 0})
                }
                reader.readAsDataURL(file)
            })

        },
        init(){
            this.mediaError = null
            this.imageCaptured = false
            this.getCameraSelection()
            if(this.audioRecordTimerInterval) clearInterval(this.audioRecordTimerInterval)
            this.$nextTick(() => {
                this.startMedia(this.mediaType)
            })

        },
        async getCameraSelection  ()  {
            const devices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = devices.filter(device => device.kind === 'videoinput');
            this.devices = videoDevices.map(videoDevice => {
                return { deviceId: videoDevice.deviceId, label: videoDevice.label.charAt(0).toUpperCase()+videoDevice.label.substring(1) }
            });
        },
        startMedia(type) {
            this.mediaError = null
            this.video = document.getElementById('videoElement')
            
            if(type==2 && !this.isDesktop){
                this.$refs.mobileCapture.click()
                return false
            }

            // if(this.stream) {
            //     gumStream = this.stream
            //     return this.captureMedia(type)
            // }
        console.log('Here')
            ReleaseMedia(gumStream)
            var mediaConfig = type==1?{ audio: true }:{
                video: {
                    width: 640,
                    height: 480,
                    deviceId: this.videoInput
                }
            }

            if (navigator.mediaDevices.getUserMedia) {
            
                navigator.mediaDevices.getUserMedia(mediaConfig)
                .then( (stream) => {
                    console.log('Stream found')
                    gumStream = stream
                    this.localStream = stream
                    this.captureMedia(type)
                })
                .catch( (err0r) => {
                    if(/permission/.test(err0r.message.toLowerCase())){
                        this.mediaError = "Please allow this website to access webcam/audio!"
                    } else {
                        this.mediaError = err0r.message.replace(/[^\:]*:/, '')
                    }
                });
            }
            
        },
        captureMedia(type) {
            if(type==2){
                 this.video.srcObject = gumStream;
                this.video.play();
                this.video.addEventListener('canplay', (ev) => {
                    this.height = this.video.videoHeight / (this.video.videoWidth/this.width);
                })
            } else if(type==1) {
                audioContext = new AudioContext();
                input = audioContext.createMediaStreamSource(gumStream);
                rec = new Recorder(input,{numChannels:1, workerPath: '/recorderWorker.js'})
                this.recordAudio()
            }
        },
        takepicture() {
            var canvas = document.getElementById('canvas')
            var context = canvas.getContext('2d');
            if (this.width && this.height) {
                canvas.width = this.width;
                canvas.height = this.height;
                context.drawImage(this.video, 0, 0, this.width, this.height);
                var data = canvas.toDataURL('image/png');
                photo.setAttribute('src', data);
                this.base64 = data
                this.imageCaptured = true
            } else {
                this.clearphoto();
            }
        },
        clearphoto() {
            var canvas = document.getElementById('canvas')
            var context = canvas.getContext('2d');
            context.fillStyle = "#AAA";
            context.fillRect(0, 0, canvas.width, canvas.height);

            var data = canvas.toDataURL('image/png');
            photo.setAttribute('src', data);
            this.imageCaptured = false

        },
        recordAudio() {
            if(rec) {
                this.audioRecordTimer = 0
                this.audioRecordTimerInterval = setInterval(()=>{
                    this.audioRecordTimer += 1
                },1000)
                this.audioBlob = null
                this.$refs.audioPlayer.src = null
                this.audioRecording = true
                rec.record()
            }
        },
        stopRecordingAudio() {
            clearInterval(this.audioRecordTimerInterval)
            this.audioRecording = false
            gumStream.getAudioTracks()[0].stop()
            rec.exportWAV( (blob) =>{
                this.audioBlob = blob;
                this.$refs.audioPlayer.src = URL.createObjectURL(blob)
            });
        },
        uploadAnswer(){
            if(this.mediaType == 2) {
                return document.getElementById('canvas').toBlob((async blob => {
                    this.uploading =false
                }), 'image/webp')
                 
            } else if(this.mediaType == 1) {
                
            }
            
        },
        mobileCamCaptured(event){
            let files = event.target.files
            if(files.length<1) return 
            this.imageCaptured = true
            let fileReader = new FileReader()
            fileReader.onload = (prog) => {
                var image = new Image()
                image.onload = () => {
                    var canvas = document.getElementById('canvas')
                    var context = canvas.getContext('2d')
                    context.drawImage(image, 0, 0, this.width, this.height);
                }
                image.src = prog.target.result
            }
            fileReader.readAsDataURL(files[0])
            
            var testID = this.$route.params.id
            
            let that = this
            new Compressor(files[0], {
                quality: 0.8,
                maxWidth: 1080,
                maxHeight: 720,
                success(result) {
                    
                },
                error(err) {
                    console.log(err.message);
                },
            });
            
        },
        internalCameraClicked(){
            if(window.api) {
                try{
                    window.api.executeCommand('hangup');
                    window.api = null
                } catch(e){
                    console.log(e)
                }
            }
            console.log('Proctor/Timer removed')
            this.$store.commit('toggle_proctor', false)
        },
        hideIfNoFileCaptured(){
            if(this.$refs.mobileCapture && (!this.$refs.mobileCapture.files || this.$refs.mobileCapture.files.length<1)) this.$emit('close')
        }
    },
    computed: {
        ...mapGetters([ 'pauseProctor' ])
    },
    watch: { 
        
    }
}
</script>

<style scoped>
.hidden{
    visibility: hidden;
    height: 0px;
    max-height: 0px;
    position: absolute;
    top: 0;
}
</style>
<style>
#videoElement {
	width: 480px;
    max-width: 100%;
	height: auto;
	background-color: #666;
}
canvas{
    max-width: 100%;
}
</style>