export default (context, config) => {
    context.testConfig = Object.assign(context.testConfig, config)
    adjustConditional(context)
    context.warningOffset = config.WARNING_OFFSET
    context.blurTime = config.MAX_BLUR_ALLOWED_TIME
    context.$store.commit('set_test_config', context.testConfig)
}

function adjustConditional(context){
    if(context.testConfig.isMobileDevice) {
        context.testConfig.MAX_BLUR_ALLOWED_TIME = context.testConfig.MAX_BLUR_ALLOWED_TIME_M
        context.testConfig.DETECT_SPEECH = !!context.testConfig.DETECT_SPEECH_MOBILE
    }
}