import {TestLocation, queryLocation, logLocation, auth, user } from './../initialValues'

export default {
    state: () => ({
        firebaseApp:null,
        showOffer: false,
        drawer: false, //window.innerWidth<600?false:true,
        toolbar: true,
        cardToolbar: false,
        testStatus: {},
        snackbar: {
          model: false,
          timeout: 2000,
          color:'primary',
          defaultColor: 'primary',
          text: ''
        },
        bookmarks:     [],
        motivation:    [],
        notices:       [],
        todos:         [],
        courseFull:    [],
        courseChapter: [],
        fcmToken: null
      }),
      mutations: {
        open_snackbar(state, val){
          val.model = true
          if(!val.color) val.color = state.snackbar.defaultColor
          state.snackbar = val
        },
        close_snackbar(state){
          state.snackbar.model = false
        },
        set_bookmarks(state, val) {
          state.bookmarks = val
        },
        delete_bookmarks(state, ids){
          state.bookmarks = state.bookmarks.filter(v => ids.indexOf(v._id)<0)
        },
        set_todos(state, val) {
          state.todos = val
        },
        add_todo(state, val) {
          state.todos.push(val)
        },
        delete_todos(state, ids){
          state.todos = state.todos.filter(v => ids.indexOf(v._id)<0)
        },
        done_todos(state, payload){
          state.todos.forEach(v => {
            if(payload.ids.indexOf(v._id)>-1) v.done = payload.done
          })
        },
        set_coursec(state, course){
          state.courseChapter = course
        },
        set_coursef(state, course){
          state.courseFull = course
        },
        toggle_drawer(state,v){
          if(v!=undefined) state.drawer = v
          else state.drawer = !state.drawer
        },
        toggle_toolbar(state,v){
          if(v) state.toolbar = v
          else state.toolbar = !state.toolbar
        },
        toggle_cardtoolbar(state,v){
          if(v) state.cardToolbar = v
          else state.cardToolbar = !state.cardToolbar
        },
        set_motivation(state,v){
          state.motivation = v
        },
        set_notices(state,v){
          state.notices = v || []
        },
        show_offer(state,v){
          state.showOffer = v
        },
       
        set_firebase_app(state, app){
          state.firebaseApp = app
        },
        set_fcm_token(state, value){
          state.fcmToken = value
        }
      },
      actions: {
        errorSnack({commit, state}, {e, b}) {
          var text = ''
          if(e.data) text = e.data.message
          else if(e.constructor==String)  text = e
          else if(e.message) text = e.message
          else text = b
          commit('open_snackbar', {color: 'red', text: text})
        },
        
        showWelcome({commit, rootState}){
          commit('open_snackbar',{text: `Welcome back ${rootState.auth.user.name}`})
        },
        showWelcomeNew({commit, state}){
          commit('open_snackbar', {text: `Registration successful. Welcome to ThinkMerit`})
        },
        showOffer({commit, state}, bool){
          commit('show_offer', bool )
        },
        saveLog({commit, state}, data){
          return state.firebaseApp.firestore().collection(logLocation).add(data)
      }
        
      },
      getters: {
        snackbar: state => {
          return state.snackbar
        },
        bookmarks: state => {
          return state.bookmarks
        },
        todos: state => {
          return state.todos
        },
        motivation: state => {
          return state.motivation
        },
        notices: state => {
          return state.notices
        },
        courseChapter: state => {
          return state.courseFull.length?state.courseFull:state.courseChapter
        },
        courseFull: state => {
          return state.courseFull
        },
        toolbar: state => {
          return state.toolbar
        },
        drawer: state => {
          return state.drawer
        },
        showOffer: state => {
          return state.showOffer
        },
        firebaseApp: state => {
          return state.firebaseApp
        },
        fcmToken : state => state.fcmToken,
      },
}