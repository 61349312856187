import firebase from "firebase/app";
import firebaseConfig from './../config.js'
import 'firebase/auth'
import "firebase/firestore";
import "firebase/messaging";
import initPresence from './presence'

export default function (vm){
  
    if (!firebase.apps.length) {
        vm.$store.commit('set_firebase_app', firebase.initializeApp(firebaseConfig))
        
    }
    firebase.auth().signInAnonymously()
    .then(() => {
      initPresence(vm)
    })
    .catch(function(error) {
      var errorCode = error.code;
      var errorMessage = error.message;
    });
}