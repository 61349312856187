<template>
    <v-dialog :value="showDialog" width="500" >
    <v-card v-if="item">
      <v-card-title
        class="headline grey lighten-2"
      >
        {{item.title}}
      </v-card-title>

      <v-card-text >
        <div v-html="item.content"></div>
        <div v-if="item.attachments && item.attachments.length>0">
          <ul>
            <li v-for="(attac, aindex) in item.attachments" :key="aindex">
              <v-btn target="_blank" :href="attac" >Attachment {{ aindex+1 }}</v-btn>
            </li>
          </ul>
        </div>
      </v-card-text>
      <v-card-text class="gray--text" ><i>Publised at {{item.created_at | formatDateTime}}</i></v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        
        <v-btn
          color="info"
          @click="() => hideNoticeDialog()"
          depressed
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import moment from 'moment'

export default{
    data(){
        return {}
    },
    props: {
        showDialog: {
            type: Boolean,
            default: false
        },
        hideNoticeDialog: {
            type: Function,
            default: () => {}
        },
        item:{
            type: Object,
            default: () => {}
        }
    },
    filters: {
        formatDate(value){
        return moment(value).format('Do MMM YYYY')
        },
        formatDateTime(value){
        return moment(value).format('H:m a Do MMM YYYY')
        }
    },
}
</script>