<template>
<div>
   <span ref="aa" v-html="data"></span>   	
</div>
</template>
<script>
export default{
	data(){
      return {}
	},
	methods:{
            renderMathJax(){
                  setTimeout(() => {
                        if(window.MathJax){ window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub,this.$refs.aa]);  }  
                        else this.renderMathJax()
                  }, 500)
            }
	},
	props: ['data'],
	mounted() {
            this.renderMathJax()
	}
}
</script>
<style scoped>
span{
   color: black
}
</style>