<template>
    <v-layout row wrap style="margin-top: 56px">
        <v-flex xs12 class="mt-4" v-for="(sfile, index) in uploadStatuses" :key="index">
        <p class="text-xs-center" v-html="sfile.uploadMessage"></p>
        <v-progress-linear :indeterminate="sfile.indeterminate" :value="sfile.uploadPercent" v-if="uploading"></v-progress-linear>
        </v-flex>
    </v-layout>
</template>
<script>
export default {
    date() {
        return {}
    },
    props: {
        uploadStatuses: {
            type: Array,
            default: () => []
        },
        uploading: {
            type: Boolean,
            default: false
        }
    }
}
</script>