<template>
    <div  class="d-flex justify-content-center" >
        <template v-if="!loading">
            <!--<span class="dark--text text--ligten-5 mt-4" style="opacity: 0.5" v-if="upcomingClasses.length <= 0">No upcoming class!</span>-->
            <div id="list" v-if="upcomingClasses.length > 0">
                <div v-for="schedule in upcomingClasses.filter((v,i)=>i==0)" :key="schedule.created_at" class="text-xs-center">
                    <v-card :class="schedule.canceled_at?`red--text`:''" flat round>
                        <v-card-text class="text-xs-left">
                            <v-layout crow wrap>
                                <v-flex xs12>
                                    <small class="float-right">{{schedule.time | time}}</small>
                                    <h5 class="caption mb-2 secondary-text op5">UPCOMING CLASS</h5>
                                </v-flex>
                                <v-flex xs8 color="primary">
                                    <span class="body">{{schedule.subject_name.toUpperCase()}}</span>
                                    <br>
                                    <!--<v-icon color="teal">timer</v-icon> {{schedule.time}} / {{schedule.duration}} mins-->
                                    <small><i class="caption op5 ">By {{ schedule.teachers.map(v => v.name).join(', ') }}</i></small>
                                </v-flex>
                                <v-flex xs4 class="text-xs-right">
                                    <v-btn flat outline color="primary" disabled class="mr-0">JOIN</v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import Schedule from '../../views/LiveClass/Schedule'
import moment from 'moment'
//import sample from './sample'

export default{
    data() {
        return {
            schedules: [],
            todaysSchedules: [],
            day: 'Monday',
            selectedDay: 'Monday',
            loading: true,
            current: {
                hour: parseInt(moment().format('H')),
                minute: parseInt(moment().format('mm'))
            },
            upcomingClasses: [],
            onGoingClass: null
        }
    },
    mounted(){
        this.fetchSchedule()
        
    },
    methods: {
        async fetchSchedule(){
            try {
                console.log("FETCHING")
                let temp = (await Schedule.fetch())
                //temp = {data: sample}
                this.schedules = temp.data.schedules.map(v => {return {...v, hour: parseInt(v.time.split(":")[0]), minute: parseInt(v.time.split(":")[1]), order: parseInt(v.time.split(':')[0])}})
                this.day  = temp.data.day
                this.selectedDay = temp.data.day+''
                this.date = temp.data.date
                this.todaysSchedules = this.schedules.filter((ss) => ss.day == this.day)
                
                this.upcomingClasses = this.todaysSchedules.filter(today => today.hour > this.current.hour ||  (today.hour == this.current.hour && today.minute > this.current.minute))
                this.onGoingClass = this.todaysSchedules.find(today => {
                    let start = moment(today.time, 'hh:mm')
                    let end = start.clone().add(today.duration, 'm')
                    return moment().isBetween(start, end)
                }) || null

            } catch (error) {
                console.log(error)
                error = error.message
            }
            this.loading = false
        },
    },
    components: {
    },
    filters:{
        time(value){
            return moment(value, 'hh:mm').format('hh:mm A')
        }
    }
   
}
</script>