import ReleaseMedia from './../../../utils/releaseMedia'
import {getCameraDeviceID,getCameraDeviceLabel} from './../../../utils/webCam'
import {ImageBitmapToBlob,ImageBitmapToCanvas} from './Webcam'

const PROCTOR_TYPES = {
    NONE: 'none',
    VANIS: 'v-s',
    SCREEN: 'screen',
    JITSIS: 'jitsi-single',
    JITSIM: 'jitsi-multi'
}

const startProctor = (context, callback = () => {}, now = false) => {
    console.log('Start Proctor Called')
    const PTYPE = context.testConfig.PROCTOR_TYPE
    if(PTYPE === PROCTOR_TYPES.SCREEN)              callback()
    else if(PTYPE === PROCTOR_TYPES.VANIS)          handleVani(context)
    else if(PTYPE.startsWith('jitsi'))  handleJitsi(context, now)
    else { console.log('Unkown Proctor Type') }

    handleImageProctorDisplay(context)
}

const handleImageProctorDisplay = (context) => {
    if(context.testConfig.DISPLAY_IMAGE_PROCTOR){
        const vid = document.getElementById('videos')
        vid.style.position = 'relative'
        vid.style.visibility = 'visible'
        vid.style.marginBottom = '200px'
    }
}

const handleVani = (context) => {
    context.$nextTick(() => {
        const jwt_token = localStorage.getItem('jwt_token')

        var vanii = document.createElement('iframe')
        vanii.setAttribute('allow', 'camera;microphone')
        vanii.setAttribute('id', 'vaniframe')
        vanii.src = `${context.testConfig.PROCTOR_DOMAIN}${context.user.id}?ss=${context.isDesktop&&context.testConfig.SCREEN_SHARE?1:0}&video=1&audio=1&defaultMute=0&token=${jwt_token}`
        vanii.onload = () => {
            const startWithDevice = { "type":"DevicesResponse" }
            console.log('Start vani ')
            if(getCameraDeviceLabel()) {
                startWithDevice.cameraId = getCameraDeviceLabel()
                console.log('Start with device')
                console.log(getCameraDeviceID(), getCameraDeviceLabel())
            }
            vanii.contentWindow.postMessage(startWithDevice,"*")
            console.log('VANI LOADED')
        }
        
        var meet = document.querySelector('#meet')
        meet.innerHTML = ''
        meet.append(vanii)
        window.addEventListener('message', (e) => { handleVaniImage(context, e) })
    })
    
}

const handleJitsi = async (context, now) => {
    const PTYPE = context.testConfig.PROCTOR_TYPE
    const JOPTIONS = {
        user: context.user, 
        mockID: context.$route.query.id, 
        roomNo: context.$route.query.room || 'common',
        roomPrefix: context.testConfig.ROOM_PREFIX,
        roomPass: context.testConfig.ROOM_PASS,
        type: context.testConfig.PROCTOR_TYPE,
        domain: context.testConfig.PROCTOR_DOMAIN
    }
    const devID = getCameraDeviceID()
    if(devID){
        const dev = await getDeviceByID(devID)
        JOPTIONS.devices = { videoInput: dev.label }
    }
  
    context.setJitsiOptions(JOPTIONS)
    let option = { }
    if(PTYPE === PROCTOR_TYPES.JITSIS && now===true)  {
        option = {
            // preJoin: () => { ReleaseMedia(context.localStream) },
            onJoin:  () => {},
            onLeave: () => {
                console.error('Loading Video Stream')
                context.initStream()
            }
        }
        context.initJitsiProctor(option)
    } else if(PTYPE === PROCTOR_TYPES.JITSIM) context.initJitsiProctor(option)
}

const handleVaniImage = function(context, e){
    let data = e.data;
    if(data.hasOwnProperty("type")){
        if(data["type"] === "ImageCapture"){
            if(data["status"] === true){
                let imageBitmap = data["imageBitmap"];
                // VANIUPLOADER(context, imageBitmap)
                ImageBitmapToCanvas(imageBitmap)
            } else{
                console.log(data, data["error"]);
            }
        }
    }
}

const initStream = async (context, callback = async () => {}) => {
    console.log('Getting media stream')
    console.log(context.testConfig.PROCTOR_TYPE)
    context.$nextTick(async () => {
        if(context.testConfig.PROCTOR_TYPE !== PROCTOR_TYPES.NONE) {
            // if(context.testConfig.PROCTOR_TYPE != PROCTOR_TYPES.VANIS ){}
            try {
                ReleaseMedia(context.localStream)
                context.localStream = await navigator.mediaDevices.getUserMedia({video:  { deviceId: getCameraDeviceID() }, audio: false});
                document.querySelector('#localVideo').srcObject = context.localStream;
            } catch (error) {
                console.log(error)
                context.denied.webcam = true
                context.askingPermission = true
                context.error = 'Please allow Webcam and Microphone access permission to start test..'
                context.permissionMessage = 'Please allow Webcam and Microphone access permission to start test..'
                return
            }
            context.askingPermission = false
            startProctor(context, callback)
        } else {
            callback()
        }
    })
}

const getDeviceByID = async function(devId){
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput' && device.deviceId==devId);
    return videoDevices[0]
}

export default {initStream, startProctor, PROCTOR_TYPES}