import Vue from 'vue'
import Vuex from 'vuex'
import AssementModule from './stores/assement'
import AuthModule from './stores/auth'
import ChatModule from './stores/chat'
import GlobalModule from './stores/global'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    assessment: AssementModule,
    auth: AuthModule,
    chat: ChatModule,
    global: GlobalModule
  }
})

