<template>
<div class="text-xs-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          Select Theme Color
        </v-card-title>

        <v-card-text>
            <!--<div v-for="(pallete, index) in palletes" :key="index">
               <div><span class="pallete" v-for="color in pallete" :key="color" :style="`background-color: ${color} ;`"></span></div> Theme {{ index+1 }}
            </div>-->
            <v-radio-group v-model="themeColor">
              <template v-slot:label>
                <div>Your favourite <strong>color combination</strong></div>
              </template>
              <v-radio v-for="(pallete, index) in palletes" :key="index" :value="index">
                <template v-slot:label>
                  <div><span class="pallete" v-for="color in pallete.slice(0,1)" :key="color" :style="`background-color: ${color} ;border-radius: 50%;margin-top: 10px;`"></span> Theme {{index+1}}</div>
                </template>
              </v-radio>
            </v-radio-group>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
        <v-btn
            flat
            @click="$emit('close')"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            flat
            @click="selectTheme()"
          >
            Select
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {palletes} from './../../colors'

export default{
    data(){
        return {
            //dialog: false,
            palletes,
            themeColor: 0
        }
    },
    mounted (){
    
      if(window.localStorage.getItem('themeIndex')){
        console.log("Theme exists")
        this.themeColor = parseInt(localStorage.getItem('themeIndex'))
      } else {
        this.themeColor = 0
      }
      this.selectTheme(true)
    },
    props: {
        dialog: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        selectTheme(hideNotification = false){
            //console.log(this.palletes)
        try {
          ['primary', 'secondary', 'error', 'warning', 'info', 'success'].forEach((meta, index) => {
              console.log(this.$vuetify.theme[meta], this.palletes[this.themeColor][index])
              this.$vuetify.theme[meta] = this.palletes[this.themeColor][index]
            })
            window.localStorage.setItem('themeIndex', this.themeColor)
            this.$emit('close')
            if(!hideNotification)
              this.$store.commit('open_snackbar', {text: 'Theme applied successfully', color: 'primary'}) 
        } catch (error) {
            console.error("Theme application failed")
        }
          //primary: '#1976D2',
          //secondary: '#424242',
          //accent: '#82B1FF',
          //error: '#FF5252',
          //info: '#2196F3',
          //success: '#4CAF50',
          //warning: '#FFC107'
        }
    }
}
</script>
<style scoped>
.pallete{
  display: inline-block;
  width: 20px;
  height: 20px;
}
</style>