export default (context) => {
    if(!context.testData.format.partwise_timing) return 
    context.testData.format.parts.every((part, pi) => {
        if(context.timeLeft <= part.time_lefts && context.timeLeft >= part.time_lefte ){
            if(context.api !== pi){
                context.restTime = false
                context.api = pi // required
                context.$store.commit('set_qi', part.fqn-1)
                context.$store.commit('set_qpi', pi)
                // context.selectQuestion({
                //     qn: part.fqn,
                //     pi: pi
                // })
                
            }
            context.timeLeftPart = Math.abs(context.timeLeft-part.time_lefte)
            return false
        }
        if(context.partwiseTimeGap>0 && context.timeLeft >= part.time_lefte && context.timeLeft <= part.time_lefte-context.partwiseTimeGap){
            context.restTime = true
            context.timeLeftPart = Math.abs(context.timeLeft-part.time_lefte+context.partwiseTimeGap)
            return false
        }
        return true
    });
    
}