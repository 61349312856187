let CURRENT = 0
let SIGNEDURL = null
const {instance} = require('./../../../_axios.js');
import Webcam, {GetImageBlob} from './Webcam'
import PINGFIREBASE from './PINGFIREBASE'
import ProctorHelper from './VideoProctor'

var vaniFrame = document.querySelector('#vaniframe')

export default function tick(context) {
    if(!context.testConfig.IMAGE_PROCTOR_URL)  return 
    CURRENT += 1
    if(context.testConfig.PROCTOR_TYPE==ProctorHelper.PROCTOR_TYPES.VANIS){
        if(!vaniFrame || !vaniFrame.contentWindow) vaniFrame = document.querySelector('#vaniframe')
        vaniFrame.contentWindow.postMessage({ "type": "ImageCaptureRequest" }, "*")
    } 
    if(CURRENT >= context.testConfig.IMAGE_PROCTOR_INTERVAL) {
        UPLOADSNAP(context)
        CURRENT = 0
    }
}

const UPLOADSNAP = async function(context){
    try {
        let blob = null
        if(context.testConfig.PROCTOR_TYPE==ProctorHelper.PROCTOR_TYPES.VANIS){
            blob = await GetImageBlob()
        } else {
            blob = await Webcam()
        }
        const url = await UPLOADER(context, blob)
        PostUpload(context)
    } catch (error) {
        console.log(error.message)
    }
}

const PostUpload = function(context){
    console.log('PINGING -' +SIGNEDURL)
    if(!SIGNEDURL) return 
    context.lastImageProctor = SIGNEDURL.split('.png')[0]+'.png'
    PINGFIREBASE(context)
}

const UPLOADER = async function(context, blob, dest = null){
    const resp = await instance({ 
        url: `/get-upload-url/${context.userData.mock_id}/${context.$store.state.assessment.activeQuestion.qn}/${context.testConfig.BUCKET}`, 
        baseURL: context.testConfig.IMAGE_PROCTOR_URL,
        method: 'POST',
        data: { dest }
    })
    SIGNEDURL = resp.data.signedURL

    var options = {
        headers: {
            'Content-Type': 'image/png',
            'x-amz-acl': 'public-read'
        }
    }
    await instance.put(SIGNEDURL, blob, options)
    return SIGNEDURL.split('.png')[0]+'.png'
}

// const VANIUPLOADER = async function(context, imagebitmap){
//     await UPLOADER(context, await ImageBitmapToBlob(imagebitmap))
//     PostUpload(context)
// }


export {UPLOADER}