<template>
    <v-card :color="colors[idx%colors.length]" class="white--text">
    <v-card-text>
        <div class="headline">{{idx+1}}. {{sm.subject_name}}</div>
        <code class="text-uppercase body-2" v-if="sm.type=='assignment'" v-html="`Assignment Due Date: ${assignmentDate}`"></code> <br>
        <!-- <b>Description:</b> {{sm.description || 'NA'}} <br> -->
        Teacher: {{sm.author_name}}<br>
        <span v-if="sm.assignment_count">Assignments: {{sm.assignment_count}}<br></span>
        <span v-if="sm.material_count">Materials: {{sm.material_count}}<br></span>
        <span v-if="sm.video_lecture_count">Video Lecture: {{sm.video_lecture_count}}<br></span>
        <v-icon class="bg"  x-large >{{sm | icon}}</v-icon>
    </v-card-text>
    <v-card-actions class="white-action">
        <v-btn flat @click="goTo(sm)" outline round>View</v-btn>
        <v-spacer ></v-spacer>
        <span>Total Items: {{sm.item_count}}</span>
        <!-- <v-btn  flat dark readonly class="hidden-sm-and-down">Published At {{publishedAt}}</v-btn> -->
    </v-card-actions>
   </v-card>
</template>
<script>
export default {
    data(){
        return {
            // colors:{
            //     'assignment':"cyan darken-2",
            //     'default': "blue-grey darken-2"
            // },
            colors: [
                "cyan darken-2", "blue-grey darken-2", '#005005'
            ]
        }
    },
    props: {
        sm: {
            type: Object,
            default: () => {}
        },
        idx: {
            type: Number,
            default: 0
        }
    },
    methods: {
        goTo(sm){
            let query = { tid: sm.author_id, sn: sm.subject_name, tn: sm.author_name}
            if(this.$route.query && this.$route.query.type) query.type = this.$route.query.type
            this.$router.push({path: `/study/${sm.subject_id}`, query})
        }
    },
    filters:{
        icon(val){
            // if(val.type.indexOf('image')==0) return 'image'
            // if(val.type.indexOf('application/pdf')==0) return 'picture_as_pdf'
            // if(val.type.indexOf('sound')==0) return 'audiotrack'
            // if(val.type.indexOf('video')==0) return 'videocam'
            return 'description' //'val'
        },
        dateFilter(val){
            try {
                return Date.parse(val).toLocaleString()   
            } catch (error) {
                console.log(error)
                return val
            }
        }
    },
    computed: {
        assignmentDate(){
            if(!this.sm.is_assignment) return null
            try {
                const date = (new Date(this.sm.due_date))
                return date.toLocaleTimeString()+" "+date.toLocaleDateString()   
            } catch (error) {
                console.log(error)
                return val
            } 
        },
        publishedAt(){
            try {
                const date = (new Date(this.sm.published_at))
                return date.toLocaleTimeString()+" "+date.toLocaleDateString()   
            } catch (error) {
                console.log(error)
                return val
            } 
        }
    }
}
</script>
<style scoped>
.bg{
    position: absolute;
    float: right;
    color: white;
    opacity: 0.2;
    right: 20px;
    top: 20px;
}

.white-action{
    background: white;
    color: black;
}
</style>