<template>
<div>
    <v-dialog v-model="receiptDialog" fullscreen :scrollable="true" persistent>
        <v-card>
            <!-- <v-card-title class="text-xs-center">Upload Receipt for {{feeItem ? feeItem.fee_head_title : ''}}</v-card-title> -->
            <v-card-text>
                <h3 class="text-xs-center title">Upload Receipt for {{feeItem ? feeItem.fee_head_title : ''}}</h3>
                <div class="text-xs-center"  v-if="loading"><v-progress-circularcolor="indigo" indeterminate /></div>
                <v-layout v-else row wrap class="mt-2" gx-2>
                    <v-flex xs12 md6>
                        <v-card>
                            <v-card-text>
                                <v-text-field label="Transaction Date (Required)" type="date" v-model="date"></v-text-field>
                                <v-text-field label="Transaction ID (Optional)" v-model="transactionId"></v-text-field>
                                <v-text-field label="Cheque Number (For cheque only)" v-model="chequeNumber"></v-text-field>
                                <v-textarea label="Comment (Optional)" v-model="comment"></v-textarea>
                                <ImageUploadVue presign-url="/upload-receipt" post-upload-url="/save-receipt" :disable-upload="disableUpload" :payload="payloadData" @fileUploaded="clearData()"/>
                            </v-card-text>
                        </v-card>
                    </v-flex>
                    <v-flex xs12 md6>
                        <p v-if="uploadedReceipts.length <= 0" class="text-xs-center"> <v-icon small>info</v-icon> No receipt data uploaded!</p>
                        <div v-else>
                            <h4 class="pl-4" >Uploaded Receipts</h4>
                            <ul style="list-style: none">
                                <li v-for="(receipt, pi) in uploadedReceipts" :key="pi" class="mt-1">
                                    <v-card>
                                        <v-card-title><i><u>{{receipt.transaction_date | transactionDateFormat}}</u></i></v-card-title>
                                        <v-card-text>
                                            <v-layout row wrap class="pt-0">
                                                <v-flex xs6>
                                                    Comment : <b>{{receipt.comment}}</b> <br/>
                                                    Transaction ID : <b>{{receipt.transaction_id}}</b> <br/>
                                                    Cheque Number : <b>{{receipt.cheque_number || 'NA'}}</b> <br/>
                                                    Comment : <b>{{receipt.comment}}</b><br/>
                                                    Status : <b>{{receipt.status || 'Waiting for processing!'}}</b>
                                                </v-flex>
                                                <!-- <v-flex xs6><v-img :src="payment.upi_qr_code" style="max-width: 340px;"/></v-flex> -->
                                            </v-layout>
                                        </v-card-text>
                                    </v-card>
                                </li>
                            </ul>
                        </div>
                        
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-card-actions style="justify-content:center"><v-btn round @click="closeDialog"><v-icon>close</v-icon> Close</v-btn></v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
import ImageUploadVue from '../../components/ImageUpload.vue'
import { instance } from '../../_axios'
import moment from 'moment'

export default{
    data(){
        return {
            loading: false,
            uploadedReceipts: [ ], // existing receipts
            transactionId: '',
            chequeNumber: '',
            date: new Date().toISOString().substring(0, 10),
            comment: ''
        }
    },
    mounted() { },
    methods: {
        async showPayment(){
            this.loading = true
            try {
                let resp = await instance.get('/uploaded-receipts/'+this.feeItem._id)
                this.uploadedReceipts = resp.data
            } catch (error) {
                this.$store.commit('open_snackbar',{text: 'Failed fetching data!',color: 'red'})
            }
            this.loading = false
        },
        closeDialog() {
            this.$emit('close')
        },
        clearData() {
            this.chequeNumber = ''
            this.transactionId = ''
            this.comment = ''
            this.date = new Date().toISOString().substring(0, 10)
            this.showPayment()
        }
    },
    props: {
        receiptDialog: {
            type: Boolean,
            default: false
        },
        feeItem: {
            type: Object,
            default: () => { }
        },
    },
    watch: {
        'receiptDialog' (newval) {
            if(newval) {
                this.showPayment()
            }
        }
    },
    components: {
        ImageUploadVue
    },
    filters: {
        feeName(val){
            return val.fee_head_title + (['tuition', 'transport', 'hostel'].indexOf(val.fee_type) >= 0 ? ` (${ moment(val.due_month).format('MMM YY') })`:'')
        },
        transactionDateFormat(val){
            return moment(val).format('Do MMM Y')
        }
    },
    computed: {
        disableUpload(){
            return !this.date
        },
        payloadData(){
            if(!this.feeItem) return {}
            return {
                id: this.feeItem._id,
                comment: this.comment,
                transaction_date: this.date,
                transaction_id: this.transactionId,
                cheque_number: this.cheque_number
            }
        }
    }
}
</script>
