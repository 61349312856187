
export default (context) => {
    if(context.user.browser=='safari') return
    if(context.testConfig.DETECT_FULLSCREEN){
        if(!(document.fullscreenElement || document.webkitFullscreenElement) || !document.hasFocus() || context.mouseOut){ // !document.fullscreen || 
            context.blurTime -= 1 
            context.showWarning = true
            context.showWarningTimer = true
        } else {
            context.showWarningTimer = false
            // context.showWarning = false
            // context.blurTime = context.testConfig.MAX_BLUR_ALLOWED_TIME
        }
    }
    // terminate if blur time out
    if(context.blurTime <= 0) {
        context.blurTime = 0
        if(context.testConfig.AUTO_TERMINATE) context.submitTest('BLUR_TIME_OUT')
    }
}