<template>
  <v-dialog v-model="showDialog" persistent max-width="500" style="z-index:208" >
    <v-card>
      <v-card-text class="text-xs-center">
        <h3  class="headline mb-1">SUBMIT TEST?</h3>
        <v-divider></v-divider>
        <p class="mt-1">This action can't be undone. Make sure you have saved all the answers. <br v-if="$vuetify.breakpoint.smAndDown"> You have still time left.</p>
        <div class="subheading pt-3">
            <small class="timer-icon"><v-icon>schedule</v-icon> &nbsp;</small>
            <span v-html="timeLeft"></span>
            <table class="mx-auto table-bordered mt-2" v-if="!hideCount">
              <thead>
                <tr>
                  <td>Attempted*</td>
                  <td>Skipped*</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{submitOverview.attempted}}</td>
                  <td>{{submitOverview.skipped}}</td>
                </tr>
              </tbody>
            </table>
            <!-- <p><br>* The count doesn't include image answer sheet uploaded just before submit screen.</p> -->
          </div> 
        <br>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green darken-1" outline flat @click="$store.commit('set_submit_dialog', false)"  round>Resume</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="orange" dark  @click="showDialog = false; submit()"  round>Submit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data() {
      return {
        showDialog: false
      }
    },
    props: {
      'hideCount': {
        type: Boolean,
        default: false
      },
    },
    computed: {
        ...mapGetters([ 'timeLeft', 'submitDialog', 'submitOverview']),
    },
    watch: {
      submitDialog(val) {
        this.showDialog = val
      }
    },
    methods:{
      submit () {
        setTimeout(() => this.$emit('submitTest'), 100)
      }
    }
}
</script>

<style>

</style>