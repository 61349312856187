<template>
<v-container fluid grid-list-lg class="pa-0 mt-4">
    <v-layout row wrap >
      <v-flex xs12 >
        <v-card class="mx-auto" >
          <!-- <v-card-title>
            <v-icon large left > mdi-twitter </v-icon>
            <span class="title font-weight-light">Question Wise Analysis</span>
          </v-card-title> -->
          <v-card-text>
            <v-layout row wrap>
              <!-- <v-flex sm6 md3 >
                <v-select :items="partSelectItems" label="Part" item-value="value" item-text="name" v-model="filters.part"></v-select>
              </v-flex>-->
              <v-flex sm6 md3 v-if="sectionSelectItems.length>1">
                <v-select :items="sectionSelectItems" label="Section" item-value="value" item-text="name"  v-model="filters.section"></v-select>
              </v-flex>
              <!-- <v-spacer></v-spacer>
               <v-flex sm6 md3 >
                <v-select :items="difficultyText" label="Difficulty" item-value="value" item-text="name"   v-model="filters.difficulty"></v-select>
              </v-flex> -->
             
            </v-layout>
            <v-data-table
              :headers="headers"
              :items="questions"
              class="elevation-1"
              :pagination="{rowsPerPage:-1}"
            >
              <template  slot="items" slot-scope="props">
                <tr @click="showQuestion=true; selectedQuestion=(props.item)" title="Show question in review" style="pointer: cursor">
                <td class="text-xs-left">{{ props.item.qn }}</td>
                <td class="text-xs-center">{{ props.item.time_spent }}</td>
                <!-- <td class="text-xs-center">{{ difficultyText[props.item.difficulty].name }}</td> -->
                <td class="text-xs-center">
                  <!-- >0 || props.item.score>0 -->
                  <v-icon 
                    :color="(typeof(props.item.scored) !== 'undefined'?props.item.scored:props.item.score)>0?'green':'red'" 
                    v-if="props.item.scored || props.item.is_correct===true || props.item.is_correct===false">
                    {{(typeof(props.item.scored) !== "undefined"?props.item.scored:props.item.score)>0?'check':'clear'}} 
                  </v-icon>
                  <span v-else>NA</span>
                </td>
                <td class="text-xs-center">{{typeof(props.item.scored) !== "undefined"?props.item.scored:props.item.score}}</td>
                </tr>
              </template>
            </v-data-table>
            <br>  
            *NA : Not attempted
          </v-card-text>
        </v-card>
      </v-flex>

      <v-dialog v-model="showQuestion" fullscreen>
        <div v-if="showQuestion">
          <q-component :question="selectedQuestion" @close="showQuestion=false"></q-component>
        </div>
      </v-dialog>
     
    </v-layout>
  </v-container>	
</template>
<script type="text/javascript">
// console.log('MERIT POINT SMALL INT CHANGE IN PAYMENT DATABASE')
import QuestionComponent from './ResultAnalysis/QuestionComponent'
import groupBy from './../utils/groupBy'

export default {
	data() {
		return {
      questions: [],
      subjects_ids: {},
      chapter_ids: [],
      topic_ids: [],
      columnWidth: this.$vuetify.mdAndUp?'auto':'100px',
      filters: {part: -1, section: -1, difficulty: 0},
      headers: [
          { text: 'Q No.', sortable: false, value: 'qn', width: '50px' },
          { text: 'Time Spent(sec)', align: 'center', value: 'time_spent', width: this.columnWidth, sortable: false },
          // { text: 'Difficulty', align: 'center', value: 'difficulty',  width: this.columnWidth, sortable: false },
          { text: 'Correct', align: 'center', value: 'is_correct', width: this.columnWidth, sortable: false },
          { text: 'Score', align: 'center', value: 'score', width: this.columnWidth, sortable: false }
      ],
      difficultyText: [{name: 'All', value:0},{name:'Easy', value: 1}, {name:'Medium', value: 2}, {name:'Hard', value: 3}],
      showQuestion: null,
      selectedQuestion: null
		}
	},
	methods:{

   setupData() {
      this.questions = []
        this.testData.format.parts.forEach((part, pIndex) => {
          if(this.filters.part>-1 && pIndex!==this.filters.part) return 
          part.sections.forEach((section, sIndex) => {
            if(this.filters.section>-1 && sIndex!==this.filters.section) return 
              this.questions = this.questions.concat(section.questions.map((q, qi) => { 
                var qData = this.userData.test_data[q.qn-1]
                return {...q, part: pIndex+1, section: sIndex+1, score: qData.s, qi, is_correct: qData.ic, user_answer: qData.ua }})
              )  
          })
        })

        if(this.filters.difficulty>0){
          this.questions = this.questions.filter(v => v.difficulty==this.filters.difficulty)
        }
      this.subjects_ids = groupBy(this.questions, 'subject_id')

    },

    // showQuestion(item) {
    //   console.log(this.testData._id)
    //   console.log(item)
    //   // this.$router.push({path: '/test/'+this.testData._id+'/review'+`?pi=${item.part-1}&si=${item.section-1}&qi=${item.qi}`})
    // }
	},
	props: ['testData','part','userData'],
	mounted(){
      this.setupData()
	},
	watch:{
    'filters.part':{
      handler() { this.filters.section = -1; this.setupData()  },
      deep:true
    },
    'filters.section':{
      handler() { this.setupData() },
      deep:true
    },
    'filters.difficulty':{
      handler() { this.setupData() },
      deep:true
    },
    'part'(val){
      this.filters.part = val
    }
  },
  components: {
    'q-component' : QuestionComponent
  },
  computed: {
    // partSelectItems(){
    //   let temp = this.testData.format.parts.map((part,pi) => {
    //       return {
    //         name: part.name?part.name:'Part '+(pi+1), 
    //         value: pi
    //       }
    //     })
    //   temp.unshift({
    //      name: 'All Part', 
    //      value: -1
    //   })
    //   return temp
    // },
    selectedPart() {
        return this.testData.format.parts[this.filters.part]
    },
    sectionSelectItems(){
      let temp = this.selectedPart?this.selectedPart.sections.map((part,pi) => {
          return {
            name: part.name?part.name:'Section '+(pi+1), 
            value: pi
          }
        }):[]
       temp.unshift({
         name: 'All Section', 
         value: -1
        })
      return temp
    }
  }
}
</script>