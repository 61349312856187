// const firebaseConfig = {
//     apiKey: "AIzaSyBgyk7mo9G7k4nInihdpur22zHSU-gNS28",
//     authDomain: "classroom-thinkmerit.firebaseapp.com",
//     databaseURL: "https://classroom-thinkmerit.firebaseio.com",
//     projectId: "classroom-thinkmerit",
//     storageBucket: "classroom-thinkmerit.appspot.com",
//     messagingSenderId: "608355172196",
//     appId: "1:608355172196:web:6c27cceb09a0ce02dd4471",
//     measurementId: "G-MQ3987PSHC"
//   };
// new classroom
  const firebaseConfig = {
    apiKey: "AIzaSyBTvjujZUQ5uhGiyCvunPPG9UT1Q5vfMo4",
    authDomain: "thinkmerit-classroom-91669.firebaseapp.com",
    databaseURL: "https://thinkmerit-classroom-91669-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "thinkmerit-classroom-91669",
    storageBucket: "thinkmerit-classroom-91669.appspot.com",
    messagingSenderId: "1048758292087",
    appId: "1:1048758292087:web:52da49c258f07db0350072",
    measurementId: "G-SWS91B2YBD"
  };
export default firebaseConfig