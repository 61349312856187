<template>
    <v-card class="text-xs-center" elevation="1">
    <v-toolbar flat>
        <v-toolbar-title class="blue-grey--text">DAILY SCHEDULE {{day?`(${day.substring(0,3)})`:''}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items >
        <v-btn light right fab small color="blue-grey lighten-1" depressed dark to="/timetable"><v-icon ripple> arrow_outward </v-icon></v-btn>
        </v-toolbar-items>
    </v-toolbar>
    
    <v-card-text style="min-height: 198px" class="d-flex justify-content-center" :class="schedules.length <= 0?' align-center':''">
        <v-progress-circular indeterminate color="blue-grey" v-if="loading"></v-progress-circular>
        <template v-else>
            <span class="dark--text text--ligten-5 mt-4" style="opacity: 0.5" v-if="todaysSchedules.length <= 0">No schedule found!</span>
            <div id="list" v-else>
                <div v-for="schedule in todaysSchedules" :key="schedule.created_at" class="text-xs-center">
                    <!-- @click="showJoin(selectedDay==day, selectedDay, od)" -->
                    <v-card :class="schedule.canceled_at?`red--text`:'teal--text'" flat color="teal lighten-5" style="border-radius: 5px">
                        <v-card-text class="text-xs-left">
                            <v-layout crow wrap>
                                <v-flex xs8>
                                    <span class="subheading">{{schedule.subject_name.toUpperCase()}}</span> - <span class="body-2 pull-right">{{schedule.teacher_name}}</span>
                                    <br>
                                    <v-icon color="teal">timer</v-icon> {{schedule.time}} / {{schedule.duration}} mins
                                </v-flex>
                                <v-flex xs4>
                                    <v-btn depressed color="secondary">JOIN</v-btn>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </v-card>
                </div>
            </div>
        </template>
        
    </v-card-text>
    </v-card>
</template>
<script>
//import DaySlotVue from '../../views/LiveClass/DaySlot.vue'
import Schedule from '../../views/LiveClass/Schedule'

export default{
    data() {
        return {
            schedules: [],
            todaysSchedules: [],
            day: 'Monday',
            selectedDay: 'Monday',
            loading: true,
        }
    },
    mounted(){
        this.fetchSchedule()
        console.log("HELLO")
    },
    methods: {
        async fetchSchedule(){
            try {
                console.log("FETCHING")
                let temp = (await Schedule.fetch())
                this.schedules = temp.data.schedules.map(v => {return {...v, order: parseInt(v.time.split(':')[0])}})
                this.day  = temp.data.day
                this.selectedDay = temp.data.day+''
                this.date = temp.data.date
                this.todaysSchedules = this.schedules.filter((ss) => ss.day == this.day)
            } catch (error) {
                console.log(error)
                error = error.message
            }
            this.loading = false
        },
    },
    components: {
        //'dayslot': DaySlotVue
    }
   
}
</script>