<template>
<v-card >
    <v-toolbar dark color="primary">
        <v-btn icon dark @click="$emit('close')"> <v-icon>close</v-icon> </v-btn>
        <v-toolbar-title>Test Preview ( Not for answering )</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items></v-toolbar-items>
    </v-toolbar>
    <v-card-text style="max-height:100vh; overflow-y:auto" @scroll="scrolling()" ref="content">
        <template v-for="(question,qi) in mquestions">
            <div :key="qi">
                <h3 class="subheading mt-4 mb-2">&nbsp;Question {{question.qnf || qi+1}}</h3>
                <p>
                    <i>Type</i>: <u>{{questionType[question.qt]}}</u>,
                    <i>Max Marks</i>: <u>{{question.pm}}</u>
                </p>
                <question-item 
                    :key="question.id" 
                    :_question="question" 
                    :readonly="true"
                    :hidebuttons="true"> 
                </question-item>
            </div>
        </template>
        <v-btn v-show="this.scrolled>300" @click="scrollPreviewToTop()" fab dark color="primary" style="position: fixed; bottom: 15px; right: 15px">
            <v-icon dark>keyboard_arrow_up</v-icon>
        </v-btn>
    </v-card-text>
</v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import TestQuestionItem from './../../components/TestQuestionItem.vue'

export default {
    data(){
        return {
            questionType: {
                1:'Subjective',
                3:'MCQ Single Correct',
                4:'MCQ Multi Correct',
                5:'Paragraph',
                6:'Numerical Type',
                8:'Subjective',
            },
            scrolled: 0
        }
    },
    props: {
        part:{
            type: Number,
            default: -1
        }
    },
    mounted(){
        
    },
    methods: {
       scrolling(){
           this.scrolled = this.$refs.content.scrollTop
       },
       scrollPreviewToTop(){
           this.$refs.content.scrollTop = 0
       }
    },
    components:{
        'question-item': TestQuestionItem,
    },
    computed:{
        ...mapGetters(['questions']),
        mquestions(){
            if(this.part>-1) return this.questions.filter((q) => q.pi == this.part)
            return this.questions
        }
    }
}
</script>