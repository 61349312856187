<template>
<div>
    <h3 class="subheading mb-2">
        &nbsp;Question  <b>{{question.qn}}</b> , <i>{{part.name || `Part ${partIndex+1}`}} (Q{{part.fqn}}-Q{{part.lqn}}) </i> 
    </h3>
    <p>
        <!-- <v-btn small icon style="float:right; color: #0d0d" ><v-icon>info</v-icon></v-btn> -->
        <i>Type</i>: <b>{{questionType[question.qt]}}</b>,
        <i>Max Marks</i>: <b>{{question.pm}}</b>
    </p>
</div>
</template>
<script>
export default {
    data(){
        return {
            questionType: {
                1:'Subjective',
                3:'MCQ Single Correct',
                4:'MCQ Multi Correct',
                5:'Paragraph',
                6:'Numerical Type',
                8:'Subjective',
            }
        }
    },
    props: {
        question: {
            type:    Object,
            default: () => {}
        },
        part: {
            type:    Object,
            default: () => {}
        },
        partIndex: {
            type: Number,
            default: 0
        },
        totalQuestions: {
            type: Number,
            default: 0
        }
    }
}
</script>