<template>
  <div></div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import sanitize from './../utils/textSanitizer.js'
var SpeechRecognition
var recognitor


export default {
    data(){
        return {
            recognizing: false,
            status: null,
            lastDetected: Date.now(),
            timer: null
        }
    },
    beforeDestroy(){
        this.clearTimer()
        if(recognitor){
            try {
                recognitor.stop()
            } catch (error) {   
                console.log(error.message)
            }
        }
    },
    mounted(){
        try{
            SpeechRecognition = SpeechRecognition || webkitSpeechRecognition
            document.addEventListener('click', this.init)
            this.setTimer()
        } catch(e){
            console.log(e)
        }
    },
    methods:{
        ...mapActions([ 'sendNewChatMessage', 'sendChatMessage' ]),
        clearTimer(){
            if(this.timer!=null) clearInterval(this.timer)
        },
        setTimer(){
            this.timer = setInterval(() => {
                this.init()
                console.log('Restarting Speech Recognition')
            }, 30000)
        },
        init(){
            document.removeEventListener('click', this.init)

            if(recognitor){
                try {
                    recognitor.stop()
                } catch (error) {   
                    console.log(error.message)
                }
            }

            console.log('Initiating SpeechRecognition')
            recognitor = new SpeechRecognition()
            recognitor.continuous  = true
            recognitor.lang = 'en-US'
            recognitor.start();
            

            recognitor.addEventListener('result', (event) => {
                let currentTranscript = event.results[event.resultIndex][0].transcript.trim()
                // this.sendNewChatMessage({
                //     userId: this.user.id,
                //     payload: {
                //         m:  'STUDENT_SPEAK::'+sanitize(currentTranscript),
                //         uid: this.user.id,
                //         tid: this.mockId,// test id
                //         un:  this.user.name,
                //         tn:  '',
                //         r: 0,
                //         ts: Date.now()
                //     }   
                // })

                this.sendChatMessage({
                     payload: {
                        m: 'STUDENT_SPEAK::'+sanitize(currentTranscript),
                        uid: this.user.id,
                        tid: this.mockId,
                        teid: null,
                        ts: Date.now(),
                        r: 0
                    }
                })

                
                
            })
             recognitor.addEventListener('start', (event) => {
                console.log('started' )
                this.recognizing = true
                this.status = 'started'
            })
             recognitor.addEventListener('error', (event) => {
                this.recognizing = false
                console.log('Error : '+event.error)
                if(event.error=='not-allowed'){
                    // alert allow microphone
                    console.error('Please allow microphone access')
                } else if(event.error=='service-not-allowed'){
                    console.error('Service not allowed')
                } else {
                    // this.init()
                }
                // this.status = 'error'
            })

            recognitor.addEventListener('soundend', (event) => {
                this.recognizing = false
                console.log('Sound ended')
                // this.status = 'soundend'
            })

            recognitor.addEventListener('speechend', (event) => {
                this.recognizing = false
                console.log('Speech ended')
                // this.status = 'speechend'
            })

            recognitor.addEventListener('end', (event) => {
                console.log('Ended')
                this.recognizing = false
            })
        },
        
    },
    computed: {
        ...mapGetters(['user'])
    },
    props: {
        mockId: {
            type: String,
            default: null
        }
    }
}
</script>

<style>

</style>