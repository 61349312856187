const renderMathJax = function(ctx,element){
 // setTimeout(() => {
 //  if(window.MathJax)  element?window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub,window.document.querySelector(element)]):window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub])
 //  else setTimeout(renderMathJax(element),500)
 // }, 500)
 window.MathRendCount = window.MathRendCount || 0
 ctx.$nextTick(() => {
 	// console.log(ctx)
 	if(window.MathJax) {
 		element?window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub,window.document.querySelector(element)]):window.MathJax.Hub.Queue(["Typeset",window.MathJax.Hub])
 		 window.MathRendCount = 0
 	} 
 	else {
 		if( window.MathRendCount <20) setTimeout(renderMathJax(ctx,element),100)
 		window.MathRendCount++
 	}
 })
}

export {renderMathJax}