<template>
 <div class="text-xs-center">
    <v-icon :size="80" class="animate__animated animate__zoomIn  " >{{icon}}</v-icon>
    <h3 class="body-1 animate__animated animate__zoomIn mt-4"> <slot></slot> </h3>
    <!--<v-alert color="warning" value="true" class="mt-4 animate__animated animate__bounce" outline> <slot></slot></v-alert>-->
</div>
</template>
<script>
export default{
    data(){
        return {

        }
    },
    props: {
        icon: {
            type: String,
            default: () => ''
        }
    }
}
</script>