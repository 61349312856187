<template>
<div>
  <v-card class="mx-auto" style="margin-top: -4px;" >

    <v-toolbar class="pt-1 pb-1" flat>
      <v-icon large left ripple> campaign </v-icon>
      <v-toolbar-title class="blue-grey--text">NOTICE</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="enableNotification()"><v-icon color="blue-grey">{{fcmToken?'notifications':'notifications_off'}}</v-icon></v-btn>
      
    </v-toolbar>

    <v-card-text class="pa-0" flat style="height: 220px; overflow-y: auto">
      <div v-if="loading" class="headline font-weight-bold text-xs-center" style="min-height:300px">
        <v-progress-circular :width="4"  color="deep-purple lighten-1 mt-4" indeterminate ></v-progress-circular>
      </div>
      <div v-else>
        <h4 v-if="notices.length<1" 
              class="text-xs-center text-uppercase mt-4 font-weight-light" >
              No data found!
        </h4>

        <v-list two-line>
            
            <v-list-tile v-for="(item, index) in notices" :key="index" avatar @click="showNotice(item)" >
              <v-list-tile-avatar>
                <v-icon :color="(item.read_at==null?'grey':'info')+' lighten-1'">circle</v-icon>
              </v-list-tile-avatar>
              <v-list-tile-content>
                <v-list-tile-title v-html="item.title"></v-list-tile-title>
                <v-list-tile-sub-title v-html="item.content"></v-list-tile-sub-title>
              </v-list-tile-content>

              <v-list-tile-action>
                <small class="text-muted" style="opacity: 0.5"><i>{{item.created_at | formatDate}}</i></small>
              </v-list-tile-action>

            </v-list-tile>
        </v-list>
        
      </div>
    </v-card-text>

  </v-card>

  <NotificationDialogVue :showDialog="showNoticeDialog" :item="showNoticeItem" :hideNoticeDialog="() => { showNoticeDialog = false }"></NotificationDialogVue>

</div>


</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import InitCloudMessaging from './../CloudMessaging'
import moment from 'moment'
import NotificationDialogVue from './desk/NotificationDialog.vue'

export default{
	data(){
    return {
      showNoticeDialog: false,
      showNoticeItem: null,
      items: [],
    	loading: false
    }
	},
	mounted(){
  },
  props:{
    fetch: {
      type: Boolean,
      default: true
    }
  },
  computed:{
    ...mapGetters(['notices', 'fcmToken']),
  },
  methods: {
    enableNotification(){
      if(!this.fcmToken) {
        InitCloudMessaging(this)
      } else {
        this.$store.commit('open_snackbar', {text: 'Notification already enabled!', color: 'blue'})
      }
    },
    showNotice(item) {
      this.showNoticeDialog = true
      this.showNoticeItem = item
      // NotificationModel.markRead(item.id)
      // item.read_at = new Date()
    }
  },
  components: {
    NotificationDialogVue
  },
  filters: {
    formatDate(value){
      return moment(value).format('Do MMM YYYY')
    },
    formatDateTime(value){
      return moment(value).format('H:m a Do MMM YYYY')
    }
  },
  
}
</script>