import firebase from "firebase/app";
import 'firebase/auth'
import "firebase/firestore";
import "firebase/database";
import {presenceLocation} from './initialValues'

function initPresence(context){
    var state = context.$store.state.auth
    
    if(!state.auth) return console.log('User not authenticated!')
    else console.log('Initializing Presence!')
    
    var location = presenceLocation+`/${state.user.id}`
   
    var userStatusDatabaseRef  = firebase.database().ref(location);
    var userStatusFirestoreRef = firebase.firestore().doc(location);
    
    // We'll create two constants which we will write to 
    // the Realtime database when this device is offline
    // or online.
    var isOfflineForDatabase = {
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    };
  
    var isOnlineForDatabase = {
        state: 'online',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
    };
  
    // Firestore uses a different server timestamp value, so we'll 
    // create two more constants for Firestore state.
    var isOfflineForFirestore = {
        state: 'offline',
        name: `${state.user.name}`,
        uid: state.user.id,
        last_changed: firebase.firestore.FieldValue.serverTimestamp(),
    };
  
    var isOnlineForFirestore = {
        state: 'online',
        name: `${state.user.name}`,
        uid: state.user.id,
        last_changed: firebase.firestore.FieldValue.serverTimestamp(),
    };
  
    firebase.database().ref('.info/connected').on('value', function(snapshot) {
        if (snapshot.val() == false) {
            // Instead of simply returning, we'll also set Firestore's state
            // to 'offline'. This ensures that our Firestore cache is aware
            // of the switch to 'offline.'
            userStatusFirestoreRef.set(isOfflineForFirestore);
            return;
        };
  
        userStatusDatabaseRef.onDisconnect().set(isOfflineForDatabase).then(function() {
            userStatusDatabaseRef.set(isOnlineForDatabase);
            // We'll also add Firestore set here for when we come online.
            userStatusFirestoreRef.set(isOnlineForFirestore);
        });
    });
  
  }
export default initPresence