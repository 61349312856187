<template>
<v-card v-if="showSectionInstruction" class="mb-2"> 
    <v-card-text v-if="testData.format.parts[activeQuestion.pi].instruction" v-html="testData.format.parts[activeQuestion.pi].instruction || ``"></v-card-text>
    <v-card-text v-html="testData.format.parts[activeQuestion.pi]['sections'][activeQuestion.si].instruction || `No instruction available.`"></v-card-text>
</v-card>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data() {
       return {

       }  
    },
    props: ['testData'],
    computed: {
        ...mapGetters([ 'activeQuestion', 'showSectionInstruction']),
    }
}
</script>