export default (duration) => {
    let out = ''
    Object.keys(duration).every(key => {
        if(duration[key]>0){
            // || duration.minutes>0
            if(key==='seconds' && (duration.hours>0)) return false
            out += Math.round(duration[key]) +' '+ key.substring(0,1)+ ' '
        } else if(out.length>0) {
            return false
        }
        return true
    })
    return out
}