const { instance } = require('./../../../_axios.js');
import NavigateHelper from './NavigateHelper'

const SyncPayload = (context, type) => {
 /* create params
      *  set answer and set marked if exists
      *  return payload
      */
    // context.localUpdate[context.qn-1] = true
    // console.log(context.$store.state.assessment.stagedQuestions)
    return { 
        id: context.userData._id, 
        updates: getLocalUpdates(context), 
        qn: context.$store.state.assessment.qi+1
    }
}

const getLocalUpdates = (context) => {
  if(context.testData.type==='third-party') return []
  
    return Object.keys(context.$store.state.assessment.stagedQuestions).map( index => {
      
      let question = {...context.$store.state.assessment.questions[index]}
      
      if(question.qt==3){ if(typeof question.ua !=null || typeof question.ua !='number') { console.error('Invalid MCQS question answer') } }
      if(question.qt==4){ if(typeof question.ua !=null || typeof question.ua !='object') { console.error('Invalid MCQM question answer') } }
      if(question.qt==6){ if(typeof question.ua !=null || typeof question.ua !='number') { console.error('Invalid NUMERIC question answer') } }

      delete question.question
      delete question.A
      delete question.B
      delete question.C
      delete question.D
      delete question.E
      delete question.F
      delete question.G
      delete question.H
      delete question.I
      delete question.J
      delete question.ic
      delete question.pdf
      delete question.pdf_images
      delete question.answer
      delete question.solution
      delete question.sk
      delete question.noo
      delete question.id
      delete question.qt
      delete question.pm
      delete question.nm
      
      

      return question
    })
}

const SaveAndNext = (context) => {
  context.fab = 3
  context.syncing = true

  instance.post('/test/sync', SyncPayload(context)) // 
  .then(data => { 
    reflectLocalUpdate(context)
    console.log("ss", context.testData.format.partwise_timing)
    if(context.testData.format.partwise_timing){
        let question = context.$store.state.assessment.questions[context.$store.state.assessment.qi+1]
        if(!question || question.pi != context.api){
          context.$store.commit('open_snackbar', {text: 'You have reached last question of the Part!', color: 'success'})
        } else {
          context.nextQuestion() 
          NavigateHelper.updateRoute(context)
        }
    } else {
      context.nextQuestion() 
      NavigateHelper.updateRoute(context)
    }
    
      
  })
  .catch(err =>  {
    context.$store.commit('open_snackbar', {text: err.message || 'Answer save failed!', color: 'red'})
  })
  .finally(NavigateHelper.revertFab(context))
}

const PrevQuestion =  (context) => {

  if(context.testData.format.partwise_timing){
    let question = context.$store.state.assessment.questions[context.$store.state.assessment.qi-1]
    if(question.pi != context.api){
      context.$store.commit('open_snackbar', {text: 'You have reached first question of the Part!', color: 'success'})
    } else {
      context.prevQuestion() 
      NavigateHelper.updateRoute(context)
    }
  } else {
    context.prevQuestion() 
    NavigateHelper.updateRoute(context)
  }
}

const MarkQ = (context) => {
  context.fab = 2
  context.syncing = true
  instance.post('/test/sync', SyncPayload(context,'mark'))
  .then(data => { 
    reflectLocalUpdate(context)
    context.nextQuestion()  
  })
  .catch(err => {
    context.$store.commit('open_snackbar', {text: err.message, color: 'red'})
  })
  .finally(NavigateHelper.revertFab(context))
}

const ClearQ = (context) => {
  context.fab = 4
  context.syncing = true

  instance.post('/test/sync', SyncPayload(context,'clear'))
  .then(data => { 
      // context.disableClear = true
      context.$refs.activeQuestionItem.clearLocalTypedAnswer() // for clearing local typed answer
      reflectLocalUpdate(context)
    })
  .catch(err => {
    context.$store.commit('open_snackbar', {text: err.message, color: 'red'})
  })
  .finally(NavigateHelper.revertFab(context))
}

const reflectLocalUpdate = (context) => {
  // context.localUpdate = {}
  context.$store.commit('clear_stage_questions')
}

const SetAnswer = (context, {qn, answer}) => {
  // var question = {...context.questions[qn-1]}
  //     if(Array.isArray(answer)) {
  //        question.ua = answer
  //       if(answer.length) question.ats = new Date()
  //       else question.ats = null
  //     } else {
  //       if(answer==='' || answer === null) {
  //         question.ua = null
  //         if(question.iu.length || question.vu.length) question.ats = new Date()
  //         else question.ats = null
  //     } else {
  //         question.ua = answer
  //         question.ats = new Date()
  //     }
  //   }
  //   context.localUpdate[qn-1] = true
  //   context.updateQuestion({index: qn-1, question})
}

const DeleteAnswer = (context, data) => {
  let question = {...context.questions[context.qn-1]}
      question[data.type] = data.data
      if(data.data.length<1) question.ats = null
      else question.ats = new Date()
      context.$set(context.question,question)
      context.updateQuestion({index: question.qn-1, question})
}


export default {
   SyncPayload, SaveAndNext, PrevQuestion, MarkQ, ClearQ, SetAnswer, DeleteAnswer, getLocalUpdates
}