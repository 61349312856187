const { instance, setAccessToken } = require('./_axios.js');
import { clearToken, hasToken, tokenHandler } from './utils/tokenManager'
import initFirebase from './initFirebase'

const AUTH_API = process.env.VUE_APP_AUTH_API || ''

const getUser = function(vm) {
  if(!hasToken()) return false
	 instance.get(AUTH_API+'/user')
	.then((resp) => {
     tokenHandler(vm, resp.data)
     vm.$store.dispatch('showWelcome')
     redirect(vm)
	})
	.catch((error) => {
    clearToken()
    vm.$store.dispatch('clearAuthenticated')
	  console.log(error)
	})
}

const logIn = function(vm) {
  vm.authenticating = true
  const ajax = instance.post(AUTH_API+'/login',vm.user)
  .then((resp) => {
    tokenHandler(vm, resp.data)
    redirect(vm)
  })
  .catch((error) => {
    console.log(error)
  	clearToken()
    eHandler(vm,typeof error=='object'?error.response.data.message:error)
    vm.authenticating = false
    if(typeof error=='object' && error.response.data.verifyOTP) {
      vm.verify_OTP = true
      vm.type = 'register'
    }
  })
  return ajax
}

const register = function(vm) {
  vm.authenticating = true
   instance.post(AUTH_API+'/register',vm.user)
  .then((resp) => {
    tokenHandler(vm,resp.data)
    vm.$store.dispatch('showWelcomeNew', vm.user.name )
    redirect(vm)
  })
  .catch((error) => {
    clearToken()
    eHandler(vm,error)
    vm.authenticating = false
  })
}

const registerPhone = function(vm) {
  vm.authenticating = true
   instance.post(AUTH_API+'/registerphone',vm.user)
  .then((resp) => {
    vm.$router.replace({path: '/auth/register', query: {verifyotp: vm.user.phone}})
    vm.verify_OTP = true
  })
  .catch((error) => {
    clearToken()
    eHandler(vm,error)
    vm.authenticating = false
  })
  .then(()=> vm.authenticating = false)
}

const verifyOTP = function(vm) {
  vm.authenticating = true
  instance.post(AUTH_API+'/verifyOTP', { phone: vm.user.phone, OTP: vm.user.OTP })
  .then((resp) => {
    tokenHandler(vm, resp.data)
    vm.showWelcome = true
    setTimeout(() => {
      redirect(vm)
    },4000)
  })
  .catch((error) => {
    console.log(error);
    eHandler(vm,error)
    vm.authenticating = false
  })
  .then(()=> vm.authenticating = false)
}

const redirect = function(vm) {
  initFirebase(vm)
  if(window.location.pathname.indexOf('/auth')>-1){
    setTimeout(() => { vm.$router.push({path:vm.$route.query.nextUrl || '/'}) },1000)
  } 
}


const eHandler = function(vm,e) {
  vm.$store.commit('open_snackbar', {text: e || 'Error! please login again.'})
  clearToken()
} 

const getParameterByName = function (name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const resetMail = function (vm) {
  vm.sending = true
   instance.post(AUTH_API+'/password/forgotMail',{email: vm.phoneOrEmail})
  .then((resp) => {
    vm.email = ''
    vm.$store.commit('open_snackbar',{text:'Reset mail has been sent!', color: 'blue'})
    vm.message = 'Reset mail has been sent!'
    vm.sent = true
    vm.$router.push({path: '/password/reset', query: {sent: true,message:  'Reset mail has been sent!',is_phone: false}})
  })
  .catch((error) => {
    console.log(error)
    eHandler(vm,error)
  })
  .then(() =>vm.sending = false)
}

const resetOTP = function (vm) {
  vm.sending = true
  instance.post(AUTH_API+'/password/forgotOTP',{phone: vm.phoneOrEmail})
  .then((resp) => {
    vm.email = ''
    vm.$store.commit('open_snackbar',{text:'An OTP has been sent!', color: 'blue'})
    vm.message = 'Reset OTP has been sent!'
    vm.sent = true
    vm.resetToken = 123
    vm.$router.push({path: '/password/reset', query: {sent: true,message: 'OTP sent to your mobile number!',is_phone: true}})
  })
  .catch((error) => {
    console.log(error)
    eHandler(vm,error)
  })
  .then(() =>vm.sending = false)
}

const resetPassword = function (vm) {
  if(vm.$route.query && !vm.$route.query.is_phone){
    if(vm.$route.query && vm.$route.query.token){
      setAccessToken(vm.$route.query.token)
    } else {
      eHandler(vm,{response:{data:{message: 'Token required!'}}})
      return
    }
    // Mail Reset
    vm.sending = true
    instance.post(AUTH_API+'/password/resetMail',{email: vm.phoneOrEmail, password: vm.password})
    .then((resp) => {
      vm.$store.commit('open_snackbar',{text:'Password has been changed successfully!', color: 'green'})
      vm.$router.replace({path:'/auth'})
    })
    .catch((error) => {
      console.log(error)
      eHandler(vm,error)
    })
    .then(() => vm.sending = false)

  } else {
    // OTP Reset
    vm.sending = true
    instance.post(AUTH_API+'/password/resetOTP',{phone: vm.phoneOrEmail, password: vm.password, OTP: vm.OTP})
    .then((resp) => {
      vm.$store.commit('open_snackbar',{text:'Password has been changed successfully!', color: 'green'})
      vm.$router.replace({path:'/auth'})
    })
    .catch((error) => {
      console.log(error)
      eHandler(vm,error)
    })
    .then(() => vm.sending = false)
  }
  
}


export { getUser, logIn, register, redirect, resetMail, resetPassword, registerPhone, verifyOTP, resetOTP }