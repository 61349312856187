<template>
<div style="max-width: 500px; margin: 0 auto">
    <h3 class="sub-title">Change Password</h3>
    <v-alert :type="messageType" :value="true" v-if="Boolean(message)">{{message}}</v-alert>
    <v-form  v-model="formValid" ref="changeForm">
         <v-text-field label="Old Password" v-model="old_password" :rules="[rules.required]"></v-text-field>
         <v-text-field label="Password" v-model="password" :rules="[rules.required, rules.passLength]"></v-text-field>
         <v-text-field label="Confirm Password" v-model="password_confirmation" :rules="[rules.required, rules.confirmation]"></v-text-field>
        <v-btn color="primary" @click="changePassword()" :loading="loading">Save</v-btn>
        <v-btn to="/" :disabled="loading">Back</v-btn>
    </v-form>
</div>
</template>
<script>
import {instance} from './../../_axios'
export default {
    data() {
        return {
            loading: false,
            formValid: false,
            old_password: '',
            password: '',
            password_confirmation: '',
            message: '',
            messageType: 'error',
            rules: {
                required:   value => !!value || 'Required.',
                passLength: value => value && value.length >= 6 || 'Min 6 characters',
                confirmation: value => value && value.length >= 6 && value===this.password || 'Password doesn\'t match'
            }
        }
    },
    methods: {
        validate(){
            if(this.password!==this.password_confirmation){
                this.message = 'Password confirmation doesn\'t match!'
                return false
            }
            return true
        },
        changePassword(){
            this.$refs.changeForm.validate()
            if(this.formValid){
                this.callApi()
                console.log('Is valid')
            } else {
                console.log('asd')
            }
        },
        callApi(){
            this.loading = true
            instance.post('/changePassword', {
                old_password: this.old_password,
                password: this.password,
                password_confirmation: this.password_confirmation
            })
            .then(resp => {
                this.old_password = '',
                this.password = ''
                this.password_confirmation = ''
             
                this.$refs.changeForm.reset()
                this.messageType = 'success'
                this.message = 'Password updated!'
            })
            .catch((err) => {
                console.log(err)
                this.message = String(err)
            })
            .finally(() => {
                this.loading = false
            })
        }
    }
}
</script>