<template>
<div v-if="testData">

  <v-layout row wrap >
    <v-flex xs12 class="mt-2">
      <v-tabs color="transparent"  fixed-tabs v-model="resultTab"><v-tab v-for="n in tabs.length" :key="n" :value="n" > {{ tabs[n-1] }} </v-tab> </v-tabs>
    </v-flex>
  </v-layout>

<v-tabs-items v-model="resultTab" :touchless="$vuetify.breakpoint.smAndDown">
  <v-tab-item>
	   <result-overall :result="resultDisplay" :testdate="userData.test_created_at" :partnames="testData.format.parts.map(v => v.name)" :title="title" :reattempt="retestLink"></result-overall>
  </v-tab-item>
  <v-tab-item>
     <result-detailed :test-data="testData" :user-data="userData" :part="part-1"></result-detailed>
  </v-tab-item>

</v-tabs-items>

</div>
</template>
<script>
import ResultOverall from './ResultOverall'
import ResultDetailed from './ResultDetailed'
import ResultRanking from './ResultRanking'

export default{
	data(){
    return {
      showMore: false,
      infoDialog: false,
      tabs: ['Score Card' /*, 'Detailed' /*, 'Suggestions' , 'Ranking'*/],
      resultTab: 0
    }
	},
	mounted(){
    this.$store.commit('set_in_test', false)
    if(process.env.VUE_APP_HIDE_RESULT_QUESTIONWISE){
      this.tabs.splice(this.tabs.indexOf('Detailed'),1 )
    }
	},
  filters:{
    maxChar(v) {
      if(v.length >50) return v.substring(0, 50)+' ...'; else return v
    }
  },
  props: [ 'testData', 'userData', 'part', 'title'],
	computed:{
    resultDisplay() {
      if(this.part<1) return this.result
      else return this.result.partResults[this.part-1]
    },
    retestLink(){
      return '/test/'+this.testData.mock_id+'/instruction'
    },
    result(){
      return this.userData?this.userData.result:[]
    }
	},
  components: {
    'result-overall': ResultOverall,
    'result-detailed': ResultDetailed,
    'result-ranking': ResultRanking
  }
}
</script>
<style>
.sm-chart{
  position: relative; 
  height:50vh !important; 
  width:80vw !important	
}
</style>