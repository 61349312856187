<template>
    <v-toolbar-items class="ahidden-sm-and-down" v-if="auth">
        <NotificationVue></NotificationVue>
        <AccountVue></AccountVue>
    </v-toolbar-items>
    <v-toolbar-items class="ahidden-sm-and-down" v-else></v-toolbar-items>
</template>
<script>
import { mapGetters } from 'vuex'
import AccountVue from './Account.vue';
import NotificationVue from './Notification.vue';

export default {
    
    computed: {
        ...mapGetters(['auth'])
    },
    components: {
        AccountVue,
        NotificationVue
    }
}
</script>