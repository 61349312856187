<template>
<v-card>
    <v-card-title><h4>Result Insight</h4></v-card-title>
    <v-divider></v-divider>
    <v-list >
       <v-list-tile>
        <v-list-tile-content><span><font-awesome-icon icon="dot-circle" />  Attempted: </span></v-list-tile-content>
        <v-list-tile-content class="align-end">{{ result.attempted }}</v-list-tile-content>
      </v-list-tile>
       <v-list-tile>
        <v-list-tile-content><span><font-awesome-icon icon="angle-double-right" /> Skipped:</span></v-list-tile-content>
        <v-list-tile-content class="align-end">{{ Math.max(result.total - result.attempted, 0) }}</v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-content><span><font-awesome-icon icon="check-circle" /> Correct:</span></v-list-tile-content>
        <v-list-tile-content class="align-end">{{ result.correct }}</v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-content><span><font-awesome-icon icon="times-circle" /> Wrong:</span></v-list-tile-content>
        <v-list-tile-content class="align-end">{{ result.wrong }}</v-list-tile-content>
      </v-list-tile>
      <v-list-tile>
        <v-list-tile-content><span> <font-awesome-icon icon="bolt" />&nbsp; Avg. Speed (per min):</span></v-list-tile-content>
        <v-list-tile-content class="align-end">{{ result.avg_speed }}</v-list-tile-content>
      </v-list-tile>
      
      <v-list-tile>
        <v-list-tile-content><span><font-awesome-icon icon="bullseye" /> Accuracy (%):</span></v-list-tile-content>
        <v-list-tile-content class="align-end">{{ result.accuracy }} </v-list-tile-content>
      </v-list-tile>

      
      <v-list-tile>
        <v-list-tile-content><span><font-awesome-icon icon="clock" /> Time Spent (min):</span></v-list-tile-content>
        <v-list-tile-content class="align-end">{{ Math.round(result.time_spent*100/60)/100 }}</v-list-tile-content>
      </v-list-tile>
      
    </v-list>
  	</v-card>
</template>
<script>
export default{
  props: ['result']
}
</script>