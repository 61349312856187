<template>
    <v-layout row wrap >
        <v-flex xs12 v-if="state==0">
            <div v-if="!cameraError">
                <div class="mt-4">Take Picture of your answer sheet.</div>
                <video autoplay="true" id="videoElement" ref="videoElement" class="mt-3 mb-2"></video>
                <v-select
                    v-if="devices.length>1"
                    :items="devices"
                    v-model="videoInput"
                    item-text="label"
                    item-value="deviceId"
                    label="Change Camera Device"
                    outline
                    @change="initStream()"
                    style="max-width:350px; margin: 0 auto"
                ></v-select>
                <div>
                    <v-btn large btnprimary @click="takepicture()" color="primary" round><v-icon left>photo_camera</v-icon> Capture</v-btn>
                    <!-- <v-btn btnprimary @click="cancel()" color="secondary" outline round>Cancel</v-btn> -->
                </div>
            </div>
            <div v-else>
                <p class="p-4 mt-4">Please allow website to access webcam!</p>
                <img class="p-4" src="./../../assets/video-permission.png" alt="Allow Permission">
                <v-btn outline @click="allow()" large>Recheck</v-btn>
                <v-btn btnprimary @click="state=0" outline large>Back</v-btn>
            </div>
            
        </v-flex>

        <v-flex xs12 v-show="state==1" class="text-xs-center mt-2">
            <p>Please make sure your content is clearly visible.</p>
            <canvas id="canvas"></canvas>
            <div>
                <v-btn @click="uploadImage()" color="primary" round large><v-icon left>upload</v-icon> Upload</v-btn>
                <v-btn @click="init()" color="info" round large><v-icon left>restart_alt</v-icon> Retake</v-btn>
            </div>
            <div class="output" style="visibility:hidden; width: 0;height: 0;position:absolute;top:0">
                <img id="photo" alt="The screen capture will appear in this box.">
            </div>
        </v-flex>
    </v-layout>
</template>
<script>
import ReleaseMedia from './../../utils/releaseMedia'
var gumStream, videoElement
export default {
    data() {
        return {
            state: 0,
            cameraError: null,
            canvas: null,
            width:  640,
            height: 360,
            devices: [],
            videoInput: null
        }
    },
    beforeMount(){
        ReleaseMedia(gumStream)
    },
    beforeDestroy(){
        ReleaseMedia(gumStream)
    },
    mounted() {
        this.init()
    },
    // props: {
    //     uploadFile: {
    //         type: Function,
    //         default: () => {}
    //     }
    // },
    methods: {
        init(){
            this.state = 0
            this.cameraError = null
            
            this.getCameraSelection()
            this.initStream()
        },
        async getCameraSelection  ()  {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                this.devices = videoDevices.map(videoDevice => {
                    return { deviceId: videoDevice.deviceId, label: videoDevice.label.charAt(0).toUpperCase()+videoDevice.label.substring(1) }
                });
            } catch (error) {
                console.log(error)
            }
            
        },
        async initStream(){
            try {
                ReleaseMedia(gumStream)
                this.cameraError = null
                gumStream = await navigator.mediaDevices.getUserMedia({ video: {
                                width: 640,
                                height: 480,
                                deviceId: this.videoInput
                            }})
                this.onGettingMediaStream()
            } catch(err0r) {
                if(/permission/.test(err0r.message.toLowerCase())){
                    this.cameraError = "Please allow this website to access webcam!"
                } else {
                    this.cameraError = err0r.message.replace(/[^\:]*:/, '')
                }
                console.log(err0r)
            }
        },
        onGettingMediaStream(){
            videoElement = document.getElementById('videoElement')
            videoElement.srcObject = gumStream;
            videoElement.play()
            videoElement.addEventListener('canplay', (ev) => { this.height = videoElement.videoHeight / (videoElement.videoWidth/this.width); })
        },
        takepicture(){
            var canvas = document.getElementById('canvas')
            var context = canvas.getContext('2d');
            canvas.width = this.width;
            canvas.height = this.height;
            context.drawImage(videoElement, 0, 0, this.width, this.height);
            this.state = 1
        },
        uploadImage(){
            var canvas = document.getElementById('canvas')
            canvas.toBlob((blob) => {
                console.log(blob)
                this.$emit('takePicture', [blobToFile(blob, 'Snapshot.png')])
            }, 'image/webp')
        },
        cancel(){
            ReleaseMedia(gumStream)
            this.$emit('updateState', null)
        },
        allow(){
            window.location.reload()
        }
    }
}

function blobToFile(theBlob, fileName){
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
}
</script>


<style>
#videoElement {
	max-width: 100%;
	height: auto;
	background-color: #666;
}
</style>