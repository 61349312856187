<template>
    <div>
      <div>
        <page-title>Request Certificate</page-title>
        <div class="text-xs-center" v-if='loading'>
            <v-progress-circular
              :size="250"
              :width="10"
              color="indigo"
              indeterminate
              >Loading Data...
            </v-progress-circular>
        </div>
        <v-flex xs12 _md8 _offset-md2 v-else>
          <v-btn color="primary" to="/certificates">View Certificates</v-btn>
          <v-card class="mt-2">
            <v-layout>
              <v-flex xs12>
                  <v-data-table
                    :headers="headers"
                    :items="certificateTypeList"
                    item-key="_id"
                    :rows-per-page-items="[10, 20, 50]"
                    class="elevation-1" >
                    <template v-slot:items="props">
                        <td >{{props.index+1}}</td>
                        <td ><a @click="dialog=false; fee = props.item">{{ props.item.title}} </a></td>
                        <td >{{ parseFloat(props.item.fee_amount).toFixed(2) }}</td>
                      <td >{{ props.item.description }}</td>
                      <td class="text-xs-center">
                        <v-btn small round outline color="danger" target="_blank" @click="requestNow(props)">Request</v-btn>
                        <!-- <v-btn small round outline @click="feePaymentItem = props.item; receiptDialog = true;"> Upload Receipt</v-btn> -->
                        <!--<v-btn small round outline @click="printReceiptRequest(props.item.fee_head_id, props.item._id, props.item.student_id)"> Print Receipt</v-btn>-->
                      </td>
                    </template>
                </v-data-table>
              </v-flex>
            </v-layout>
           </v-card>
        </v-flex>
        </div>
    
        <v-dialog v-model="dialog" width="500" :scrollable="true">
         <v-card>
            <v-card-title class="headline grey lighten-2"  primary-title> Request Certificate </v-card-title>
            <v-card-text class="pb-4">
              It will redirect to payment page. Once the payment is successfull the request will be generated.
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="primary" flat @click="dialog = false; openPaymentPage()">Pay Now</v-btn>
              <v-spacer></v-spacer>
              <v-btn flat @click="dialog = false;">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    
        <v-dialog v-model="loadingPrint" fullscreen :scrollable="true" persistent>
          <v-card>
            <v-card-text>
              <v-progress-circular :size="200" :width="7" color="indigo" indeterminate>Loading please wait...</v-progress-circular>
            </v-card-text>
          </v-card>
          
        </v-dialog>
    
    </div>
    </template>
    
    <script>
    const {instance} = require('./../../_axios.js');
import { Capacitor } from '@capacitor/core';
    import moment from 'moment';
    import { mapGetters } from 'vuex';

    export default {
        data() {
            return {
                loading: true,
                data: {},
                certificateList: [],
                certificateTypeList: [],
                selected: [],
                fee: null,
                dialog: false,
                paymentDialog: false,
                receiptDialog: false,
                feePaymentItem: null,
                loadingPrint: false,
                headers: [
                  { text: 'SL', sortable: false, value: '_id' },
                  { text: 'Certificate', sortable: false, value: 'title' },
                  { text: 'Fee Amount', sortable: false, value: 'fee_amount' },
                  { text: 'Description', sortable: false, value: 'description' },
                  { text: 'Action', sortable: false, align: 'center'},
                ],
                paymentLink: null,
                paymentWindow: null
            }
        },
        mounted(){
            this.load()
        },
        methods: {  
            async load(){
                try {
                    this.loading = true
                    let resp = await instance.get(`/certificate-request-types`)
                    this.certificateTypeList = resp.data
                    //resp = await instance.get(`/certificate-requests`)
                    //this.certificateList = resp.data
                } catch (error) {
                    console.log(error.message)
                } finally{
                    this.loading = false
                }
            },
            requestNow(props){
              if(Capacitor.isNativePlatform()){
                // TODO : CHANGE
                this.paymentLink = 'https://admin.purvinternationalschool.com' + `/certificate-request?type=${props.item._id}&student=${this.user.id}`
              } else {
                this.paymentLink = 'https://'+window.location.hostname.split('.').map((v,i) => i==0?'admin':v).join('.') + `/certificate-request?type=${props.item._id}&student=${this.user.id}`
              }
              this.dialog = true
            },
            openPaymentPage(){
              this.paymentWindow = window.open(this.paymentLink)
              this.paymentWindow.onbeforeunload = () => {
                  this.load()
              }
            }
        },
        filters: {
            dateFormat: (val, format = "DD-MM-Y") => {
                return moment(new Date(val)).format(format)
            }
        },
        computed:{
          ...mapGetters([ 'user' ])
        }
    }
    </script>