<template>
  <div class="home">
    <v-card max-width="400" class="mx-auto" style="z-index: 1; border-radius: 10px;" hover>
      <v-card-title class="text-xs-center justify-center" primary-title>
        <router-link :to="{path:'/'}"><img :src="logo" style="max-width: 300px;max-height: 300px;"></router-link>
      </v-card-title>
      <v-card-text class="text-xs-center pb-0">
        <v-divider></v-divider>
        <v-container>
          <v-layout row wrap >
            <!-- <v-flex xs5 >
              <v-btn color="blue" round  
                    block 
                    dark 
                    :outline="$route.path.indexOf('login')<0" 
                    @click="$router.push({path:'/auth/login', query: {ref:referral || undefined}})">Login</v-btn>
            </v-flex> -->
            <!-- <v-spacer></v-spacer>
            <v-flex xs5 >
              <v-btn color="teal" round  
                    block 
                    dark 
                    :outline="$route.path.indexOf('register')<0" 
                    @click="$router.push({path:'/auth/register', query: {ref:referral||undefined}})">Register</v-btn>
            </v-flex> -->
          </v-layout>
        </v-container>
        <router-view></router-view>
      </v-card-text>
    </v-card>
     <div id="circles">
      <span class="c"></span>
      <span class="c"></span>
      <span class="c"></span>
      <span class="c"></span>
    </div>
  </div>
  
</template>

<script>
let logoData = require('../../assets/logo-placeholder.png')
if(process.env.VUE_APP_LOGO) logoData = process.env.VUE_APP_LOGO
if(process.env.VUE_APP_HOST_LOGO) logoData = `https://s3.eu-central-1.wasabisys.com/subdomainlogo/${window.location.host}.png`

export default {
    data(){
        return {
            referral: this.$route.query.ref || '',
            logo: logoData,
        }
    },
    mounted(){
        this.$nextTick(() => {
            this.$store.commit('toggle_drawer',false)
            this.$store.commit('toggle_toolbar',false)
        })
    },
    beforeDestroy(){
        // this.$store.commit('toggle_drawer',true)
        this.$store.commit('toggle_toolbar',true)
    },
   

}
</script>

<style>
.auth-form{
    max-width: 300px; margin: 0 auto; padding-bottom: 10px
}
</style>