<template>
  <div>
      <v-btn :href="`/marksheet/${$route.params.id}?print=true`" v-if="!$route.query.print && !loading"><v-icon>print</v-icon> PRINT</v-btn>
      <v-card color="primary" dark v-if="loading">
        <v-card-text>
        Loading data please wait...
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
      <div ref="viewer" style="width: 100%; height; 100%; ">
          <iframe ref="frame" name="frame1" frameborder="0"></iframe>
      </div>
  </div>
</template>

<script>
const {instance} = require('./../../_axios.js');
export default {
  data(){
    return {
      loading: true,
      list: [],
      headers: [
        {
	        text: 'SN',
	        align: 'left',
	        class: 'text-xs-left',
	        value: 'name',
	      },
	      {
	        text: 'Name',
	        align: 'left',
	        class: 'text-xs-left',
	        value: 'name',
	      },
	      { text: 'Publish Date', value: 'updated_at', sortable: false },
	      { text: 'Action', sortable: false }
	    ],
      pagination: {
	      // sortBy: 'name',
	      rowsPerPage: 10
	    },
    }
  },
  mounted(){
    this.fetchData()
  },
  methods: {
    async fetchData(){
      this.loading = true
      var that = this
      try {
        let temp = (await instance.get(`marksheets/${this.$route.params.id}`))
        let frame1 = this.$refs.frame
        frame1.setAttribute('style', 'position: absolute; top: -1000000px')
        let frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
        console.log(temp.data)
        frameDoc.document.open();
        frameDoc.document.write('<html>');
        frameDoc.document.write('<head>');
        frameDoc.document.write('<title></title>');
        frameDoc.document.write('</head>');
        frameDoc.document.write('<body>');
        frameDoc.document.write(temp.data.marksheet);
        frameDoc.document.write('</body>');
        frameDoc.document.write('</html>');
        frameDoc.document.close();
        setTimeout(function () {
            document.title = "MARKSHEET"
            window.frames["frame1"].focus();
           
            console.log("AS")
            if(that.$route.query.print) {
                window.frames["frame1"].print();
                frame1.remove();
                window.document.write((that.$route.query.print?"PRINT SUCCESS ":""))
            } else {
                frame1.setAttribute('style', 'position: absolute;width: 100%;min-height: 100vh')
            }
        }, 500);
      } catch (error) {
        console.log(error)
        window.document.write((that.$route.query.print?"PRINT FAILED: ":"DATA FETCH FAILED: ")+(error.message||'Something went wrong'))
      } finally{
        this.loading = false
      }
    }
  }
}
</script>

<style>

</style>