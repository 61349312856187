import {TestLocation } from './../initialValues'
import testConfig from './../views/Test/utils/readEnv'
import submitCount from './../utils/TestQuestionAttemptCounts'

const proctor = {
        paused: false,
        instance: null,
        options: {
            roomName: 'TESTROOM',
            width: 250,
            height: 250,
            parentNode: document.querySelector('#meet'),
            interfaceConfigOverwrite: {
                DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                DISABLE_RINGING: true,
                DISABLE_FOCUS_INDICATOR: true,
                TOOLBAR_BUTTONS: [
                    'microphone', 'fullscreen', 'tileview'// , 'camera','videoquality', 'filmstrip'
                ],
            },
        },
        domain: 'haproctor.thinkmerit.in',
        type: 'jitsi-single',
        pass: null,
}

const Assement = {
    state: () => ({ 
        qi: 0, // question index
        qpi: 0, // question part index
        qsi: 0, // question section index
        activeQuestion: null, // active question data
        questions: [],
        stagedQuestions: {},
        testConfig,
        pauseProctor: false,
        proctor, 
        jitsiLoaded: false,
        inTest:false,
        timeLeft: '00:00:00',
        timeLeftPart: '00:00:00',
        submitDialog: false,
        submitOverview: {
          skipped: 0,
          marked: 0
        },
        mQDialog: false,
        showChatPanel: false,
        showSectionInstruction: true
    }),
    mutations: { 
        set_in_test(state, val) {
            if(val) state.drawer = false
            state.inTest = val
        },
        set_test_status(state, val) {
           state.testStatus = val || {}
        },
        set_test_config(state,v){
            state.testConfig = v
        },
        set_jitsi_loaded(state, bool){
            state.jitsiLoaded = bool
        },
        toggle_proctor(state, bool = false) {
            state.pauseProctor = bool
        },
        set_proctor_option(state, {type, pass, options, domain}){
            state.proctor.domain = domain
            state.proctor.options = {...state.proctor.options, ...options}
            state.proctor.type = type 
            state.proctor.pass = pass 
        },
        set_questions(state, questions = []){
            state.questions = questions
        },
        update_question(state, {index, question}){
            try {
              state.questions[index] = {...state.questions[index], ...question }
            } catch (error) {
              console.log(error.message)
            }
        },
        stage_question(state, index){
          state.stagedQuestions[index] = true
        },
        clear_stage_questions(state){
          state.stagedQuestions = { [state.qi]: true }
        },
        set_qi(state, index){
          state.qi = index
        },
        set_qpi(state, index){
          state.qpi = index
        },
        set_qsi(state, index){
          state.qsi = index
        },
        set_active_question(state, question){
          state.activeQuestion = question
        },
        set_time_left(state, tleft){
          state.timeLeft = tleft
        },
        set_time_left_part(state, tleft){
          state.timeLeftPart = tleft
        },
        set_section_instruction(state, bool){
          state.showSectionInstruction = bool
        },
        show_chat_panel(state, bool){
          state.showChatPanel = bool
        },
        set_submit_dialog(state, bool){
          state.submitDialog = bool
        },
        set_submit_overview(state, data){
          state.submitOverview = data
        },
        set_mQDialog(state, bool){
          state.mQDialog = bool
        }
    },
    actions: { 
      selectQuestion({commit, state}, index){
        commit('set_qi', index)
        commit('set_active_question', state.questions[index])
      },
        initJitsiProctor({commit, state}, callbacks){
            this.dispatch('hangupVideoProctor')
            commit('set_jitsi_loaded', false)
            let options = state.proctor.options
            try {
              if(isFunction(callbacks.preJoin)) callbacks.preJoin()
            } catch (error) {
              console.log('Pre-Join Execution Error')
              console.log(error.message)
            }
      
            window.api = new JitsiMeetExternalAPI( state.proctor.domain+'?room='+state.proctor.options.roomName, options)
            window.api.on('passwordRequired', function ()
            {
              api.executeCommand('password', state.proctor.pass || 'The Password');
            })
      
            window.api.addEventListener('videoConferenceJoined', () => {
              commit('set_jitsi_loaded', true)
              if(isFunction(callbacks.onJoin)) callbacks.onJoin()
            })
      
            if(state.proctor.type==='jitsi-single') {
              window.api.addEventListener('participantLeft', () => {
                if(window.api.getNumberOfParticipants()>1) return
                setTimeout(() => {
                  console.log('Auto closed Video Proctor.')
                  this.dispatch('hangupVideoProctor')
                  if(isFunction(callbacks.onLeave)) callbacks.onLeave()
                }, 2000)
              })
            }
        },
        pingFirebase({state, rootState}, overwrite = {}) {
            try {
              rootState.global.firebaseApp.firestore().collection(TestLocation).doc('status').collection(state.testStatus.tid) 
                .doc(`${rootState.auth.user.id}`).set({ ...state.testStatus, ...overwrite }, { merge: true })
            } catch (error) {
                console.log(error.message)
            }
        },
        hangupVideoProctor({}) {
            try{
              if(window.api) {
                window.api.executeCommand('hangup');
                window.api.dispose()
              }
            } catch(e) {
              console.log(e)
            }
        },
        setJitsiOptions({commit}, {user, mockID, roomNo, roomPrefix, roomPass, type, domain}){
            if(roomNo=='undefined') roomNo = 'common'
            let options = {
              configOverwrite: {
                startWithAudioMuted: type==='jitsi-multi',
                resolution: type==='jitsi-multi'?180:720,
                constraints: {
                  video: {
                    height: {
                      ideal: 180,
                      max: 360,
                      min: 180
                    }
                  }
                },
              },
              roomName: (roomPrefix|| '') + (type==='jitsi-multi'?mockID+'-'+roomNo:user.id),//user.id+'-'+mockID,//process.env.VUE_APP_PROCTOR_TYPE==='multi'?$route.query.id:user.id,
              userInfo: {
                email: user.email,
                displayName: user.name
              }
            }
            commit('set_proctor_option',{type, pass: roomPass, options, domain})
        },
        nextQuestion({commit, state}){
          if(state.qi<state.questions.length-1) commit('set_qi', state.qi+1)
          else commit('open_snackbar',{text: `Reached last question of the test.`, color: 'blue'})
        },
        prevQuestion({commit, state}){
          if(state.qi>0) commit('set_qi', state.qi-1)
        },
        increaseTimeSpent({commit, state}){
          let temp = {...state.questions[state.qi]}
          temp.ts = temp.ts+1 
          commit('stage_question', state.qi)
          commit('update_question', {index: state.qi, question: temp})
        },
        updateQuestion({commit, state}, {question}){
          commit('update_question', {index: state.qi, question})
        },
        setAnswer({commit, state}, {index, answer}){
          var question = { ...state.questions[index] }
          if(Array.isArray(answer)) {
            question.ua = answer
            if(answer.length) question.ats = new Date()
            else question.ats = null
          } else {
            if(answer==='' || answer === null) {
              question.ua = null
              if((question.iu &&question.iu.length) || (question.vu && question.vu.length)) question.ats = new Date()
              else question.ats = null
            }
            else {
              question.ua  = answer
              question.ats = new Date()
            }
          }
          commit('stage_question', index)
          commit('update_question', {index, question})
        },
        stageQuestion({commit, state}, index){
          commit('stage_question', index)
        },
        toggleChatPanel({commit, state}){
          commit('show_chat_panel', !state.showChatPanel)
        },
        toggleSubmitDialog({commit, state}){
          let overview = submitCount(state.questions)
          commit('set_submit_overview', overview)
          commit('set_submit_dialog', !state.submitDialog)
        },
        togglemQDialog({commit, state}, bool=null){
          if(bool===null) commit('set_mQDialog', !state.mQDialog)
          else commit('set_mQDialog', bool)
        }
    },
    getters: { 
        testConfig : state => state.testConfig,
        inTest: state => state.inTest,
        pauseProctor: state => state.pauseProctor,
        questions: state => state.questions,
        // question: state => state.questions[state.qi],
        activeQuestion: state => state.questions[state.qi],
        stagedQuestions: state => state.stagedQuestions,
        jitsiLoaded: state => state.jitsiLoaded,
        proctorType: state =>  state.proctor.type,
        qi: state => state.qi,
        qpi: state => state.qpi,
        qsi: state => state.qsi,
        mQDialog: state => state.mQDialog,
        timeLeft: state => state.timeLeft,
        timeLeftPart: state => state.timeLeftPart,
        submitDialog: state => state.submitDialog,
        submitOverview: state => state.submitOverview,
        showChatPanel: state => state.showChatPanel,
        showSectionInstruction: state => state.showSectionInstruction
    }
  }

export default Assement

const isFunction = value => value && (Object.prototype.toString.call(value) === "[object Function]" || "function" === typeof value || value instanceof Function);
