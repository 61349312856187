import {updateTimer} from './TestTimer'
export default (context, minutes) => {
    try {
        clearInterval(context.updateIntervalID)
        context.timeEnd += minutes*60*1000
        context.timeLeft += minutes*60

        context.updateIntervalID = null
        setTimeout(() => { updateTimer(context) },1000)
    } catch (error) {
        console.log(error)
    }
    context.$store.commit('open_snackbar', {text: `Test time extended by ${minutes} minutes.`, color: 'info'})
}