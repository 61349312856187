import firebase from "firebase/app";
const { instance } =  require('./_axios')

export default (context) => {
    if(!process.env.VUE_APP_NOTIFICATION) {
      console.log('App env not configured for notification')
      return
    }
    if(firebase.apps.length<1) return 
    const messaging = firebase.messaging();
    // console.log('Getting token')
    messaging.getToken({vapidKey: "BLRfBsEsSr39oBwVkdysuyUPAZJpSMCZbZN7l-7i3l0TerkZ_HadV_e0ALa4oI09m0UBIXp4yZhuNPbj7Oo-c1k"})
    .then((currentToken) => {
        if (currentToken) {
          sendTokenToServer(currentToken);
          // updateUIForPushEnabled(currentToken);
          context.$store.commit('set_fcm_token', currentToken)
          // console.log('Has token ', currentToken)
        } else {
          // Show permission request.
          console.log('No registration token available. Request permission to generate one.');
          // Show permission UI.
          // updateUIForPushPermissionRequired();
          // setTokenSentToServer(false);
        }
      }).catch((err) => {
        context.$store.commit('open_snackbar', {text: 'Notification blocked by user!', color: 'red'})
        console.log('An error occurred while retrieving token. ', err);
        // showToken('Error retrieving registration token. ', err);
        // setTokenSentToServer(false);
    });
}

const sendTokenToServer = (token) => {
  instance.post('/fcm-token', {token})
  .then(resp => {
    // console.log('Token saved on server')
  })
  .catch(err => {
    console.log(err)
  })
}

const FCMReceiver = (context, callback = () => {}) => {
  if(!process.env.VUE_APP_NOTIFICATION) return
  const messaging = firebase.messaging();
  messaging.onMessage((payload) => {
    console.log('Message received - ', payload);
    const { title, body } = payload.notification
    context.notificationModalData.title = title
    context.notificationModalData.body = body
    context.notificationModal = true
    callback()
  });
}

export {FCMReceiver}