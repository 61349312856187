<template>
	<v-card class="mx-auto" >
            <v-card-title><h4>SWOT Analysis</h4></v-card-title>
            <v-divider></v-divider>
            <v-card-text >
              <div :class="{'sm-chart':$vuetify.breakpoint.smAndDown}" style="position: relative; width:800px; max-width:100%;height:350px;margin: 0 auto;">
                <canvas id="chart" ref="chart" ></canvas>
              </div>

              <div style="height: 100px"></div>

              <p>In the chart above, the 50% Attempt and 50% Accuracy lines divide the chart into 4 parts. If we take the intersection of the 50% lines as the centre then the chart is divided into 4 quadrants</p>
              <v-data-table
              :headers="SWOTTable.headers"
              :items="SWOTTable.data"
              class="elevation-1"
              :pagination="{rowsPerPage:-1}"
              :hide-actions="true" >
              <template  slot="items" slot-scope="props">
                <td class="text-xs-left">{{ props.item.swot }}</td>
                <td class="text-xs-left">{{ props.item.ATTEMPT }}</td>
                <td class="text-xs-left">{{ props.item.ACCURACY }}</td>
                <td class="text-xs-left">{{ props.item.QUADRANT }}</td>
                <td class="text-xs-left">{{ props.item.NQUADRANT }}</td>
              </template>
            </v-data-table>

            </v-card-text>
        </v-card>
</template>
<script>
import Chart from 'chart.js'
export default{
	data() {
		return {
      pointBackgroundColor: ['blue','rgba(250, 99, 132, 0.5)', 'rgba(54, 162, 235, 0.5)', 'rgba(255, 206, 86, 0.5)'],
			dataSWOT: [],
		      SWOTTable: {
		        headers: [
		          {
		            text: '',
		            value: 'swot',
		            sortable: false,
                width: '100px'
		          },
		          {
		            text: 'ATTEMPT',
		            value: 'ATTEMPT',
		            sortable: false,
                width: '80px'
		          },
		          {
		            text: 'ACCURACY',
		            value: 'ACCURACY',
		            sortable: false,
                width: '80px'
		          },
		          {
		            text: 'QUADRANT',
		            value: 'QUADRANT',
		            width: '150px',
		            sortable: false,
                width: '80px'
		          },
		          { 
		            text: '',
		            value: 'NQUADRANT',
		            sortable: false,
                width: '50px'
		          }
		        ],
		        data: [
		          {swot:'STRENGTH', ATTEMPT: '>50%', ACCURACY: '>50%', QUADRANT: 'Top-Right', NQUADRANT: 'I' },
		          {swot:'WEAKNESS', ATTEMPT: '<50%', ACCURACY: '<50%', QUADRANT: 'Bottom-Left' , NQUADRANT: 'II' },
		          {swot:'OPPORTUNITY', ATTEMPT: '<50%', ACCURACY: '>50%', QUADRANT: 'Top-Left', NQUADRANT: 'III' },
		          {swot:'THREAT', ATTEMPT: '>50%', ACCURACY: '<50%', QUADRANT: 'Bottom-right', NQUADRANT: 'IV' },
		        ]
		      }
		}
	},
	props: ['result', 'partnames'],
	mounted() {
		this.callDrawSWOTCharts()
	},
	methods: {
    callDrawSWOTCharts(){
      this.$nextTick(()=> {
        
      let temp = this.result.partResults
                     .map((pR, pI) =>{ 
                        return {
                          label: this.partnames[pI] || 'Part'+(pI+1),
                          borderWidth: 0,
                          pointBackgroundColor: [this.pointBackgroundColor[pI+1]],
                          backgroundColor: [this.pointBackgroundColor[pI+1]],
                          data: [
                          {
                            x: (pR.attempted*100/pR.total).toFixed(2),
                            y: pR.accuracy,
                          }]
                      }})

      if(temp.length<2) temp = []

      let swotDATA =  {
          labels: [''],
          datasets: [
            {
              label: 'Overall',
              data: [{
                x: (this.result.attempted*100/this.result.total).toFixed(2),
                y: this.result.accuracy
              }],
              pointBackgroundColor: this.pointBackgroundColor,
              pointBorderColor: this.pointBackgroundColor,
              backgroundColor: 'blue',
              borderWidth: 0
            }
          ].concat(temp)
        }

        const dataSWOT = {
          label: 'SWOT Analysis',
          data: swotDATA     
        }

        const optionSWOT =  {
          scales: {
              yAxes: [{
                  ticks: {
                    suggestedMin: 0,
                    suggestedMax: 100,
                    stepSize: 50
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Accuracy(%)'
                  }
              }],
              xAxes: [{
                  ticks: {
                    suggestedMin: 0,
                    suggestedMax: 100,
                    stepSize: 50
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Attempt(%)'
                  }
              }]
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                var label = data.labels[tooltipItem.index];
                return label + ': (' + tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ')';
              }
            }
          },
          legend: { onClick: (e) => e.stopPropagation() }
        }
        this.drawCharts(this.$refs.chart, dataSWOT, 'scatter', optionSWOT)
      })
    },
    drawCharts(elem,data,type, options){
      var myChart = new Chart(elem, {
          type: type,
          data: data.data,
          options: options
      });
    },
	}
}
</script>