<template>
 <v-layout class="mt-2">
    <v-flex xs12 >
      <v-card>
        <v-card-title v-if="localAudios.length">Uploaded Audios</v-card-title>
        <v-container grid-list-sm fluid>
          <v-layout row wrap>
            <v-flex
              v-for="(image, n) in localAudios"
              :key="n"
              xs12
              d-flex>
              <v-card flat tile class="d-flex">
                <ul>
                  <li><audio :src="`${testConfig.CDN}test-files/${image}`" controls></audio> <v-btn flat outline fab color="red" @click="deleteIndex = n; confirmDelete = true" style="vertical-align: bottom;" v-if="!hideActions"><v-icon>delete</v-icon></v-btn></li>
                </ul>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>

      <v-dialog
        v-model="confirmDelete"
        max-width="290"
        persistent
        >
      <v-card>
        <v-card-title class="headline red lighten-3 dark">Warning !</v-card-title>
        <v-card-text class="text-xs-center">
          Are you sure?
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary darken-1"
            flat="flat"
            @click="confirmDelete = false"
            :disabled="deleting"
          >
            No
          </v-btn>
            <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            flat="flat"
            @click="deleteAudio()"
            :loading="deleting"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-flex>
  </v-layout>
</template>
<script>
import {instance} from './../_axios.js'
import {mapGetters} from 'vuex'
export default {
  data(){
    return {
      deleting: false,
      confirmDelete: false,
      deleteIndex: -1,
      localAudios: []
    }
  },
  mounted(){
    this.localAudios = this.audios
  },
  methods: {
    deleteAudio(){
      this.deleting = true
      var file_name = this.audios[this.deleteIndex]
      instance.post('/deleteFile',{test_id:  this.$route.params.id, qn: this.$route.query.qn || 1, file_name})
      .then((resp) => {
        this.confirmDelete = false
        this.localAudios.splice(this.deleteIndex,1)
        this.$emit('answerDeleted', {data: resp.data.newData, type: 'vu'})
      })
      .catch(error => {
        this.$emit('open_snackbar', {text: 'Failed to delete. Please try again.', color: 'red'})
      })
      .then(() => {
        this.deleting = false
      })
    }
  },
  props: {
    audios: {
      type: Array,
      default: () => []
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    ...mapGetters(['user', 'testConfig'])
  }
}
</script>