<template>
<div class="fs">
    <div class="text-xs-center pb-0"><h3 class="headline mb-1" v-html="'Time left: '+ timeLeft"></h3></div>
    <v-progress-linear :indeterminate="true" v-if="uploading"></v-progress-linear>
    <image-media v-if="mediaType==2" @close="$emit('close')" @uploadAnswer="uploadAnswer" :uploading="uploading"></image-media>
    <audio-media v-if="mediaType==1" @close="$emit('close')" @uploadAnswer="uploadAnswer" :uploading="uploading"></audio-media>
    <input style="width:0; height:0; visibility: hidden" type="file" accept="image/*" capture="environment" @change="mobileCamCaptured" ref="mobileCapture" @click="internalCameraClicked()"/>
</div>
</template>

<script>
import {mapActions,mapGetters} from 'vuex'
import Compressor from 'compressorjs'
import {instance as axios} from './../../_axios'

import ErrorMedia from './QuestionMediaCapture/Error.vue'
import ImageMedia from './QuestionMediaCapture/Image.vue'
import AudioMedia from './QuestionMediaCapture/Audio.vue'

export default {
    data(){
        return {
            uploading: false,
            base64: null,
            localStream: null,
            resume: Boolean(this.$route.query.resume),
            testId: this.$route.params.id,
            isDesktop: window.innerWidth>500
        }
    },
    props: {
        mediaType: {
            type: Number,
            defualt: 1
        },
        stream: {
            type: MediaStream,
            default: () => null
        }
    },
    mounted(){
        if(this.mediaType==2 && !this.isDesktop){
            this.$refs.mobileCapture.click()
            return false
        }
    },
    beforeDestroy(){
        this.$store.commit('toggle_proctor', false)
    },
    methods: {
        ...mapActions(['initJitsiProctor']),
        newFormData(content_type = 'image/png'){
            let fd = new FormData();
            fd.append('test_id', String(this.testId));
            fd.append('content_type', content_type)
            if(this.testConfig.BUCKET) fd.append('bucket', this.testConfig.BUCKET)
            return fd
        },
        uploadAnswer({blob, type, ext}){
            this.uploading = true
            this.uploadFunction(blob, type, ext)
        },
        createHeaders(content_type){
            return {
                        headers: {
                            'Content-Type': content_type,
                            'x-amz-acl': 'public-read'
                        }
                    }
        },
        uploadFunction(blob, type = 'image', ext = 'png'){
          let contentType = `${type}/${ext}`
          var fd = this.newFormData(contentType)
          return  axios.post(`/presignURL/${this.testId}/${this.activeQuestion.qn}/${ext}`, fd)
                .then(resp => {
                    fd.append('fileName', resp.data.fileName)
                    let options = this.createHeaders(contentType)
                    return axios.put(resp.data.signedURL, blob, options)
                })
                .then(resp => {
                    return axios.post(`/savePresignedURL/${this.testId}/${this.activeQuestion.qn}/${ext}`, fd)
                })
                .then(resp => {
                    this.$emit('answerUploaded', {'data': resp.data.newData, type: this.getResourceProp(type) })
                    return { success: true, newData: resp.data.newData }
                })
                .catch(error => {
                    this.$store.commit('open_snackbar', {text: `${type} upload failed. Please try again.`, color: 'red'})
                    return { success: false, error }
                })
                .finally(() => {
                    this.uploading = false
                })
        },
        getResourceProp(type){
            if(type=='image') return 'iu'
            if(type=='audio') return 'vu'
            throw Error('Unknown resource!')
        },
        mobileCamCaptured(event){
            console.log(event)
            let files = event.target.files
            if(files.length<1) return 
            this.uploading = true
           
           this.imageCaptured = true
            let fileReader = new FileReader()
            fileReader.onload = (prog) => {
                var image = new Image()
                image.onload = () => {
                    var canvas = document.getElementById('canvas')
                    var context = canvas.getContext('2d')
                    context.drawImage(image, 0, 0, this.width, this.height);
                }
                image.src = prog.target.result
            }
            fileReader.readAsDataURL(files[0])
            
            let that = this
            new Compressor(files[0], {
                quality: 0.8,
                maxWidth: 1080,
                maxHeight: 720,
                success(blob) {
                    that.uploadFunction(blob, 'image', 'png')
                },
                error(err) {
                    console.log(err.message);
                },
            });
        },
        internalCameraClicked(){
            if(window.api) {
                try{
                    window.api.executeCommand('hangup');
                    window.api = null
                } catch(e){
                    console.log(e)
                }
            }
            console.log('Proctor/Timer removed')
            this.$store.commit('toggle_proctor', true)
        },
        hideIfNoFileCaptured(){
            if(this.$refs.mobileCapture && (!this.$refs.mobileCapture.files || this.$refs.mobileCapture.files.length<1)) this.$emit('close')
        }
    },
    computed: {
        ...mapGetters([ 'pauseProctor', 'testConfig', 'timeLeft', 'qi', 'activeQuestion' ]),
    },
    components: {
        'error-media': ErrorMedia,
        'image-media': ImageMedia,
        'audio-media': AudioMedia
    } 
}

</script>
<style scoped>
.fs{
    height: 100%;
    background: white;
}
</style>

