<template>
<v-card >
    <v-toolbar dark color="primary">
        <v-toolbar-title>My Answer Sheet: {{testData?testData.name:''}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items></v-toolbar-items>
    </v-toolbar>
    <v-card-text @scroll="scrolling()" ref="content">
        <template  v-for="(question,qi) in questions">
            <div :key="qi" >
                <h3 class="subheading mt-4 mb-2">&nbsp;Question {{question.qn}}</h3>
                <p>
                    <i>Question Type</i>: <u>{{questionType[question.qt]}}</u>,
                    <i>Max Marks</i>: <u>{{question.pm}}</u>
                </p>
                <question-item 
                    :key="question.id" 
                    :_question="question" 
                    :readonly="true"
                    :hidebuttons="true"> 
                </question-item>
            </div>
        </template>
        <v-btn v-show="this.scrolled>300" @click="scrollPreviewToTop()" fab dark color="primary" style="position: fixed; bottom: 15px; right: 15px">
            <v-icon dark>keyboard_arrow_up</v-icon>
        </v-btn>
        
    <!-- LOADING PROGRESS -->
  <v-dialog v-model="overlayLoading" persistent width="300">
    <v-card color="info" dark >
      <v-card-text>
        {{overlayMessage}}
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>

    </v-card-text>
</v-card>
</template>
<script>
const {instance} = require('./../../_axios.js');
import TestQuestionItem from './../../components/TestQuestionItem.vue'
import {extractQuestion, appendUserData} from './../../utils/questionExtractor'
export default {
    data(){
        return {
            questionType: {
                1:'Subjective',
                3:'MCQ Single Correct',
                4:'MCQ Multi Correct',
                5:'Paragraph',
                6:'Numerical Type',
                8:'Subjective',
            },
            scrolled: 0,
            overlayLoading: false,
            overlayMessage: '',
            questions: [],
            testData: null,
            userData: null
        }
    },
    props: {
        // questions: {
        //     type: Array,
        //     default: () => []
        // },
    },
    mounted(){
        this.fetchTest()
    },
    methods: {
        scrolling(){
           this.scrolled = this.$refs.content.scrollTop
        },
        scrollPreviewToTop(){
           this.$refs.content.scrollTop = 0
        },
        fetchTest(){
            this.messageLoader(true, 'Fetching Test Data. Please wait ... ')

            instance.get(this.testURL)
            .then((resp) => {
                this.extractData(resp.data)
            })
            .catch((err) => { 
                console.log(err)
                try{
                    this.error = err.message || err.response?err.response.data.message:'';
                }catch(e){
                    console.log(e)
                    this.error = 'Something went wrong!'
                }
                this.$store.commit('open_snackbar', { text: this.error, color: 'red' })
                this.$router.go(-1) 
            })
            .then(() => {
                this.overlayLoading = false
            })
        },
        messageLoader(bool, text=''){
            this.overlayLoading = bool
            this.overlayMessage = text
        },
        extractData(data){
            this.testData = data.testData
            this.userData = data.userData

            this.instructions = data.instructions
            this.questions = appendUserData( extractQuestion(this.testData), this.userData.test_data)
            // this.$store.commit('set_questions', appendUserData( extractQuestion(this.testData), this.userData.test_data))

            this.totalQuestions = this.questions.length //parseInt(data.userData.test_data[data.userData.test_data.length-1].qn)
            if(this.userData.last_qn) this.qn = parseInt(this.userData.last_qn)
            // delete data
        },
    },
    computed: {
        testURL() {
            return '/test/answer/'+this.$route.params.id
        },
    },
    components:{
        'question-item': TestQuestionItem,
    }
}
</script>