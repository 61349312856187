<template>
<div>
  <page-title>{{$route.meta.title}}</page-title>
  <div>
    <v-dialog
    v-model="loading"
    persistent
    width="300">
    <v-card color="primary" dark>
      <v-card-text>
      Loading data please wait...
      <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
      ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  	<v-container
     v-if='!loading'
      
      grid-list-lg class="pa-0" >
      <v-layout row wrap justify-center>
          
        <v-flex xs12 v-if="!loading">
          <ul style="list-style:none">
              <li><v-alert class="text-xs-center" type="info" value="true">You can verify you submitted answer here.</v-alert></li>
            </ul>
        </v-flex>

          
          <v-flex xs12 md4 v-for="(test,ti) in tests" :key="ti">
            <v-card hover  
             class="white--text" 
             style="border-radius: 10px" >
                  <v-card-title :style="`background:${colors[ti<colors.length?ti:ti%colors.length]}`" style="padding: 10 0 10 0" >
                      <div class="headline white--text" >{{test.name}}</div>
                  </v-card-title>
                  
                  <v-card-text :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
                    <v-list style="padding: 0">

                      <v-list-tile style="height: 40px">
                        <v-list-tile-content>Started At</v-list-tile-content>
                        <v-list-tile-content class="align-end">{{test.test_created_at | activationTime}}</v-list-tile-content>
                      </v-list-tile>

                       <v-list-tile style="height: 40px">
                        <v-list-tile-content>Submitted At</v-list-tile-content>
                        <v-list-tile-content class="align-end">{{test.test_submitted_at | activationTime}}</v-list-tile-content>
                      </v-list-tile>

                      <!-- <v-list-tile style="height: 40px">
                        <v-list-tile-content>Max Marks</v-list-tile-content>
                        <v-list-tile-content class="align-end">{{test.format.max_marks}}</v-list-tile-content>
                      </v-list-tile>

                      <v-list-tile style="height: 40px">
                        <v-list-tile-content>Questions</v-list-tile-content>
                        <v-list-tile-content class="align-end">{{test.format.noq || 0}}</v-list-tile-content>
                      </v-list-tile>
                      
                      <v-list-tile style="height: 40px">
                        <v-list-tile-content>Duration</v-list-tile-content>
                        <v-list-tile-content class="align-end">{{test.format.duration}} min</v-list-tile-content>
                      </v-list-tile> -->
                      <!-- ?test.activation_date.split('T')[0].split('-').reverse().join('-'):'-' -->
                      </v-list>
                  </v-card-text>
                 <!--  <v-card-text :class="{'pb-2': $vuetify.breakpoint.smAndDown}" v-html="test.description" style="max-height: 100px; height: 100px;text-overflow: clip; overflow: hidden;" :style="$vuetify.breakpoint.smAndDown?'max-height:auto; height: auto':''">
                  </v-card-text>-->
              <v-divider light></v-divider> 
              <v-card-actions class="justify-center" >
                <!-- <v-btn  flat @click="selectedTest=test;infoDialog=true">Info </v-btn>
                <v-spacer></v-spacer> -->
                <v-btn 
                  outline 
                  round 
                  :disabled="!test.test_submitted_at" 
                  :to="`/answer-sheet/${test._id}`">
                    View My Answer Sheet
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
          <v-flex xs12 class="justify-center" v-if="!tests.length">
            <page-no-data icon="rule">No data found!</page-no-data>
          </v-flex>
          
        </v-layout>
      </v-container>
      <div>
        <br><br><br>
      </div>
  </div>


<v-dialog
  v-model="infoDialog"
  width="500"
  :fullscreen="$vuetify.breakpoint.smAndDown">
  <v-card v-if="selectedTest">
    <v-card-title class="headline grey lighten-2">
      <span class="headline">{{selectedTest.name}} </span>
      <v-spacer></v-spacer>
      <v-btn small fab icon @click="infoDialog=false"><v-icon>close</v-icon></v-btn>
    </v-card-title>

    <v-card-text>
      <h4>Description</h4>
      <p v-html="selectedTest.description"></p>
    </v-card-text>

  </v-card>
</v-dialog>
</div>
</template>
<script>

const {instance} = require('./../../_axios.js');
import { mapActions, mapGetters } from 'vuex'
import sortByKey from './../../utils/sortByKey'
import { DateTime,  Interval, Duration } from "luxon";

export default{
	data() {
	  return {
      tests: [],
      loading: false,
      infoDialog: false,
      selectedTest: null,
      selected: 0,
      mobNav: ['Select', 'Selected'],
      colors: ['#0091EA','#8bc34a','#ef5350','#ef6c00','#00bcd4','#455a64'],

      year: null,
      exam: null,
      testType: ''
    }
	},
	mounted(){
    this.fetchTests()
  },
	methods:{
    ...mapActions(['errorSnack']),
	  fetchTests(){
      this.loading = true
	  	instance.get(`/test/attempted-list`)
	  	.then((resp) => {
        this.tests = resp.data
	  	})
	  	.catch((e)=>{ this.errorSnack({e,b:'Error fetching tests!'}) })
      .then(() => { this.loading = false, 2000 }  )
	  }
   
	},
  computed:{ 
    ...mapGetters(['user'])
  },
  watch:{
    '$route.path'(){
      this.tests = []
      this.fetchTests()
    }
  },
  filters: {
    timeAgo(test){
      if(test.time_to_start<0) {
       let temp = Duration.fromMillis(-1*test.time_to_start).shiftTo( 'days', 'hours', 'minutes', 'seconds' ).toObject()
       let out = ''
       console.log(Object.keys(temp), Object.values(temp))
        Object.keys(temp).every((key) => {
          if(temp[key]>0) {
            out += temp[key]+' '+ key +' ago'
            return false
          }
          else return true
          console.log(temp[key])
        })
       return `Test ended ${out}`
      }
      // if(test.time_to_start>0) return `Test will start in ${Duration.fromMillis(test.time_to_start).shiftTo( 'days', 'hours', 'minutes', 'seconds' ).toObject()}`
      if(test.resumeable) return 'Resume'
      if(test.attempted) return 'Attempted'
      // if(value<0) return `Test ended ${DateTime.fromJSDate(new Date(value))}`
      return 'Ready Check'
    },
    activationTime(activation_time){
      if(!activation_time) return '-'
      return DateTime.fromISO(activation_time).toFormat('HH:mm a, dd LLL, yyyy') 
    }
  },
  components: {}
}
</script>
<style>

</style>