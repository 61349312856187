<template>
<div>
  <v-dialog v-model="mediaDialog" fullscreen style="background: white">
    <test-media 
      v-if="mediaDialog" 
      :stream="stream" 
      :media-type="mediaType" 
      @answerUploaded="answerUploaded" 
      @close="mediaDialog=false" 
      ref="mediaDialog">
    </test-media>
  </v-dialog>
  <v-card style="border-radius: 10px">
    <v-card-text class="OSX" >
      <div>
        <div v-if="localQuestion.extra"><v-btn @click="compDialog=true" color="info darken-1" outline flat round>{{extraName}}</v-btn></div>
        <div v-html="localQuestion.question" ></div>
          <attachments :attachments="localQuestion.attachments || []" :hide-empty-message="true"></attachments>
          <div v-if="localQuestion.type==8">
            <quill-editor
              ref="myQuillEditor"
              v-model="localQuestion.ua"
              autocomplete="off"
              :options="editorOption"
              :disabled="readonly" 
              :readonly="readonly" 
              data-gramm="false"
              data-gramm_editor="false"
              data-enable-grammarly="false"
              spellcheck="false"
             />
          </div>
          <div v-if="localQuestion.type==8 && !readonly" class="mb-1">
            <v-btn v-if="testConfig.AUDIO_RECORDING" color="primary" outline @click="mediaDialog=true;mediaType=1"> <v-icon dark >mic</v-icon> &nbsp;Record Audio</v-btn>
            <v-btn v-if="testConfig.IMAGE_CAPTURE" color="primary" outline @click="onCaptureImageClick()"> <v-icon dark >camera_alt</v-icon> &nbsp;Capture Image</v-btn>
            <!-- <v-btn color="primary" :disabled="!audioRecording" @click="stopRecordingAudio()" >Stop</v-btn> -->
          </div>
          <image-grid v-if="localQuestion.iu && localQuestion.iu.length" :images="localQuestion.iu" :hideActions="readonly" @answerDeleted="(data) => $emit('answerDeleted', data)"></image-grid>
          <audio-list v-if="localQuestion.vu && localQuestion.vu.length" :audios="localQuestion.vu" :hideActions="readonly" @answerDeleted="(data) => $emit('answerDeleted', data)"></audio-list>

        <div class="choices" v-if="choices.indexOf(localQuestion.type)>-1">
          
          <v-radio-group column v-if="localQuestion.type==3||localQuestion.type==7" v-model="localQuestion.ua">
            <v-radio v-for="(cl,ci) in choiceLabels.filter((v,i)=> i<localQuestion.noo).shuffle(testConfig.SHUFFLE_OPTIONS || localQuestion.so, localQuestion.qn+user.id) "  :key="cl" :value="choiceLabels.indexOf(cl)" :disabled="disabled" :readonly="readonly" color="primary">
              <div slot="label" v-html="choiceLabels[ci]+'. '+localQuestion[cl]" class="black--text"></div>
            </v-radio>
          </v-radio-group>

          <div v-if="localQuestion.type==4">
            <v-checkbox v-for="(cl,ci) in choiceLabels.filter((v,i)=> i<localQuestion.noo).shuffle(testConfig.SHUFFLE_OPTIONS || localQuestion.so, localQuestion.qn+user.id)" :key="cl" :value="choiceLabels.indexOf(cl)" :disabled="disabled" :readonly="readonly" color="primary" v-model="localQuestion.ua">
              <div slot="label" v-html="choiceLabels[ci]+'. '+localQuestion[cl]"></div>
            </v-checkbox>
          </div>
          <div v-if="(localQuestion.type==4 || localQuestion.type==3 || localQuestion.type==7 || localQuestion.type==8) && !readonly">
            <v-btn @click="clearAnswer(localQuestion)" outline :color="localQuestion.ats?'dark':'secondary'" round>Clear Answer</v-btn>
          </div>
          
          <div v-if="localQuestion.type==6" class="mb-2">
              <v-flex xs12 d-flex mt-6 style="max-width: 400px; flex-wrap: wrap;"> 
                <v-text-field  v-model="localQuestion.ua" readonly :disabled="disabled" ></v-text-field>
                <v-btn  round outline  @click="backspace(localQuestion)" color="info" block :readonly="readonly" :disabled="disabled||readonly">Backspace</v-btn>
                <v-btn  round outline  v-for="x in [7,8,9,4,5,6,1,2,3,0,'.','-']" :key="x" @click="answerInteger(localQuestion,isNaN(x)?x:x)" :color="'info'" :disabled="disabled||readonly">{{isNaN(x)?x:x}}</v-btn>
                <v-btn  round outline  @click="clearAll(localQuestion)" color="info" block :readonly="readonly" :disabled="disabled||readonly">Clear All</v-btn>
              </v-flex>
          </div>

        </div>
      </div>
    </v-card-text>
  </v-card>

  <v-dialog v-model="compDialog" fullscreen>
    <v-card>
      <v-card-title>
        <v-toolbar>
          <v-toolbar-title>{{extraName}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items >
          <v-btn flat icon @click="compDialog=null"><v-icon>close</v-icon></v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-card-text v-html="localQuestion.extra"></v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>
<script>
import {renderMathJax} from './../utils.js'
import {mapActions, mapGetters} from 'vuex'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
 
import TestMedia from './../views/Test/TestQuestionMedia'
import AudioList from './AudioList'
import ImageGrid from './ImageGrid'
import Attachments from './QuestionAttachments'

import { quillEditor } from 'vue-quill-editor'
var toolbarOptions = [
  ['bold', 'italic', 'underline',],        // toggled buttons
  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'direction': 'rtl' }],                         // text direction
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  ['clean']                                         // remove formatting button
];

export default{
	data(){
    return {
      extraName: process.env.VUE_APP_EXTRA_NAME || 'Comprehension',
      localQuestion: {
        ats: null,
        ua: null
      },
      choices: [3,4,6,7],
      choiceLabels: ['A','B','C','D','E','F','G','H','I','J'],
      menuProps: {},
      editorOption: {
        modules: {
          toolbar: toolbarOptions
        }
      },
      mediaDialog: false,
      mediaType: 1,
      compDialog: null
    }
  },
  mounted(){
    this.resetMenuProps()
    if(this._question) this.localQuestion = Object.assign({}, this._question)
    else this.localQuestion = Object.assign({}, this.questions[this.qi])
    if(this.localQuestion.type==4 && this.localQuestion.ua==null) this.$set(this.localQuestion,'ua',[])
    renderMathJax(this)
  },
	methods: {
    ...mapActions(['updateQuestion']),
    answerUploaded(data) {
      this.localQuestion[data.type] = data.data
      this.updateQuestion({ question: { [data.type]: data.data, ats: new Date() } })
      this.$emit('initStream')
      this.mediaDialog = false
    },
    //Integer pad
    backspace(question) {
      if(question.ua && question.ua.length>0) question.ua = question.ua.substring(0,question.ua.length-1)
    },
    clearAnswer(question) {
      question.ua = question.type==4?[]:null
      question.ats = null
    },
    answerInteger(question, val) {
      if(val === '-'){
        if(question.ua!=null && question.ua.indexOf('-')>-1) { 
          console.log(question.ua.split('-').join(''))
          return question.ua=question.ua.split('-').join(''); 
        }
        else {
          return question.ua = '-'+(question.ua || '');
        }
      }
      if(val==='.' && question.ua && question.ua.indexOf('.')>-1) return
      if(question.ua===null || question.ua===undefined) question.ua=''
      question.ua = question.ua.concat(val)
    },
    resetMenuProps(){
      this.menuProps = { 'z-index': 206 }
    },
    hideIfNoFileCaptured(){
      this.$refs.mediaDialog.hideIfNoFileCaptured()
    },
    onCaptureImageClick(){
      this.mediaDialog=true;
      this.mediaType=2
    },
    hasAnswer(answer){
      if(answer===null) return false
      if(Array.isArray(answer) && answer.length>1) return true
      if(answer>-1) return true
      return !!answer
    },
    shuffle(val) {
      return val.reverse()
    }
	},
	props:{
    '_question': {
      type: Object,
      default: null
    },
    'disabled': {
      type: Boolean,
      default: false
    },
    'readonly': {
      type: Boolean,
      default: false
    },
    'hidebuttons': {
      type: Boolean,
      default: false
    },
    'stream': {
      type: MediaStream,
      default: () => null
    },
    'toggleProctor': {
      type: Function,
      default: () => {}
    },
	},
  watch: {
    'this.submitDialog'(val){
      if(val) this.menuProps['z-index'] = 8
      else this.resetMenuProps()
    },
    'activeQuestion'(val){
      if(!this._question) this.localQuestion = {...val}
      renderMathJax(this)
    },
    'localQuestion.ua':{
      deep: true,
      handler(answer, oldanswer){
        if(this.hasAnswer(answer)) this.$set(this.localQuestion, 'ats', new Date())
        if(answer!==oldanswer){
          this.$store.dispatch('setAnswer', {index: this.qi, answer})
        }
      }
    }
  },
  components: {
    quillEditor,
    'test-media': TestMedia,
    'image-grid': ImageGrid,
    'audio-list': AudioList,
    'attachments': Attachments
  },
  computed: {
    ...mapGetters(['testConfig', 'activeQuestion', 'qi', 'user', 'questions', 'submitDialog'])
  },
  
}
</script>
<style>
.ql-editor{
  min-height: 100px;
}
.OSX img{
  max-width: 100%;
}
.OSX table {
  border-collapse: collapse;
}
.OSX table td{
  border: 1px solid rgb(75, 75, 75) !important;
}
</style>
<style scoped>
.v-progress-circular{
  margin-top: 4rem
}
.v-card__actions{
  justify-content: space-between;
}
.question-action-progress{
  margin-top: 0
}
label{
  color: black
}
img{
  max-width: 100%;
}

</style>
