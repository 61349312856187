<template>

<v-dialog v-model="showWarningDialog"  persistent max-width="500">
    <v-card>

        <v-card-title class="headline red lighten-3 dark">
          <v-icon>warning</v-icon> Warning !!
        </v-card-title>

        <v-card-text class="text-xs-center">
            <h3 class="red--text title" v-if="showWarningTimer">The test will auto submit, <br> if you go outside this exam or full screen mode is not activated in <strong>{{blurTime}} s</strong>.</h3>
            <h3 class="red--text title" v-else>Please go back to fullscreen mode to resume test otherwise the test will be terminated.</h3>
            <br/>
            <span>It will also auto submit, if such behavior is detected repeatedly. <br> Click <code>Resume</code> button to go back to fullscreen.</span>
        </v-card-text>

        <v-card-actions class="text-xs-center justify-center">
            <v-btn color="info"  @click="openFullscreen();$emit('hidewarning'); ">Resume</v-btn>
        </v-card-actions>

    </v-card>
</v-dialog>

</template>
<script>
import {openFullscreen, closeFullscreen} from './../../utils/fullscreen.js'
// import {AUTO_TERMINATE} from './../Test/readEnv'

export default {
    data() {
        return {
            // AUTO_TERMINATE
        }
    },
    props: {
        showWarningDialog: {
            type: Boolean,
            default: false
        },
        blurTime: {
            type: String,
            default: '100'
        },
        showWarningTimer: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isFullscreen: () => Boolean(document.fullscreenElement)
    },
    methods: {
        openFullscreen: openFullscreen
    }
}
</script>