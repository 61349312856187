<template>
  <div>
      <h3 class="title mb-4 op5">MARKSHEET</h3>
      <v-card color="primary" dark v-if="loading">
        <v-card-text>
        Loading data please wait...
        <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
      <v-data-table
        :headers="headers"
        :items="list"
        :pagination.sync="pagination"
        select-all
        item-key="name"
        class="elevation-1"
        :loading="loading">

          <template slot="no-data">
            <v-alert :value="true" color="warning" icon="warning" outline>
              No test result found!
            </v-alert>
          </template>

          <template slot="headers" slot-scope="props">
            <tr>
              <th
                v-for="header in props.headers"
                :key="header.text"
                :class="header.class" 
                >
                <v-icon small>arrow_upward</v-icon>
                {{ header.text }}
              </th>
            </tr>
          </template>
          <template slot="items" slot-scope="props">
            <tr class="pa-4" :style="{background:props.index%2!=0?'#eee':'#fff'}">
              <td class="nowrap" style="padding-top: 10px;padding-bottom: 10px" >
                <h4 class="subheading" >{{ props.index+1 }}</h4>
              </td>
              <td class="nowrap" style="padding-top: 10px;padding-bottom: 10px" >
                <h4 class="subheading" >{{ props.item.name }}</h4>
              </td>
               <td class="text-xs-center nowrap" style="padding-top: 10px;padding-bottom: 10px" >
                <h4 class="subheading" >{{ (new Date(props.item.updated_at)).toLocaleDateString() }}</h4>
              </td>
              <td class="text-xs-right">
                  <v-btn target="_blank" :href="`/marksheet/${props.item._id}`" title="View" round outline deep-purple >View</v-btn>
                  <v-btn target="_blank" :href="`/marksheet/${props.item._id}?print=true`" title="Print" round outline deep-purple >Print</v-btn>
              </td>
            </tr>
          </template>
	    </v-data-table>
  </div>
</template>

<script>
const {instance} = require('./../../_axios.js');
export default {
  data(){
    return {
      loading: true,
      list: [],
      headers: [
        {
	        text: 'SN',
	        align: 'left',
	        class: 'text-xs-left',
	        value: 'name',
	      },
	      {
	        text: 'Name',
	        align: 'left',
	        class: 'text-xs-left',
	        value: 'name',
	      },
	      { text: 'Publish Date', value: 'updated_at', sortable: false },
	      { text: 'Action',class: 'text-xs-right', sortable: false }
	    ],
      pagination: {
	      // sortBy: 'name',
	      rowsPerPage: 10
	    },
    }
  },
  mounted(){
    this.fetchData()
  },
  methods: {
    async fetchData(){
      this.loading = true
      try {
        let temp = (await instance.get('marksheets'))
        this.list = temp.data.marksheets
      } catch (error) {
        console.log(error)
      } finally{
        this.loading = false
      }
    }
  }
}
</script>

<style>

</style>