<template>
<v-container fluid grid-list-lg class="pa-0 mt-4">
    <v-layout row wrap >
      	<v-flex xs12 md6 >
			<v-card class="mx-auto" color="#26c6da" dark height="405px">
				<v-card-title>
					<span class="title font-weight-light">{{title?title:'Marks Scored'}}</span>
					<v-spacer></v-spacer>
					<i class="title font-weight-light">{{testdate.split('T')[0].split('-').reverse().join('-')}}</i>
				</v-card-title>
				<v-card-text class="text-xs-center mt-4">
					<h1 class="display-3 font-weight-bold">{{result.scored | fixed}} <small><small>/ {{result.max_marks | fixed}}</small> </small> </h1>
					<v-layout align-center justify-end class="title mt-3">{{result.percentage.toFixed(2)}}% </v-layout>
					<v-progress-linear color="white" height="6" :value="result.percentage" ></v-progress-linear>
					<div style="height:28px;"></div>
				</v-card-text>
				<v-card-actions class="mt-5 justify-center">
					<v-btn flat outline round to="review" v-if="!user.config.HIDE_TEST_REVIEW">Review</v-btn>
				</v-card-actions>
			</v-card>
		</v-flex>

		<v-flex xs12 md6> <result-insight :result="result"></result-insight> </v-flex>
		<v-flex xs12> <swot-analysis :result="result" :partnames="partnames"></swot-analysis> </v-flex>

    </v-layout>
  </v-container>	
</template>
<script type="text/javascript">
import ResultInsight from './../components/ResultInsight'
import SWOT from './../components/SWOT'
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			// hideReview: Boolean(process.env.VUE_APP_HIDE_TEST_REVIEW==='true')
		}
	},
	methods:{
		retestLink(){ return '/test/'+this.testData.mock_id+'/instruction' }
	},
	props: ['result','title','reattempt','partnames','testdate'],
	mounted(){},
	watch:{},
	components: {
		'result-insight':ResultInsight,
		'swot-analysis':SWOT
	},
	computed: {
		...mapGetters(['user'])
	},
	filters: {
		fixed(value){
			if(parseInt(value)!=value) return (Math.round(value*100)/100).toFixed(2)
			return value
		}
	}
}
</script>