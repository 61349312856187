let pingTime = 4;
import submitCount from './../../../utils/TestQuestionAttemptCounts'
import { DateTime } from "luxon";
import firebase from "firebase/app";

function PingFirebase(context, terminateReason, ai_count_increase) {
    if(context.testData)
        {
            let counts = submitCount(context.questions)
            let currentStatus = {
                qn: context.$store.state.assessment.qi+1, // currently at
                p:  context.$route.path,
                tid: context.testData._id,
                ts: DateTime.local().toMillis(),
                b:  !document.hasFocus(),
                f:  context.pauseProctor?true:!!document.fullscreen,
                fc: context.faceCount,
                s:  context.submitted,
                ac: counts.attempted, // attempted count
                sc: counts.skipped, // skipped count
                v:  String(navigator.vendor),
                ua: String(navigator.userAgent),
                pm: String(navigator.platform),
                t:  terminateReason?terminateReason:null,
                cp: context.pauseProctor,
                jl: context.jitsiLoaded,
                lip: context.lastImageProctor,
                bv: window.buildTime,
            }
            if(ai_count_increase) {
                currentStatus.aic = firebase.firestore.FieldValue.increment(1)
            }  
        context.$store.commit('set_test_status', currentStatus)
        context.$store.dispatch('pingFirebase')
    }
}

function FirePingTimer( context ){
    pingTime -= 1
    if(pingTime <= 0) {
        pingTime = context.testConfig.PING_INTERVAL
        PingFirebase(context)
    }
}

export default PingFirebase

export {FirePingTimer}