<template>
<v-container grid-list-lg class="pa-0" >
    <v-layout row wrap justify-center >
        <v-flex  xs12>
            <page-title>My Attendance</page-title>

            <v-layout row wrap>
                <v-flex xs12 class="animate__animated animate__slideInUp">
                    <attendance-stat></attendance-stat>
                </v-flex>
                    <v-flex xs12>
                        <i class="caption op5">Legends - </i>
                    </v-flex>
                    <v-flex md4 xs6 v-for="legend in ['present', 'absent', 'late', 'half_day', 'leave']" :key="legend" class="animate__animated animate__zoomIn">
                        <v-badge :color="legend | colorFilter">
                            <template slot="badge">
                                <span>{{legend | attFilter}}</span>
                            </template>
                        </v-badge> 
                        <span class="text-capitalize d-inline-block" style="text-indent: 28px;"> {{ legend.replace('_', ' ') }}</span>
                    </v-flex>
                </v-layout>

            <v-card class="mt-4">
                <v-card-title>
                    <h4 class="title">{{ startDate | headerCalendar }}</h4>
                </v-card-title>
                <v-card-text>
                    <v-calendar 
                        ref="calendar"
                        v-model="startDate"
                        :start="startDate"
                        color="primary"
                        >
                        <template v-slot:day="{date}">
                            <v-sheet v-if="attendances[date]" class="text-xs-center white--text pt-2 pb-2" color="transparent">
                                <v-badge :color="attendances[date] | colorFilter">
                                    <template slot="badge">
                                        <span>{{attendances[date] | attFilter}}</span>
                                    </template>
                                </v-badge>
                            </v-sheet>
                            <template v-else>
                                <v-sheet v-if="isSunday(date)" class="text-xs-center white--text pt-2 pb-2"></v-sheet>
                                <template v-else>
                                    <v-sheet v-if="hasPassed(date)" class="text-xs-center red--text pt-2 pb-2">
                                        <v-badge color="red">
                                            <template slot="badge">
                                            <span>A</span>
                                        </template></v-badge>
                                    </v-sheet>
                                    <v-sheet v-else class="text-xs-center white--text pt-2 pb-2">N</v-sheet>
                                </template>
                            </template>
                        </template>
                    </v-calendar>
                </v-card-text>
            </v-card>
        </v-flex>

        <v-flex sm4 xs4 class="text-sm-left text-xs-center">
            <v-btn @click="previous()" :loading="loading" elevation="1" round>
                <v-icon dark left >
                keyboard_arrow_left
                </v-icon>
                Prev
            </v-btn>
        </v-flex>
        <v-flex  sm4  xs4 class="text-xs-center"  > </v-flex>
        <v-flex  sm4  xs4  class="text-sm-right text-xs-center" >
            <v-btn @click="next()" :loading="loading" elevation="1" round>
                Next
                <v-icon right dark>
                keyboard_arrow_right
                </v-icon>
            </v-btn>
        </v-flex>
    </v-layout>
</v-container>
</template>
<script>
const {instance} = require('./../../_axios.js');
import moment from 'moment'
import AttendanceCounterVue from '../../components/desk/AttendanceCounter.vue';

function toString(date){
    return moment(date).format('Y-MM-DD')
    // return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()
}

function getFirstDate(date){
    return new Date(date.getFullYear(), date.getMonth(), 1)
}

function getLastDate(date){
    return moment(new Date(date.getFullYear(), date.getMonth()+1, 1)).format('Y-MM-DD')
}


export default {
    data: () => ({
      today: toString(new Date()),
      startDate: toString(getFirstDate(new Date())),
      endDate: toString(getLastDate(new Date())),
      attendances: {},
      colors: ['#1867c0', '#fb8c00', '#000000'],
      category: ['Development', 'Meetings', 'Slacking'],
      loading: true,
    }),
    mounted(){
        this.fetchData()
        // console.log(this.$refs.cal)
    },
    methods: {
        fetchData(){
            this.loading = true
            instance.get(`/attendances?startDate=${this.startDate}&endDate=${this.endDate}`)
            .then(resp => {
                // console.log(resp.data.data)
                try {
                    this.attendances = {};
                    (resp.data.data || []).forEach(element => {
                        // let d = new Date(element.attendance_date)
                        // let dString = d.getFullYear()+"-"+(d.getMonth()<10?`0${d.getMonth()+1}`:(d.getMonth()+1))+"-"+(d.getDate()<10?`0${d.getDate()}`:d.getDate())
                        // this.attendances[dString] = 'present'

                        let d = moment(element.attendance_date)
                        let dString = d.format('Y-MM-DD')
                        // if(this.attendances[dString]) 
                        this.attendances[dString] = element.attendance
                    });
                    console.log(this.attendances, resp.data.data)
                    // console.log(this.attendances)
                } catch (error) {
                    console.log(error)
                }
            })
            .catch(error => {
                console.log(error.message)
            })
            .finally(() => {
                this.loading = false
            })
        },
        hasPassed(date){
            // console.log(date, (new Date(date)).getTime(), Date.now(), (new Date(date)).getTime() < Date.now())
            return (new Date(date)).getTime() < Date.now()
        },
        isSunday(date){
            // console.log(date, (new Date(date)).getDay(), (new Date(date)).getDay()==0)
            return (new Date(date)).getDay()==0
        },
        isSaturday(date){
            // console.log(date, (new Date(date)).getDay(), (new Date(date)).getDay()==0)
            return (new Date(date)).getDay()==6
        },
        next(){
            // $refs.calendar.next()
            this.today = this.endDate+""
            this.$nextTick(() => {
                this.endDate = toString(getLastDate(new Date(this.today)))
                this.startDate = toString(getFirstDate(new Date(this.today)))
                this.fetchData()
            })
        },
        previous(){
            // $refs.calendar.next()
            
          let d = new Date(this.startDate)
                d.setMonth(d.getMonth()-1)
                d.setDate(1)

                this.startDate = toString(getFirstDate(d))
                this.endDate = toString(getLastDate(d))
                this.today = this.startDate+''
                this.fetchData()
          
        },
        dateChanged(value) {
            console.log(value)
        }
    },
    filters: {
        attFilter (newval) {
            if(newval==='present') return 'P'
            if(newval==='absent') return 'A'
            if(newval==='late') return 'LA'
            if(newval==='half_day') return 'HD'
            if(newval==='leave') return 'LE'
            return '-'
        },
        colorFilter (newval) {
            if(newval==='present') return 'green'
            if(newval==='absent') return 'red'
            if(newval==='late') return 'orange'
            if(newval==='half_day') return 'blue'
            if(newval==='leave') return 'red'
            return '-'
        },
        headerCalendar(dateString){
            let start = moment(dateString, "Y-MM-DD")
            let end = start.clone().add(34, 'days')
            return start.format('MMMM Y')//+" -" +end.format('D-MMM Y')
        }
    },
    watch: {
        startDate (newval){
            console.log(newval)
        }
    },
    components:{
        'attendance-stat': AttendanceCounterVue
    }
}
</script>