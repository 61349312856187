<template>
<v-layout row>
    <v-flex md6 sm12>
        <h3 class="subheading mb-2">
            &nbsp;Question 
            <b>{{qi+1}}</b> of 
            <b>{{questions.length}}</b>   
        </h3>
        <p>
            <i v-if="!hideSectionName && !partShuffle"><b>{{sectionName}} {{sectionRange}}</b>,</i>
            <i>Type</i>: <b>{{questionType[activeQuestion.qt]}}</b>,
            <br>
            <i>Max Marks</i>: <b>{{activeQuestion.pm}}</b>
        </p>
    </v-flex>
    <v-flex md6 sm12 class="text-xs-left text-md-right">
        <v-btn flat @click="toggleInstruction()" outline round small>{{showSectionInstruction?'Hide':'Show'}} Instruction</v-btn>
    </v-flex>
</v-layout>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            questionType: {
                1:'Subjective',
                3:'MCQ Single Correct',
                4:'MCQ Multi Correct',
                5:'Paragraph',
                6:'Numerical Type',
                8:'Subjective',
            },
            sectionName: '',
            sectionRange: '',
            partShuffle: false
        }
    },
    props: {
        parts: {
            type: Array,
            default: () => []
        },
        // hideSectionName: {
        //     type: Boolean,
        //     default: false
        // }
    },
    mounted(){
        if(this.activeQuestion.si>=0){
            this.updateMeta(this.activeQuestion.si)
        }
    },
    methods: {
        updateMeta(si){
            var part = this.parts[this.activeQuestion.pi]
            var section = part.sections[si]
            this.sectionName = section.name || `Section ${si+1}`
            this.sectionRange = `(${section.questions[0].qn} to ${section.questions[section.noq-1].qn})`
            this.partShuffle = part.sq
        },
        toggleInstruction(){
            this.$store.commit('set_section_instruction', !this.showSectionInstruction)
        }
    },
    computed: {
        ...mapGetters(['qi', 'qsi', 'questions', 'activeQuestion', 'showSectionInstruction', 'testConfig']),
        hideSectionName(){
            return this.testConfig.HIDE_SECTION_LABEL || this.testConfig.SHUFFLE_QUESTIONS!='no shuffle'
        }
    },
    watch: {
        'activeQuestion.si': {
            deep:true,
            handler (val) {
                if(val>=0){
                   this.updateMeta(val)
                }
            }
        }
    }
}
</script>