import { instance } from "../_axios";

export default class NotificationModel{
    static updateInterval = 5 // min
    static fetch(){
        if(window.sessionStorage){
            this.cacheNextUpdate()
        }
        return instance.get('/notifications')
    }

    static markAllRead(){
        return instance.post('/notifications/mark-all-read')
    }

    static markRead(id){
        return instance.post('/notifications/mark-read', {id})
    }

    static cacheNextUpdate(){
        let next = Date.now() + 60*NotificationModel.updateInterval*1000
        sessionStorage.setItem('_thnu', next)
    }

    static getNextUpdate(){
        return sessionStorage.getItem('_thnu') || 0
    }

    // static checkAndExecuteUpdate(){
    //     if(window.sessionStorage){
    //         if (Date.now() > parseFloat(getNextUpdate()) {
    //             NotificationModel.fetch()
    //         }
    //     }
    // }
}