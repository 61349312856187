<template>
 <v-layout class="mt-2">
    <v-flex xs12 v-if="localImages.length">
      <v-card :elevation="0">
        <p><i>Uploaded {{title}}</i></p>
        <v-container grid-list-sm fluid class="m-0">
          <v-layout row wrap style="margin: 0">
            <v-flex
              v-for="(image, n) in localImages"
              :key="n"
              xs4
              d-flex
            >
              <v-card flat tile class="d-flex">
                <v-img
                  :src="image.startsWith('https://')?image:`${testConfig.CDN}test-files/${image}`"
                  :lazy-src="image.startsWith('https://')?image:`${testConfig.CDN}test-files/${image}`"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  style="cursor:pointer; max-width: 250px"
                  @click="zoom(n)"
                >
                </v-img>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>
      <v-dialog v-model="zoomImage" fullscreen>
           <v-layout
              fill-height
              align-center
              justify-center
              ma-0
              style="background: rgba(0,0,0,0.5)"
              wrap
              >
            <div style="width:100%" class="text-xs-center" v-if="zoomImageIndex>-1 && localImages">
                <v-img
                :src="localImages[zoomImageIndex].startsWith('https://')?localImages[zoomImageIndex]:`${testConfig.CDN}test-files/${localImages[zoomImageIndex]}`"
                :lazy-src="localImages[zoomImageIndex].startsWith('https://')?localImages[zoomImageIndex]:`${testConfig.CDN}test-files/${localImages[zoomImageIndex]}`"
                max-width='600'            
                class="grey lighten-2"
                style="margin: 0 auto;"
                ></v-img>
                <v-btn fab @click="zoomImage=false" :disabled="deleting"> <v-icon>close</v-icon></v-btn>
                <v-btn v-if="!hideActions" fab @click="deleteImage()" color="red" dark :loading="deleting"> <v-icon>delete</v-icon></v-btn>
            </div>
           </v-layout>
      </v-dialog>
    </v-flex>
  </v-layout>
</template>
<script>
import {instance} from './../_axios.js'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
          zoomImage: null,
          zoomImageIndex: -1,
          deleting: false,
          localImages: []
        }
    },
    methods: {
        zoom(index){
            this.zoomImageIndex = index
            this.zoomImage = true
        },
        deleteImage(){
            this.deleting = true
            var file_name = this.images[this.zoomImageIndex]
            instance.post('/deleteFile',{test_id:  this.$route.params.id, qn: this.questions[parseInt(this.$route.query.qi)].qn || 1, file_name})
            .then((resp) => {
              this.localImages.splice(this.zoomImageIndex,1)
              this.zoomImage = false
              this.zoomImageIndex = -1
              this.$emit('answerDeleted', {data: resp.data.newData, type: 'iu'})
            })
            .catch(error => {
                console.log(error)
                this.$emit('open_snackbar', {text: 'Failed to delete. Please try again.', color: 'red'})
            })
            .then(() => {
                this.deleting = false
            })
        },
        updateLocalImages(){
          this.localImages = this.images && this.images.length?this.images.concat():[]
        }
    },
    mounted(){
      this.updateLocalImages()
    },
    props: {
      images: {
        type: Array,
        default: () => []
      },
      hideActions: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: 'Images'
      }
    },
    watch:{
      images(val){
        this.updateLocalImages()
      }
    },
    computed:{
      ...mapGetters(['user', 'testConfig', 'questions'])
    }
}
</script>