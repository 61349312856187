<template>
    <v-card color="#ECEFF1">
    <v-card-title><h4 class="headline" >{{index}}. {{sm.title}}</h4></v-card-title>
    <v-card-text class="pt-0">
       <template v-if="sm.type=='assignment' || sm.type=='home work' ">
            <p class="text-uppercase body-2" v-html="sm.type.substring(0,1).toUpperCase()+sm.type.substring(1)+` Due Date- <br class='show-sm-and-down'> ${assignmentDate}`"></p> 
            <h4 >Max Marks: {{sm.max_marks}}</h4>
            <h4 v-if="sm.result_published">Result Published <> Score: {{sm.user_assignment?sm.user_assignment.score:0}}</h4>
       </template>
        Subject: {{sm.subject_name}} <br>
        Author: {{sm.author_name}}<br>
        Description: <span v-html="sm.description || 'NA'"></span> <br>
        <v-icon class="bg" x-large >{{sm | icon}}</v-icon>
        <v-list two-line v-if="showAttachments" dark>
            <template v-for="(att, iidx) in sm.attachment_names" >
                <v-list-tile :key="iidx">
                <v-list-tile-action class="hidden-sm-and-down">
                    {{iidx+1}}
                </v-list-tile-action>
                <v-list-tile-content>
                <v-list-tile-title>{{sm.attachment_names[iidx]}}</v-list-tile-title>
                <v-list-tile-sub-title> <small>{{sm.attachment_types[iidx] | attachmentType}}</small> </v-list-tile-sub-title>
                </v-list-tile-content>
                 <v-list-tile-action>
                   <v-btn outline @click="$emit('selected', {sm, iidx})">View</v-btn>
                </v-list-tile-action>
                </v-list-tile>
            </template>
           
        </v-list>
    </v-card-text>
    <v-card-actions>
        <!-- $emit('selected', sm) -->
        <v-btn flat @click="toggleAttachments()" :disabled="sm.attachment_names.length < 1" outline round :block="$vuetify.breakpoint.smAndDown">{{sm.attachment_names.length<1?'NO':''}}{{sm.attachment_names.length?(showAttachments?'Hide':'Show'):''}} Attachments</v-btn>
        <v-btn v-if="sm.type=='assignment' || sm.type=='home work'" flat @click="showUploadModal()"  outline round :block="$vuetify.breakpoint.smAndDown">Uploads</v-btn>
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <v-btn flat disabled class="hidden-sm-and-down">Published At {{publishedAt}}</v-btn>
    </v-card-actions>
   </v-card>
</template>
<script>
import moment from 'moment'

export default {
    data(){
        return {
            colors:{
                'assignment':"cyan darken-2",
                'default': "blue-grey darken-2"
            },
            showAttachments: false,
            metaType: this.$route.meta.type
        }
    },
    mounted (){
        if(this.metaType==='Video-lectures'){
            this.showAttachments = true
        }
    },
    props: {
        sm: {
            type: Object,
            default: () => {}
        },
        idx: {
            type: Number,
            default: 0
        }
    },
    filters:{
        icon(val){
            if(val.type.indexOf('image')==0) return 'image'
            if(val.type.indexOf('application/pdf')==0) return 'picture_as_pdf'
            if(val.type.indexOf('sound')==0) return 'audiotrack'
            if(val.type.indexOf('video')==0) return 'videocam'
            return 'description' //'val'
        },
        dateFilter(val){
            try {
                return Date.parse(val).toLocaleString()   
            } catch (error) {
                console.log(error)
                return val
            }
        },
        attachmentType(type){
            if(type == 'application/pdf') return 'pdf'
            if(type == 'image/png' || type == 'image/jpeg' || type == 'image/jpg') return 'image'
            if(type.startsWith('video/png')) return 'video'
            return type
        }
    },
    computed: {
        assignmentDate(){
            if(!this.sm.type=='assignment' && !this.sm.type=='home work') return null
            try {
                if(!this.sm.due_date) return "-"
                const date = (new Date(this.sm.due_date))
                return moment(date).format('hh:mm a MM-DD-Y') 
            } catch (error) {
                console.log(error)
                return val
            } 
        },
        publishedAt(){
            try {
                const date = (new Date(this.sm.published_at))
                return moment(date).format('hh:mm a MM-DD-Y') //date.toLocaleTimeString()+" "+date.toLocaleDateString()   
            } catch (error) {
                console.log(error)
                return val
            } 
        }, 
        index(){
            return (this.$route.query.page?(parseInt(this.$route.query.page)-1)*10:0)+this.idx+1
        }
    },
    methods: {
        toggleAttachments(){
            this.showAttachments =  !this.showAttachments
        },
        showUploadModal(){
            console.log('asdsa')
            this.$emit('showuploads', this.sm); 
        }
    }
}
</script>
<style scoped>
.bg{
    position: absolute;
    float: right;
    color: #263238;
    opacity: 0.2;
    right: 20px;
    top: 20px;
}
.attachments{
    border: 1px solid white;
    margin-top: 10px;
    border-radius: 5px;
}
</style>