<template>
<div>
   <!--  color="teal "
    max-width="500"
    dark -->
  <v-card
    class="mx-auto"
    elevation="1"
    >
    <v-toolbar class="pt-1 pb-1" flat>
    <v-icon large left ripple color="blue-grey"> assignment_turned_in </v-icon>
    <v-toolbar-title class="blue-grey--text">TODO</v-toolbar-title>
    <v-spacer></v-spacer>
    <v-toolbar-items >
     <v-fab-transition>
        <v-btn v-show="selected.length" light right fab small color="red" depressed @click="deleteTodo()" dark ><v-icon ripple> delete </v-icon></v-btn>
      </v-fab-transition>
      <v-btn light right fab small color="blue-grey" depressed @click="newTodoDialog=true" dark><v-icon ripple> add </v-icon></v-btn>
    </v-toolbar-items>
    </v-toolbar>
    <v-divider></v-divider>

    <v-card-text style="height: 301px;overflow:auto" :class="{'fs':fullscreen}">
      <div v-if="loading" class="headline font-weight-bold  text-xs-center">
        <v-progress-circular :width="3"  color="white"  indeterminate ></v-progress-circular>
        <br><br>Loading ...
      </div>
      <div v-else>
        <div v-if="!todos.length" class="pt-4">
          <p class="headline text-xs-center white--text"><v-icon size="4em" color="light-blue lighten-4" left ripple>assignment_turned_in</v-icon></p>
          <p class="subheading text-xs-center white--text"> You have no todos!</p>
        </div>
        <div v-else class="pl-0 pr-0" >
          <v-list-tile   v-for="(todo, idx) in todos" :key="idx" class="prl-0">
            <v-list-tile-action> <v-checkbox v-model="selected" :value="todo._id"></v-checkbox> </v-list-tile-action>
            <v-list-tile-content >
              <v-list-tile-title><span :class="{'done black--text':todo.done}">{{todo.text}}</span></v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </div>
      </div>
    </v-card-text>
  </v-card>
	


<v-dialog v-model="newTodoDialog" width="500" transition="scale-transition" persistent>
  <v-card >
    <v-card-title class="headline blue-grey white--text" primary-title> 
    Add New Todo 
    <v-spacer></v-spacer>
    <v-btn light right fab small color="blue-grey" depressed @click="newTodoDialog=false" dark :disabled="progress"><v-icon ripple> close </v-icon></v-btn>
  </v-card-title>

    <v-card-text>
       <v-textarea label="Type here" outline v-model="newTodoText" :disabled="progress" row-height="3"></v-textarea>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn  color="light-blue" dark @click="saveNewTodo()" :loading="progress">Save</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>


</div>
</template>
<script>
import {instance} from './../_axios.js'
import { mapGetters, mapActions } from 'vuex'
export default{
	data(){
      return {
      	loading:  false,  // if loading data
        progress: false, // if action progress
        selected: [],
        pagination: {
          sortBy: 'title'
        },
        headers: [
          {
            text: 'Title',
            align: 'left',
            value: 'title'
          }
        ],
        newTodoDialog: false,
        newTodoText: ''
      }
	},
	mounted(){
    if(!this.dontFetch) this.init()
	},
	methods:{
      ...mapActions(['errorSnack']),
      init() {
        if(!this.auth) return false
      	this.loading = true
        instance.get('/todo/list')
        .then(resp => this.$store.commit('set_todos', resp.data) )
        .catch((e)=>{this.errorSnack({e,b:'Error loading todos!' }) })
        .then(() => this.loading = false )
      },
      saveNewTodo(){
        // this.newTodoDialog = false
        if(!this.auth) return this.$store.commit('open_snackbar',{text: 'Please log in first!', color: 'red'})
        if(this.newTodoText.length<1) return this.$store.commit('open_snackbar',{text: 'Please type something!'})

        this.progress = true
        instance.post('/todo/save',{text: this.newTodoText})
        .then(resp => {
          this.$store.commit('open_snackbar',{text: 'New todo saved!', color: 'green'})
          this.$store.commit('add_todo', resp.data.doc)
          this.newTodoText = ''
        })
        .catch(err => this.$store.commit('open_snackbar',{text: 'Error saving new todo!', color: 'red'}))
        .then(() => {
          this.newTodoDialog = false
          this.progress = false
        })
      },
      deleteTodo(){
        this.progress = true
        if(confirm("Are you sure to delete?")){
          instance.post('/todo/delete',{ids: this.selected})
        .then(resp => {
          this.$store.commit('open_snackbar',{text: 'Todos deleted successfully!', color: 'green'})
          this.$store.commit('delete_todos', this.selected)
          this.selected = []
        })
        .catch(err => this.$store.commit('open_snackbar',{text: 'Error deleting todos!', color: 'red'}))
        .then(() => {
          this.newTodoDialog = false
          this.progress = false
        })
        }
        
      },
      updateTodo(type){
        this.progress = true
        var params = { ids: this.selected }
        params.updates = this.selected.length==1&&type!=='done'?[{text: this.todos.filter(v=>v._id==this.selected[0])[0].text}]:null // update only one
        if(type==='done')   params.done = this.todos.filter(v=>this.selected.indexOf(v._id)>-1).find(v=>v.done==true)?false:true
       

        instance.post('/todo/update',params)
        .then(resp => {
          this.$store.commit('open_snackbar',{text: 'Todos updated successfully!', color: 'green'})
          if(type==='done') this.$store.commit('done_todos', {ids: this.selected, done: params.done })
          else console.warn('update it')
          this.selected = []
        })
        .catch(err => this.$store.commit('open_snackbar',{text: 'Error deleting todos!', color: 'red'}))
        .then(() => {
          this.newTodoDialog = false
          this.progress = false
        })
      }
	},
	props:{
      close: {
        type: Boolean,
        default: false
      },
      fullscreen: {
        type: Boolean,
        default: false
      },
      dontFetch: {
        type: Boolean,
        default: false
      }
	},
	computed: {
		...mapGetters(['todos','auth'])
	}
}
</script>
<style scoped>
.done{
  text-decoration: line-through;
}
.prl-0 a.v-list__tile{
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.fs{
  height: calc(100vh - 134px) !important;
}
</style>