
import { DateTime } from "luxon";
import requestWakeLock from './../../../utils/wakeLock'
import {openFullscreen} from './../../../utils/fullscreen.js';
import ReleaseMedia from './../../../utils/releaseMedia'

export default (context) => {
    window.addEventListener('visibilitychange', (event) => {
        if(event.target.visibilityState=='hidden') {
            sessionStorage.setItem('blur_'+context.userData._id, DateTime.local().toMillis())
            sessionStorage.setItem('tl_'+context.userData._id, context.timeLeft)
            if((context.testConfig.PROCTOR_TYPE !== 'none' ) && context.testConfig.isMobileDevice) {
                ReleaseMedia(context.localStream)
                context.$store.dispatch('hangupVideoProctor')
            }
        }
        else {
            requestWakeLock()
            if((context.testConfig.PROCTOR_TYPE !== 'none' ) && context.testConfig.isMobileDevice) {
                // this.initJitsiProctor(callback())
                context.initStream(() => {})
                // context.joinWebex()
                // WebexMeeting('1580215425@webex.com','MMhH22Cjqi9', context.user)
            }
            // If was paused for camera,  hide if no file selected, And toggle pause proctor 
            if(context.pauseProctor) {
                setTimeout(() => {
                    context.$refs.activeQuestionItem.hideIfNoFileCaptured()
                    context.$store.commit('toggle_proctor', true)
                }, 500)
            }
            if(context.testConfig.DETECT_FULLSCREEN) openFullscreen()
            // IF not paused CHECK if BLUR TIME OUT
            try {
                let timeout = (DateTime.local().toMillis() - parseInt(sessionStorage.getItem('blur_'+context.userData._id)))/1000 // s
                if(timeout > context.testConfig.MAX_BLUR_ALLOWED_TIME && context.testConfig.AUTO_TERMINATE) context.submitTest('BLUR_TIME_OUT')
                context.timeLeft -= Math.abs(timeout) 
            } catch (error) {
                console.log(error.message)
            }
        }
    })
}