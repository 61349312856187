<template>
<div id="chat-overlay" v-if="showChatPanel">
    <v-card>
        <v-toolbar>
            <v-toolbar-title>{{subTitle || 'Query'}}</v-toolbar-title><v-spacer></v-spacer>
            <v-toolbar-items> <v-btn flat icon @click="toggleChatPanel()"><v-icon>close</v-icon></v-btn> </v-toolbar-items>
        </v-toolbar>
        <v-progress-linear v-if="loadingInitial" :indeterminate="true"></v-progress-linear>
        <v-card-text id="chatMain">
            <v-layout row wrap fill-height>
                <v-flex d-flex xs12 grow ref="chatHolder" id="chatHolder">
                    <p v-for="(chat,index) in chatMessage" :key="index" class="chat-item" :class="chat.teid==null?'':'tchat'"> {{chat.m}} </p>
                    <p v-if="chatMessage.length==0 && !loadingInitial" class="text-xs-center mt-4">
                       <v-icon>chat_bubble_outline</v-icon> Chat using the Textbox at bottom.
                    </p>
                </v-flex>
                <v-flex d-flex xs12 align-self-end>
                    <form @submit.prevent="send()" >
                        <v-text-field
                            v-model="current"
                            :rules="rules"
                            label="Type your query here"
                            append-icon="send"
                            :loading="sending"
                            @click:append="send()"
                        ></v-text-field>
                    </form>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import {setCameraDevice } from './../utils/webCam'

let initialLoad = true

export default {
    data(){
        return {
            sending: false,
            current: '',
            rules: [ v => !!v || '' ],
            chatData: [],
            chatHolder: null,
            loadingInitial: true,
        }
    },
    beforeMount(){
        initialLoad = true
    },
    mounted(){
        this.chatHolder = this.$refs.chatHolder
        this.hookChats()
    },
    methods: {
        ...mapActions([ 'sendNewChatMessage', 'sendChatMessage', 'hookChat', 'setAllRead', 'initJitsiProctor', 'toggleChatPanel' ]),
        async send ()  {
            if(this.current.length<1) return
            try {
                this.sending = true
                await this.sendNewChatMessage({
                    userId: this.user.id, 
                    payload: {
                        m:   this.current,
                        uid: this.user.id,
                        tid: this.testMockid,// test id
                        un:  this.user.name,
                        tn:  this.testName,
                        r:   0,
                        ts:  Date.now()
                    }   
                })

                await this.sendChatMessage({
                    payload: {
                        m: this.current,
                        uid: this.user.id,
                        tid: this.testMockid,
                        teid: null,
                        ts: Date.now(),
                        r: 0
                    }
                })
                this.current = ''
                this.sending = false
            } catch(e) {
                console.log(e.message)
                this.$store.commit('open_snackbar', {text: 'Message was not sent! Please try again.', color: 'red'})
                this.sending = false
            }
        },
        hookChats(){
            try{
                this.hookChat({
                    mockId: this.testMockid, 
                    userId: this.user.id, 
                    onData: (snapShot) => {
                        this.chatData = snapShot.docs.map( s => s.data());
                        this.$nextTick(()=>{
                            if(this.chatHolder) this.chatHolder.scrollTop = this.chatHolder.scrollHeight
                        })
                        this.loadingInitial = false
                        let lastChat = this.chatData[0]
                        this.bindNotificationViewer(lastChat)
                        this.bindNotificationAction(lastChat)
                    }
                })
            } catch(e){
                console.log(e)
            }
        },
        bindNotificationViewer(lastChat){
            // if(this.showChatPanel) return
            if(lastChat && lastChat.teid!==null && lastChat.m.indexOf('::')<0){
                this.$store.commit('open_snackbar', { text: 'New message', color: 'info'})
                this.$emit('fetched', this.chatMessage.length)
            }
        },
        async bindNotificationAction(lastChat){
            // no action should be taken during inital load
            if(initialLoad) return initialLoad = false
            
            if(!lastChat || !this.$store.state.assessment.inTest) return
            if(lastChat.m.indexOf('ACTION::')>=0){
                switch (lastChat.m) {
                    case 'ACTION::PROCTOR_TERMINATE':
                        this.$parent.submitTest('PROCTOR_TERMINATE')
                        break;
                    case 'ACTION::RELOAD':
                        window.reload(true)
                        break;
                    case 'ACTION::RELOAD_IMAGE':
                        console.info('Reloading image proctor')
                        this.$emit('initVideoStream')
                        break;
                    case 'ACTION::RELOAD_VIDEO':
                        console.log('Reloading JITSI')
                        this.$emit('startProctor')
                        break;
                    case 'ACTION::EXTEND_TEST':
                        const toAddMinutes = lastChat.et || 0
                        this.$emit('extendTest', toAddMinutes)
                        break;
                    case 'ACTION::SELECT_WEBCAM':
                        if(lastChat.ed){
                            const devices = await navigator.mediaDevices.enumerateDevices();
                            const videoDevices = devices.filter(device => device.deviceId == lastChat.ed);
                            if(videoDevices.length==1) setCameraDevice(videoDevices[0])
                            this.$emit('startProctor')
                        }
                        break;
                    default:
                        break;
                }
            }
        }
    },
    props: {
        // showChatPanel: {
        //     type: Boolean,
        //     default: false
        // },
        testMockid: {
            type: String,
            default: null
        },
        testName: {
            type: String,
            default: null
        },
        subTitle: {
            type: String,
            default: null
        }
    },
    computed: {
        ...mapGetters(['auth', 'user', 'firebaseApp', 'showChatPanel']),
        chatMessage(){
            return this.chatData.filter(chat => chat.m && chat.m.indexOf('::') < 0)
        }
    },
    watch: {
    //   showChatPanel(v){ 
    //       if(v) {
    //        this.hookChats()
    //       }
    //   }
    }
}
</script>
<style>
#chatMain{
    height: calc( 100vh - 130px)
}
@media screen and (max-width: 500px) {
    #chatMain{
        height: calc( 100vh - 100px );
    }
}
#chat-overlay{
    position: fixed;
    right: 0;
    top: 0;
    bottom: 64px;
    width: 400px;
    max-width: 100vw;
    background: white;
    z-index: 10;
}
.flex.d-flex.xs12.grow>.chat-item{
    max-width: 80%;
    text-align: right;
    background: grey;
    display: flex;
    align-self: flex-end;
    flex-grow: 0 !important;
    padding: 15px;
    color: white;
    border-radius: 5px;
}

.flex.d-flex.xs12.grow>.chat-item.tchat{
    align-self: flex-start;
    text-align: left;
    background: #455a64;
}
#chatHolder::-webkit-scrollbar-thumb{
    background-color: #818B99;
    border: 3px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}
#chatHolder{
    flex-direction: column;max-height: calc(100% - 68px); overflow: hidden auto;flex-flow:column-reverse
}
</style>