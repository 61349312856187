<template>
<div class="text-xs-center"  style="background: white;">
    <div class="mt-4 mb-4">
        <v-btn color="primary" round :disabled="audioRecording || uploading" @click="captureMedia()">Record {{audioBlob?'Again':''}}</v-btn>
        <v-btn color="primary" round :disabled="!audioRecording || uploading" @click="stopRecordingAudio()" >Stop</v-btn>
        <p v-if="audioRecording">Recording Answer : {{recordingDuration}}s</p>
    </div>
    <audio v-show="audioBlob" ref="audioPlayer" controls controlsList="nodownload"/>
    <p v-if="audioBlob">
        Size = {{audioBlob.size | size}} <br>
        Duration = {{recordingDuration}}s
    </p>
    <div class="mt-4 mb-4">
        <v-btn outline round :disabled="audioRecording || uploading" @click="$emit('close')" >Cancel</v-btn>
        <v-btn color="success" round :disabled="audioRecording || uploading" @click="$emit('uploadAnswer', {blob: audioBlob, type:'audio', ext:'wav'})">Upload</v-btn>
    </div>
</div>
</template>
<script>
import Recorder from './../../../utils/recorder'
import ReleaseMedia from './../../../utils/releaseMedia'
const AudioContext = window.AudioContext || window.webkitAudioContext;
var audioContext                    //audio context to help us record
var gumStream; 						//stream from getUserMedia()
var rec; 							//Recorder.js object
var input; 							//MediaStreamAudioSourceNode we'll be recording
var URL = window.URL || window.webkitURL;

export default {
    data() {
        return {
            audioRecording: false,
            audioBlob: null,
            recordingDuration: 0,
            audioRecordTimerInterval: null,
            mediaError: false,
        }
    },
    mounted(){
        this.init()
    },
     beforeDestroy(){
        ReleaseMedia(gumStream)
    },
    props: ['stream', 'uploading'],
    methods: {
        async init(){
            if(this.audioRecordTimerInterval) clearInterval(this.audioRecordTimerInterval)
            
            this.mediaError = null
            this.audioBlob = null
            
            if(this.stream) {
                gumStream = this.stream
                return this.captureMedia()
            }

            ReleaseMedia(gumStream)
            try {
                gumStream = await navigator.mediaDevices.getUserMedia({audio: true})
                this.captureMedia()
                console.log(gumStream)
            } catch (err0r) {
                if(/permission/.test(err0r.message.toLowerCase())) this.mediaError = "Please allow this website to access webcam!"
                else  this.mediaError = err0r.message.replace(/[^\:]*:/, '')
            }

            console.log('here')
        },
        captureMedia(){
            audioContext = new AudioContext();
            input = audioContext.createMediaStreamSource(gumStream);
            rec = new Recorder(input,{numChannels:1, workerPath: '/recorderWorker.js'})
            this.recordAudio()
        },
        recordAudio(){
            if(rec) {
                this.recordingDuration = 0
                this.audioRecordTimerInterval = setInterval(()=>{ this.recordingDuration += 1 },1000)
                this.audioBlob = null
                this.$refs.audioPlayer.src = null
                this.audioRecording = true
                rec.record()
            }
        },
        stopRecordingAudio() {
            clearInterval(this.audioRecordTimerInterval)
            this.audioRecording = false
            gumStream.getAudioTracks()[0].stop()
            rec.exportWAV( (blob) =>{
                this.audioBlob = blob;
                this.$refs.audioPlayer.src = URL.createObjectURL(blob)
            });
        },
    },
    filters: {
        size(x){
            const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
            let l = 0, n = parseInt(x, 10) || 0;
            while(n >= 1024 && ++l){
                n = n/1024;
            }
            return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
        }
    }
}
</script>