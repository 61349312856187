<template>
<v-layout row wrap justify-center >
    <v-flex xs4 md3 sm2 v-for="(home_item,hi) in home_items" class="home_item pa-2" :key="home_item.to">
    <v-card  
        elevation="0"
        max-width="450" 
        :class="`mx-auto animate__animated ${getAnimation(hi)} ${hi===active_hover?'white--text show-particles ':''} `  " 
        cl="${hi>=3 && hi <=5? `animate__delay-1s`:(hi>=6 && hi <=8?`animate__delay-2s`:'')}"
        hover 
        :to="home_item.to" 
        :style="{'backgroundColor':(hi===active_hover?$vuetify.theme.primary:'transparent'), borderRadius: '4px'}"
        @mouseover="active_hover=hi" 
        @mouseleave="active_hover=-1">
        <v-card-text> 
        <v-layout column>
            <v-flex xs12 align-self-center > 
            <v-icon v-if="home_item.icon" :color="hi==active_hover?'white':'primary'" :x-large="!$vuetify.breakpoint.xsOnly">{{home_item.icon}}</v-icon> 
            <v-img v-else style="margin: 0 auto" :src="home_item.img" width="40"></v-img>
            </v-flex>
            <v-flex xs12 class="text-xs-center" >
            <p  class="mt-2 home-menu-item">{{home_item.title}}</p>
            </v-flex>
            <particles :active_hover="active_hover" colorDark="white" :hi="hi"></particles>
        </v-layout>
        </v-card-text>
        <!--<v-divider></v-divider>-->
    </v-card>
    </v-flex>
</v-layout>
</template>
<script>
import Particles from './../../components/Particles.vue'
import { mapGetters } from 'vuex'
import {filteredMenu} from './../../data/sidebar-menu'

export default{
    data(){
      return {
        active_hover: -1,
        home_items: [],
      }
    },
    mounted(){
        if(this.user){  this.initMenus(this.user.config) }
    }, 
    methods:{
        initMenus(config){
            console.log(config)
            this.home_items = filteredMenu(config).filter(v => v.title!='Profile')
        },
        getAnimation(index){
        //if(index == 0 || index%3 == 0) return `animate__slideInLeft`
        //else if((index+1)%3==0) return `animate__slideInRight`
        //else if(index == 2) return `animate__slideInRight`
            return `animate__slideInUp animate__fadeIn`
        }
    },
    computed: {
	  ...mapGetters([ 'auth' ,'user']),
	},
    components: {
        'particles': Particles,
    }
}
</script>
<style>
.home-menu-item{
  white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 11px;
}
</style>