<template>
<div>
  <!-- <h4>{{$route.meta.title}}</h4> -->
  <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
  <div>
    <div class="text-xs-center" v-if='loading'>
    <v-progress-circular
      :size="250"
      :width="10"
      color="indigo"
      indeterminate
      >Loading available tests
    </v-progress-circular>
    </div>
  	<v-container
      v-else
      fluid
      grid-list-lg class="pa-0" >
      <v-layout row wrap class="justify-center">
          
          <v-flex xs12 md6 >
            <v-card v-if="test" hover  style="border-radius: 10px">
                  <v-card-title v-if="$vuetify.breakpoint.smAndDown"  lass="colors[ti<colors.length?ti:ti%colors.length]" class="blue-grey darken-1">
                      <div class="headline white--text" >{{test.name}}</div>
                  </v-card-title>
                  <v-card-text :class="{'pa-0': $vuetify.breakpoint.smAndDown}">
                    <v-layout row wrap>
                      <v-flex md6 >
                        <template v-if="$vuetify.breakpoint.mdAndUp">
                          <h4 class="title pb-2">{{test.name}}</h4>
                          <v-divider></v-divider>
                        </template>
                        <p class="pt-2" :class="{'pa-2': $vuetify.breakpoint.smAndDown}" v-html="test.description"></p>
                      </v-flex>
                      <v-flex md6 class="pb-2">
                         <v-list style="padding: 0">
                          <v-list-tile style="height: 40px">
                            <v-list-tile-content>Max Marks</v-list-tile-content>
                            <v-list-tile-content class="align-end">{{test.format.max_marks}}</v-list-tile-content>
                          </v-list-tile>
                          
                          <v-list-tile style="height: 40px">
                            <v-list-tile-content>Duration</v-list-tile-content>
                            <v-list-tile-content class="align-end">{{test.format.duration}} min</v-list-tile-content>
                          </v-list-tile>

                          <v-list-tile style="height: 40px">
                            <v-list-tile-content>Questions</v-list-tile-content>
                            <v-list-tile-content class="align-end">{{test.format.noq || 0}}</v-list-tile-content>
                          </v-list-tile>

                          <v-list-tile style="height: 40px">
                            <v-list-tile-content>Merit Points</v-list-tile-content>
                            <v-list-tile-content class="align-end">{{test.mp_cost}} </v-list-tile-content>
                          </v-list-tile>
                          </v-list>
                      </v-flex>
                    </v-layout>
                   
                      
                  </v-card-text>
              <v-divider light></v-divider>
              <v-card-actions  class="justify-center">
                <!-- class="pa-3"  -->
                <!-- <v-btn flat v-else @click="chapterDialog.model=true" outline color="info">Select Chapter</v-btn> -->
              	<!-- <v-btn flat  @click="selectedTest=test;infoDialog=true">Info</v-btn> -->
                <!-- <v-spacer></v-spacer> -->
                <v-btn outline round color="info" :to="'/test/'+test._id+'/instruction'">Start Test #{{results.length+1}}</v-btn>

              </v-card-actions>
            </v-card>
          </v-flex>

          <v-flex xs12 class="justify-center" v-if="!test">
            <div class="text-xs-center">
              <v-icon :size="200">insert_chart</v-icon>
              <h3 class="headline"> Test not found! </h3>
            </div>
          </v-flex>

        
          
  </v-flex>
      

        </v-layout>

      <v-card>
          <v-card-title><h4 class="title grey--text">Results</h4></v-card-title>
          <v-data-table
            :headers="headers"
            :items="results"
            :pagination.sync="pagination"
            :loading="loading"
            select-all
            item-key="name"
            class="elevation-1">
         <template slot="no-data">
          <v-alert :value="true" color="warning" icon="warning" outline> No test result found! </v-alert>
         </template>

        <template slot="headers" slot-scope="props">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']" 
               >
              {{ header.text }}
            </th>
          </tr>
        </template>
        <template slot="items" slot-scope="props">
          <tr >
            <td class="nowrap">
              #{{props.index+1}}
              <span v-if="props.item.name=='Chapter Test'"><br>({{props.item.chapters.map(v => v.name).join(', ') | maxChar}})</span>
            </td>
            <td class="text-xs-center" v-if="!$vuetify.breakpoint.smAndDown">{{ props.item.result.correct }} / {{ props.item.result.wrong }} / {{ props.item.result.skipped }}</td>
            <td class="text-xs-center">{{ props.item.result.scored || 0}}</td>
            <td class="text-xs-center"><v-progress-circular :rotate="270" size="40" :width="5" :value="props.item.result.percentage" color="blue" :indeterminate="loading"> {{props.item.result.percentage || 0}} </v-progress-circular>
        </td>
            <td class="text-xs-center">
              <v-btn small flat :to="{path:`/test/${props.item._id}/result`}" color="primary">Result</v-btn>
              <span v-if="$vuetify.breakpoint.mdAndUp"></span>
              <span v-else><br></span>
              <v-btn small flat :to="{path:`/test/${props.item._id}/review`}" color="warning">Review</v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      </v-card>
      

      </v-container>
      <div>
        <br><br><br>
      </div>
  </div>


<v-dialog
  v-model="infoDialog"
  width="500"
  :fullscreen="$vuetify.breakpoint.smAndDown">
  <v-card v-if="selectedTest">
    <v-card-title class="headline grey lighten-2">
      <span class="headline">{{selectedTest.name}} </span>
      <v-spacer></v-spacer>
      <v-btn small fab icon @click="infoDialog=false"><v-icon>close</v-icon></v-btn>
    </v-card-title>

    <v-card-text>
      <h4>Description</h4>
      <p v-html="selectedTest.description"></p>
    </v-card-text>

  </v-card>
</v-dialog>


</div>
</template>
<script>
const {instance} = require('./../_axios.js');
import { mapActions, mapGetters } from 'vuex'

export default{
	data(){
	  return {
      test: null,
      results: [],
      loading: false,
      infoDialog: false,
      selectedTest: null,
      selected: 0,
      colors: ['orange lighten-2','light-blue lighten-2','deep-purple lighten-1','brown lighten-1'],
      year: null,
      exam: null,

      pagination: {
        sortBy: 'name',
        rowsPerPage: 10
      },
      headers: [
        {
          text: 'Test#',
          align: 'left',
          class: 'text-xs-left',
          value: 'name',
          width: 50,
          sortable: false
        },
        { text: 'Correct / Wrong / Skipped',      value: 'result.correct', sortable: false },
        // { text: 'Attempted / Skipped',   value: 'result.attempted' },
        { text: 'Scored',     value: 'result.scored', sortable: false },
        { text: 'Percentage',       value: 'result.percentage', sortable: false },
        { text: 'Action', sortable: false , width: this.$vuetify.breakpoint.mdAndUp?'auto':100       }
      ],
      breadcrumbs: [
        {
          text: 'Mock Test',
          disabled: false,
          href: '/mock-test'
        },
        {
          text: '',
          disabled: true,
        }
      ]
    }
	},
	mounted(){
	  this.fetchTest()
     if(this.$vuetify.breakpoint.smAndDown){
      this.headers = this.headers.filter(v => v.value!='result.correct')
    }
	},
	methods:{
    ...mapActions(['errorSnack']),
	  fetchTest(){
      console.log(this.$route.params)
      this.loading = true
	  	instance.get(`/test/get/${this.$route.params.id}`)
	  	.then((resp) => {
        this.test = resp.data.test
        this.results = resp.data.results

        this.breadcrumbs[1].text = this.test.name
	  	})
	  	.catch((e)=>{ this.errorSnack({e,b:'Error fetching tests!'}) })
      .then(() => { this.loading = false, 2000 }  )
	  },
    startChapterTest(){
      this.$router.push('/chapter-test/'+this.chapterDialog.chapters.map(v => v.id).join(',')+'/instruction')
    },
	},
  watch:{
    '$route.path'(){
      this.test = []
      this.fetchTest()
    }
  },
  components: {
  }
}
</script>
<style>

</style>