<template>
 <v-dialog fullscreen v-model="showDialog" persistent>
     <v-card>
        <v-toolbar dark color="primary">
            <v-card-title><v-icon>warning</v-icon> Multiple Faces Detected!</v-card-title>
        </v-toolbar>
        <v-card-text class="title text-xs-center mt-4 pt-4">Except you, there should be no one in front or nearby your device!</v-card-text>
     </v-card>
</v-dialog>  
</template>
<script>
const {instance} = require('./../../_axios.js');
import Webcam from './utils/Webcam.js'

var videoElement, model, canvasElement, context;
const DEBUG = true
const gap = 12000
const normal = 7000
export default {
    data() {
        return {
            message: '',
            showDialog: false,
            lastDetected: null,
            count: 0,
            isDesktop: window.innerWidth > 500
        }
    },
    async mounted(){
        if(!this.isDesktop) return false
        this.$emit('modelLoading', true)
        videoElement    = document.querySelector('#localVideo')
        canvasElement   = document.querySelector('#localCanvas')
        context         = canvasElement.getContext('2d')

        videoElement.addEventListener('canplay', () => {
            canvasElement.height = videoElement.videoHeight
            canvasElement.width = videoElement.videoWidth
        })

        videoElement.addEventListener('loadeddata', async () => {
            console.log('Loading model')
            model = await blazeface.load();
            this.$emit('modelLoading', false)
            this.predict()
        })

        document.addEventListener('keydown',function(e){
            //CTRL + 
            if(e.ctrlKey){
                switch(e.code){
                    case 'KeyQ':
                        console.log('CTRL + Shift + Q');
                        document.querySelector('#videos').classList.toggle('show-video') 
                    break;
                }
            }
        });
    },
    props: {
        submitted: {
            type: Boolean,
            default: false
        },
        gap: {
            type: Number,
            default: 14000
        },
        normal: {
            type: Number,
            default: 10000
        }
    },
    methods: {
        async predict(){
            // console.log('predict called')
            // Pass in an image or video to the model. The model returns an array of
            // bounding boxes, probabilities, and landmarks, one for each detected face.
            const returnTensors = false; // Pass in `true` to get tensors back, rather than values.
            const predictions = await model.estimateFaces(videoElement, returnTensors);
            
            this.count = predictions.length
            
            // if(DEBUG && this.count>0){
            //     context.clearRect(0, 0, canvasElement.width, canvasElement.height);
            //     context.drawImage(videoElement, 0 , 0)
            //     context.fillStyle = "rgba(255, 0, 0, 0.5)";
            //     for (let i = 0; i < this.count; i++) {
            //         const start = predictions[i].topLeft;
            //         const end = predictions[i].bottomRight;
            //         const size = [end[0] - start[0], end[1] - start[1]];

            //         // Render a rectangle over each detected face.
            //         context.fillRect(start[0], start[1], size[0], size[1]);
            //         const landmarks = predictions[i].landmarks;
            //         context.fillStyle = "blue";
            //         for (let j = 0; j < landmarks.length; j++) {
            //             const x = landmarks[j][0];
            //             const y = landmarks[j][1];
            //             context.fillRect(x, y, 5, 5);
            //         }
            //     }
            // } 

            if (this.count != 1) {
                Webcam(this.captureSnap)
                if(this.submitted) return false
                return setTimeout(() => { this.predict() }, gap)
            } else {
                console.log('1 face detected')
            }
            setTimeout(() => { if(this.submitted) { return false } this.predict() }, normal)
        },
        captureSnap(blob){
            this.lastDetected = Date.now()
            let fd = new FormData();
                fd.append('test_id', String(this.$route.params.id));
                fd.append('data', blob);
                instance.post(`/snapUpload/${this.$route.params.id}/png`, fd)
                .then(resp => {
                    this.$emit('multiFaces', this.count)
                    return { success: true }
                })
                .catch(error => {
                    return { success: false, error }
                })
                .then(() => {
                    console.log('Snap uploaded')
                })
        }
    }
}
</script>