<template>
  <v-app id="app" >
   
    <v-navigation-drawer
      v-model="openDrawer"
      :temporary="true"
      :clipped="primaryDrawer.clipped"
      :floating="primaryDrawer.floating"
      :mini-variant="primaryDrawer.mini"
      fixed
      overflow
      app>
      <nav-drawer-content></nav-drawer-content>
    </v-navigation-drawer>
    
    <v-toolbar :clipped-left="primaryDrawer.clipped" app fixed v-show="toolbar" :card="$store.state.global.cardToolbar">
      <v-toolbar-side-icon v-if="primaryDrawer.type !== 'permanent' && auth" @click.stop="$store.commit('toggle_drawer')" ></v-toolbar-side-icon>
      <v-spacer></v-spacer>
      <!-- NAVRIGHT -->
      <nav-right-menu></nav-right-menu>
    </v-toolbar>

    <v-content>
      <v-container fluid>
        <v-slide-y-transition hide-on-leave leave-absolute>
          <router-view :key="$route.path"></router-view>
        </v-slide-y-transition>
      </v-container>
    </v-content>
      
    <v-snackbar
      v-model="snackbar.model"
      :bottom="true"
      :color="snackbar.color"
      :timeout="snackbar.timeout" >
      {{ snackbar.text }}
      <v-btn flat dark @click="$store.commit('close_snackbar')">
        Close
      </v-btn>
    </v-snackbar> 

  </v-app>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'
//import ReferralDialog from './components/ReferralDialog'
import NavDrawerContent from './components/NavigationDrawerContent'
import NavRightMenuVue from './components/nav/NavRightMenu.vue'

export default{
  data() {
    return  {
      dark: true,
      primaryDrawer: {
        model: false,
        type: null,
        clipped: false,
        floating: false,
        mini: false
      },
      aidx: 0,
      right: null,
    }
  },
  computed:{
    ...mapGetters([ 'snackbar', 'auth', 'toolbar', 'showOffer', 'user' ]),
    menus() {
      return this.items.filter((val,index) => index>0)
    },
    openDrawer:{
      get(){
        return this.$store.state.global.drawer && this.auth
      },
      set(v){
        this.$store.commit('toggle_drawer',v)
      }
    }
  },
  mounted(){
    window.addEventListener('contextmenu', function (e) { 
      if(process.env.NODE_ENV == 'production') e.preventDefault(); 
    }, false);
  },
  components: {
    //'referral-dialog': ReferralDialog,
    'nav-drawer-content': NavDrawerContent,
    'nav-right-menu': NavRightMenuVue
  },
  methods: {
    ...mapActions([ 'openSnack', ]),
    toggle(val){
      this.$store.assessment.commit('set_in_test', val)
    },
   
  },
 
}


</script>
<style>
#app{
  position: relative;
}
.v-footer{
  margin:-16px
}
@media only screen and (max-width: 959px){
  .container {
    padding: 10px;
  }
  .v-footer{
    margin:-16px
  }
}
.OSX{
  white-space: normal;
  overflow: auto hidden !important
}

@media screen and (min-width: 600px ){
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }
}

.nowrap{
  white-space: nowrap;
}
.v-navigation-drawer{
  border-right: 1px solid rgb(238, 238, 238);
}

#videos, #meet{
  position: absolute;
    top: 0;
    z-index: -1;
    max-width: 100%;
    overflow: hidden;
    opacity: 0;
    /* height: 10px; */
    max-height: 100vh;
}

#videos.show-video{
  position: absolute;
  z-index: 10000;
  display: flex;
  visibility: visible;
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  top: 0;
  left: 0;
  justify-content: center;
  background: #404040;
}

.op5{
  opacity: 0.5;
}
.float-right{
  float: right;
}
</style>
