function PrintPDF(data, title)
{
    var frame1 = document.createElement('iframe');
    frame1.setAttribute('name', "frame1");
    frame1.style.position = 'absolute'
    frame1.style.top = '-1000000px'

    window.document.body.append(frame1)
    var frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write('<html>');
    frameDoc.document.write('<head>');
    frameDoc.document.write('<title></title>');
    frameDoc.document.write('</head>');
    frameDoc.document.write('<body>');
    frameDoc.document.write(data);
    frameDoc.document.write('</ body>');
    frameDoc.document.write('</html>');
    frameDoc.document.close();

    setTimeout(function () {
        window.document.title = title? title : "Print.pdf"
        window.frames["frame1"].focus();
        window.frames["frame1"].print();
        frame1.remove();
    }, 500);
    return true;
}


export default PrintPDF