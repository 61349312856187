<template>
<v-card color="blue-grey darken-2 " class="white--text" hover style="border-radius: 10px">
<v-layout column>
    <v-flex xs12 md12>
        <v-card-title primary-title>  
            <div class="title"> Time Left = <span v-html="part>=0?timeLeftPart+` <small>(Overall - ${timeLeft})</small>`:timeLeft"></span></div> 
        </v-card-title>
    </v-flex>
</v-layout>
<v-divider light></v-divider>
<v-card-text>
    <question-nav-all :qpi="part"></question-nav-all>
    <v-spacer></v-spacer>
    <br>
    <v-btn v-if="!hidePreview" color="info" @click="$emit('showPreview')" block>Preview All Questions</v-btn>
    <v-btn color="blue" dark @click="$emit('showAttachments')" block>Show Attachments</v-btn>
    <v-switch dark v-model="large" @change="cache()" :label="`${large?'Large':'Normal'} Text Size`" ></v-switch>
</v-card-text>
</v-card>
</template>
<script>
import {mapGetters} from 'vuex'
import QuestionNavigatorAll from './../../components/QuestionNavigatorAll.vue'

export default {
    data() {
        return { large: localStorage.getItem('large_text')==='true' }
    },
    mounted() { this.large = this.largeText },
    methods: {
        cache(){
            localStorage.setItem('large_text', this.large)
            this.$emit('toggleSize')
        }
    },
    props: {
        qn: {
            type: Number,
            default: 1
        },
        timeLeftPartHtml: {
            type: String,
            default: () => ''
        },
        showPreview: {
            type: Function,
            default: () => {}
        },
        selectQuestion: {
            type: Function,
            default: () => {}
        },
        largeText: {
            type: Boolean,
            default: false
        },
        hidePreview: {
            type: Boolean,
            default: false
        },
        part: {
            type: Number,
            default: -1
        },
        attachmentCount: {
            type: Number,
            default: 0
        }
    },
     components: {
        'question-nav-all': QuestionNavigatorAll
    },
    filters: {
        filterPart (questions , partIndex ) {
            if(partIndex>=0) return questions.filter(q => q.pi===partIndex)
            return questions
        }
    },
    computed: {
        ...mapGetters([ 'questions', 'timeLeft', 'timeLeftPart' ])
    }
}
</script>