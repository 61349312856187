import {instance} from './../../../_axios'
import {UPLOADER} from './ImageProctor'
import PINGFIREBASE from './PINGFIREBASE.js'
import FireLog from './Firelog'
import ProctorHelper from './VideoProctor'

let GAP    = 15000
let NORMAL = 10000
const DEBUG = true
let model  = null
let canvas = document.querySelector('#localCanvas')
const canvasContext = canvas.getContext('2d')
let interval = null

export default async function(vm){
    if(vm.testConfig.PROCTOR_TYPE===ProctorHelper.PROCTOR_TYPES.VANIS) return
    if(!vm.testConfig.DETECT_MULTIFACE) return
    if(vm.testConfig.DETECT_FACE_NINTERVAL) NORMAL = vm.testConfig.DETECT_FACE_NINTERVAL
    if(vm.testConfig.DETECT_FACE_DINTERVAL) GAP    = vm.testConfig.DETECT_FACE_DINTERVAL

    model = await blazeface.load()
    // need gap intially for vani
    // console.log(`Face Detection Starting in ...${NORMAL/1000}s`)
    console.log(`Face Detection Initiating in `,NORMAL)
    interval = setInterval(() => {
      console.log(`Face Detection Started...`, NORMAL, GAP)
      predict(vm)
    }, NORMAL);
}

export const predict = async function(vm){
    // Pass in an image or video to the model. The model returns an array of
    // bounding boxes, probabilities, and landmarks, one for each detected face.
    console.log('MFP -', (new Date()).toLocaleTimeString())
    const predictions = await model.estimateFaces(canvas, false)
    const count = predictions.length
    if(vm.submitted) return clearInterval(interval)

    if(DEBUG && count!=1){
        // canvasContext.clearRect(0, 0, canvas.width, canvas.height);
        // canvasContext.draw(canvas, 0 , 0)
        canvasContext.fillStyle = "rgba(255, 0, 0, 0.5)";
        for (let i = 0; i < count; i++) {
            const start = predictions[i].topLeft;
            const end = predictions[i].bottomRight;
            const size = [end[0] - start[0], end[1] - start[1]];

            // Render a rectangle over each detected face.
            canvasContext.fillRect(start[0], start[1], size[0], size[1]);
            // const landmarks = predictions[i].landmarks;
            // canvasContext.fillStyle = "blue";
            // for (let j = 0; j < landmarks.length; j++) {
            //     const x = landmarks[j][0];
            //     const y = landmarks[j][1];
            //     canvasContext.fillRect(x, y, 5, 5);
            // }
        }
        canvasContext.font="20px Arial"
        canvasContext.fillText(`${count} faces detected`, 4, canvas.height-30)
    } 
    
    
    if (count != 1) {
        NotifyFaceCount(vm, count)
        console.info('Face count  = '+count)  // return setTimeout(() => { predict(vm) }, GAP)
    } else {
        console.info('1 Face Detected')
    }
    // setTimeout(() => { predict(vm) }, NORMAL)
}

async function upload(vm){
    return new Promise((resolve, _) => { canvas.toBlob((async blob => {  return resolve(await UPLOADER(vm, blob, 'ai')) }), 'image/webp') })
}



const NotifyFaceCount = async (vm, count) => {
    const URL = await upload(vm)
    await saveAIDetection(vm, URL)
    vm.lastImageProctor = URL
    PINGFIREBASE(vm, null, true)
    FireLog(vm, count>1?'MULTI_FACE':'NO_FACE')
}

async function saveAIDetection(vm, imagePath){
    let fd = new FormData();
    fd.append('test_id', String(vm.$route.params.id));
    fd.append('image', imagePath);
    try {
        await instance.post(`/saveAIImage/${vm.$route.params.id}/png`, fd)
        console.log('AI Snap uploaded')
    } catch (error) {
        console.log(error.message)
    }
}

export {NotifyFaceCount}