<template>
    <v-dialog v-model="showAttachments" fullscreen  style="z-index:202" scrollable>
      <v-card v-if="testData">
      <v-toolbar dark color="primary">
          <v-btn icon dark @click="$emit('hide')"> <v-icon>close</v-icon> </v-btn>
          <v-toolbar-title>Test Attachments</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items></v-toolbar-items>
      </v-toolbar>
      <v-card-text v-if="showAttachments">
          <attachments :attachments="testData.attachments || []"></attachments>
      </v-card-text>
      </v-card>
    </v-dialog>
</template>
<script>
import Attachments from './../../components/QuestionAttachments.vue'
export default {
    props: { // showAttachments = false
        testData: {
            type: Object,
            default: () => {}
        }, 
        showAttachments: {
            type: Boolean,
            default: false
        }
    },
    components: {
        'attachments': Attachments
    }
}
</script>