<template>
    <v-menu  left fixed offset-y >
        <v-btn flat :icon="$vuetify.breakpoint.xsOnly" slot="activator"> 
          <!-- Account -->
          <v-icon color="grey">account_circle</v-icon>
        </v-btn>
        <v-list>
          <v-list-tile v-if="user && user.config && user.config.ALLOW_CHANGE_PASSWORD" :to="'/password/change'">
            <v-list-tile-title v-text="'Change Password'" ></v-list-tile-title>
          </v-list-tile>
          <v-list-tile v-for="item in user_items" :key="item.title" @click="item.action?item.action():''" :to="item.to?item.to:''">
            <v-list-tile-title v-text="item.title" ></v-list-tile-title>
          </v-list-tile>
        </v-list>
    </v-menu>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    data(){
        return {
            user_items: [
                { title: 'Profile', action: null, to: '/profile'},
                { title: 'Logout',  action: this.signOut }
            ],
        }
    },
    computed: {
        ...mapGetters(['auth', 'user'])
    },
    methods: {
      ...mapActions(['clearAuthenticated', 'clearToken' ]),
      signOut(){
        this.$store.commit('open_snackbar', {color:'green' ,text: 'Signed out successfully!'})
        this.clearAuthenticated()
        this.clearToken()
        this.$router.push({path: '/'})
        setTimeout(() => window.location.reload(), 500)
      },
    }
}
</script>